const React = require('react');
const T = require('prop-types');
const { default: styled } = require('styled-components');

const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: Grid } = require('@mui/material/Grid');

const Switch = require('components/Switch');

const { default: Classes } = require('./styles.scss');

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    display:flex;
    justify-content:space-between;
    flex-direction:row-reverse;
    margin-left:0;
    margin-right:0;
  }
`;

const QuestionSwitch = (props) => {

    const {
        questionLabel,
        checkedLabel,
        uncheckedLabel,
        switchId,
        checked,
        onChange,
        disabled
    } = props;

    return (
        <StyledFormControlLabel
            control={
                <Grid component='label' container alignItems='center' classes={{
                    root:Classes.customSwitchGrid
                }}  justifyContent={'flex-end'} spacing={0}>
                    <Grid item style={{
                        color: 'rgba(0, 0, 0, .7)',
                        fontSize: '12px'
                    }}>{uncheckedLabel}</Grid>
                    <Grid item>
                        <Switch
                            checked={checked}
                            onChange={onChange}
                            className={Classes.formToggle}
                            color='primary'
                            id={switchId}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item style={{
                        color: 'rgba(0, 0, 0, .7)',
                        fontSize: '12px'
                    }}>{checkedLabel}</Grid>
                </Grid>

            }
            label={<label htmlFor={switchId}>{questionLabel}</label>}
        />
    );
};

QuestionSwitch.propTypes = {
    questionLabel: T.string,
    checkedLabel: T.string,
    uncheckedLabel: T.string,
    switchId: T.string.isRequired,
    checked: T.bool.isRequired,
    onChange: T.func,
    disabled:T.bool
};

module.exports =  QuestionSwitch;
