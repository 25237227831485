const React = require('react');


const T = require('prop-types');

const Prompt = require('react-router-dom').Prompt;
const { default:Button } = require('@mui/material/Button');
const { default:Dialog } = require('@mui/material/Dialog');
const { default:DialogActions } = require('@mui/material/DialogActions');
const { default:DialogContent } = require('@mui/material/DialogContent');
const { default:DialogContentText } = require('@mui/material/DialogContentText');
const { default:DialogTitle } = require('@mui/material/DialogTitle');



class SupportPagePreventNavigationDialog extends React.Component {

    state = {
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false
    }

    // eslint-disable-next-line hapi/hapi-scope-start
    showModal = (location) => this.setState({
        modalVisible: true,
        lastLocation: location
    })

    // eslint-disable-next-line hapi/hapi-scope-start
    closeModal = (callback) => this.setState({
        modalVisible: false
    }, callback)

    handleBlockedNavigation = (nextLocation) => {

        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
            this.showModal(nextLocation);
            return false;
        }

        return true;
    }
    // eslint-disable-next-line hapi/hapi-scope-start
    handleDiscardNavigationClick =  () => this.closeModal(() => {

        const { navigate } = this.props;
        const { lastLocation } = this.state;
        if (lastLocation) {
            this.setState({

                confirmedNavigation: true
            }, () => {
                // Navigate to the previous blocked location with your navigate function
                navigate(lastLocation.pathname,lastLocation.state);
            });
        }
    })
    // eslint-disable-next-line hapi/hapi-scope-start
    handleCancelNavigationClick = () => this.closeModal(() => {

        const { lastLocation } = this.state;
        if (lastLocation) {
            this.setState({
                confirmedNavigation: false
            });
        }
    })
    // eslint-disable-next-line hapi/hapi-scope-start
    handleSaveNavigationClick = () => this.closeModal(() => {

        const { navigate,saveFunction,pageHasErrors } = this.props;
        const { lastLocation } = this.state;
        if (lastLocation ) {
            saveFunction();

            if (!pageHasErrors){
                this.setState({
                    confirmedNavigation: true
                }, () => {
                    // Navigate to the previous blocked location with your navigate function
                    navigate(lastLocation.pathname,lastLocation.state);
                });
            }
        }
    })
    render() {

        const { when,alertMsg } = this.props;
        const { modalVisible } = this.state;

        return (
            <React.Fragment>
                <Prompt
                    when={when}
                    message={this.handleBlockedNavigation}/>
                <Dialog
                    open={modalVisible}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Unsubmitted Form'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDiscardNavigationClick} color="secondary" >
                            Leave
                        </Button>
                        <Button onClick={this.handleCancelNavigationClick} color="primary" autoFocus>
                            Stay
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

}

SupportPagePreventNavigationDialog.propTypes = {
    when: T.bool.isRequired,
    navigate: T.any,
    shouldBlockNavigation: T.any,
    alertMsg:T.string.isRequired,
    saveFunction:T.func,
    pageHasErrors:T.bool
};

module.exports = SupportPagePreventNavigationDialog;
