const React = require('react');
const PropTypes = require('prop-types');
const { default: Classes } = require('./styles.scss');
const { withTheme } = require('styled-components');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const internals = {};

class CenteredLayout extends React.PureComponent {

    static propTypes = {
        header: PropTypes.node,
        header_2: PropTypes.node,
        footer: PropTypes.node,
        children: PropTypes.node,
        brandBackground: PropTypes.bool,
        className: PropTypes.string,
        mainClass: PropTypes.string,
        footerClass: PropTypes.string,
        theme: PropTypes.object
    }

    static defaultProps = {
        brandBackground: true,
        className: '',
        mainClass: '',
        footerClass: ''
    }

    componentDidMount() {

        if (this.props.brandBackground) {
            document.body.style.backgroundColor = this.props.theme.palette.primary.main;
        }
    }

    componentWillUnmount() {

        if (this.props.brandBackground) {
            document.body.style.backgroundColor = 'unset';
        }
    }

    render() {

        const {
            children,
            header,
            header_2,
            footer,
            mainClass,
            footerClass,
            className
        } = this.props;

        return <div
            className={`${Classes.wrapper} ${className}`}
            onScroll={() => AnimatedFocusIndicator.wrapFocusIndicatorForElement()}
        >
            <div className={Classes.layoutItem} />
            <div className={`${Classes.layoutItem} ${Classes.main} ${mainClass}`}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {header && <h1 className={Classes.header}>{header}</h1>}
                    {header_2 && <h1 className={Classes.header}>{header_2}</h1>}
                    {children}
                </div>
            </div>
            <div className={`${Classes.layoutItem} ${footerClass}`}>{footer}</div>
        </div>;
    }
}

module.exports = withTheme(CenteredLayout);
