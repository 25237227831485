const T = require('prop-types');
const SearchResult = require('containers/SearchResult');
const { default: ImageList } = require('@mui/material/ImageList');
const { useMediaQuery } = require('@mui/material');

const { default: Classes } = require('./styles.scss');
const { default: styled } = require('styled-components');

const StyledImageList = styled(ImageList)(({ theme }) => ({
    overflow: 'hidden',
    [theme.breakpoints.between(theme.breakpoints.values.xs, theme.breakpoints.values.md)]: {
        justifyContent: 'center',
        width: '100%'
    }
}));

const SearchResultGrid = ({ results, showConnectionStatus, showDistance }) => {

    const mql = useMediaQuery('screen and (min-width: 600px), screen and (max-width: 415px)');
    const mqlMobile = useMediaQuery('(max-width: 600px)');

    return (
        <div className={Classes.resultWrapperGridContainer}>
            <StyledImageList
                length={results.length}
                classes={{
                    root: results.length > 1 && !mqlMobile && Classes.center
                }}
                rowHeight={200}
                cols={mqlMobile ? 1 : 2}
                className={Classes.imageList}
            >
                {results.map((result) => (

                    <SearchResult
                        key={result.id}
                        result={result}
                        numOfResults={results.length}
                        useIconButton={mql}
                        showConnectionStatus={showConnectionStatus}
                        showDistance={showDistance}
                    />
                ))}
            </StyledImageList>
        </div>
    );
};

SearchResultGrid.propTypes = {
    results: T.array,
    showConnectionStatus: T.bool,
    showDistance: T.bool
};

module.exports =  SearchResultGrid;
