const internals = {};

/*
 * Ex.
 * const processors = { mention: ([name, id]) => `@${name}` }
 * const processFullBody = exports.processFullBody(processors);
 *
 * processFullBody('Hey {{mention(["Devin", 1])}}!');       // 'Hey @Devin!'
 * processFullBody('Hey {{mention(["Devin", 1])}}!', true); // ['Hey ', '@Devin', '!']
 */
exports.processFullBody = (processors) => {

    const processPart = internals.processPart(processors);

    return (fullBody, keepSeparate) => {

        const output = fullBody.split(internals.PARTS_EXP).map(processPart);

        return keepSeparate ? output : output.join('');
    };
};

/*
 * Ex.
 * const processors = {
 *     fruit: {
 *         search: (ctx) => /banana/gi
 *         value: (ctx, match) => match[0].toLowerCase()
 *     }
 * };
 * const processEditBody = exports.processEditBody(processors);
 *
 * processEditBody('Hey BaNaNa!', ctx); // 'Hey {{fruit("b")}}!'
 */
exports.processEditBody = (processors) => (editBody, ctx = {}) => { // eslint-disable-line

    return Object.keys(processors).reduce((body, type) => {

        const processor = processors[type];
        const replacers = [].concat(processor.search(ctx) || []);

        return replacers.reduce((fullBody, replacer) => {

            return fullBody.replace(replacer, (match, ...args) => {

                const value = processor.value(ctx, match, ...args);

                if (typeof value === 'undefined') {
                    return match;
                }

                return `{{${type}(${JSON.stringify(value)})}}`;
            });
        }, body);
    }, editBody);
};

internals.processPart = (processors) => (part) => { // eslint-disable-line

    const matches = part.match(internals.ONE_PART_EXP);

    if (!matches) {
        return part;
    }

    const method = matches[1];

    if (!processors[method]) {
        return part;
    }

    let args;

    try {
        args = JSON.parse(matches[2]);
    }
    catch (ignoreErr) {
        return part;
    }

    return processors[method](args);
};

exports.fullToEditBody = exports.processFullBody({
    mention: ([name, id]) => `@${name}`
});

exports.editToFullBody = exports.processEditBody({
    mention: {
        search: ({ mentionMap }) => {

            return Object.keys(mentionMap).map(exports.makeMentionRegExp);
        },
        value: ({ mentionMap }, match, typedName) => {

            const name = Object.keys(mentionMap).find((n) => n.toLowerCase() === typedName.toLowerCase());

            if (!name) {
                return;
            }

            return [name, mentionMap[name]];
        }
    }
});

exports.makeMentionRegExp = (name) => {

    const nameExp = exports.regEscape(name);

    return new RegExp(`@(${nameExp})(?!\\w)`, 'gi');
};

exports.regEscape = (s) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

internals.PARTS_EXP = /({{.+?}})/g;
internals.ONE_PART_EXP = /^{{([a-z]+)\((.*?)\)}}$/;
