const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { context } = require('app-context');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: Typography } = require('@mui/material/Typography');
const { useDispatch } = require('react-redux');

const internals = {};

module.exports = function ChooseSignupMethod(props) {

    const {
        isSignup,
        onChange
    } = props;

    const {
        Button,
        ButtonsWrapper
    } = internals;

    const label = isSignup ? 'signup' : 'login';

    const dispatch = useDispatch();

    const update = (method) => {

        const newSignupVars = {
            usingPassword: method === 'password',
            usingSSO: method === 'sso'
        };

        dispatch(context.actions.signup.updateContext(newSignupVars));

        onChange?.(newSignupVars);
    };

    return <Dialog
        open
        aria-labelledby='alert-logout-dialog-title'
        aria-describedby='alert-logout-dialog-description'
    >
        <div tabIndex={0} data-focus-outline='radius:15'>
            <DialogTitle id='alert-logout-dialog-title'>{`Please choose a ${label} method`}</DialogTitle>
            <ButtonsWrapper>
                <Button
                    data-focus-outline='radius:15'
                    onClick={() => update('password')}
                >
                    <Typography>
                        Nearpeer Password
                    </Typography>
                </Button>
                <Button
                    data-focus-outline='radius:15'
                    onClick={() => update('sso')}
                >
                    <Typography>
                        Single Sign On (Google, etc.)
                    </Typography>
                </Button>
            </ButtonsWrapper>
        </div>
    </Dialog>;
};

module.exports.propTypes = {
    isSignup: T.bool,
    onChange: T.func
};

internals.ButtonsWrapper = Styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 0 16px 16px;
`;

internals.Button = Styled.button`
    padding: 20px;
    border: 2px solid #eee;
    margin-top: 20px;
    width: 320px;
    background: white;
    border-radius: 10px;
    cursor: pointer;
`;
