const TrimStart = require('lodash/trimStart');

module.exports = (arr) => {

    if (arr.length === 1) {
        return arr[0];
    }

    if (arr.length === 0) {
        return '';
    }

    const firsts = arr.slice(0, arr.length - 1);
    let last = arr[arr.length - 1];

    if (last) {
        last = TrimStart(last);
        last = last.replace('and ', '');
    }

    return firsts.join(', ') + ' and ' + last;
};
