const React = require('react');
const PropTypes = require('prop-types');
const { default: Classes } = require('./styles.scss');
const { NavLink: Link } = require('react-router-dom');

module.exports = class PrivacyPolicyContents extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string
    }

    render() {

        const { className } = this.props;

        return <div className={className || ''}>

            <div className={Classes.header}>
                <h2>Privacy Policy</h2>
                <h3>(Revised March 29, 2024)</h3>
            </div>

            <p>
                Nearpeer, Inc., a Delaware corporation (&ldquo;Nearpeer,&rdquo; &ldquo;us,&rdquo; &ldquo;we,&rdquo; or &ldquo;our&rdquo;) respects your privacy. We created this Privacy Policy to let you know what information we collect when you access and use our Website, or create an account via the Service, and how we use it, as well as our information sharing and protection practices. Capitalized terms used but not otherwise defined herein shall have the meaning ascribed to such terms set forth in the Nearpeer <Link to='/terms-of-service' data-focus-outline='padding:3,radius:5'>Terms of Service</Link>.
            </p>

            <p>
                This Privacy Policy applies to:
                <ul>
                    <li>
                        Your use of the Nearpeer [Service], via the Nearpeer website and associated domains and sub-domains of <Link to='/' data-focus-outline='padding:3,radius:5'>https://nearpeer.net</Link> (the &ldquo;Website&rdquo;), your mobile phone, or through Nearpeer&apos;s future applications for mobile devices.
                    </li>
                    <li>
                        Any email, text and other electronic communications you send to Nearpeer through the Service.
                    </li>
                </ul>
            </p>

            <p>
                This Privacy Policy does <u>not</u> apply to:
                <ul>
                    <li>
                        Information we collect through other websites, products or services we may offer; and
                    </li>
                    <li>
                        Information collected by third parties, which may have their own privacy policies.
                    </li>
                </ul>
            </p>


            <p>
                Please read this Privacy Policy carefully and save it. If you do not agree with it, you should not access or use the Service. By using the Service or by submitting electronic communications to us through the Service, you consent to the practices prescribed in this Privacy Policy.
            </p>

            <h3>What information does Nearpeer collect?</h3>
            <ul>
                <li>
                    <b>Personal Information.</b> When we refer to “<b>Personal Information</b>” in this Privacy Policy, we mean any information by which you may be personally identified or contacted, whether online or offline.  When you register for the Service or otherwise fill out any forms via the Service, we may collect certain Personal Information from you, such as your first and last name, home or other physical address, email address, phone number, interests, and other profile information. It is always your choice whether or not to provide Personal Information. If you choose not to provide requested Personal Information, you may not be able to use the Service, or certain features of the Service.
                </li>
                <li>
                    <b>Message Content.</b> This includes messages you send or receive within the Service or which you send to us or our agents, including messages to individual users and to groups of users.  Group of users are often created for members of each Community. Depending on the permissions set for you, you may also be able to create a new group in your Community, through which, you may send messages to one or more members of your Community. Depending on the configuration of that group, your messages to that group may be visible for all users or they may be only available to recipients in that group. If you are removed from the Community, your messages will usually remain associated with the group.
                </li>
                <li>
                    <b>Technical Information.</b> We may also collect and use technical data and related information, such as information about your device, system and application software, peripherals and other data related to your interactions with the Service (“<b>Technical Information</b>”).  We may automatically collect Technical Information through cookies, flash cookies, web beacons and log files. The information we collect from these technologies may include your IP address, device ID, browser information and the page you request. Nearpeer does not consider this information to be Personal Information.
                </li>
                <li>
                    <b>Geo-Location Information.</b> We may also collect information about your geographic location from you or your device, including your country, region, city, zip code, street address, time zone, latitude, longitude, velocity, bearing, altitude and horizontal position representation information (“<b>Geo-Location Information</b>”).
                </li>
                <li>
                    <b>Online Tracking and Do Not Track Signals.</b> We may use the Website and the Service to collect information from your web browser about your activities over time and across third-party websites, applications or other online services (“<b>Online Tracking Information</b>”). Your web browser may allow you to opt-out of our collection of Online Tracking Information by selecting a “do not track” (or similar) setting. However, we do not currently have processes to address those settings or other “do not track” requests, and consequently, cannot guarantee that we will honor such requests. If you do not want us to collect this information, do not use the Website or the Service.
                </li>
            </ul>

            <h3>Information You Voluntarily Disclose</h3>
            <p>
                Please keep in mind that whenever you voluntarily disclose Information through any public-facing features of the Service (for example, via public forums), that Information can be collected and used by others. You submit this Information at your own risk.  We are not responsible for the security or privacy of any Information you choose to submit in connection with these public features.
            </p>

            <h3>How does Nearpeer use Information?</h3>
            <p>
                Except as described in this Privacy Policy or in our Terms of Service or similar document, Personal Information, Technical Information, Geo-Location Information and Online Tracking Information (collectively, “<b>Information</b>”) that you provide or that we collect will be kept confidential and used to support your relationship with Nearpeer.
            </p>

            <p>
                We may use your Information, including your Personal Information, as follows:
                <ul>
                    <li>
                        To provide support and other services to you related to the Service.
                    </li>
                    <li>
                        To improve, market or promote Nearpeer’s products and services.
                    </li>
                    <li>
                        To alert you to product upgrades, updated information and other new products and services offered by Nearpeer or its third-party partners.
                    </li>
                    <li>
                        To respond to comments, questions or requests for customer service.
                    </li>
                    <li>
                        To display advertisements, including advertisements from third parties.
                    </li>
                    <li>
                        To display to other users viewing your profile.
                    </li>
                    <li>
                        To use in our recommendation engine for recommending peer connections based on common interest and/or profile.
                    </li>
                    <li>
                        To prepare analyses provided to other organizations, such as colleges and universities.
                    </li>
                    <li>
                        To help transition your engagement to mobile app from our initial web app.
                    </li>
                    <li>
                        For any other purpose, we may describe when you provide the Information.
                    </li>
                </ul>

                We may also use your Information for any other purpose with your consent.
            </p>

            <h3>Data and Blind Data</h3>
            <p>
                You own all right, title, and interest in and to any data (excluding Technical Information) that we collect from you via your use of and interaction with the Service (“<b>Data</b>”). We do not consider this to be Personal Information.  You also grant to us a non-exclusive, transferable, sublicensable, royalty free license to use any data we collect based on your use of the Service in order to provide, monitor and improve the Service and to compile, synthesize and analyze this Data (“<b>Blind Data</b>”), such as to generate anonymous usage statistics. If we generate Blind Data, it will be owned by us and we may use it for any lawful business purpose. Any Blind Data will not personally identify you or the source of the Data.
            </p>

            <h3>Information We Share</h3>
            <p>
                Except as described in this Privacy Policy, Nearpeer does not share, rent or sell your Personal Information collected through the Service with third parties.
            </p>
            <p>
                Nearpeer or its agents, vendors or contractors may disclose Information, including your Personal Information, to third parties in the following circumstances:
                <ul>
                    <li>
                        To our agents, vendors or contractors that we use to support the internal operations of our business who are required to keep the Information confidential and use it only for the purposes for which we disclose it to them.
                    </li>
                    <li>
                        If required to do so by law, comply with legal process or to comply with a governmental or regulatory request;
                    </li>
                    <li>
                        To protect and defend the rights or property of Nearpeer or its agents or contractors;
                    </li>
                    <li>
                        To enforce our Terms of Service or other Nearpeer agreements;
                    </li>
                    <li>
                        To act in urgent circumstances to protect the personal safety of users of the Service or the public; and
                    </li>
                    <li>
                        As part of a transaction or potential transaction where Nearpeer merges with another organization, files for bankruptcy, or sells its assets or capital stock.
                    </li>
                </ul>
            </p>

            <h3>Security of Your Information</h3>
            <p>
                We maintain physical, electronic, and procedural safeguards designed to protect the Information. These safeguards include storing your Information on our secure servers behind firewalls and using encryption technology. Despite the actions and precautions we take, no data transmissions over the Internet can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of the Information and you acknowledge and agree that you transmit it to us at your own risk.
            </p>

            <h3>Third-Party Websites, Services and Technologies</h3>
            <p>
                We may provide links to third-party websites through the Service or in other communications to you. These links may appear as a specific domain name or URL or may be activated by clicking on an advertisement or other icon or graphic. Please be aware that other websites and services, including the websites of third parties that you connect with through the Service, may collect personally identifiable information about you. We cannot control and are not responsible for the information collection practices of any third-party websites, services or applications. We encourage you to carefully review their terms of use, privacy policies, and any other legal notifications before using or providing information through such websites, services or applications.
            </p>
            <p>
                Further, the Service may employ third party technologies that require you to accept such third party’s terms. This Privacy Policy does not cover the information practices of those third-party technologies.
            </p>

            <h3>International Transfers</h3>
            <p>
                Information collected from you may be stored and processed in the United States or any other country in which Nearpeer or its affiliates, subsidiaries, agents or contractors maintain facilities. If you are accessing the Service from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your data to the United States and processing globally.  By providing your Information, you consent to any transfer and processing in accordance with this Privacy Policy.
            </p>

            <h3>Children’s Privacy</h3>
            <p>
                The Website are not intended for children under the age of 13.  We do not knowingly collect Information from children under 13. If you believe that we may have collected Information from a child under the age of 13, please contact us at privacy@nearpeer.net.
            </p>

            <h3>Information Choices and Changes</h3>
            <p>
                We may need to communicate with you about the Service, and we would like to make certain commercial offers available to you from time to time. As such, by creating an account on the Service or signing up for a newsletter that we may offer, you consent to receive commercial messages (whether by phone, email, text or push notifications) from us or our third-party partners, and acknowledge and agree that your primary phone numbers and email addresses and other information may be used for the purpose of initiating commercial messages.  We will allow you to opt-out of receiving commercial messages, but in order to stop receiving any messages from us whatsoever (including administrative messages regarding the Service), you will need to terminate your account.
            </p>
            <p>
                You may update or correct your profile information and preferences at any time by accessing your account settings pages from within the Nearpeer application. You may permanently close your account, and optionally request that all of your data be permanently deleted from our systems, from within the Nearpeer application via the “Support” section. Alternatively, you can contact us directly at “administrator@nearpeer.net” to request that your account be closed and/or that your data is permanently deleted from our systems.
            </p>
            <p>
                Depending on your browser, you may be able to remove and reject cookies from our Website by changing your browser settings. The default setting of many browsers is set to accept cookies until you change your settings. Please note that if you disable or refuse cookies, certain features of the Service may become inaccessible or may not function properly.
            </p>

            <h3>Notice to California Residents:</h3>
            <p>
                If you are a California resident, you may have certain additional rights. California Civil Code Section 1798.83 permits you to request information regarding the disclosure of your personal information by Nearpeer to third parties for the third parties’ direct marketing purposes. To make such a request, please send an email to privacy@nearpeer.net or write us at: Nearpeer, Inc., 63 Federal St, Portland, ME 04101
            </p>

            <h3>Notice to Athabasca Students:</h3>
            <p>
                The personal information collected as you use Nearpeer is to enable you to engage with other learners and take advantage of the intended benefits of the platform, to allow Athabasca University to administer, assess, and review the effectiveness and suitability of Nearpeer at AU, and to enhance learner engagement with Athabasca University. This personal information is being collected under the authority of Section 33(c) of {'Alberta\'s'} Freedom of Information and Protection of Privacy Act.
            </p>
            <p>
                Nearpeer is a University-sponsored activity and falls under Athabasca {'University\'s'} code of conduct. If you have any questions about the collection and use of this information, please contact Richard MacLeod, Director of Strategic Enrollment Management at rmacleod@athabascau.ca.
            </p>

            <h3>Changes to this Privacy Policy</h3>
            <p>
                This is our entire Privacy Policy and it replaces any earlier version. We may change this Privacy Policy by posting a new version on the Service or Website, and it is your responsibility to review this Privacy Policy periodically. If we make material changes to our practices regarding the collection, use and sharing of your Personal Information, we will notify you via e-mail to the email address you provide to us and/or through a notice on the Service.
            </p>
            <p>
                When we do change the Privacy Policy, we will also revise the “revised” date. Your continued use of the Service after such date constitutes your agreement to this Privacy Policy and any updates.
            </p>

            <h3>Contacting Nearpeer</h3>
            <p>
                For questions or comments regarding this Privacy Policy, please contact us at 63 Federal St, Portland, ME 04101, 207-615-0414 and privacy@nearpeer.net
            </p>
        </div>;
    }
};
