const React = require('react');
const T = require('prop-types');
const { default: Paper } = require('@mui/material/Paper');
const { default: FlatButton } = require('@mui/material/Button');

const { default: Classes } = require('./styles.scss');
const Colors = require('styles/colors.json');
const Fonts = require('styles/fonts.json');
const { ELEMENT_IDS } = require('utils/constants');

const internals = {};
internals.height = 72;

class BottomButton extends React.PureComponent {

    static propTypes = {
        label: T.string,
        onClick: T.func,
        bottomButtonContent: T.any,
        className: T.string,
        classes: T.object,
        hide: T.bool
    }

    static height = internals.height

    render() {

        const {
            label,
            onClick,
            className,
            bottomButtonContent,
            hide,
            ...rest
        } = this.props;

        const { styles } = internals;

        return <div className={`${Classes.bottomButtonWrapper} ${className ? className : ''}`}>
            <Paper
                id={ELEMENT_IDS.bottomButton}
                className={`${Classes.paper} contentWrapper`}
                elevation={0}
                style={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }}
            >
                {bottomButtonContent &&
                    <div className={`${Classes.button} ${Classes.innerWrapper}`}>
                        {bottomButtonContent}
                    </div>
                }
                {!bottomButtonContent && !hide &&
                    <div className={Classes.button}>
                        <FlatButton
                            color={'primary'}
                            onClick={onClick}
                            fullWidth
                            {...rest}
                            style={{ ...styles.button, ...styles.buttonLabel }}
                            data-focus-outline='radius:40,padding:4'
                        >{label}</FlatButton>
                    </div>
                }
            </Paper>
        </div>;
    }
}

module.exports = BottomButton;

internals.styles = {
    button: {
        backgroundColor: Colors.lightText,
        height: '2.25em'
    },
    buttonLabel: {
        fontFamily: Fonts.headerFont,
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '1.25em',
        letterSpacing: 1,
        lineHeight: '2.5em'
    }
};
