const React = require('react');
const T = require('prop-types');
const QuestionSwitch = require('../QuestionSwitch');

const { default: SelectField } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');

const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: FormControl } = require('@mui/material/FormControl');

const FuzzyFilter = require('utils/fuzzyFilter');

const GooglePlaceAutocomplete = require('components/GooglePlaceAutocomplete');

const { default: Classes } = require('./styles.scss');

const { createRef } = React;

const internals = {};

module.exports = class HousingPicker extends React.PureComponent {

    static propTypes = {
        className: T.string,
        value: T.shape({
            id: T.string,
            placeId: T.string,
            name: T.string,
            onCampus: T.bool
        }),
        searchText: T.string,
        onTextChange: T.func.isRequired,
        onChange: T.func.isRequired,
        onCampusHousingList: T.array,
        offCampusHousingList: T.array,
        showSwitch: T.bool,
        offCampusLabel: T.string
    };

    static defaultProps = {
        showSwitch: true,
        offCampusLabel: 'Off-campus location:'
    }

    constructor(props) {

        super(props);

        this.state = {
            customPlace: this.getHousing().name
        };

        this.campusHousingTextfield = createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.value?.name !== this.props.value?.name) {
            this.setState({ customPlace: nextProps.value.name });
        }
    }

    getHousing() {

        return this.props.value || {
            id: null,
            placeId: null,
            name: null,
            onCampus: false
        };
    }

    handleClearField = () => {

        this.props.onChange({
            ...this.getHousing(),
            id: null,
            placeId: null,
            name: null
        });

        this.setState({
            housingIdErrorShow: false
        });
    }

    validate = () => {

        const housing = this.getHousing();

        if (!housing.name && !!this.props.searchText
        ) {
            return 'Please choose an item in the list';
        }

        return null;
    }

    error = (field) => {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }

        return this.validate(field);
    }

    validateAndMaybeShowErr = () => {

        if (this.validate()) {
            this.setState({
                housingIdErrorShow: true
            });
        }
    }

    maybeHideErrorIfShown = (field, show) => {

        return () => {

            show = (typeof show === 'undefined') ? this.validate() : show;

            if (!show) {
                return this.setState({ [`${field}ErrorShow`]: show });
            }
        };
    }

    onToggleCampusHousing = (ev, isChecked) => {

        this.props.onChange({
            onCampus: isChecked,
            id: null,
            placeId: null,
            name: null
        });
    }

    setOnCampusHousing = (event) => {

        const value = event.target.value;

        if (value === '') {
            this.setState({
                customPlace: value,
                housingIdErrorShow: false
            });

            return this.props.onChange({
                ...this.getHousing(),
                id: null,
                placeId: null,
                name: null
            });
        }

        this.setState({
            customPlace: value,
            housingIdErrorShow: false
        });

        const selectedOnCampusHousing = this.props.onCampusHousingList.slice()
        .find(({ name }) => name === value);

        return this.props.onChange({
            ...this.getHousing(),
            id: selectedOnCampusHousing.id,
            placeId: selectedOnCampusHousing.placeId,
            name: selectedOnCampusHousing.name
        });
    }

    setOffCampusHousing = (data) => {

        // We only want to save IDs for our custom locations.
        // data.reference is unique to google places, so if it exists, set id to null

        this.props.onChange({
            ...this.getHousing(),
            name: data.description,
            id: data.reference ? null : data.id,
            placeId: data.place_id
        });

        // Want to run this on the next tick because this func
        // fires before onBlur
        setTimeout(() => {

            this.setState({
                housingIdErrorShow: false
            });
        }, 0);
    }

    normalizeForGooglePlace = (dataSource) => {

        // Rename our custom housing list keys to match the structure GooglePlaceAutocomplete expects
        // This could be refactored to be more general and use a custom dataSourceConfig,
        // but for our purposes, let's just change it statically

        const normalizedDataSource = dataSource.map((housingItem) => {

            const { name, placeId, ...otherHousing } = housingItem;

            return {
                ...otherHousing,
                description: name,
                place_id: placeId
            };
        });

        return normalizedDataSource;
    }

    renderAutocomplete = () => {

        const {
            searchText,
            onTextChange,
            showSwitch,
            offCampusLabel
        } = this.props;

        const { styles } = internals;

        const { name } = this.getHousing(); // Uses 'value' prop

        return (
            <GooglePlaceAutocomplete
                style={{ width: '100%', position: 'relative' }}
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes:{
                        root: Classes.textFieldInputLabelRoot
                    }
                }}
                inputRef={this.campusHousingTextfield}
                className={Classes.fullWidthOnMobile}
                placeholder='Type to search...'
                hintStyle={styles.hintText}
                ariaLabel={showSwitch ? null : offCampusLabel}
                label={showSwitch ? null : offCampusLabel}
                onBlur={this.maybeHideErrorIfShown('housingId')}
                onFocus={this.maybeHideErrorIfShown('housingId', false)}
                error={this.state.housingIdErrorShow}
                helperText={this.error('housingId')}
                onChange={this.setOffCampusHousing}
                onTextChange={onTextChange}
                searchText={searchText}
                value={name}
                handleClearField={this.handleClearField}
                openOnFocus={false}
                filter={FuzzyFilter}
                staticDataSource={
                    this.props.offCampusHousingList
                    && this.normalizeForGooglePlace(this.props.offCampusHousingList)
                }
                types={[
                    'locality',
                    'sublocality',
                    'neighborhood',
                    'administrative_area_level_3',
                    'colloquial_area'
                ]}
                id='campus-location-input'
            />
        );
    }

    render() {

        const {
            className,
            showSwitch,
            offCampusLabel
        } = this.props;

        const { capitalize } = internals;
        const { customPlace } = this.state;
        const { onCampus } = this.getHousing(); // Uses 'value' prop

        let searchLabel;

        if (onCampus) {
            searchLabel = 'select from on-campus housing communities';
        }
        else {
            searchLabel = 'search off-campus housing communities or enter custom location';
        }

        if (showSwitch) {
            return <div className={`${className} housingPicker`}>
                <QuestionSwitch
                    questionLabel={`Do you live on campus?`}
                    uncheckedLabel={'No/Skip'}
                    checkedLabel={'Yes'}
                    checked={onCampus}
                    onChange={this.onToggleCampusHousing}
                    switchId={'campus-toggle'}
                />

                <div className={Classes.offCampusHousingContainer}>
                    <div className={Classes.toggleTextGroup}>
                        <label
                            htmlFor='campus-location-input'
                            id='campus-location-input-label'
                            aria-label={searchLabel}
                            className={Classes.toggleTextGroupSpecifyLabel}
                        >
                            {onCampus && 'On-campus location:'}
                            {!onCampus && offCampusLabel}
                        </label>
                        {!onCampus && this.renderAutocomplete()}
                        {onCampus && <FormControl
                            variant={'standard'}
                            classes={{ root: Classes.selectFormControl }}
                            aria-labelledby='campus-location-input-label'
                        >
                            <SelectField
                                id={'onCampus-housing-select-input'}
                                fullWidth
                                value={customPlace}
                                defaultValue={customPlace}
                                classes={{
                                    root:Classes.selectRoot
                                }}
                                MenuProps={{
                                    className: 'role-dropdown'
                                }}
                                displayEmpty
                                onChange={this.setOnCampusHousing}
                                maxHeight={220}
                            >
                                <MenuItem
                                    key='clear-selection'
                                    value=''
                                    disabled
                                >
                                    <ListItemText
                                        primary={capitalize('Select…')}
                                    />
                                </MenuItem>
                                {this.props.onCampusHousingList.slice().map((onCampusLocation) => {

                                    return <MenuItem
                                        key={onCampusLocation.name}
                                        value={onCampusLocation.name}
                                    >
                                        <ListItemText primary={capitalize(onCampusLocation.name)} />
                                    </MenuItem>;
                                })}
                            </SelectField>
                        </FormControl>}
                    </div>
                </div>
            </div>;
        }

        return this.renderAutocomplete();
    }
};

internals.styles = {
    errorHalf: {
        position: 'absolute',
        bottom: '-.75em',
        right: 0,
        whiteSpace: 'nowrap'
    },
    autocompleteMenu: {
        maxHeight: 220
    },
    hintText: {
        fontSize: 14,
        whiteSpace: 'nowrap'
    }
};
internals.capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
