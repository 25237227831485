
exports.majors = [
    'Accelerated Graduate Pathway - Economics to Policy, Planning, and Management (MPPM)',
    'Accelerated Graduate Pathway - Engineering to MBA',
    'Accelerated Graduate Pathway - Engineering to Masters in Business Administration (MBA)',
    'Accelerated Graduate Pathway - Environmental Planning and Policy to MPPM',
    'Accelerated Graduate Pathway - Geography-Anthropology to Policy, Planning, and Management (MPPM)',
    'Accelerated Graduate Pathway - Technology Management to MBA',
    'Accelerated Graduate Pathway Leading to Initial Certification in Special Education',
    'Accelerated Graduate Pathway to Leadership Studies',
    'Accelerated Graduate Pathway to MBA',
    'Accelerated Graduate Pathway to Occupational Therapy',
    'Accelerated Graduate Pathway to Statistics',
    'Accelerated Pathway to M.S.Ed. in Teaching and Learning (ETEP)',
    'Accounting',
    'Accounting - General',
    'Accounting Major (BS)',
    'Actuarial Science',
    'Aerospace Engineering',
    'Animal and Veterinary Sciences',
    'Anthropology',
    'Apparel Design and Merchandising',
    'Apparel/Textile Design',
    'Applied Technical Leadership (BS)',
    'Architectural & Engineering Design',
    'Architectural Drafting & Design Certificate',
    'Architecture',
    'Art Education',
    'Art History',
    'Art and Visual Culture',
    'Art with a Concentration in Art Education (BFA)',
    'Art with a Concentration in Art History (BA)',
    'Art with a Concentration in Art and Entrepreneurial Studies (BA)',
    'Arts Management',
    'Astronomy',
    'Athletic Training',
    'Athletic Training (BS)',
    'Aviation/Aeronautics',
    'Bachelor of University Studies, CLAS pathway',
    'Baking & Pastry Certificate',
    'Behavioral Health Studies',
    'Biochemistry',
    'Biochemistry (BS)',
    'Bioengineering',
    'Biology',
    'Biology - Secondary Teacher Education (BS)',
    'Biology: Biotechnology Concentration (BS)',
    'Biology: General Biology (BS)',
    'Biology: Human Biology Concentration (BS)',
    'Biomedical Engineering',
    'Botany',
    'Building Sciences',
    'Business - General',
    'Business Administration',
    'Business Administration - Finance Option',
    'Business Administration in Accounting',
    'Business Administration in Finance',
    'Business Administration in Management',
    'Business Administration in Marketing',
    'Career Studies',
    'Career and Technical Education',
    'Chemical Engineering',
    'Chemistry',
    'Chemistry (BA)',
    'Chemistry (BS)',
    'Chemistry - Secondary Teacher Education (BA)',
    'Child Development',
    'Child Development and Family Relations',
    'Civil Engineering',
    'Commercial Photography',
    'Communication',
    'Communication (BA)',
    'Communication Sciences and Disorders',
    'Communication Studies',
    'Communications',
    'Community and Regional Planning',
    'Composition (BM)',
    'Computer Engineering',
    'Computer Information Systems',
    'Computer Science',
    'Computer Science (BS)',
    'Construction Engineering Technology',
    'Construction Management',
    'Criminal Justice',
    'Criminology (BA)',
    'Culinary Arts',
    'Culinary Arts - Baking & Pastry Option',
    'Cyber Security (BS)',
    'Dance',
    'Dance Studies',
    'Digital Media',
    'Digital Media - Animation Option',
    'Digital Media - Graphic Design Option',
    'Early Childhood Education',
    'Earth Sciences',
    'Ecology and Environmental Sciences',
    'Economics',
    'Economics (BA)',
    'Economics (BS)',
    'Education',
    'Electrical Engineering',
    'Electrical Engineering (BS)',
    'Electrical Engineering Technology',
    'Elementary Education',
    'Emergency Management',
    'Energy Science',
    'Engineering',
    'Engineering (Transfer Program)',
    'Engineering Physics',
    'English',
    'English (BA)',
    'English - Elementary Teacher Education (BA)',
    'English - Secondary Teacher Education (BA)',
    'English/Writing',
    'Environmental Horticulture',
    'Environmental Planning and Policy (BA)',
    'Environmental Science',
    'Environmental Science (BS)',
    'Environmental Science - Secondary Teacher Education (BS)',
    'Equine Science/Mgmt',
    'Exercise Sci/Kinesiology',
    'Exercise Science (BS)',
    'Family & Child Science',
    'Fermentation Science',
    'Film/Broadcast',
    'Finance & Economics',
    'Finance Major (BS)',
    'Finance and Banking',
    'Financial Economics',
    'Fine/Studio Art',
    'Fisheries and Wildlife',
    'Food Science',
    'Food Science and Human Nutrition',
    'Food Service Specialist Certificate',
    'Forest Management',
    'Forest Operations, Bioproducts, and Bioenergy',
    'Forestry',
    'French',
    'Gender, Women’s and Sexuality Studies',
    'General Management Major (BS)',
    'General Management Major with Entrepreneurship and Small Business Track (BS)',
    'General Management Major with General Management Track (BS)',
    'General Management Major with International Business Track (BS)',
    'General Management Major with Risk Management and Insurance Track (BS)',
    'General Management Major with Sustainable Business Track (BS)',
    'General Studies',
    'Geography',
    'Geography-Anthropology - Elementary Teacher Education (BA)',
    'Geography-Anthropology - Secondary Teacher Education (BA)',
    'Geography-Anthropology, Specialization in Applied GIS and Geospatial Analysis (BA)',
    'Geography-Anthropology, Specialization in Cultural and Natural Heritage Management (BA)',
    'Geography-Anthropology, Specialization in Sustainable Cultures and Communities (BA)',
    'Geology',
    'German',
    'Gerontology',
    'Gerontology Certificate',
    'Global Studies',
    'Graphic Arts and Imaging Technology',
    'Graphic Design',
    'Health Sciences (BS)',
    'Health Studies',
    'Health and Physical Education, K-12',
    'Healthcare Management',
    'Help Desk & User Support Certificate',
    'History',
    'History (BA)',
    'History - History/Social Sciences Elementary Teacher Education (BA)',
    'History - History/Social Studies Secondary Teacher Education (BA)',
    'Hospitality & Tourism Management',
    'Hospitality Management',
    'Human Dimensions of Climate Change',
    'Human Resources Mgmt',
    'Imaging Science',
    'Industrial Design',
    'Industrial Engineering',
    'Industrial Technology',
    'Information Security Advanced Certificate',
    'Information Systems (MIS)',
    'Information Technology',
    'Information Technology (BS)',
    'Insurance & Risk Mgmt',
    'Interdisciplinary Studies',
    'Interior Design',
    'International Affairs',
    'International Business',
    'Journalism',
    'Kinesiology and Physical Education',
    'Landscape Architecture',
    'Language Studies',
    'Languages, Literatures and Cultures',
    'Leadership and Organizational Studies (BS)',
    'Leadership and Organizational Studies - Integrative Professional Studies (BS)',
    'Liberal Studies',
    'Liberal Studies - American Studies Concentration',
    'Liberal Studies-Humanities (BA)',
    'Liberal Studies-Humanities - Elementary Teacher Education (BA)',
    'Linguistics (BA)',
    'Linguistics - ASL Linguistics Concentration (BA)',
    'Linguistics - ASL/English Interpreting Concentration (BA)',
    'Linguistics - Speech and Language Science Concentration (BA)',
    'Management',
    'Marine Science',
    'Marketing',
    'Marketing Major (BS)',
    'Mass Communication',
    'Master of Business Administration (MBA)',
    'Materials Science',
    'Mathematics',
    'Mathematics (BA)',
    'Mathematics - Applied Mathematics/Operations Research Concentration (BA)',
    'Mathematics - Pure Mathematics Concentration (BA)',
    'Mathematics - Secondary Teacher Education (BA)',
    'Mathematics - Statistics Concentration (BA)',
    'Mechanical Drafting & Design Certificate',
    'Mechanical Engineering',
    'Mechanical Engineering (BS)',
    'Mechanical Engineering Technology',
    'Media Studies (BA)',
    'Medical Assisting',
    'Medical Laboratory Sciences',
    'Microbiology',
    'Middle Grades Education',
    'Modern Languages',
    'Molecular and Cellular Biology',
    'Music',
    'Music (BA)',
    'Music Education',
    'Music Education (BM)',
    'Music Industry Studies',
    'Music Performance',
    'Music Performance (BM)',
    'Music Therapy',
    'National Parks Management',
    'Natural and Applied Sciences (BA)',
    'Natural and Applied Sciences - Elementary Teacher Education (BA)',
    'Natural and Applied Sciences - Secondary Teacher Education (BA)',
    'Networked Systems Technology Certificate',
    'New Media',
    'Non-Profit Management',
    'Nursing',
    'Nursing (BS)',
    'Nursing (RN/BSN)',
    'Nursing - Accelerated Program (BS)',
    'Nursing - RN to Bachelor of Science Degree (BS)',
    'Nutrition and Foods',
    'Organic/Urban Farming',
    'Other',
    'Parks, Recreation and Tourism',
    'Peace/Conflict Studies',
    'Performance - Jazz Studies (BM)',
    'Performance - Musical Theatre (BM)',
    'Performance - Piano Pedagogy (BM)',
    'Pharmacy',
    'Philosophy',
    'Philosophy (BA)',
    'Physicians Assistant',
    'Physics',
    'Physics (BA)',
    'Physics - Secondary Teacher Education (BA)',
    'Political Science',
    'Political Science (BA)',
    'Pre - Dental',
    'Pre - Medical',
    'Pre - Veterinary Medicine',
    'Pre-pharmacy: two-year, non-degree (Transfer Program)',
    'Precision Machining Operations Certificate',
    'Precision Machining Technology',
    'Psychology',
    'Psychology (BA)',
    'Public Health',
    'Public Health Administration',
    'Pulp and Paper Technology',
    'Recreation Management',
    'Recreation and Leisure Studies - Therapeutic Recreation Concentration (BS)',
    'Religious Studies',
    'Risk Management and Insurance',
    'Romance Languages',
    'Secondary Education',
    'Self-Designed Major - Elementary Teacher Education (BA)',
    'Social Science',
    'Social Work',
    'Social Work (BA)',
    'Social and Behavioral Sciences (BA)',
    'Sociology',
    'Sociology (BA)',
    'Spanish',
    'Special Education',
    'Sport Management',
    'Sport Management Major (BS)',
    'Sports Turf/Golf Mgmt',
    'Studio Art',
    'Studio Art (BA)',
    'Studio Arts (BFA)',
    'Supply Chain Mgmt (Logistics)',
    'Survey Engineering Technology',
    'Sustainable Agriculture',
    'Sustainable Development',
    'Technology Management (BS)',
    'Theatre',
    'Theatre (BA)',
    'Theatre Arts',
    'Tourism and Hospitality (BA)',
    'Trade & Technical Occupations',
    'Undecided',
    'University Studies',
    'Urban Planning',
    'Veterinary Technology',
    'Video Game Design',
    'Web Design/Digital Media',
    'Web Development Certificate',
    'Wildlife Ecology',
    'Women and Gender Studies (BA)',
    'Women\'s, Gender, and Sexuality Studies',
    'Women/Gender Studies',
    'Zoology'
];

exports.careers = [
    'Accountant',
    'Actuary',
    'Advertising Managers and Promotions Managers',
    'Advertising Sales Agent',
    'Aircraft Mechanic',
    'Airline Pilot',
    'Airport Security Screener',
    'Airline Reservations Agent',
    'Air Traffic Controller',
    'Architect',
    'Auto Mechanic',
    'Bank Teller',
    'Bartender',
    'Biological Technician',
    'Biomedical Engineer',
    'Bookkeeping, Accounting, and Auditing Clerks',
    'Brick Mason',
    'Budget Analyst',
    'Cardiovascular Technologist',
    'Chef',
    'Chief Executive Officer (CEO)',
    'Chemical Technician',
    'Childcare Worker',
    'Chiropractor',
    'Claims Adjuster, Appraiser, Examiner, and Investigator',
    'Compensation and Benefits Manager',
    'Computer Programmer',
    'Computer Systems Analyst',
    'Construction Laborer',
    'Consultant',
    'Cook',
    'Correctional Officer',
    'Court Reporter',
    'Curator',
    'Customer Service Representative',
    'Database Administrator',
    'Dental Hygienist',
    'Dentist',
    'Derrick Operator',
    'Diagnostic Medical Sonographer',
    'Director',
    'Dietitian/Nutritionist',
    'Doctor',
    'Editor',
    'Electrician',
    'EMTs and Paramedics',
    'English as a Second Language (ESL) Teacher',
    'Epidemiologist',
    'Event/Meeting Planner',
    'Fashion Designer',
    'Financial Advisor',
    'Financial Manager',
    'Financial Services Sales',
    'Firefighter',
    'Fitness Trainer',
    'Flight Attendant',
    'Funeral Director',
    'Fundraiser',
    'Judge',
    'Glazier',
    'Grant Writer',
    'Graphic Designer',
    'Guidance Counselor',
    'Hairdressers, Hairstylists, and Cosmetologists',
    'Health Educator',
    'Human Resources Assistant',
    'Human Resources Manager',
    'Home Health Aide',
    'Industrial Designer',
    'Industrial Production Manager',
    'Insurance Underwriter',
    'Interior Designer',
    'Interpreter and Translator',
    'Lawyer',
    'Librarian',
    'Library Assistant/Technician',
    'Licensed Practical Nurse',
    'Loan Officer',
    'Lodging Manager',
    'Manicurist',
    'Manufacturing Sales Representative',
    'Market Research Analyst',
    'Marriage and Family Therapist',
    'Massage Therapist',
    'Mechanical Engineer',
    'Medical Assistant',
    'Medical Laboratory Technician',
    'Model',
    'Nurse Practitioner',
    'Nursing Assistant',
    'Occupational Therapist',
    'Occupational Therapy Assistant',
    'Painter',
    'Paralegal and Legal Assistant',
    'Personal Trainer',
    'Pharmacist',
    'Pharmacy Technician',
    'Physician Assistant',
    'Photographer',
    'Physical Therapist',
    'Physical Therapy Assistant',
    'Plumber',
    'Police Officer',
    'Postal Service Worker',
    'Producer',
    'Psychiatric Aide',
    'Public Relations Specialist',
    'Purchasing Manager',
    'Receptionist',
    'Registered Nurse',
    'Retail Supervisor',
    'Roofer',
    'Secretary / Administrative Assistant',
    'Security Guard',
    'Social Media Manager',
    'Social Worker',
    'Software Developer',
    'Special Education Teacher',
    'Subway Operator',
    'Taxi Driver',
    'Teacher',
    'Teacher Assistant',
    'Technical Writer',
    'Training and Development Manager',
    'Veterinarian',
    'Web Developer',
    'Writer'
];

exports.states = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
];
