const React = require('react');
const T = require('prop-types');

const { Prompt } = require('react-router-dom');
const { default: Button } = require('@mui/material/Button');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogTitle } = require('@mui/material/DialogTitle');

const internals = {};

class PreventNavigationDialog extends React.Component {

    state = {
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false
    }

    // eslint-disable-next-line hapi/hapi-scope-start
    showModal = (location) => {

        this.setState({
            modalVisible: true,
            lastLocation: location
        });
    }

    getDialogRef = (node) => {

        if (node && !internals.initFocus) {
            internals.initFocus = true;
            node.focus();
        }
    }

    // eslint-disable-next-line hapi/hapi-scope-start
    closeModal = (callback) => this.setState({
        modalVisible: false
    }, callback)

    handleBlockedNavigation = (nextLocation) => {

        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
            this.showModal(nextLocation);
            return false;
        }

        return true;
    }
    // eslint-disable-next-line hapi/hapi-scope-start
    handleDiscardNavigationClick = () => this.closeModal(() => {

        const { navigate } = this.props;
        const { lastLocation } = this.state;
        if (lastLocation) {
            this.setState({

                confirmedNavigation: true
            }, () => {
                // Navigate to the previous blocked location with your navigate function
                navigate(lastLocation.pathname,lastLocation.state);
            });
        }
    })
    // eslint-disable-next-line hapi/hapi-scope-start
    handleCancelNavigationClick = () => this.closeModal(() => {

        const { lastLocation } = this.state;
        if (lastLocation) {
            this.setState({
                confirmedNavigation: false
            });
        }
    })
    // eslint-disable-next-line hapi/hapi-scope-start
    handleSaveNavigationClick = () => this.closeModal(() => {

        const { navigate, saveFunction, pageHasErrors } = this.props;
        const { lastLocation } = this.state;
        if (lastLocation ) {
            saveFunction();

            if (!pageHasErrors){
                this.setState({
                    confirmedNavigation: true
                }, () => {
                    // Navigate to the previous blocked location with your navigate function

                    navigate(lastLocation.pathname,lastLocation.state);
                });
            }
        }
    })

    escListener = (evt) => {

        if (evt.key === 'Escape') {
            this.setState({ modalVisible: false });
        }
    };

    render() {

        const { when, alertMsg } = this.props;
        const { modalVisible } = this.state;

        if (!modalVisible) {
            internals.initFocus = false;
        }

        return (
            <React.Fragment>
                <Prompt
                    when={when}
                    message={this.handleBlockedNavigation}
                />
                <Dialog
                    open={modalVisible}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <div
                        tabIndex={0}
                        ref={this.getDialogRef}
                        data-focus-outline='radius:15'
                        onKeyDown={this.escListener}
                    >
                        <DialogTitle id='alert-dialog-title'>Unsaved changes</DialogTitle>
                        <DialogContent>
                            <DialogContentText id='alert-dialog-description' color='secondary'>
                                {alertMsg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleCancelNavigationClick}
                                color='inherit'
                                data-focus-outline='radius:40'
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.handleDiscardNavigationClick}
                                color='secondary'
                                data-focus-outline='radius:40'
                            >
                                Discard
                            </Button>
                            <Button
                                onClick={this.handleSaveNavigationClick}
                                color='primary'
                                data-focus-outline='radius:40'
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

PreventNavigationDialog.propTypes = {
    when: T.bool.isRequired,
    navigate: T.any,
    shouldBlockNavigation: T.any,
    alertMsg:T.string.isRequired,
    saveFunction:T.func,
    pageHasErrors:T.bool
};

module.exports = PreventNavigationDialog;
