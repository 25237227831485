const IsArray = require('lodash/isArray');
const IsUndefined = require('lodash/isUndefined');
const IsNull = require('lodash/isNull');
const Identity = require('lodash/identity');
const Constant = require('lodash/constant');

const transform = ({ source, filter = [], sort, map = Identity }) => {

    if (IsUndefined(source) || IsNull(source)) {
        return null;
    }

    // Make filter from list of filters, combining with "and"
    filter = [].concat(filter).reduce((g, f) => (...args) => f(...args) && g(...args), Constant(true)); // eslint-disable-line

    if (IsArray(source)) {
        if (sort) {
            return source.filter(filter).sort(sort).map(map);
        }

        return source.filter(filter).map(map);
    }

    return filter(source) ? map(source) : null;
};

module.exports = { transform };
