const React = require('react');
const T = require('prop-types');

const { default: RadioGroup } = require('@mui/material/RadioGroup');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: FormLabel } = require('@mui/material/FormLabel');

module.exports = class RadioButtonGroup extends React.Component {

    static propTypes = {
        // Just calling out that this is important but not mentioned
        // children: T.node,
        label: T.string.isRequired,
        labelId: T.string.isRequired,
        labelProps: T.string,
        valueSelected: T.any.isRequired,
        required: T.bool
    }

    render() {

        const {
            label,
            labelProps,
            labelId,
            valueSelected,
            required,
            ...rest
        } = this.props;

        return (
            <FormControl required={required}>
                <FormLabel id={labelId} {...labelProps}>{label}</FormLabel>
                <div role='radiogroup' aria-labelledby={labelId}>
                    <RadioGroup
                        value={valueSelected}
                        aria-label={labelId}
                        {...rest}
                    />
                </div>
            </FormControl>
        );
    }
};
