const React = require('react');
const PlainLayout = require('layouts/PlainLayout');
const AppBarLayout = require('layouts/AppBarLayout');
const PrivacyPolicyContents = require('components/PrivacyPolicyContents');

module.exports = class PrivacyPolicy extends React.PureComponent {

    render() {

        return (
            <PlainLayout>
                <AppBarLayout>
                    <PrivacyPolicyContents />
                </AppBarLayout>
            </PlainLayout>
        );
    }
};
