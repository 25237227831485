/* eslint hapi/no-arrowception: 0, hapi/hapi-scope-start: 0 */

const { OK, UNAUTHORIZED, INTERNAL_SERVER_ERROR, makeHttp } = require('../../../tests/_quick/_utils/api-testing-helpers');

const makeMockData = () => {

    const token = 'token-1234';
    const verificationToken = 'ff23f816-6b62-4a1f-94e4-f7f130ec89f0';
    const resetToken = 'reset-token-1234';

    const id = 123;
    const email = 'q@q.q';
    const schoolId = 1;
    const firstName = 'Someone';
    const lastName = 'Cool';
    const type = 'some type';
    const major = null;
    const career = 'some career';
    const profession = 'some profession';
    const bio = 'some bio';

    const picture = null;
    const password = '123';

    const user = { id, email, schoolId, firstName, lastName, type, major, career, profession, bio };
    const users = [user];

    const authorization = `Bearer ${token}`;

    return {
        token,
        verificationToken,
        resetToken,
        authorization,
        user,
        users,
        picture,
        password
    };
};

const mockData = makeMockData();

const rulesFactory = (ruleBuilder) =>

    ruleBuilder.onGet('/schools').reply(unauthorized(

        () => OK([
            { id: 1, name: 'Indiana University', domains: ['iu.org', 'iu.com'] },
            { id: 2, name: 'Tufts University', domains: ['tu.org', 'tu.com'] },
            { id: 3, name: 'University of North Carolina', domains: ['unc.org', 'unc.com'] }
        ])

    )).onGet('/interests').reply(unauthorized(

        () => OK([
            { id: 1, interest: 'Rock Climbing', category: 'Outdoor' },
            { id: 2, interest: 'Reading Books', category: 'Indoor'  }
        ])

    )).onPost('/login').reply(unauthorized(

        ({ data: { email, password } }) => {

            return email === mockData.user.email && password === mockData.password
                ? OK(mockData.token)
                : UNAUTHORIZED();
        }

    )).onPost('/logout').reply(authorized(

        () => OK()

    )).onGet('/user').reply(authorized(

        () => OK(mockData.user)

    )).onPost('/users').reply(unauthorized(

        ({ data: { email } }) => {

            return email !== 'err' ? OK() : INTERNAL_SERVER_ERROR();
        }

    ));

const unauthorized = (next) => (request) => {

    return next(normalizeRequestData(request));
};

const authorized = (next) => (request) => {

    const { headers: { Authorization } } = request;

    return Authorization === mockData.authorization ? next(normalizeRequestData(request)) : UNAUTHORIZED();
};

const normalizeRequestData = (request) => {

    const { data: jsonData } = request;

    return jsonData
        ? Object.assign({}, request, { data: JSON.parse(jsonData) })
        : request;
};

module.exports = {
    makeNearPeerHttpMock: () => makeHttp(rulesFactory).http
};
