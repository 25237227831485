const React = require('react');

// TODO use this util to generate links for chat too

const internals = {};
const utils = {};

module.exports = utils;

utils.renderLinks = (parts, options) => {

    if (!options) {
        options = {};
    }

    const urlExp = /(\b(?:https?:\/\/)?[a-z0-9\-.]+\.[a-z]{2,24}(?:\/\S*)?\b)/gi;

    return parts.reduce((out, part) => {

        if (typeof part !== 'string') {
            return out.concat(part);
        }

        const subparts = part.split(urlExp).map((url) => {

            if (!url.match(urlExp)) {
                return url; // Not actually a url
            }

            if (options.linkClass) {
                return <a key={url} target='_blank' rel="noopener noreferrer" className={options.linkClass} href={internals.ensureProtocol(url)}>{url}</a>;
            }

            return <a key={url} target='_blank' rel="noopener noreferrer" href={internals.ensureProtocol(url)}>{url}</a>;
        });

        return out.concat(subparts);
    }, []);
};

internals.ensureProtocol = (url) => {

    if (url.match(/^https?:\/\//i)) {
        return url;
    }

    return `http://${url}`;
};
