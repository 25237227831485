const { connect } = require('react-redux');
const { context } = require('app-context');
const SurveysManagement = require('../components/SurveysManagement');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getSchoolRoles(state),
        surveys: selectors.getSchoolSurveys(state),
        surveysIsLoading: selectors.getIsLoadingSurveys(state),
        surveysManagementFilter: selectors.getSurveysManagementFilter(state),
        rolePermissions: selectors.getCurrentUserRolePermissions(state)
    }), {
        stopSurvey: actions.surveys.stopSurvey,
        setSurveysManagementFilter: actions.app.setSurveysManagementFilter,
        deleteSurvey: actions.surveys.delete
    }
)(SurveysManagement);
