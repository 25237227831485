const { mergeSelectors } = require('../utils/redux-helpers');

exports.selectors = mergeSelectors({
    alerts: require('./alerts'),
    dataFetching: require('./data-fetching'),
    auth: require('./auth'),
    signup: require('./signup'),
    classes: require('./classes'),
    connectivity: require('./connectivity'),
    app: require('./app'),
    chats: require('./chats')
});
