const { ASYNC, SIMPLE } = require('../utils/redux-helpers');

module.exports = {
    REQUEST_CHAT: ASYNC,
    REQUEST_PEER: ASYNC,
    ACCEPT_PEER: ASYNC,
    DISCONNECT_PEER: ASYNC,
    CREATE_TWILIO_CONVERSATION: ASYNC,
    ENABLE_USER: ASYNC,
    STORE_MESSAGE_FOR_SENDING_ON_SERVER: SIMPLE,
    REMOVE_MESSAGE_FOR_SENDING_ON_SERVER: SIMPLE,
    REMOVE_MESSAGES_FOR_SENDING_ON_SERVER: SIMPLE,
    SEND_MESSAGE: ASYNC,
    SEND_LOCAL_ANNOUNCEMENT_MESSAGE: ASYNC,
    MENTION: ASYNC,
    UPDATE_MESSAGE_BODY: ASYNC,
    UPDATE_LOCAL_MESSAGE_BODY: ASYNC,
    SET_LOCAL_LAST_READ_MESSAGE_INDEX: ASYNC,
    MODERATE_MESSAGE: ASYNC,
    PIN_MESSAGE: ASYNC,
    PIN_LOCAL_MESSAGE: ASYNC,
    MODERATE_LOCAL_MESSAGE: ASYNC,
    REMOVE_MESSAGE: ASYNC,
    REMOVE_LOCAL_MESSAGE: ASYNC,
    REMOVE_OWN_MESSAGE: ASYNC,
    SET_LAST_READ_MESSAGE_INDEX: ASYNC,
    SET_LAST_READ_MESSAGE_INDEX_BY_CONVERSATION_SID: ASYNC,
    FLAG_MESSAGE_AS_INAPPROPRIATE: ASYNC,
    FLAG_LOCAL_MESSAGE_AS_INAPPROPRIATE: ASYNC,
    TARGET_NOTIFY_USER: ASYNC,
    BATCH_TARGET_NOTIFY: ASYNC,
    UPLOAD_PREAPPROVED: ASYNC
};
