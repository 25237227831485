const React = require('react');
const T = require('prop-types');
const { default: TextField } = require('@mui/material/TextField');
const { default: RaisedButton } = require('@mui/material/Button');
const FullScreenDialog = require('containers/FullScreenDialog');
const { default: AnnouncementIcon } = require('@mui/icons-material/Announcement');

const internals = {};

const NOTIFICATION_BLAST_LIMIT = 1600;


module.exports = class NotifyUserDialog extends React.Component {

    static propTypes = {
        user: T.shape({
            id: T.any.isRequired,
            firstName: T.string.isRequired
        }),
        schoolId: T.any.isRequired,
        open: T.bool.isRequired,
        onRequestClose: T.func.isRequired,
        onSubmit: T.func.isRequired
    }

    constructor() {

        super();

        this.state = {
            text: ''
        };

        this.reset = this._reset.bind(this);
        this.submit = this._submit.bind(this);
        this.updateText = this._updateText.bind(this);
    }

    _submit() {

        const { user: { id: userId }, schoolId } = this.props;
        const { text } = this.state;

        // Handles the, I'm assuming, unlikely case
        // where the form user bypasses the form's built-in (attribute) constraints
        // e.g. submitting programmatically
        if (!text || text.length > NOTIFICATION_BLAST_LIMIT) {
            return;
        }

        this.props.onSubmit({ userId, schoolId, text });
        this.props.onRequestClose();
        this.reset();
    }

    _reset() {

        this.setState({
            text: '' // Warning: must be an empty string, NOT null, given we render assuming state.text has a length property
        });
    }

    _updateText(ev) {

        const text = ev.target.value;
        this.setState({ text });
    }

    render() {

        const { styles } = internals;
        const { user, open, onRequestClose } = this.props;
        const { text } = this.state;

        const floatingLabelText = `Type message (${NOTIFICATION_BLAST_LIMIT}-char limit):`;
        const maxLength = '' + NOTIFICATION_BLAST_LIMIT;
        return <FullScreenDialog
            title={<span style={styles.title}>Notify {user.firstName}</span>}
            open={open}
            onRequestClose={onRequestClose}
        >
            <div>
                <TextField
                    fullWidth
                    label={floatingLabelText}
                    value={text}
                    inputProps={{
                        maxLength,
                        style:{
                            marginTop:'0.75rem'
                        }
                    }}
                    multiline
                    minRows={4}
                    maxRows={4}
                    onChange={this.updateText}
                />
                <span style={{ marginTop: '0' }}>{text.length}/{NOTIFICATION_BLAST_LIMIT}</span>
                <div style={styles.sendButtonContainer}>
                    <RaisedButton
                        color={'primary'}
                        variant={'contained'}
                        disabled={!(text && text.length <= NOTIFICATION_BLAST_LIMIT)}
                        startIcon={<AnnouncementIcon />}
                        onClick={this.submit}
                    >Send Notification</RaisedButton>
                </div>
            </div>
        </FullScreenDialog>;
    }
};

internals.styles = {
    title: {
        display: 'inline-block',
        width: '100%',
        textAlign: 'center'
    },
    sendButtonContainer: {
        marginTop: '1.5em'
    }
};
