const { connect } = require('react-redux');
const { context } = require('app-context');
const VerifyEmail = require('../components/VerifyEmail');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        signupDetails: selectors.getSignupDetailsForStep1(state)
    }), {
        resendEmail: actions.signup.step1.resendEmail
    }
)(VerifyEmail);
