const { connect } = require('react-redux');
const { context } = require('../app-context');
const FullScreenDialog = require('components/FullScreenDialog');

const actions   = context.actions;

module.exports = connect(
    () => ({}), {
        setModalOpen: () => actions.app.openModal(),
        setModalClosed: () => actions.app.closeModal()
    }
)(FullScreenDialog);
