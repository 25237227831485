module.exports =  (searchText, key) => {

    const compareString = key.toLowerCase();
    const searchTextLower = searchText.toLowerCase();

    let searchTextIndex = 0;
    for (let i = 0; i < key.length; ++i) {
        if (compareString[i] === searchTextLower[searchTextIndex]) {
            searchTextIndex += 1;
        }
    }

    return searchTextIndex === searchTextLower.length;
};
