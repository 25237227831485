const { connect } = require('react-redux');
const { context } = require('app-context');
const Login = require('../components/Login');
const { push: Push } = require('connected-react-router');

const actions = context.actions;
const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        isAuthenticated: selectors.getIsAuthenticated(state)
    }),
    {
        initContext: actions.signup.initContext,
        checkSchoolSSO: actions.signup.checkSchoolSSO,
        onSubmit: actions.auth.login,
        onInvalidSubmit: actions.auth.loginInputFailure,
        push: Push
    }
)(Login);
