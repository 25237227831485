const React = require('react');
const T = require('prop-types');
const ReactDOM = require('react-dom');

const internals = {};

internals.createPortal = (component, destination) => {

    return ReactDOM.createPortal(component, destination);
};

module.exports = class Portal extends React.Component {

    static propTypes = {
        children: T.element,
        selector: T.string
    }

    static defaultProps = {
        selector: 'body'
    }

    componentWillUnmount() {

        this.closePortal();
    }

    renderPortal() {

        const {
            children,
            selector
        } = this.props;

        this.portalDestination = this.portalDestination || document.querySelector(selector);

        if (!this.portalDestination) {
            return null;
        }

        this.portalComponent = <div>{children}</div>;

        this.portal = internals.createPortal(
            this.portalComponent,
            this.portalDestination
        );

        return this.portal;
    }

    closePortal() {

        if (!this.portalDestination) {
            return null;
        }

        // Empty out the portal
        this.portalComponent = <div />;
        this.portal = internals.createPortal(
            this.portalComponent,
            this.portalDestination
        );
    }

    render() {

        return this.renderPortal();
    }
};
