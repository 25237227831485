const { context } = require('../app-context');
const { isOfflineError } = require('utils/network');

module.exports = () => {

    const {
        redux: { hooks },
        http: { nearpeer },
        actions,
        selectors
    } = context;

    const offline = (isOffline) => hooks.dispatch(actions.connectivity.offline({ isOffline }));

    nearpeer.interceptors.response.use(
        (response) => {

            if (selectors.all.getIsOffline(hooks.getState())) {
                offline(false);
            }

            return response;
        },
        (error) => {

            if (isOfflineError(error) && !selectors.all.getIsOffline(hooks.getState())) {
                offline(true);
            }

            return Promise.reject(error);
        }
    );
};
