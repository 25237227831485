
const utils = {};

module.exports = utils;

utils.getDefinedProps = (obj) => {

    return Object.keys(obj)
    .map((key) => typeof obj[key] !== 'undefined')
    .reduce((collector, key) => {

        collector[key] = obj[key];
        return collector;
    }, {});
};

utils.getPropsChanged = (oldProps, newProps) => {

    const definedNewProps = utils.getDefinedProps(newProps);

    return Object.keys(oldProps)
    .map((key) => {

        if (definedNewProps[key]) {
            return key;
        }
    });
};
