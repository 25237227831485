const React = require('react');

const internals = {};

module.exports = class GenericError extends React.Component {

    render() {

        const { styles } = internals;

        return <div style={styles.errorContainer}>
            <h2>Page load failed. Sorry!</h2>
            <p>We had trouble loading the page you requested. It may have been removed or the link
                you followed may no longer go to that page.</p>
        </div>;
    }
};

internals.styles = {
    errorContainer: {
        margin: '20%',
        marginTop: '50%'
    }
};
