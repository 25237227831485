const React = require('react');
const T = require('prop-types');
const { Switch, Route, Redirect } = require('react-router-dom');
const PageTitleByPath = require('utils/pageTitleByPath');

const { cloneElement } = React;

const internals = {};

exports.Routes = function Routes(props) {

    let { routes } = props;
    const { onUpdate } = props;

    const {
        keyForRoute,
        renderRoute,
        cloneWithKeys,
        markBasePaths
    } = internals;

    routes = markBasePaths('/', [].concat(routes));

    const renderBase = renderRoute(routes, '/', onUpdate);

    return (
        <Switch>
            {cloneWithKeys({
                items: routes.map(renderBase),
                keyGetter: (route) => keyForRoute(route)
            })}
        </Switch>
    );
};

// Recursive prop-types strategy grabbed from
// https://github.com/facebook/react/issues/5676#issuecomment-739092902
// eslint-disable-next-line hapi/hapi-scope-start, prefer-spread
const routeType = (...args) => T.shape({
    path: T.string,
    exact: T.bool,
    strict: T.bool,
    sensitive: T.bool,
    component: T.any,
    fallback: T.any,
    wrapFallbackWithComponent: T.bool,
    redirect: T.shape({
        from: T.string,
        to: T.string
    }),
    childRoutes: T.arrayOf(routeType)
}).apply(null, args);

exports.Routes.propTypes = {
    routes: T.oneOfType([
        routeType,
        T.arrayOf(routeType)
    ]).isRequired,
    onUpdate: T.func
};

internals.markBasePaths = (basePath, routes) => {

    if (!routes) {
        return null;
    }

    const { concatPaths } = internals;

    return routes.map((route) => {

        route.basePath = basePath;

        if (route.childRoutes) {
            route.childRoutes = internals.markBasePaths(
                concatPaths(basePath, route.path),
                route.childRoutes
            );
        }

        return route;
    });
};

internals.cloneWithKeys = ({ items, keyGetter }) => {

    return items.map((item) => {

        if (!item) {
            return null;
        }

        return cloneElement(item, { key: keyGetter(item) });
    });
};

internals.keyForRoute = (route) => {

    if (!route) {
        return null;
    }

    return String(
        route.path
        + String(route.props)
        + !!route.exact
        + !!route.strict
        + !!route.sensitive
        + !!route.childRoutes
    );
};

internals.handleRedirect = function HandleRedirect(basePath, route) {

    const { concatPaths } = internals;

    // Redirect is special and must be exclusive of other props
    let { redirect: { from, to } } = route;

    if (typeof from === 'string') {
        // redirect.from must be relative
        from = concatPaths(basePath, from);
    }

    if (typeof to === 'string') {
        // If redirect.to is absolute, leave it be. Otherwise make it relative
        to = to.startsWith('/') ? to : concatPaths(basePath, to);
    }
    else if (to && typeof to.pathname === 'string') {
        // to is an object
        to = {
            ...to,
            pathname: to.pathname.startsWith('/') ? to.pathname : concatPaths(basePath, to.pathname)
        };
    }

    return <Redirect from={from} to={to} />;
};

const { useEffect, useState } = React;

internals.renderRoute = function RenderPath(routes, basePath, onUpdate) {

    return function RenderRoute(route) {

        const {
            handleRedirect,
            concatPaths,
            renderRoute,
            keyForRoute,
            cloneWithKeys,
            RouteComponentLifecycleWrapper
        } = internals;

        if (!route) {
            return null;
        }

        const {
            path,
            exact,
            strict,
            sensitive,
            component: RouteComponent,
            wrapFallbackWithComponent
        } = route;

        const normalizedPath = concatPaths(basePath, path);
        const renderRouteFromPath = renderRoute(routes, normalizedPath, onUpdate);

        return (
            <Route
                path={normalizedPath}
                exact={exact}
                strict={strict}
                sensitive={sensitive}
                render={(props) => {

                    if (!route || !path || !RouteComponent) {
                        return null;
                    }

                    if (route.redirect) {
                        return handleRedirect(basePath, route);
                    }

                    const routerProps = {
                        ...props,
                        ...route.props,
                        route
                    };

                    const render = function Render(renderProps) {

                        const componentProps = { ...routerProps, ...renderProps };

                        if (onUpdate) {
                            onUpdate(componentProps);
                        }

                        const pageTitle = PageTitleByPath(routerProps.location.pathname);

                        if (pageTitle && document.title !== `Nearpeer - ${pageTitle}`) {
                            document.title = `Nearpeer - ${pageTitle}`;
                        }
                        else if (!pageTitle) {
                            document.title = 'Nearpeer';
                        }

                        return (
                            <RouteComponent {...componentProps}>
                                {route.childRoutes && (
                                    <Switch>
                                        {cloneWithKeys({
                                            // 'childRoutes' mapped to 'renderRouteFromPath' — It's a recursion excursion! =P
                                            items: [].concat(route.childRoutes).map(renderRouteFromPath),
                                            keyGetter: (routeEl) => normalizedPath + keyForRoute(routeEl.props)
                                        })}
                                    </Switch>
                                )}
                            </RouteComponent>
                        );
                    };

                    if (RouteComponentLifecycleWrapper.isTrivialRoute(route)) {
                        return render();
                    }

                    const renderFallback = function RenderFallback(renderProps) {

                        const Fallback = route.fallback || route.props?.fallback || null;

                        const componentProps = { ...routerProps, ...renderProps };

                        if (Fallback) {
                            if (wrapFallbackWithComponent) {
                                return (
                                    <RouteComponent {...componentProps}>
                                        <Fallback {...componentProps} />
                                    </RouteComponent>
                                );
                            }

                            return <Fallback {...componentProps} />;
                        }

                        return null;
                    };

                    return (
                        <RouteComponentLifecycleWrapper
                            route={route}
                            routerProps={routerProps}
                            // eslint-disable-next-line react/jsx-no-bind
                            render={render}
                            // eslint-disable-next-line react/jsx-no-bind
                            renderFallback={renderFallback}
                        />
                    );
                }}
            />
        );
    };
};

internals.RouteComponentLifecycleWrapper = function RouteComponentLifecycleWrapper(props) {

    const [shouldMount, setShouldMount] = useState(false);

    const { route, routerProps } = props;

    useEffect(() => {

        if (shouldMount) {
            setShouldMount(false);
        }

        const { history } = routerProps;

        let {
            onWillMount,
            onUnmount
        } = route;

        const noop = () => null;

        onWillMount = onWillMount || noop;
        onUnmount = onUnmount || noop;

        const runOnWillMount = async () => {

            const { replace } = await onWillMount(routerProps) || {};

            if (replace) {
                history.replace(replace);

                // Still want to mount if it's a parent route
                if (!route.childRoutes && !replace.includes(route.path)) {
                    return;
                }
            }

            setShouldMount(true);
        };

        runOnWillMount();

        return function cleanup() {

            onUnmount(routerProps);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerProps.match.url]);

    const { renderFallback, render, ...rest } = props;

    return shouldMount ? render(rest) : renderFallback(rest);
};

internals.RouteComponentLifecycleWrapper.propTypes = {
    route: T.object.isRequired
};

internals.RouteComponentLifecycleWrapper.isTrivialRoute = (route) => {

    if (!route) {
        return true;
    }

    return !route.onWillMount && !route.onUnmount;
};

internals.concatPaths = (base, path) => {

    base = base || '';
    path = path || '';

    base = base.endsWith('/') ? base.slice(0, -1) : base; // /my-path/ -> /my-path
    path = path.startsWith('/') ? path.slice(1) : path;   // /my-path -> my-path

    return `${base}/${path}`;
};
