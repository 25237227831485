// 100% copied from bugReport/containers.

const { connect } = require('react-redux');
const { context } = require('../app-context');
const LoginHelpContents = require('components/LoginHelpContents');
const { push: Push } = require('connected-react-router');
const { safeWarning } = require('assertions-simplified');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        schools: selectors.getSchools(state),
        signupContext: selectors.getSignupContext(state)
    }), {
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onSubmitBugReport: ({ bugType, bugDetails, email }) => (dispatch) => {

            return dispatch(actions.appFeedback.submitBugReport({ bugType, bugDetails, email })).then((success) => {

                if (!success) {
                    throw new Error('Login help report failed');
                }

                dispatch(Push('/login'));
            }).catch(safeWarning);
        }
    }
)(LoginHelpContents);
