const T = require('prop-types');
const { default: Typography } = require('@mui/material/Typography');

module.exports = function FormHelperText(props) {

    const { children, ...rest } = props;

    return (
        <Typography
            style={{ paddingBottom: 20 }}
            {...rest}
        >
            {children}
        </Typography>
    );
};

module.exports.propTypes = {
    children: T.string
};
