const { connect } = require('react-redux');
const { context } = require('app-context');
const MySurveys = require('../components/MySurveys');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getSchoolRoles(state),
        userSurveys: selectors.getCurrentUserSurveys(state),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        surveysIsLoading: selectors.getIsLoadingSurveys(state)
    }), {
        onSubmitCreate: actions.surveys.create
    }
)(MySurveys);
