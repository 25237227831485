const React = require('react');
const T = require('prop-types');
const { default:Tooltip } = require('@mui/material/Tooltip');
const BadgeSvg = require('static/badge.svg');
const { default: Classes } = require('./styles.scss');
const { default: styled } = require('styled-components');

const CustomTooltip = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: rgba(255, 255, 255, 0.87);
    font-size: 0.75rem;
  }

  .MuiTooltip-arrow {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

class UserBadge extends React.PureComponent {

    static propTypes = {
        badges: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            svg: T.string,
            icon: T.any,
            label: T.string,
            userPermissions: T.string
        })),
        userType: T.string.isRequired,
        mini: T.bool,
        extraLarge: T.bool,
        large: T.bool,
        useTooltip: T.bool,
        className: T.string,
        style: T.object
    }

    constructor() {

        super();


        this.state = {
            tooltipIsOpen:false
        };

        this.handleClose = this._handleClose.bind(this);
        this.handleOpen = this._handleOpen.bind(this);
    }

    _handleClose() {

        this.setState({
            tooltipIsOpen:false
        });
    }

    _handleOpen(){

        this.setState({
            tooltipIsOpen:true
        });
    }

    render() {

        const {
            className,
            mini,
            large,
            extraLarge,
            badges,
            useTooltip,
            userType,
            style
        } = this.props;

        const { tooltipIsOpen } = this.state;
        const badge = badges && badges.find(({ name }) => name === userType);

        if (!badge) {
            return null;
        }

        let miniClass = '';
        if (mini) {
            miniClass = Classes.mini;
        }

        let largeClass = '';
        if (large) {
            largeClass = Classes.large;
        }

        let extraLargeClass = '';
        if (extraLarge) {
            extraLargeClass = Classes.extraLarge;
        }

        let image;

        const iconAlt = badge.label !== null ? badge.label : 'Badge';

        if (badge.bgSvg) {
            const buff = Buffer.from(badge.bgSvg);
            const base64data = buff.toString('base64');

            image = <img src={`data:image/svg+xml;base64,${base64data}`} alt={iconAlt} />;
        }
        else {
            image = <img src={BadgeSvg} alt={iconAlt} />;
        }

        if (useTooltip){
            return <CustomTooltip  title={iconAlt} placement={'top'} arrow open={tooltipIsOpen} onClose={this.handleClose} onOpen={this.handleOpen}>
                <div
                    style={{
                        ...style
                    }}
                    className={`${Classes.badgeContainer} ${miniClass} ${largeClass} ${extraLargeClass} ${className || ''}`}
                    onClick={(event) => {

                        event.preventDefault();
                        this.setState({
                            tooltipIsOpen:true
                        });

                    }}
                >
                    {image}
                    <div className={Classes.innerIcon}>
                        {badge.icon}
                    </div>
                </div>
            </CustomTooltip>;
        }

        return (
            <div style={{
                ...style
            }} className={`${Classes.badgeContainer} ${miniClass} ${largeClass} ${extraLargeClass} ${className || ''}`}>
                {image}
                <div className={Classes.innerIcon}>
                    {badge.icon}
                </div>
            </div>
        );
    }

}

module.exports = UserBadge;
