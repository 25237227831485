const Storage = require('utils/storage');
const GetQueryParams = require('utils/get-query-params');

exports.getSignupContextWithQueryParams = async (context) => {

    const redux     = context.redux.hooks;
    const actions   = context.actions;
    const selectors = context.selectors.all;

    const {
        v,
        s,
        r,
        email: e,
        sso: querySSO
    } = GetQueryParams();

    const signupContext = selectors.getSignupContext(redux.getState());

    const newSignupContext = {
        ...signupContext,
        verificationToken: v || signupContext.verificationToken,
        schoolId: s || signupContext.schoolId,
        roleId: r || signupContext.roleId,
        email: e ? decodeURIComponent(e.replaceAll(' ', '+')) : signupContext.email,
        sso: querySSO || signupContext.sso
    };

    try {
        if (!newSignupContext.schoolId && newSignupContext.verificationToken) {
            // Fetch school into redux
            const res = await redux.dispatch(actions.dataFetching.fetchSchoolByVerificationToken({
                token: newSignupContext.verificationToken,
                email: newSignupContext.email
            }));

            const newSchoolId = res?.payload?.result?.result?.school;
            newSignupContext.schoolId = newSchoolId;
        }

        if (newSignupContext.schoolId && !newSignupContext.sso) {
            const school = selectors.getSchoolById(
                redux.getState(),
                newSignupContext.schoolId
            );

            newSignupContext.sso = newSignupContext.sso || school?.sso;
        }
    }
    catch (err) {
        console.log('getSignupContextWithQueryParams err', err);
        // Ignore err
    }

    const {
        email,
        sso
    } = newSignupContext;

    const isInternalEmail = exports.getIsInternalEmail(email);

    if (isInternalEmail || !sso) {
        newSignupContext.usingPassword = true;
        newSignupContext.usingSSO = false;
    }
    else {
        newSignupContext.usingPassword = false;
        newSignupContext.usingSSO = true;
    }

    return newSignupContext;
};

exports.getSignupVars = (type) => {

    if (type === 'context') {
        return {
            ...Storage.get('signup-context') || {},
            fetchedProgress: false
        };
    }
    else if (type === 'step1') {
        const {
            email,
            schoolId
        } = Storage.get('signup-context') || {};

        return {
            email,
            schoolId
        };
    }
    else if (type === 'step2') {
        const {
            hasPassword,
            hasSSOToken,
            password,
            agreeToTerms,
            verificationToken
        } = Storage.get('signup-context') || {};

        return {
            hasPassword,
            hasSSOToken,
            password,
            agreeToTerms,
            verificationToken
        };
    }
};

exports.getIsInternalEmail = (email) => (email?.includes('nearpeer.net') || email?.includes('nearpeer.com')) && email?.includes('+');
