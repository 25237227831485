const { connect } = require('react-redux');
const { context } = require('app-context');
const SurveyReportPage = require('../components/SurveyReportPage');
const { goBack: GoBack } = require('connected-react-router');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state, { match: { params: { id } } }) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        survey: selectors.getSurvey_forEdit(state,id)
    }), {
        getReport: actions.surveys.getReport,
        goBack: GoBack
    }
)(SurveyReportPage);
