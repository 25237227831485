
module.exports = (context) => {

    const selectors = context.selectors.all;
    const redux = context.redux.hooks;

    const getSchoolExistsBySlug = (slug) => selectors.getSchoolExistsBySlug(redux.getState(), slug);

    return {
        resolveSchoolExists(slug) {

            return new Promise((resolve, reject) => {

                const check = () => {

                    const exists = getSchoolExistsBySlug(slug); // true, false, or null

                    if (exists === true) {
                        stop();
                        return resolve();
                    }
                    else if (exists === false) {
                        stop();
                        return reject(new Error('That school doesn\'t exist'));
                    }
                };

                const stop = redux.subscribe(check);

                check();
            });
        }
    };
};
