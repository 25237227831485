const { connect } = require('react-redux');
const { context } = require('../app-context');
const UserBadge = require('components/UserBadge');

const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        badges: selectors.getAllBadges(state)
    })
)(UserBadge);
