const { ASYNC } = require('../utils/redux-helpers');

module.exports = {
    MARK_READ: ASYNC,
    CLEAR_MENTIONS: ASYNC,
    ACCEPT: ASYNC,
    ACCEPT_TRANSFER: ASYNC,
    DECLINE: ASYNC,
    DISMISS: ASYNC,
    ANSWER_QUESTION: ASYNC
};
