const { default: styled } = require('styled-components');
const { useDispatch } = require('react-redux');

const ChangePasswordCard = require('components/ChangePasswordCard');

const { context } = require('app-context');
const { useAppSelector } = require('utils/redux');
const React = require('react');

const internals = {};

module.exports = function ChangePasswordPage() {

    const { Container } = internals;

    const dispatch = useDispatch();

    const usePasswordRules = useAppSelector(context.selectors.all.getUsePasswordRules);
    const currentUser = useAppSelector(context.selectors.all.getCurrentUser).user();

    const onSubmitChangePassword = async ({ currentPassword, newPassword, confirmNewPassword, logoutOtherDevices }) => {

        await dispatch(context.actions.profileManagement.changePassword({
            password: currentPassword,
            newPassword,
            newPasswordConfirm: confirmNewPassword,
            logoutOtherDevices
        }));
    };

    return (
        <Container>
            <ChangePasswordCard
                mode={'light'}
                useCurrentPassword={true}
                useLogoutOtherDevices={true}
                usePasswordRules={usePasswordRules}
                mainTitle='Change Password'
                showAgreeToTerms={false}
                onSubmit={onSubmitChangePassword}
                logout={() => dispatch(context.actions.auth.logout())}
                passwordReset={() => dispatch(context.actions.auth.requestPasswordReset({ email: currentUser.email }))}
            />
        </Container>
    );
};

internals.Container = styled.div`
    width: 100%;
    text-align: center;
    padding: 12px 12px 1.5em;
`;
