const React = require('react');
const T = require('prop-types');

const { default: Typography } = require('@mui/material/Typography');
const { default: List } = require('@mui/material/List');

const SurveyListItem = require('../SurveyListItem');

const { default: Classes } = require('./styles.scss');
const Loader = require('components/Loader');

const MySurveys = (props) => {

    const {
        userSurveys,
        surveysIsLoading,
        rolePermissions
    } = props;

    const { canCreateSurveys,canSeeSurveysReports } = rolePermissions;

    // eslint-disable-next-line no-unused-vars

    return (
        <div className={Classes.wrapper}>
            <div className={Classes.header}>
                <Typography variant='h2'>
                    My Surveys
                </Typography>
            </div>
            {surveysIsLoading ?
                <Loader /> :
                (userSurveys.length ? <List style={{ marginTop: 14 }}>
                    {userSurveys.map((survey) => {

                        return <SurveyListItem
                            key={survey.id}
                            survey={survey}
                            showStart={true}
                            canEdit={canCreateSurveys}
                            canSeeReport={canSeeSurveysReports}
                        />;
                    })}
                </List> : <h3 style={{ textAlign:'center' }}>None available at this time</h3>
                )
            }
        </div>
    );
};

MySurveys.propTypes = {
    userSurveys:T.array,
    surveysIsLoading:T.bool,
    rolePermissions:T.shape({
        id:T.number,
        name:T.string,
        label:T.string,
        schoolId:T.number,
        canCreateSurveys:T.bool,
        canSeeSurveysReports:T.bool
    })
};

module.exports = MySurveys;

