const { connect } = require('react-redux');
const { context } = require('../app-context');
const AppContextFilter = require('components/AppContextFilter');

const actions   = context.actions;
const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        appContextFilter: selectors.getAppContextFilter(state),
        availableRoleGroups: selectors.getRoleGroups(state)
    }), {
        setAppContextFilter: actions.app.setAppContextFilter
    }
)(AppContextFilter);
