const safeLog = (...toLog) => {

    const showLogs = process.env.NODE_ENV !== 'production' ||
        process.env.SHOW_LOGS === 'true';

    if (showLogs) {
        console.log(...toLog);
    }
};

module.exports = safeLog;

module.exports.safeLog = safeLog;
