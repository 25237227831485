const { useState, useEffect } = require('react');
const T = require('prop-types');
const FallbackSpinner = require('./fallback-spinner');

module.exports = function DelayedFallbackSpinner(props) {

    const { delay = 300 } = props;

    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {

        setTimeout(() => {

            setShowSpinner(true);
        }, delay);
    }, []);

    return showSpinner ? <FallbackSpinner /> : null;
};

module.exports.propTypes = {
    delay: T.number
};
