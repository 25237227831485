const { connect } = require('react-redux');
const { context } = require('app-context');
const BatchNotify = require('../components/BatchNotify');
const { USERS_SORT_TYPE } = require('utils/constants');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        currentUserId: selectors.getCurrentUserId(state),
        schoolId: selectors.getCurrentSchoolId(state),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getSchoolRoles(state),
        schoolRoleGroups: selectors.getRoleGroups(state),
        searchResults: selectors.getUsers_forNotifyUsersSearch(state) || [],
        activeSearchFilter:selectors.getNotifySearchFilter(state),
        interestsActiveFilter_notify: selectors.getInterestsActiveFilter_notifySearch(state),
        badges: selectors.getAllBadges(state),
        departments:selectors.getDepartmentsList_forProfileEditPage(state),
        yearsHiredList: selectors.getAllYearsHired(state),
        officesList: selectors.getAllOffices(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        majors: selectors.getMajorsList_forCurrentUser(state)
    }), {
        onSubmit: actions.communication.batchTargetNotify,
        onSubmitTest: actions.communication.targetNotifyUser,
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onClickResetSearchFilters: () => (dispatch) => {

            dispatch(actions.app.setNotifySearchFilter({ filter : {} }));
            return dispatch(actions.dataFetching.fetchUserSearchNotifyResults({
                allByMatches: true,
                sortType: USERS_SORT_TYPE.ALPHABETICAL
            }));
        }
    }
)(BatchNotify);
