const { context } = require('../app-context');

const ONE_SEC_MS = 1000;

const selectors = context.selectors.all;
const actions = context.actions;

module.exports = (store) => {

    const startPollingAction = (action, pollTime) => {

        // Schedule to happen almost immediately– don't want to dispatch unconditionally in time with subscribe()
        setTimeout(() => store.dispatch(action()));

        return setInterval(() => store.dispatch(action()), pollTime);
    };

    const stopPollingAction = (p) => {

        clearInterval(p);

        return null;
    };

    let notificationPoll = null;
    let unreadsPoll = null;
    //let allMessagesPoll = null;

    store.subscribe(() => {

        const state = store.getState();
        const isAuthenticated = selectors.getIsAuthenticated(state);
        const isVerified = selectors.getVerifiedStatus(state);

        // When the user becomes authenticated, start polling for notifications and unreads
        if (isAuthenticated && isVerified && !notificationPoll) {
            notificationPoll = startPollingAction(actions.dataFetching.fetchNotifications, 8 * ONE_SEC_MS);
        }

        if (isAuthenticated && isVerified && !unreadsPoll) {
            unreadsPoll = startPollingAction(actions.dataFetching.fetchUnreadMessageCounts, 12 * ONE_SEC_MS);
        }

        /*if (isAuthenticated && !allMessagesPoll) {
            allMessagesPoll = startPollingAction(actions.dataFetching.fetchMessageCounts, 12 * ONE_SEC_MS);
        }*/

        // TODO stop polling when app is in background
        // When the user becomes unauthenticated, stop polling for notifications
        if (!isAuthenticated && notificationPoll) {
            notificationPoll = stopPollingAction(notificationPoll);
        }

        if (!isAuthenticated && unreadsPoll) {
            unreadsPoll = stopPollingAction(unreadsPoll);
        }

        /*if (!isAuthenticated && allMessagesPoll) {
            allMessagesPoll = stopPollingAction(allMessagesPoll);
        }*/
    });
};
