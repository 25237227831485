const { useRef, useState, useEffect } = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@mui/material/Typography');
const { default: FlatButton } = require('@mui/material/Button');
const Colors = require('styles/colors.json');
const Fonts = require('styles/fonts.json');
const Loader = require('components/Loader');
const { context } = require('app-context');
const { transient$Props } = require('utils/styles');

const PlainLayout = require('layouts/PlainLayout');
const AppBarLayout = require('layouts/AppBarLayout');

const Divider = require('components/Divider');
const EmptySpaceLogo = require('./EmptySpaceLogo');
const UserPreferences = require('components/UserPreferences');
const BottomButton = require('components/BottomButton');

const { default: Classes } = require('./styles.scss');

const internals = {
    resubscribeTypes: [
        'verification-reminders',
        'profile-reminders'
    ]
};

module.exports = function Unsubscribe(props) {

    const {
        BottomScrollContentContainer,
        TopScrollContentContainer,
        MainContentContainer,
        MainContent,
        UpdatePrefsHeader,
        PreferencesContainer,
        EmptySpaceLogoContainer,
        resubscribeTypes,
        onClickResubscribe,
        parseSchoolFromUrl
    } = internals;

    const { match: { params: { type } } } = props;

    const userPreferencesRef = useRef();
    const [preferences, setPreferences] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {

        const fetchPrefsByVal = async () => {

            const val = internals.parseValFromUrl();
            const prefs = await context.api.nearpeer.profileManagement.fetchUserPreferencesByVal(val);
            setPreferences(prefs);
        };

        fetchPrefsByVal();
    }, []);

    const updatePrefs = async (prefs) => {

        setPreferences(prefs);
        const val = internals.parseValFromUrl();
        await context.api.nearpeer.profileManagement.updateUserPreferencesByVal(val, prefs);

        context.redux.hooks.dispatch(context.actions.alerts.notification.push({
            message: 'Your preferences have been updated.'
        }));

        setHasSubmitted(true);
    };

    const onBottomButtonClick = () => {

        userPreferencesRef.current.submit();
    };

    const isResubscribeType = resubscribeTypes.includes(type);

    return (
        <PlainLayout>
            <AppBarLayout
                hideFooter
                contentWrapperClass={Classes.contentWrapper}
            >
                <MainContentContainer>
                    <TopScrollContentContainer>
                        <Typography variant='h2'>
                            {type === 'verification-reminders' ? 'You will no longer receive invitations to join Nearpeer' : null}
                            {type === 'profile-reminders' ? 'You will no longer receive reminders to complete your Nearpeer profile' : null}
                            {!hasSubmitted && type === 'pending-connections' ? 'Successfully Unsubscribed From Connection Request Reminders' : null}
                            {!hasSubmitted && type === 'digest' ? 'Successfully Unsubscribed From Digest Emails' : null}
                            {hasSubmitted && 'Your Preferences Have Been Updated'}
                        </Typography>
                        <Divider />
                    </TopScrollContentContainer>
                    <MainContent>
                        {isResubscribeType && (
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant='body1'>
                                    <span dangerouslySetInnerHTML={{
                                        __html: `To resubscribe so that you can <b>find your people at ${parseSchoolFromUrl()}</b> with similar interests, backgrounds and life experiences, click the button below!`
                                    }} />
                                </Typography>
                                <FlatButton
                                    color={'primary'}
                                    onClick={() => onClickResubscribe(type)}
                                    fullWidth
                                    style={{
                                        backgroundColor: Colors.darkBrandColor,
                                        color: 'white',
                                        height: '2.25em',
                                        fontFamily: Fonts.headerFont,
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        fontSize: '1.25em',
                                        letterSpacing: 1,
                                        lineHeight: '2.5em',
                                        marginTop: 20
                                    }}
                                >
                                    Resubscribe
                                </FlatButton>
                                <EmptySpaceLogoContainer>
                                    <EmptySpaceLogo />
                                </EmptySpaceLogoContainer>
                            </div>
                        )}
                        {!isResubscribeType && (<>
                            <UpdatePrefsHeader>
                                <Typography variant='body1'>
                                    {!hasSubmitted && type === 'pending-connections' ?
                                        <span dangerouslySetInnerHTML={{
                                            __html: `You will no longer be notified when peers are trying to connect with you. To resubscribe so that you can <b>more easily make connections and find your people</b>, update your preferences below.`
                                        }} /> : null
                                    }
                                    {!hasSubmitted && type === 'digest' ?
                                        <span dangerouslySetInnerHTML={{
                                            __html: `You'll now miss emails letting you know peers are inviting you to connect and that unread messages are waiting for you. To avoid missing these, <b>resubscribe below</b>.`
                                        }} /> : null
                                    }
                                </Typography>
                                {/* <Typography variant='body1'>
                                    Your preferences have been updated.
                                </Typography> */}
                            </UpdatePrefsHeader>
                            <PreferencesContainer>
                                {!Object.keys(preferences).length && <Loader />}
                                {!!Object.keys(preferences).length && (
                                    <UserPreferences
                                        onSubmit={updatePrefs}
                                        preferences={preferences}
                                        ref={userPreferencesRef}
                                        isSignupPage={false}
                                        isEditPage={false}
                                        headersToShow={['emails']}
                                        fieldsToShow={[
                                            'digestInterval',
                                            'pendingConnections'
                                        ]}
                                    />
                                )}
                            </PreferencesContainer>
                        </>)}
                    </MainContent>
                    {!isResubscribeType && <BottomScrollContentContainer>
                        <BottomButton label='Update' onClick={onBottomButtonClick} />
                    </BottomScrollContentContainer>}
                </MainContentContainer>
            </AppBarLayout>
        </PlainLayout>
    );
};

module.exports.propTypes = {
    match: T.shape({
        params: T.shape({
            type: T.string.isRequired
        }).isRequired
    }).isRequired
};

internals.MainContentContainer = Styled.div`
    width: 100%;
    padding-bottom: 12px;
`;

internals.TopScrollContentContainer = Styled('div', transient$Props)`
    max-width: 600px;
    width: 100%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    h2 {
        padding-top: 10px;
        font-size: 22px;
        font-weight: bold;
    }

    padding-top: 20px;
    @media screen and (max-width: 600px) {
        padding-top: 20px;
    }
`;

internals.UpdatePrefsHeader = Styled.div`
    text-align: center;
    h3 {
        font-size: 24px;
        padding-bottom: 10px !important;
    }
`;

internals.BottomScrollContentContainer = Styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    .contentWrapper {
        @media screen and (max-width: 600px) {
            max-width: initial !important;
        }
    }
`;

internals.MainContent = Styled.div`
    max-width: 600px;
    margin: auto;
    height: 100%;
    padding: 0 50px 90px;

    @media screen and (max-width: 600px) {
        padding: 0 30px 90px;
    }
`;

internals.PreferencesContainer = Styled.div`
    width: 100%;
`;

internals.EmptySpaceLogoContainer = Styled.div`
    width: 70%;
    margin: auto;
    padding-top: 60px;
    @media screen and (max-width: 600px) {
        width: 90%;
        padding-top: 30px;
    }
`;

internals.parseSchoolFromUrl = () => {

    return window.location.search.split('school=')[1].split('&val=')[0].replaceAll('%20', ' ');
};

internals.parseValFromUrl = () => {

    return encodeURIComponent(window.location.search.split('val=')[1]);
};

internals.onClickResubscribe = (type) => {

    const val = internals.parseValFromUrl();
    window.location = `${process.env.API_HOST}${process.env.API_PREFIX}/resubscribe/${type}?val=${val}`;
};
