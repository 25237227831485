const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { APP_FEEDBACK: Types } = require('../action-types');
const { goBack: GoBack } = require('connected-react-router');

module.exports = (context) => {

    const api = context.api.nearpeer;
    const actions = context.actions;

    const appFeedbackSuccess = 'Thanks for your feedback – we\'re on it!';
    const troubleLoggingInSuccess = 'Thanks for your feedback – we\'re on it!';

    return {
        submitBugReport: makeActionCreator(Types.SUBMIT_BUG_REPORT,
            { bugType: T.string, bugDetails: T.string, suggestedInterestCategory: T.string, email: T.string },
            {
                async: api.appFeedback.submitBugReport,
                after: ({ payload: { request: { bugType } } }) => {

                    GoBack();

                    return actions.alerts.notification.push({
                        message: (bugType === 'Trouble Logging In') ? troubleLoggingInSuccess : appFeedbackSuccess
                    });
                }
            }
        )
    };
};
