const Constant = require('lodash/constant');
const Unary = require('lodash/unary');
const First = require('lodash/first');
const IsEmpty = require('lodash/isEmpty');
const IsPromise = require('is-promise');
const FromPairs = require('lodash/fromPairs');
const { Client } = require('@twilio/conversations');
const { AccessManager } = require('twilio-common');
const { TransportUnavailableError } = require('twilsock');
const Memoize = require('lodash/memoize');
const { normalize, schema: S } = require('normalizr');
const { safeWarning, safeInvariant } = require('assertions-simplified');
const { safeThrow } = require('../utils/safe-throw');

module.exports = (context) => {

    const MESSAGES_PAGE_SIZE = 100;
    const TWILIO_CONSUMPTION_REPORT_INTERVAL__IN_SECONDS = 10;
    const CLEAR_OPTIMISTIC_CACHE_DELAY__IN_MILLIS = (TWILIO_CONSUMPTION_REPORT_INTERVAL__IN_SECONDS + 1) * 1000;

    const actions = context.actions;
    const selectors = context.selectors.all;
    const redux = context.redux.hooks;
    const configuration = context.configuration.twilioApi;

    const getIsAuthenticated = () => selectors.getIsAuthenticated(redux.getState());
    const getTwilioToken = () => selectors.getTwilioToken(redux.getState());

    // cache-simplified returns rather than throws errors. We want them thrown!
    const makeCacheWithErrors = (...args) => {


        return Object.entries(...args).reduce((collect, [key, fn]) => ({
            ...collect,
            [key]: Memoize((...fnArgs) => {

                return fn(...fnArgs).then((result) => {

                    if (result instanceof Error) {
                        throw result;
                    }

                    return result;
                });
            })
        }), {});
    };

    const newCache = () => makeCacheWithErrors({ // eslint-disable-line hapi/hapi-scope-start

        getClient:  () => {

            const getClient = async () => await new Client(getTwilioToken());

            return Promise.resolve()
            .then(getClient)   // We want errors (e.g. not logged-in) here to be caught and propagated to the cache to unset the client item
            .catch((err) => {

                if (err instanceof TransportUnavailableError) {
                    // Retry if the token expired in the interim without the accessmanager
                    // catching it, e.g. app coming to the foreground from the background.
                    return redux.dispatch(actions.auth.updateTwilioToken())
                    .then(getClient);
                }

                throw err;
            });
        },

        getConversation: ({ channelSid }) => cache.getClient().then((client) => client.getConversationBySid(channelSid))
    });

    const onTokenUpdated = (am) => cache.getClient().then((client) => client.updateToken(am.token));
    const onTokenExpiry = () => redux.dispatch(actions.auth.updateTwilioToken());
    const onTokenError = safeThrow;

    const makeAccessManager = () => {

        const am = new AccessManager(getTwilioToken());

        am.on('tokenUpdated', onTokenUpdated);
        am.on('tokenWillExpire', onTokenExpiry);
        am.on('tokenExpired', onTokenExpiry);
        am.on('error', onTokenError);

        return am;
    };

    const destroyAccessManager = (am) => {

        am.removeListener('tokenUpdated', onTokenUpdated);
        am.removeListener('tokenWillExpire', onTokenExpiry);
        am.removeListener('tokenExpired', onTokenExpiry);
        am.removeListener('error', onTokenError);

        return;
    };

    let cache;
    let messageEntities;
    let messagesPaginator_current;
    let messagesPaginator_next;
    let optimisticAllRead;
    let accessManager;

    const initialize = () => {

        cache = newCache();
        messageEntities = {};
        messagesPaginator_current = {};
        messagesPaginator_next = {};
        optimisticAllRead = {};

        if (!accessManager && getIsAuthenticated()) {
            accessManager = makeAccessManager();
        }
        else if (accessManager && !getIsAuthenticated()) {
            accessManager = destroyAccessManager(accessManager);
        }
    };

    const messageEntities_set = (messages) => {

        [].concat(messages).forEach((m) => {

            messageEntities[m.state.sid] = m;
        });

        return messages;
    };

    const messageEntities_get = (sid) => {

        const m = messageEntities[sid] || null;

        if (m === null) {
            safeWarning(`Haven't seen message ${sid} yet!`);
        }

        return m;
    };

    const messageEntities_del = (sid) => delete messageEntities[sid];

    const optimisticAllRead_set = ({ channelSid }) => {

        const attemptIdentifier = {};
        optimisticAllRead[channelSid] = { attemptIdentifier };

        setTimeout(() => {

            if (optimisticAllRead[channelSid] && optimisticAllRead[channelSid].attemptIdentifier === attemptIdentifier) {
                delete optimisticAllRead[channelSid];
            }
        }, CLEAR_OPTIMISTIC_CACHE_DELAY__IN_MILLIS);
    };

    const optimisticAllRead_get = ({ channelSid }) => {

        return Object.prototype.hasOwnProperty.call(optimisticAllRead, channelSid);
    };

    const messagesPaginator_firstPage = ({ channelSid }) => {

        const currentPaginator = messagesPaginator_current[channelSid];

        if (currentPaginator && IsPromise(currentPaginator)) {
            return currentPaginator;
        }

        const resultPromise = messagesPaginator_current[channelSid] = Promise.resolve(
            cache.getConversation({ channelSid })
        ).then(
            (channel) => channel.getMessages(MESSAGES_PAGE_SIZE)
        ).then(
            (paginator) => {

                safeInvariant(messagesPaginator_current[channelSid] === resultPromise, 'Value should not change during the fetch.');

                if (messagesPaginator_current[channelSid] === resultPromise) {
                    messagesPaginator_current[channelSid] = paginator;
                }

                return paginator.items;
            }
        );

        return resultPromise;
    };

    const messagesPaginator_nextPage = ({ channelSid }) => {

        const currentPaginator = messagesPaginator_current[channelSid];
        const nextPaginator = messagesPaginator_next[channelSid];

        if (!currentPaginator) {
            return messagesPaginator_firstPage({ channelSid });
        }

        if (IsPromise(currentPaginator)) {
            return currentPaginator;
        }

        if (nextPaginator) {
            safeInvariant(IsPromise(nextPaginator), 'nextPaginator should be promise or null.');
            return IsPromise(nextPaginator) ? nextPaginator : Promise.resolve([]);
        }

        if (!currentPaginator.hasPrevPage) {
            return Promise.resolve([]);
        }

        const resultPromise = messagesPaginator_next[channelSid] = Promise.resolve(
            currentPaginator.prevPage()
        ).then(
            (paginator) => {

                safeInvariant(messagesPaginator_next[channelSid] === resultPromise, 'Value should not change during the fetch.');
                if (messagesPaginator_next[channelSid] === resultPromise) {
                    messagesPaginator_current[channelSid] = paginator;
                    messagesPaginator_next[channelSid] = null;
                }

                return paginator.items;
            }
        );

        return resultPromise;
    };

    const fetchAllChannels = async (client) => {

        let items = [];

        const getPage = (paginator) => {

            items = items.concat(paginator.items);

            if (paginator.hasNextPage) {
                return paginator.nextPage().then(getPage);
            }

            return items;
        };

        const subscribedConversationsPaginator = await client.getSubscribedConversations();

        return getPage(subscribedConversationsPaginator);
    };

    const twilio = {
        shutdown: () => {

            if (!cache) {   // May occur on login before cache as been created
                return Promise.resolve();
            }

            return cache.getClient().then((client) => client.shutdown()).catch(safeWarning);
        },
        updateToken: () =>

            accessManager ? accessManager.updateToken(getTwilioToken()) : Promise.resolve(undefined),

        getSubscribedConversations: () => cache.getClient().then(fetchAllChannels),
        getConversationByName: ({ uniqueName }) =>

            cache.getClient()
            .then((client) => client.getConversationByUniqueName(uniqueName))
            .then((c) => cache.getConversation({ channelSid: c.sid })),

        getUnreadMessageCounts: () => {

            const getUnreadCounts_forConversation = (conversation) => {

                if (optimisticAllRead_get({ channelSid: conversation.sid })) {
                    return [conversation.sid, 0];
                }

                const currentUser = selectors.getCurrentUser(redux.getState()).user();

                if (!currentUser) {
                    return [conversation.sid, 0];
                }

                if (conversation.lastReadMessageIndex === null) {
                    // If this is null, the user hasn't been to the channel yet,
                    // so every message is unread
                    return conversation.getMessagesCount()
                    .then((count) => [conversation.sid, count]);
                }

                // if the member has consumed any messages, the count is good
                return conversation.getUnreadMessagesCount()
                .then((count) => [conversation.sid, count]);
            };

            return cache.getClient().then(
                fetchAllChannels
            ).then(
                (items) => {

                    const stateChannels = redux.getState().dataFetching.entities.channels;

                    if (stateChannels !== null) {

                        const currentChannelCount = Object.keys(stateChannels).length;

                        if (items.length !== currentChannelCount) {
                            redux.dispatch(actions.dataFetching.fetchChannels())
                            .then((res) => twilio.getUnreadMessageCounts());

                            // Abort since we'll be re-running this code
                            return [];
                        }
                    }

                    return items.map(getUnreadCounts_forConversation);
                }
            ).then(
                (promises) => Promise.all(promises)
            ).then(
                FromPairs
            );
        },
        getMessageCounts: () => {

            const getMessageCounts_forChannel = (channel) => {

                if (optimisticAllRead_get({ channelSid: channel.sid })) {
                    return [channel.sid, 0];
                }

                const currentUser = selectors.getCurrentUser(redux.getState()).user();

                if (!currentUser) {
                    return [channel.sid, 0];
                }

                return channel.getMessagesCount()
                .then((count) => [channel.sid, count]);
            };

            return cache.getClient().then(
                fetchAllChannels
            ).then(
                (items) => {

                    const stateChannels = redux.getState().dataFetching.entities.channels;

                    if (stateChannels !== null) {

                        const currentChannelCount = Object.keys(stateChannels).length;

                        if (items.length !== currentChannelCount) {
                            redux.dispatch(actions.dataFetching.fetchChannels())
                            .then((res) => twilio.getMessageCounts());

                            // Abort since we'll be re-running this code
                            return [];
                        }
                    }

                    return items.map(getMessageCounts_forChannel);
                }
            ).then(
                (promises) => Promise.all(promises)
            ).then(
                FromPairs
            );
        },
        getConversationUpdateDates: () => {

            const getUpdateDate_forConversation = (conversation) => {

                return conversation.getMessages(1).then((paginator) => [conversation.sid, IsEmpty(paginator.items) ? conversation.dateUpdated : paginator.items[0].dateUpdated]);
            };

            return cache.getClient().then(
                fetchAllChannels
            ).then(
                (items) => items.map(getUpdateDate_forConversation)
            ).then(
                (promises) => Promise.all(promises)
            ).then(
                FromPairs
            );
        },
        getConversationsLastMessage: () => {

            const getLastMessage_forConversation = (conversation) => {

                return conversation.getMessages(1).then((paginator) => [conversation.sid, IsEmpty(paginator.items) ? null : toDomain.message(paginator.items[0])]);
            };

            return cache.getClient().then(
                fetchAllChannels
            ).then(
                (items) => items.map(getLastMessage_forConversation)
            ).then(
                (promises) => Promise.all(promises)
            ).then(
                FromPairs
            );
        },

        getConversation: ({ channelSid }) =>

            cache.getConversation({ channelSid }, { forceRefresh: true }),

        getMessages_firstPage: ({ channelSid }) =>

            messagesPaginator_firstPage({ channelSid }).then(messageEntities_set),

        getMessages_nextPage: ({ channelSid }) =>

            messagesPaginator_nextPage({ channelSid }).then(messageEntities_set),

        sendMessage: ({ channelSid, message }) =>

            cache.getConversation({ channelSid }).then((channel) => channel.sendMessage(message)),

        markMessageAsLastRead: ({ channelSid, messageIndex }) => {

            optimisticAllRead_set({ channelSid });
            return cache.getConversation({ channelSid }).then((conversation) => conversation.updateLastReadMessageIndex(messageIndex));
        },

        updateMessageBody: ({ sid, body }) => {

            const message = messageEntities_get(sid);

            if (!message) {
                return Promise.reject(new Error(`No message ${sid}`));
            }

            return message.updateBody(body).then(messageEntities_set);
        },

        removeMessage: ({ messageSid }) => {

            const message = messageEntities_get(messageSid);

            if (!message) {
                return Promise.reject(new Error(`No message ${messageSid}`));
            }

            return message.remove().then((m) => {

                messageEntities_del(m.sid);

                return m;
            });
        }
    };

    const schema = {
        Member: new S.Entity('members', {}, { idAttribute: 'sid' }),
        Channel: new S.Entity('channels', {}, { idAttribute: 'sid' }),
        Message: new S.Entity('messages', {}, { idAttribute: 'sid' }),
        UnreadMessageCounts: new S.Object({}),
        MessageCounts: new S.Object({}),
        ChannelUpdateDates: new S.Object({}),
        ChannelLastMessage: new S.Object({})
    };
    schema.Member.define({
        channel: schema.Channel
    });
    schema.Message.define({
        channel: schema.Channel
    });

    const isPrivateChannel = (channel) => {

        return channel
            && (isValid_privateDMChannelIdentifier(channel.uniqueName)
            || isValid_privateClassChannelIdentifier(channel.uniqueName));
    };

    const isValid_privateDMChannelIdentifier = (channelIdentifier) => {

        return channelIdentifier
            && channelIdentifier.split('-').length === 3
            && channelIdentifier.split('-')[0] === configuration.environmentPrefix
            && channelIdentifier.split('-')[1] === 'nearpeer'
            && channelIdentifier.split('-')[2].split(',').length === 2;
    };

    const isValid_privateClassChannelIdentifier = (channelIdentifier) => {

        return channelIdentifier
            && channelIdentifier.split('-').length === 4
            && channelIdentifier.split('-')[0] === configuration.environmentPrefix
            && channelIdentifier.split('-')[1] === 'nearpeer'
            && channelIdentifier.split('-')[2] === 'class'
            && channelIdentifier.split('-')[3];
    };

    const isValid_userIdentifier = (userIdentifier) => {

        return userIdentifier
            && userIdentifier.split('-').length === 3
            && userIdentifier.split('-')[0] === configuration.environmentPrefix
            && userIdentifier.split('-')[1] === 'nearpeer';
    };

    const extractUserIds_fromPrivateChannelIdentifier = (channelIdentifier) => {

        if (!isValid_privateDMChannelIdentifier(channelIdentifier)) {
            safeWarning(`Invalid DM channel type! Unique name: ${channelIdentifier}`);
            return [];
        }

        return channelIdentifier.split('-')[2].split(',').map(Unary(parseInt));
    };

    const extractClassId_fromPrivateChannelIdentifier = (channelIdentifier) => {

        if (!isValid_privateClassChannelIdentifier(channelIdentifier)) {
            safeWarning(`Invalid class channel type! Unique name: ${channelIdentifier}`);
            return null;
        }

        return parseInt(channelIdentifier.split('-')[3]);
    };

    const extractUserId_fromUserIdentifier = (userIdentifier) => {

        if (!isValid_userIdentifier(userIdentifier)) {
            safeWarning(`Invalid user identifier! Identifier: ${userIdentifier}`);
            return null;
        }

        return First([userIdentifier.split('-')[2]].map(Unary(parseInt)));
    };

    const create_privateChannelIdentifier = ({ uId1, uId2 }) => {

        return `${configuration.environmentPrefix}-nearpeer-${[uId1, uId2].sort()}`;
    };

    const toDomain = {
        conversations: (conversations) => conversations.filter(isPrivateChannel).map(toDomain.conversation),
        conversation: (conversation) => {

            const type = isValid_privateDMChannelIdentifier(conversation.uniqueName) ? 'dm' :
                isValid_privateClassChannelIdentifier(conversation.uniqueName) ? 'class' : null;

            if (type === null) {
                safeWarning(`Invalid channel identifier! Identifier: ${conversation.uniqueName}`);
            }

            return {
                sid: conversation.sid,
                type,
                userIds: (type === 'dm') ? extractUserIds_fromPrivateChannelIdentifier(conversation.uniqueName) : null,
                classId: (type === 'class') ? extractClassId_fromPrivateChannelIdentifier(conversation.uniqueName) : null
            };
        },
        messages: (messages) => messages.map(toDomain.message),
        message: (message) => ({
            sid: message.sid,
            channel: toDomain.conversation(message.conversation),
            author: extractUserId_fromUserIdentifier(message.author),
            body: message.body,
            timestamp: message.dateCreated,
            index: message.index,
            moderationStatus: message.attributes.moderationStatus || 'ok',
            isPinned: message.attributes.isPinned || false,
            isEdited: message.attributes.isEdited || false
        }),
        member: ({ sid, channel, identity }) => ({ sid, id: extractUserId_fromUserIdentifier(identity), conversation: toDomain.conversation(channel) })
    };

    const messageEvents = {
        listeners: null
    };

    const isListening = () => !!messageEvents.listeners;
    const messageEvents_onMessageAdded = (message) => messageEvents.listeners.onMessageAdded(normalize(toDomain.message(messageEntities_set(message)), schema.Message));
    const messageEvents_onMessageUpdated = ({ message }) => messageEvents.listeners.onMessageUpdated(normalize(toDomain.message(messageEntities_set(message)), schema.Message));
    const messageEvents_onMessageRemoved = (message) => messageEvents.listeners.onMessageRemoved(normalize(toDomain.message(messageEntities_set(message)), schema.Message));

    const messageEvents_startListening = ({ listeners }) => {

        if (isListening()) {
            safeWarning('Listening already in progress!');
            return Promise.resolve();
        }

        messageEvents.listeners = listeners;

        return cache.getClient().then((client) => {

            client.on('messageAdded', messageEvents_onMessageAdded);
            client.on('messageUpdated', messageEvents_onMessageUpdated);
            client.on('messageRemoved', messageEvents_onMessageRemoved);
        });
    };

    const messageEvents_stopListening = () => {

        if (!isListening()) {
            safeWarning('No listening in progress!');
            return Promise.resolve();
        }

        return cache.getClient().then((client) => {

            client.removeListener('messageAdded', messageEvents_onMessageAdded);
            client.removeListener('messageUpdated', messageEvents_onMessageUpdated);
            client.removeListener('messageRemoved', messageEvents_onMessageRemoved);

            messageEvents.listeners = null;
        });
    };

    return {

        getChannels: () =>

            twilio.getSubscribedConversations().then((data) => normalize(toDomain.conversations(data), [schema.Channel])),

        getDMChannelByUsers: (uId1, uId2) => {

            const channel =  twilio.getConversationByName( {
                uniqueName: create_privateChannelIdentifier( {
                    uId1,
                    uId2
                } )
            } ).then( (data) => normalize( toDomain.conversation( data ), schema.Channel ) ).catch((err) => {

                return {
                    error:err,
                    result:null
                };
            });

            return channel;
        },

        getUnreadMessageCounts: () =>

            twilio.getUnreadMessageCounts().then((data) => {

                return normalize( data, schema.UnreadMessageCounts );
            }),

        getMessageCounts: () =>

            twilio.getMessageCounts().then((data) => {

                return normalize( data, schema.MessageCounts );
            }),

        getConversationUpdateDates: () =>

            twilio.getConversationUpdateDates().then((data) => normalize(data, schema.ChannelUpdateDates)),

        getConversationsLastMessage: () =>

            twilio.getConversationsLastMessage().then((data) => normalize(data, schema.ChannelLastMessage)),

        getConversation: ({ channelSid }) =>

            twilio.getConversation({ channelSid }).then((data) => normalize(toDomain.conversation(data), schema.Channel)),

        getMessages: ({ channelSid }) =>


            twilio.getMessages_firstPage({ channelSid }).then((data) => normalize(toDomain.messages(data), [schema.Message])),

        getMoreMessages: ({ channelSid }) =>

            twilio.getMessages_nextPage({ channelSid }).then((data) => normalize(toDomain.messages(data), [schema.Message])),

        sendMessage: ({ channelSid, message }) =>

            twilio.sendMessage({ channelSid, message })
            .then((messageIndex) => {

                // Run out-of-band
                twilio.markMessageAsLastRead({ channelSid, messageIndex });

                return cache.getConversation({ channelSid });
            })
            .then((channel) => channel.getMessages(1))
            // Fetch the most recent message — we're saying it's ok if it's a different one.
            // That would only happen in very high volume messaging.
            .then((paginator) => ({ messageSid: paginator.items[0].sid })),

        updateMessageBody: async ({ sid, body }) => {

            const data = await twilio.updateMessageBody({ sid, body });
            normalize(toDomain.message(data), schema.Message);
            return { message: data };
        },
        removeMessage: ({ messageSid }) =>

            twilio.removeMessage({ messageSid }).then(Constant(undefined)),

        markMessageAsLastRead: ({ channelSid, messageIndex = null }) =>

            twilio.markMessageAsLastRead({ channelSid, messageIndex }).then(Constant(undefined)),

        startListeningMessages: ({ listeners }) =>

            messageEvents_startListening({ listeners }),

        stopListeningMessages: () =>

            messageEvents_stopListening(),

        reset: () => {

            if (isListening()) {
                messageEvents_stopListening();
            }

            twilio.shutdown().then(initialize);
        },
        shutdown: () => {

            if (isListening()) {
                messageEvents_stopListening();
            }

            twilio.shutdown().then(Constant(undefined));
        },
        initialize: () => {

            if (isListening()) {
                messageEvents_stopListening();
            }

            initialize();
        },
        updateToken: ({ token }) => twilio.updateToken({ token }).then(Constant(undefined))
    };
};
