const { ASYNC } = require('../utils/redux-helpers');

module.exports = {
    CREATE: ASYNC,
    UPDATE: ASYNC,
    DELETE_SURVEY: ASYNC,
    STOP_SURVEY: ASYNC,
    START_SOLVING: ASYNC,
    SUBMIT_ANSWERS: ASYNC,
    GET_REPORT: ASYNC
};
