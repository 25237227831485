const React = require('react');
const T = require('prop-types');
const { default: Measure } = require('react-measure');

const Portal = require('components/Portal');

const { createRef } = React;

const MAX_POLL_TIMES = 1000;

const internals = {};

const { ELEMENT_IDS } = require('utils/constants');

module.exports = class ScrollPortal extends React.PureComponent {

    static propTypes = {
        top: T.bool,
        bottom: T.bool,
        children: T.node
    }

    constructor(props) {

        super();

        const portalTargetsExist = this.getPortalTargetsExist(props);

        this.state = {
            paddingTopBounds: 0,
            paddingBottomBounds: 0,
            portalTargetsExist
        };

        this.topChild = createRef();
        this.bottomChild = createRef();

        if (!portalTargetsExist) {
            this.pollForPortalTargets();
        }
    }

    setPaddingHeight(topOrBottom) {

        return ({ bounds }) => {

            if (topOrBottom === 'top' &&
                bounds.height !== this.state.paddingTop) {
                this.setState({
                    paddingTopBounds: bounds.height
                });
            }
            else if (topOrBottom === 'bottom' &&
                bounds.height !== this.state.paddingBottom) {
                this.setState({
                    paddingBottomBounds: bounds.height
                });
            }
        };
    }

    componentWillUnmount() {

        const topPaddingElement = document.getElementById(ELEMENT_IDS.scrollPortalPaddingIds.top);

        if (topPaddingElement) {
            topPaddingElement.style.height = 0;
            topPaddingElement.style.paddingTop = 0;
            topPaddingElement.style.marginTop = 0;
        }

        const scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);

        if (scrollContainer) {
            scrollContainer.style.marginTop = 0;
            scrollContainer.style.marginBottom = 0;
        }

        const bottomPaddingElement = document.getElementById(ELEMENT_IDS.scrollPortalPaddingIds.bottom);

        if (bottomPaddingElement) {
            bottomPaddingElement.style.height = 0;
            bottomPaddingElement.style.paddingBottom = 0;
            bottomPaddingElement.style.marginBottom = 0;
        }
    }

    getPortalTargetEl = (elId) => {

        return document.getElementById(elId);
    }

    getPortalTargetsExist = (props) => {

        const { top, bottom } = props || this.props;

        const elIds = [];

        if (top) {
            elIds.push(ELEMENT_IDS.scrollPortalPaddingIds.top);
        }

        if (bottom) {
            elIds.push(ELEMENT_IDS.scrollPortalPaddingIds.bottom);
        }

        return elIds.every((elId) => this.getPortalTargetEl(elId));
    }

    respondToChildRefs = ({ type, ref }) => {

        if (type === 'top') {
            const bounds = ref.getBoundingClientRect();
            this.setPaddingHeight('top')({ bounds });
        }
        else if (type === 'bottom') {
            const bounds = ref.getBoundingClientRect();
            this.setPaddingHeight('bottom')({ bounds });
        }
    }

    pollForPortalTargets = () => {

        this.pollTimes = (this.pollTimes || 0) + 1;

        if (this.pollTimes > MAX_POLL_TIMES) {
            // Give up after polling MAX_POLL_TIMES
            console.warn('Couldn\'t find portal targets by id');
            return;
        }

        setTimeout(() => {

            if (this.getPortalTargetsExist()) {
                this.setState({ portalTargetsExist: true });
            }
            else {
                this.pollForPortalTargets();
            }
        }, 10);
    }

    render() {

        const {
            top,
            bottom,
            children
        } = this.props;

        const {
            paddingTopBounds,
            paddingBottomBounds,
            portalTargetsExist
        } = this.state;

        if (!portalTargetsExist) {
            return null;
        }

        let measuredChildren = null;

        const toRender = [];

        if (top) {
            measuredChildren = (
                <Measure
                    bounds
                    onResize={this.setPaddingHeight('top')}
                >
                    {({ measureRef }) => <div ref={measureRef}>{children}</div>}
                </Measure>
            );

            const height = (paddingTopBounds || 0);

            const scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);

            if (scrollContainer) {
                // Offset the scrollContainer so the scrollbar reaches the full height
                scrollContainer.style.marginTop = -height + 'px';
            }

            const topPaddingElement = document.getElementById(ELEMENT_IDS.scrollPortalPaddingIds.top);

            if (topPaddingElement) {
                topPaddingElement.style.height = height + 'px';
                topPaddingElement.style.paddingTop = height + 'px';
            }

            toRender.push(
                <Portal
                    selector={'#' + ELEMENT_IDS.scrollPortalIds.top}
                    key='top-portal'
                >
                    <div ref={(ref) => {

                        if (!this.topChild.current) {
                            this.topChild.current = ref;
                            this.respondToChildRefs({ type: 'top', ref });
                        }
                    }}>{measuredChildren}</div>
                </Portal>
            );
        }

        if (bottom) {
            measuredChildren = (
                <Measure
                    bounds
                    onResize={this.setPaddingHeight('bottom')}
                >
                    {({ measureRef }) => <div ref={measureRef}>{children}</div>}
                </Measure>
            );

            const height = (paddingBottomBounds || 0);

            const scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);

            if (scrollContainer) {
                // Offset the scrollContainer so the scrollbar reaches the full height
                scrollContainer.style.marginBottom = -height + 'px';
            }

            const bottomPaddingElement = document.getElementById(ELEMENT_IDS.scrollPortalPaddingIds.bottom);

            if (bottomPaddingElement) {
                bottomPaddingElement.style.height = height + 'px';
                bottomPaddingElement.style.paddingBottom = height + 'px';
            }

            toRender.push(
                <Portal
                    selector={'#' + ELEMENT_IDS.scrollPortalIds.bottom}
                    key='bottom-portal'
                >
                    <div ref={(ref) => {

                        if (!this.bottomChild.current) {
                            this.bottomChild.current = ref;
                            this.respondToChildRefs({ type: 'bottom', ref });
                        }
                    }}>{measuredChildren}</div>
                </Portal>
            );
        }

        return toRender;
    }
};
