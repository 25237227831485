const React = require('react');
const T = require('prop-types');
const { default: Classes } = require('./styles.scss');
const BottomButton = require('components/BottomButton');
const ScrollPortal = require('components/ScrollPortal');

module.exports = class BottomButtonContainer extends React.PureComponent {

    static propTypes = {
        btnLabel: T.string,
        btnClassName: T.string,
        onBtnClick: T.func,
        bottomButtonContent: T.any,
        children: T.any.isRequired,
        disabled: T.bool,
        hide: T.bool,
        disablePortal: T.bool
    }

    render() {

        const {
            btnLabel,
            onBtnClick,
            btnClassName,
            bottomButtonContent,
            children,
            disabled,
            hide,
            disablePortal
        } = this.props;

        return <div className={Classes.bottomButtonContainer}>
            <div className={Classes.innerWrapper}>
                {children}
            </div>

            {!disablePortal ?
                <ScrollPortal bottom>
                    <BottomButton
                        className={`${hide ? Classes.hideBottomBtn : ''} ${btnClassName ? btnClassName : ''}`}
                        label={btnLabel}
                        onClick={onBtnClick}
                        bottomButtonContent={bottomButtonContent ? bottomButtonContent : null}
                        disabled={disabled}
                        hide={hide}
                    />
                </ScrollPortal> :
                <BottomButton
                    className={`${hide ? Classes.hideBottomBtn : ''} ${btnClassName ? btnClassName : ''}`}
                    label={btnLabel}
                    onClick={onBtnClick}
                    bottomButtonContent={bottomButtonContent ? bottomButtonContent : null}
                    disabled={disabled}
                    hide={hide}
                />}
        </div>;
    }
};
