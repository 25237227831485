const React = require('react');
const { useState, useEffect } = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Classes } = require('./styles.scss');
const CenteredLayout = require('components/CenteredLayout');
const { useTheme } = require('@mui/material/styles');
const { default: Grid } = require('@mui/material/Grid');
const { default: Button } = require('@mui/material/Button');
const { default: Checkbox } = require('@mui/material/Checkbox');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: Typography } = require('@mui/material/Typography');
const Fonts = require('styles/fonts.json');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const FullScreenDialog = require('containers/FullScreenDialog');
const PrivacyPolicyContents = require('components/PrivacyPolicyContents');
const TosContents = require('components/TosContents');
const { useDispatch } = require('react-redux');
const { useAppSelector } = require('utils/redux');
const { context } = require('app-context');

const selectors = context.selectors.all;

const internals = {};

module.exports = function SSOAgreeToTerms(props) {

    const {
        withPreventDefault,
        StyledButton,
        StyledCheckbox
    } = internals;

    const [isChecked, setIsChecked] = useState(false);
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [tosOpen, setTosOpen] = useState(false);
    const { verificationToken } = useAppSelector(selectors.getSignupContext);

    const theme = useTheme();
    const dispatch = useDispatch();

    const onSubmit = () => {

        dispatch(context.actions.signup.step2.completeSSO({
            password: null,
            agreeToTerms: true,
            verificationToken
        }));
    };

    return <CenteredLayout
        header='Welcome to Nearpeer'
        mainClass={Classes.main}>

        <form onSubmit={withPreventDefault(onSubmit)}>
            <Grid container spacing={1} sx={{ mt: 0 }}>
                <Grid item xs={12} style={{ padding: '15px' }}>
                    <FormControl name='agreeToTerms'>
                        <FormControlLabel
                            label={
                                <Typography
                                    sx={{
                                        textAlign:'center',
                                        color: theme.palette.text.contrastText
                                    }}
                                >
                                    I agree with Nearpeer{'\''}s <a
                                        className={Classes.footerLink}
                                        href=''
                                        onClick={withPreventDefault(() => setTosOpen(true))}
                                        data-focus-outline='padding:3,radius:3'
                                    >
                                        Terms of Service
                                    </a> and <a
                                        className={Classes.footerLink}
                                        href=''
                                        onClick={withPreventDefault(() => setPrivacyPolicyOpen(true))}
                                        data-focus-outline='padding:3,radius:3'
                                    >
                                        Privacy Policy
                                    </a>.
                                </Typography>
                            }
                            control={
                                <StyledCheckbox
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                    size='small'
                                    name='agree-to-terms-checkbox'
                                    inputProps={{ 'data-focus-outline': 'radius:40' }}
                                />
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <StyledButton
                type='submit'
                disabled={!isChecked}
                data-focus-outline='radius:40,padding:4'
                onClick={() => {

                    AnimatedFocusIndicator.onBlurHandler();
                }}
            >
                Confirm
            </StyledButton>
        </form>
        <FullScreenDialog
            title='Privacy Policy'
            open={privacyPolicyOpen}
            onRequestClose={() => setPrivacyPolicyOpen(false)}
        >
            <PrivacyPolicyContents />
        </FullScreenDialog>
        <FullScreenDialog
            title='Terms of Service'
            open={tosOpen}
            onRequestClose={() => setTosOpen(false)}
        >
            <TosContents />
        </FullScreenDialog>
    </CenteredLayout>;
};

internals.withPreventDefault = (func) => {

    return (evt) => {

        evt.preventDefault();
        func?.(evt);
    };
};

internals.StyledButton = Styled(Button)`
    margin-top: 20px;
    background-color: ${({ theme }) => theme.palette.primary.contrastText};
    color: ${({ theme }) => theme.palette.primary.main};
    height: 3em;
    width: 100%;
    padding: 0;
    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.contrastText};
        color: ${({ theme }) => theme.palette.primary.main};
    }
    &.Mui-disabled{
        background-color: ${({ theme }) => theme.palette.primary.contrastText};
        color: ${({ theme }) => theme.palette.action.disabled};
    }
    font-family: ${Fonts.headerFont};
    font-weight: bold;
    text-transform: none;
    font-size: 1rem;
    line-height: 3em;
`;

internals.StyledCheckbox = Styled(Checkbox)`
    &&& {
        color: ${({ theme }) => theme.palette.common.white};
        fill: ${({ theme }) => theme.palette.common.white};
    }
`;
