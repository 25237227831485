const T = require('prop-types');

module.exports = {
    Notification: {
        message: T.string,
        action: T.shape({
            label: T.string,
            onExecute: T.func
        }),
        origin: T.any,
        popUpTitle: T.string,
        displayType: T.oneOf(['snackbar','popUp',null,undefined])
    }
};
