const { ASYNC,SIMPLE } = require('../utils/redux-helpers');

module.exports = {
    JOIN: ASYNC,
    LEAVE: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    SET_SEARCH_TEXT: SIMPLE,
    SET_ALL_GROUPS_SORTING_TYPE: SIMPLE,
    CLEAR_SEARCH_TEXT: SIMPLE,
    UPDATE_NOTIFICATION_LEVEL: ASYNC
};
