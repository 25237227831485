const T = require('prop-types');
const { push: Push } = require('connected-react-router');
const { makeActionCreator } = require('../utils/redux-helpers');
const { SIGNUP: Types } = require('../action-types');
const {
    Context: SignupContext,
    Step1,
    Step2,
    Step3,
    Complete,
    SignupCompleted,
    ProfilePicture
} = require('../domain/signup');

module.exports = (context) => {

    const api       = context.api.nearpeer;
    const selectors = context.selectors.all;
    const actions   = context.actions;
    const redux     = context.redux.hooks;

    return {
        initContext: makeActionCreator(Types.CONTEXT_INIT, SignupContext),
        updateContext: makeActionCreator(Types.CONTEXT_UPDATE, SignupContext),
        verifyEmail: makeActionCreator(Types.VERIFY_EMAIL, { verificationToken: T.string }, {
            async: api.signup.verifyEmail
        }),
        checkSchoolSSO: makeActionCreator(Types.SCHOOL_CHECK, { email: T.string }, {
            async: api.signup.checkSchoolSSO
        }),
        step1: {
            complete: makeActionCreator(Types.STEP_1.COMPLETE, Step1, {
                async: async (payload) => {

                    redux.dispatch(actions.signup.initContext(payload));

                    // Create user in DB first if not exists already
                    const signupRes = await api.signup.startSignup(payload);

                    // Get sso and other props into signup context
                    await redux.dispatch(actions.signup.checkSchoolSSO({
                        email: payload.email
                    }));

                    return signupRes;
                },
                after: ({ payload, state }) => {

                    const {
                        request: { email },
                        result: {
                            data: {
                                s: schoolId,
                                r: roleId
                            }
                        }
                    } = payload;

                    const {
                        usingSSO
                    } = selectors.getSignupContext(state);

                    redux.dispatch(actions.signup.updateContext({
                        email,
                        schoolId,
                        roleId
                    }));

                    if (usingSSO) {
                        return Push('/signup-sso');
                    }

                    return Push('/signup/verify-email');
                }
            }),
            resendEmail: makeActionCreator(Types.STEP_1.RESEND_EMAIL, { email: T.string }, {
                async: api.signup.resendEmail,
                after: () => {

                    return redux.dispatch(actions.alerts.notification.push({
                        message: 'Verification Email resent'
                    }));
                }
            }),
            failure: makeActionCreator(Types.STEP_1.FAILURE, {})
        },
        step2: {
            checkPassword: makeActionCreator(Types.STEP_2.CHECK_PASSWORD, { verificationToken: T.string, email: T.string }, {
                async: api.signup.checkPassword
            }),
            complete: makeActionCreator(Types.STEP_2.COMPLETE, Step2, {
                async: api.signup.storePassword,
                after: () => Push('/signup/user-info')
            }),
            completeSSO: makeActionCreator(Types.STEP_2.COMPLETE_SSO, Step2, {
                after: () => Push('/signup/user-info')
            })
        },
        step3: {
            fetchSignupProgress: makeActionCreator(Types.STEP_3.FETCH_SIGNUP_PROGRESS, { verificationToken: T.string }, {
                async: api.signup.fetchSignupProgress
            }),
            updateSignupProgress: makeActionCreator(Types.STEP_3.UPDATE_SIGNUP_PROGRESS, { verificationToken: T.string, signupDetails: Step3 }, {
                async: api.signup.updateSignupProgress
            }),
            updateSignupProgressProfilePic: makeActionCreator(Types.STEP_3.UPDATE_SIGNUP_PROGRESS_PROFILE_PICTURE, { verificationToken: T.string, pictureDetails: ProfilePicture }, {
                async: api.signup.updateSignupProgressProfilePic
            }),
            updatePreferencesPresignup: makeActionCreator(Types.STEP_3.UPDATE_USER_PREFERENCES_PRE_SIGNUP, { verificationToken: T.string, prefs: T.object }, {
                async: api.signup.updateUserPreferencesPreSignup
            }),
            complete: makeActionCreator(Types.STEP_3.COMPLETE, Step3, {
                after: ({ state }) => {

                    return actions.signup.complete(
                        selectors.getSignupDetailsForCompletion(state)
                    );
                }
            }),
            goBack: makeActionCreator(Types.STEP_3.GO_BACK, Step3, {
                after: ({ state }) => {

                    return Push(`/signup/choose-password`);
                }
            })
        },
        complete: makeActionCreator(Types.COMPLETE, Complete, {
            async: (prefsAndDetails) => {

                const {
                    preferences: {
                        phone,
                        phoneCountry,
                        profileEmail,
                        ...preferences
                    },
                    ...signupDetails
                } = prefsAndDetails;

                return api.signup.completeSignup({
                    phone: phone || undefined,
                    phoneCountry: phoneCountry || undefined,
                    profileEmail: profileEmail || undefined,
                    ...signupDetails
                })
                .then((signupRes) => {

                    return redux.dispatch(actions.auth.loginWithVerificationToken({
                        verificationToken: signupDetails.verificationToken
                    }));
                })
                .then(() => {

                    return redux.dispatch(actions.profileManagement.updateUserPreferences(preferences));
                })
                .then(() => undefined);
            },
            after: () => {

                redux.dispatch(actions.signup.signupCompleted({ SignupCompleted: true }));

                return Promise.resolve(

                    redux.dispatch(Push('/app/welcome'))

                ).then(() =>

                    undefined
                );
            }
        }),
        signupCompleted: makeActionCreator(Types.SIGNUP_COMPLETED, SignupCompleted)
    };
};
