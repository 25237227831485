const { context } = require('../app-context');
const LocalStorageAvailable = require('../utils/check-storage');

module.exports = (store) => {

    const selectors = context.selectors.all;
    const actions = context.actions;
    const redux = context.redux.hooks;

    if (!LocalStorageAvailable('persist')) {
        redux.dispatch(actions.auth.login({ token: false }));
        return;
    }

    const persistSet = (key, value) => localStorage.setItem(key, JSON.stringify(value));
    const persistGet = (key) => JSON.parse(localStorage.getItem(key) || 'null');
    const persistRemove = (key) => localStorage.removeItem(key);

    const getIsAuthenticated = () => selectors.getIsAuthenticated(store.getState());
    const getApiToken = () => selectors.getApiToken(store.getState());

    const token = persistGet('token');

    let previousApiToken = token;

    store.subscribe(() => {

        if (getIsAuthenticated()) {
            try {
                const apiToken = getApiToken();
                if (apiToken !== previousApiToken) {
                    persistSet('token', apiToken);
                }

                previousApiToken = apiToken;
            }
            catch (err) {
                persistRemove('token');
            }
        }
        else {
            persistRemove('token');
        }
    });

    // We unconditionally dispatch login() during init so that auth state is guaranteed to settle
    redux.dispatch(actions.auth.login({ token }));

    window.redux = redux;
};
