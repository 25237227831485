const React = require('react');
const T = require('prop-types');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: Typography } = require('@mui/material/Typography');
const { default: Slider } = require('@mui/material/Slider');
const { default: Box } = require('@mui/material/Box');
const { default: Button } = require('@mui/material/Button');
const { default: DoneIcon } = require('@mui/icons-material/Done');
const { default: RotateIcon } = require('@mui/icons-material/Rotate90DegreesCcw');
const { default: CancelIcon } = require('@mui/icons-material/Close');
const AvatarEditor = require('react-avatar-editor');
const { default: Styled } = require('styled-components');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const { createRef } = React;

const internals = {};

module.exports = class CropAvatarDialog extends React.PureComponent {

    static propTypes = {
        open: T.bool,
        file: T.any,
        onDone: T.func,
        onClose: T.func,
        editorProps: T.object
    }

    static defaultProps = {
        editorProps: {}
    }

    constructor() {

        super();

        this.initialState = this.state = {
            position: { x: .5, y: .5 },
            rotation: 0,
            scale: 1
        };

        this.editor = createRef();

        this.handlePositionChange = this._handlePositionChange.bind(this);
        this.handleScaleSliderChange = this._handleScaleSliderChange.bind(this);
        this.rotate = this._rotate.bind(this);
        this.close = this._close.bind(this);
        this.done = this._done.bind(this);
    }

    _handleScaleSliderChange(event, newValue) {

        this.setState({
            scale:newValue
        });
    }

    _handlePositionChange(position) {

        this.setState({ position });
    }

    _rotate() {

        const { rotation } = this.state;

        return this.setState({ rotation: (rotation + 270) % 360 });
    }

    _done() {

        AnimatedFocusIndicator.onBlurHandler();

        this.props.onDone({
            position: this.state.position,
            rotation: this.state.rotation,
            scale: this.state.scale,
            canvas: this.editor.current.getImage()
        });

        return this.setState(this.initialState);
    }

    _close() {

        AnimatedFocusIndicator.onBlurHandler();

        this.props.onClose();

        return this.setState(this.initialState);
    }

    valueLabelFormat = (value) => {

        return `(${parseInt(value * 100)}%)`;
    }

    render() {

        const { styles, StyledDialogContent } = internals;
        const { open, file, editorProps, ...others } = this.props;
        const { position, rotation, scale } = this.state;

        return <Dialog
            {...others}
            PaperProps={{
                'aria-label': 'Crop photo dialog',
                'aria-labelledby': null // Override internal assignment of label
            }}
            modal
            open={open}
            autoDetectWindowHeight={false}
        >
            <StyledDialogContent>
                <AvatarEditor
                    {...editorProps}
                    style={styles.editor}
                    ref={this.editor}
                    image={file}
                    height={210}
                    width={210}
                    border={24}
                    scale={scale}
                    position={position}
                    rotate={rotation}
                    onPositionChange={this.handlePositionChange}
                />
                <Box component="div" mt={2} >
                    <Slider
                        value={scale}
                        onChange={this.handleScaleSliderChange}
                        aria-labelledby="zoom-slider"
                        valueLabelDisplay="auto"
                        valueLabelFormat={this.valueLabelFormat}
                        step={0.1}
                        marks
                        min={1}
                        max={2}
                    />
                    <Typography id="zoom-slider" gutterBottom>
                        Move Slider to Zoom or Drag Photo to Pan
                    </Typography>
                </Box>
            </StyledDialogContent>
            <DialogActions style={styles.actionsContainer}>
                <Button
                    key='cancel'
                    variant="contained"
                    startIcon={<CancelIcon />}
                    onClick={this.close}
                    style={styles.rotateButton}
                    data-focus-outline='radius:40,padding:3'
                >
                    Cancel
                </Button>
                <Button
                    key='rotate'
                    variant="contained"
                    startIcon={<RotateIcon />}
                    onClick={this.rotate}
                    style={styles.rotateButton}
                    data-focus-outline='radius:40,padding:3'
                >
                    Rotate
                </Button>
                <Button
                    key='done'
                    variant="contained"
                    startIcon={<DoneIcon />}
                    onClick={this.done}
                    data-focus-outline='radius:40,padding:3'
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>;
    }
};

internals.StyledDialogContent = Styled(DialogContent)`
    padding: 8px 10px!important;
    @media screen and (min-width: 600px) {
         padding: 8px 40px!important;
    }
`;

internals.styles = {
    editor: {
        margin: '0 auto',
        display: 'block',
        cursor: 'move'
    },
    actionsContainer: {
        textAlign: 'center',
        paddingBottom: 32,
        justifyContent:'center'
    },
    rotateButton: {
        marginRight: 6
    }
};
