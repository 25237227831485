const { allCustomSignups } = require('utils/customSignups');

const internals = {};

module.exports = (path) => {

    const { pathHashMap } = internals;

    if (pathHashMap[path]) {
        return pathHashMap[path];
    }

    // Paths with dynamic parts after the last slash
    // Unfortunately, order matters here bc multiple paths can match some.
    if (path.includes('/app/profile/')) {
        return 'User Profile';
    }
    else if (path.includes('/app/messaging/')) {
        return 'Direct Message';
    }
    else if (path.includes('/app/classes/chat/')) {
        return 'Group Chat';
    }
    else if (path.includes('/app/classes/announcement/chat/')) {
        return 'Group Chat';
    }
    else if (path.includes('/app/classes/') && path.includes('/edit')) {
        return 'Edit Group';
    }
    else if (path.includes('/app/classes/')) {
        return 'Group Details';
    }
    else if (path.includes('/app/surveys/') && path.includes('/edit')) {
        return 'Edit Survey';
    }
    else if (path.includes('/app/surveys/') && path.includes('/report')) {
        return 'Survey Report';
    }
    else if (path.includes('/app/surveys/') && path.includes('/start')) {
        return 'Start Survey';
    }
    else if (path.includes('/unsubscribe/')) {
        return 'Unsubscribe';
    }
    else if (path.includes('/family')) {
        return 'Signup';
    }
    else if (path.includes('/resubscribe/')) {
        return 'Resubscribe';
    }

    const pathNoSlashes = path.replaceAll('/', '').toLowerCase();
    if (allCustomSignups[pathNoSlashes]) {
        return 'Signup';
    }
};

internals.pathHashMap = {
    '/app/welcome': 'Welcome',
    '/app/profile/edit': 'Edit Profile',
    '/app/profile/edit/interests': 'Edit Interests',
    '/app/profile/change-password': 'Change Password',
    '/app/discover': 'Discover',
    '/app/messaging': 'Messaging',
    '/app/classes': 'My Groups',
    '/app/preferences': 'User Preferences',
    '/app/classes/create': 'Create Group',
    '/app/admin/batch-notify-users': 'Admin Batch Notify',
    '/app/admin/preapprove-users': 'Admin Preapprove Users',
    '/app/surveys/my-surveys': 'My Surveys',
    '/app/surveys/management': 'Survey Management',
    '/app/surveys/create': 'Create Survey',
    '/app/bug-report': 'Bug Report',
    '/terms-of-service': 'Terms of Service',
    '/privacy-policy': 'Privacy Policy',
    '/accessibility': 'Accessibility Statement',
    '/login': 'Login',
    '/forgot-password': 'Forgot Password',
    '/password-reset': 'Password Reset',
    '/login-help': 'Login Help',
    '/nearpeer-pakistan': 'Pakistan Notice',
    '/reset-link-expired': 'Reset Link Expired',
    '/signup': 'Signup',
    '/signup/verify-email': 'Verify Email',
    '/signup/choose-password': 'Choose Password',
    '/signup/user-info': 'Create Profile',
    '/app/not-found': 'Not Found'
};
