const T = require('prop-types');

module.exports = {
    Credentials: {
        email: T.string,
        password: T.string
    },
    Token: T.string,
    User: {
        firstName: T.string,
        lastName: T.string
    }
};
