const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { PROFILE_MANAGEMENT: Types } = require('../action-types');

module.exports = (context) => {

    const api     = context.api.nearpeer;
    const actions = context.actions;

    return {
        updateUserPreferences: makeActionCreator(Types.UPDATE_USER_PREFERENCES, undefined, { // see diff in reducers/data-fetching
            async: api.profileManagement.updateUserPreferences,
            after: () => actions.dataFetching.fetchPreferences()
        }),
        updateProfile: makeActionCreator(Types.UPDATE_PROFILE, undefined, {
            async: api.profileManagement.updateProfile,
            after: ({ dispatch }) => {

                dispatch(actions.dataFetching.fetchCurrentUser());
                dispatch(actions.dataFetching.fetchPreferences());
            }
        }),
        updateProfilePicture: makeActionCreator(Types.UPDATE_PROFILE_PICTURE, undefined, {
            async: api.profileManagement.updateProfilePicture,
            after: ({ dispatch }) => {

                dispatch(actions.dataFetching.fetchCurrentUser());
            }
        }),
        changePassword: makeActionCreator(Types.CHANGE_USER_PASSWORD, { password: T.string, newPassword: T.string, newPasswordConfirm: T.string, logoutOtherDevices: T.bool }, {
            async: api.passwordManagement.changePassword
        })
    };
};
