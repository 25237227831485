const T = require('prop-types');
const { push: Push } = require('connected-react-router');
const { makeActionCreator } = require('../utils/redux-helpers');
const { SURVEYS: Types } = require('../action-types');
const Domain = require('../domain');

module.exports = (context) => {

    const api = context.api.nearpeer;
    const actions = context.actions;

    return {
        create: makeActionCreator(Types.CREATE, { ...Domain.Survey,selectedRole:T.string, selectedUsers:T.array }, {
            async: (survey) => {

                let selectedUsersIds = [];

                if (survey.selectedUsers && survey.selectedUsers.length > 0){
                    selectedUsersIds = survey.selectedUsers.map(({ id }) => parseInt(id));
                }

                return api.surveys.create( {
                    ...survey,
                    selectedUsers:selectedUsersIds
                });
            },
            after: ({ dispatch, payload }) => {


                dispatch(actions.alerts.notification.push({
                    message: `Created Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                dispatch(Push('/app/surveys/management'));
            }
        }),
        update:  makeActionCreator(Types.UPDATE, Domain.Survey, {
            async: (survey) => {

                return api.surveys.update( survey);
            },
            after: ({ dispatch, payload }) => {

                dispatch(actions.alerts.notification.push({
                    message: `Updated Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                dispatch(Push('/app/surveys/management'));
            }
        }),
        delete:  makeActionCreator(Types.DELETE_SURVEY, {
            surveyId: T.number
        }, {
            async: ({ surveyId }) => {

                return api.surveys.delete( { surveyId });
            },
            after: ({ dispatch }) => {

                dispatch(actions.alerts.notification.push({
                    message: `Deleted Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                //dispatch(Push('/app/surveys/management'));
            }
        }),
        stopSurvey:  makeActionCreator(Types.STOP_SURVEY, {
            surveyId: T.string
        }, {
            async: ({ surveyId }) => {

                return api.surveys.stopSurvey( { surveyId });
            },
            after: ({ dispatch }) => {

                dispatch(actions.alerts.notification.push({
                    message: `Stopped Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                //dispatch(Push('/app/surveys/management'));
            }
        }),
        startSolving:  makeActionCreator(Types.START_SOLVING, {
            userId: T.string,
            surveyId: T.string
        }, {
            async: ({ userId,surveyId }) => {

                return api.surveys.startSolving( { userId,surveyId });
            }
        }),
        getReport:  makeActionCreator(Types.START_SOLVING, {
            surveyId: T.string
        }, {
            async: ({ surveyId }) => {

                return api.surveys.report( { surveyId });
            }
        }),
        submitAnswers:  makeActionCreator(Types.SUBMIT_ANSWERS, { questions:T.array, userId: T.string,
            surveyId: T.string  }, {
            async: ({ questions, surveyId, userId }) => {

                return api.surveys.submitAnswers( { questions, surveyId, userId });
            },
            after: ({ dispatch, payload }) => {

                dispatch(actions.dataFetching.fetchCurrentUser());
            }
        })
    };
};
