const { connect } = require('react-redux');
const { context } = require('app-context');
const NotifyUserDialog = require('../components/NotifyUserDialog');

const selectors = context.selectors.all;
const actions   = context.actions;

const internals = {};

module.exports = connect(
    (state) => ({
        schoolId: selectors.getCurrentSchoolId(state)
    }),
    {
        onSubmit: actions.communication.targetNotifyUser
    }
)(NotifyUserDialog);
