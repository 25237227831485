exports.groupChatLink = (group) => group.isAnnouncement ? `/app/classes/announcement/chat/${group.id}` : `/app/classes/chat/${group.sid}`;

exports.groupMembersLink = (group) => `/app/classes/${group.id}`;

exports.gotoGroupLink = (group, currentUserGroups) => {

    const alreadyBelongs = currentUserGroups.find(({ id }) => id === group.id);

    if (alreadyBelongs) {
        return exports.groupChatLink(group);
    }

    return exports.groupMembersLink(group);
};
