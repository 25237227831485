const T = require('prop-types');
const { default: Styled } = require('styled-components');

const Snackbar = require('../containers/Snackbar');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const internals = {};

module.exports = function PlainLayout(props) {

    const {
        snackbarProps,
        children
    } = props;

    const { Wrapper } = internals;

    return (
        <Wrapper onFocus={AnimatedFocusIndicator.onFocusHandler} onBlur={AnimatedFocusIndicator.onBlurHandler}>
            {children}
            <AnimatedFocusIndicator />
            <Snackbar {...snackbarProps} />
        </Wrapper>
    );
};

module.exports.propTypes = {
    snackbarProps: T.object,
    children: T.element.isRequired
};

internals.Wrapper = Styled.div`
    height: 100%;

    *:focus {
        outline: none !important;
    }
`;
