const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

const { default: UploadedFileIcon } = require('@mui/icons-material/Attachment');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: SelectField } = require('@mui/material/Select');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const { default: Classes } = require('./styles.scss');
const { default: Checkbox } = require('@mui/material/Checkbox');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: TextField } = require('@mui/material/TextField');
const { default: Typography } = require('@mui/material/Typography');
const BottomButtonContainer = require('components/BottomButtonContainer');
const LinearProgress = require('components/LinearProgress');

const SimpleDialog = require('components/SimpleDialog');
const IsEmail = require('utils/is-email');

const {
    createRef,
    useState,
    useEffect,
    useRef
} = React;

const MB_IN_BYTES = 1000000;

const internals = {
    mailTypes: [
        {
            value:'student',
            label:'Students'
        },
        {
            value:'staff',
            label:'Staff'
        },
        {
            value:'parent',
            label:'Parents'
        }
    ]
};

const StyledFormControl = Styled(FormControl)`
    width: 100%;
    margin: 0.2em 0em;
`;

module.exports = class PreapprovedEmailUpload extends React.PureComponent {

    static propTypes = {
        api: T.any.isRequired,
        schoolId: T.number.isRequired,
        schoolName: T.string.isRequired,
        schoolRoles: T.array,
        onSubmit: T.func.isRequired
        // showToast: T.func.isRequired
    }

    constructor(props) {

        super(props);

        this.state = {
            csv: null,
            roleName: '',
            notifyUsers: false,
            showProcessingDialog: false,
            csvDuplicates: [],
            totalRows: 0,
            processingCacheKey: null
        };

        this.fields = ['roleName', 'csv', 'userByText', 'notifyUsers'];
        this.requiredFields = ['roleName'];

        this.error = this._error.bind(this);
        this.reset = this._reset.bind(this);
        this.selectFile = this._selectFile.bind(this);
        this.setFieldValue = this._setFieldValue.bind(this);
        this.setSelectValue = this._setSelectValue.bind(this);
        this.setCheckboxValue = this._setCheckboxValue.bind(this);
        this.validate = this._validate.bind(this);

        this.fabInputRef = createRef();
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? this.validate(field) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }

        if (field === 'roleName') {
            const error = this.validate(field);

            if (error !== null) {
                return <div id={this.id + '-' + field + '-error-message'} role='alert' aria-live='assertive'>{error}</div>;
            }

            return null;
        }

        return this.validate(field);
    }

    _selectFile(ev) {

        const newCsv = ev.target.files[0];
        if (newCsv === null) {
            return;
        }

        this.showError('csv')();
        this.setState({
            csv: newCsv
        });

        ev.target.value = null; // Reset so we can select the same file and still trigger an onChange
    }

    _setFieldValue(field) {

        return (ev, info) => {

            // Nullish operator accepts ''
            const value = info?.newValue ?? ev?.target?.value ?? ev;

            this.setState({
                [field]: value
            });
        };
    }

    _setCheckboxValue(field){

        return (event, index, value) => {

            this.setState({
                [field]: !this.state[field]
            });
        };
    }
    _setSelectValue(field) {

        return (event, index, value) => {

            if (field === 'roleName') {
                this.showError('roleName');
            }

            this.setState({
                [field]: event.target.value
            });
        };
    }

    _validate(field) {

        const value = this.state[field];

        if (this.requiredFields.find((f) => f === field) && (!value || value === '')) {
            if (field === 'roleName') {
                return 'User type is required!';
            }

            return field + ' is required!';
        }

        switch (field) {
            case 'userByText':
                if (value && !IsEmail(value)) {
                    return 'Invalid email address';
                }
                else if (!value && !this.state.csv) {
                    return 'Must specify user email here or upload CSV below';
                }

                break;
            case 'csv':
                if (!value && !this.state.userByText) {
                    return 'Must upload CSV here or specify user email above';
                }
                else if (!value) {
                    return null;
                }

                // eslint-disable-next-line no-case-declarations
                const match = value.name.match(/\.csv$/);
                if (match === null) {
                    return 'File must be a csv';
                }

                if (value.size > (4.9 * MB_IN_BYTES)) {
                    return 'csv must be under 5mb';
                }

                if (!this.state.userByText && this.state.userByTextErrorShow) {
                    this.showError('userByText', false)();
                }

                break;
            case 'roleName': {

                if (!this.state.roleName && (value === null || value === '' )) {
                    return 'Preapproved role is required';
                }

                break;
            }
        }

        return null;
    }

    _reset() {

        this.setState({
            roleName:'',
            userByText: '',
            csv: null,
            // hide errors (required field errors display on submit when still on form)
            ...this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                return collector;
            }, {})
        });
    }

    submit = async () => {

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            // On submit attempt, all errors are fair game to display

            return this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;

                if (this.validate(field)) {
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;
            }, {}));
        }

        const payload = this.fields.reduce((collector, field) => {

            collector[field] = this.state[field];
            return collector;
        }, {});

        // No errors?  Submit the field/values

        const {
            schoolId,
            onSubmit
        } = this.props;

        this.setState({ showProcessingDialog: true });

        const {
            payload: {
                result: {
                    httpPost,
                    csvDuplicates,
                    totalRows
                }
            }
        } = await onSubmit({
            ...payload,
            schoolId
        });

        this.setState({
            csvDuplicates,
            totalRows
        });

        const {
            data: {
                processingCacheKey
            }
        } = await httpPost;

        this.setState({
            processingCacheKey
        });
    }

    render() {

        const {
            schoolName,
            schoolRoles,
            api
        } = this.props;

        const {
            csv,
            userByText,
            roleName
        } = this.state;

        const {
            FileUploadText,
            OrSpacer,
            PreapprovedProcessingDetails
        } = internals;

        const isCsvDisabled = !!userByText;

        return (
            <BottomButtonContainer
                btnLabel='Create&nbsp;User(s)'
                onBtnClick={this.submit}
                disabled={this.state.submitting}
            >
                <h2>Preapprove Users</h2>
                <div className={Classes.uploadFileInstructions}>
                    For <b>{schoolName}</b>
                </div>
                <div className={Classes.uploadFileInstructions}>
                    {'Enter a single email address to create one new user -OR- upload a CSV file containing multiple user emails for preapproval.'}
                </div>
                <div className={Classes.uploadFileInstructions}>
                    {'Make sure the CSV file contains only one column with a header row named \'EMAIL\'. Please select below which user type to create.'}
                </div>

                <StyledFormControl>
                    <div style={{ marginTop: 10 }}>
                        <FormControlLabel
                            label='Notify Users'
                            labelPlacement='end'
                            control={<Checkbox
                                checked={!!this.state.notifyUsers}
                                onChange={this.setCheckboxValue('notifyUsers')}
                            />}
                        />
                    </div>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel  error={this.state.incomingClassErrorShow} id='user-type-select-input-label'>
                        User type *
                    </InputLabel>
                    <SelectField
                        // SelectField has width: 256px set as inline style
                        style={{ width: '100%' }}
                        id='user-type-select-input'
                        inputProps={{
                            'aria-labelledby': 'user-type-select-input-label'
                        }}
                        labelId='user-type-select-input-label'
                        value={roleName}
                        defaultValue={roleName}
                        error={this.state.roleNameErrorShow}
                        onBlur={this.showError('roleName')}
                        onChange={this.setSelectValue('roleName')}
                        maxheight={220}
                    >
                        <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                        {schoolRoles.map((schoolRole) => <MenuItem key={schoolRole.id} value={schoolRole.name}>{`${schoolRole.label}`}</MenuItem>)}
                    </SelectField>
                    {this.state.roleNameErrorShow && <FormHelperText error={this.state.roleNameErrorShow}>{this.error('roleName')}</FormHelperText>}
                </StyledFormControl>
                <StyledFormControl>
                    <TextField
                        fullWidth
                        label='Single Email Address'
                        id='user-text-input'
                        classes={{
                            root: Classes.textFieldRoot
                        }}
                        InputLabelProps={{
                            classes: {
                                root: Classes.textFieldInputLabelRoot
                            }
                        }}
                        error={this.state.userByTextErrorShow}
                        value={userByText}
                        inputProps={{ type: 'email' }}
                        onBlur={this.showError('userByText')}
                        onChange={this.setFieldValue('userByText')}
                    />
                    {this.state.userByTextErrorShow && <FormHelperText error={this.state.userByTextErrorShow}>{this.error('userByText')}</FormHelperText>}
                </StyledFormControl>
                <OrSpacer>
                    <Typography>{'-OR-'}</Typography>
                </OrSpacer>
                <div className={Classes.uploadFileWrapper}>
                    <div className={`${Classes.emptyUploadArea} ${isCsvDisabled ? Classes.disabledEmptyUploadArea : ''}`}>
                        <label className={`${Classes.uploadLabel} ${isCsvDisabled ? Classes.disabledUploadLabel : ''}`}>
                            {csv
                                ? (<div>
                                    <FileUploadText><UploadedFileIcon className={Classes.uploadedFileIcon} />{csv.name}</FileUploadText>
                                </div>)
                                : <FileUploadText>Click to Select a CSV File</FileUploadText>}
                            <input
                                disabled={isCsvDisabled}
                                type='file'
                                accept='text/csv'
                                onChange={this.selectFile}
                                style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                            />
                        </label>
                    </div>
                </div>
                {this.error('csv') && <FormHelperText error={this.error('csv')}>
                    {this.error('csv')}
                </FormHelperText>}
                <SimpleDialog
                    isOpen={this.state.showProcessingDialog}
                    onConfirm={() => {

                        this.reset();
                        this.setState({ showProcessingDialog: false });
                    }}
                    title='Processing CSV'
                    content={(
                        <PreapprovedProcessingDetails
                            totalRows={this.state.totalRows || 0}
                            csvDuplicates={this.state.csvDuplicates || []}
                            processingCacheKey={this.state.processingCacheKey || null}
                            checkProgress={api.communication.checkPreapprovedProgress}
                        />
                    )}
                />
            </BottomButtonContainer>
        );
    }
};

internals.FileUploadText = Styled.div`
    text-decoration: underline;
`;

internals.OrSpacer = Styled.div`
    margin: 1em 0;
    text-align: center;
`;

const CHECK_PROGRESS_INTERVAL = 1000;

internals.PreapprovedProcessingDetails = function PreapprovedProcessingDetails(props) {

    const {
        totalRows = 0,
        csvDuplicates,
        processingCacheKey,
        checkProgress
    } = props;

    const {
        formatIntegerWithCommas
    } = internals;

    const [processedCount, setProcessedCount] = useState(0);
    const [insertCount, setInsertCount] = useState(0);
    const [duplicatesCount, setDuplicatesCount] = useState(0);
    const [movedCount, setMovedCount] = useState(0);
    const [notifyCount, setNotifyCount] = useState(0);
    const [erroredCount, setErroredCount] = useState(0);

    const [isComplete, setIsComplete] = useState(false);
    const interval = useRef(null);

    useEffect(() => {

        if (processingCacheKey) {
            clearInterval(interval.current);
            interval.current = setInterval(async () => {

                let {
                    data: {
                        processedCount: newProcessedCount,
                        insertCount: newInsertCount,
                        duplicatesCount: newDuplicatesCount,
                        movedCount: newMovedCount,
                        notifyCount: newNotifyCount,
                        erroredCount: newErroredCount,
                        isComplete: newIsComplete
                    }
                } = await checkProgress(processingCacheKey);

                if (newProcessedCount === null || typeof newProcessedCount === 'undefined') {
                    newIsComplete = true;
                }
                else {
                    setProcessedCount(newProcessedCount);
                    setInsertCount(newInsertCount);
                    setDuplicatesCount(newDuplicatesCount);
                    setMovedCount(newMovedCount);
                    setNotifyCount(newNotifyCount);
                    setErroredCount(newErroredCount);

                    if (newProcessedCount === totalRows) {
                        newIsComplete = true;
                    }
                }

                if (newIsComplete) {
                    clearInterval(interval.current);
                    setIsComplete(true);
                }

            }, CHECK_PROGRESS_INTERVAL);
        }

        return () => clearInterval(interval.current);
    }, [processingCacheKey]); // eslint-disable-line react-hooks/exhaustive-deps

    // Log csvDuplicates in case the uploader wants the emails
    useEffect(() => {

        if (csvDuplicates?.length) {
            console.log('csvDuplicates', csvDuplicates);
        }
    }, [csvDuplicates?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const progress = totalRows === 0
        ? 0
        : Number(processedCount / totalRows).toFixed(2) * 100;

    return (
        <div>
            <LinearProgress value={progress || 0} />
            <Typography style={{ marginTop: 12 }}>Total: {formatIntegerWithCommas(totalRows || 0)}</Typography>
            <Typography>Processed: {formatIntegerWithCommas(processedCount || 0)}</Typography>
            <Typography>Users Created: {formatIntegerWithCommas(insertCount || 0)}</Typography>
            <Typography>Users Invited: {formatIntegerWithCommas(notifyCount || 0)}</Typography>
            <Typography>Users Moved: {formatIntegerWithCommas(movedCount || 0)}</Typography>
            <Typography>DB Duplicates: {formatIntegerWithCommas(duplicatesCount || 0)}</Typography>
            <Typography>CSV Invalid: {formatIntegerWithCommas(erroredCount || 0)}</Typography>
            <Typography>CSV Duplicates: {formatIntegerWithCommas(csvDuplicates?.length || 0)}</Typography>
            {!isComplete && <Typography color='text.secondary' style={{ marginTop: 12 }}>Processing...</Typography>}
            {isComplete && <Typography color='green' style={{ marginTop: 12 }}>Processing Complete!</Typography>}
        </div>
    );
};

internals.PreapprovedProcessingDetails.propTypes = {
    checkProgress: T.func,
    totalRows: T.number,
    csvDuplicates: T.array,
    processingCacheKey: T.string
};

internals.formatIntegerWithCommas = (num) => {

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
