const CenteredLayout = require('components/CenteredLayout');
const Loader = require('components/Loader');

const FallbackLoader = () => {

    return <CenteredLayout>
        <div style={{ height: '20vh' }}>
            <Loader />
        </div>
    </CenteredLayout>;
};

module.exports = FallbackLoader;
