const React = require('react');
const T = require('prop-types');
const OfflineIndicator = require('components/OfflineIndicator');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const { default: Classes } = require('./styles.scss');
const { ELEMENT_IDS } = require('utils/constants');

const internals = {};

module.exports = class ScrollContainerLayout extends React.PureComponent {

    static propTypes = {
        children: T.node,
        isOffline: T.bool
    }

    render() {

        const { styles } = internals;

        return <div className={Classes.wrapper}>
            <OfflineIndicator isOffline={!!this.props.isOffline} />
            <div className={Classes.main}>

                {/* Portal for scroll sibling top */}
                <div
                    id={ELEMENT_IDS.scrollPortalIds.top}
                    className={Classes.scrollPortalTop}
                />
                {/* Scroll container */}
                <div
                    id={ELEMENT_IDS.scrollContainer}
                    className={Classes.scrollContainer}
                    onFocus={AnimatedFocusIndicator.onFocusHandler}
                    onBlur={AnimatedFocusIndicator.onBlurHandler}
                >
                    {/* Portal for scroll padding top */}
                    <div
                        id={ELEMENT_IDS.scrollPortalPaddingIds.top}
                        style={styles.scrollPadding}
                    />

                    <div className={Classes.mainInnerWrapper}>
                        {/* Main content */}
                        {this.props.children}
                    </div>

                    {/* Portal for scroll padding bottom */}
                    <div
                        id={ELEMENT_IDS.scrollPortalPaddingIds.bottom}
                        style={styles.scrollPadding}
                    />
                </div>

                {/* Portal for scroll sibling bottom */}
                <div
                    id={ELEMENT_IDS.scrollPortalIds.bottom}
                    className={Classes.scrollPortalBottom}
                />
                <AnimatedFocusIndicator />
            </div>
        </div>;
    }
};

internals.styles = {
    scrollPadding: {
        width: '100%'
    }
};
