const AppContext = require('../app-context');

module.exports = (store) => {

    AppContext.registerReduxHooks({
        subscribe: store.subscribe,
        dispatch: store.dispatch,
        getState: store.getState
    });
};
