const React = require('react');

const PlainLayout = require('layouts/PlainLayout');
const AppBarLayout = require('layouts/AppBarLayout');
const AccessibilityContents = require('components/AccessibilityContents');
const { default: Classes } = require('./styles.scss');

module.exports = class Accessibility extends React.PureComponent {

    render() {

        return (
            <PlainLayout>
                <AppBarLayout>
                    <AccessibilityContents className={Classes.legalContents} />
                </AppBarLayout>
            </PlainLayout>
        );
    }
};
