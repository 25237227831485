const React = require('react');
const T = require('prop-types');
const Infinite = require('react-infinite');
const { default: Typography } = require('@mui/material/Typography');
const { alpha } = require('@mui/material/styles');

const { default: Measure } = require('react-measure');
const Debounce = require('lodash/debounce');
const Loader = require('components/Loader');
const ChatItem = require('./Item');
const { ELEMENT_IDS } = require('utils/constants');
const { default: Classes } = require('./styles.scss');
const { default: clsx } = require('clsx');
const { default: Styled } = require('styled-components');

const internals = {};

// The height of header and footer scroll siblings, plus 30px
const CHAT_FRAME_OFFSET = 126;
const LOAD_OFFSET = 1;
const MAINTAIN_BOTTOM_THRESHOLD = 250;

module.exports = class ChatList extends React.PureComponent {

    static propTypes = {
        messages: T.array,
        onSaveMessage: T.func.isRequired,
        onModerateMessage: T.func.isRequired,
        onPinMessage: T.func.isRequired,
        onRemoveMessage: T.func.isRequired,
        onRemoveOwnMessage: T.func.isRequired,
        onRequestMessages: T.func.isRequired,
        onFlagInappropriate: T.func.isRequired,
        updateAlertFunction: T.func,
        showNotification: T.func,
        rolesInteractions:T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool,
            canViewInGroup: T.bool,
            canChat: T.bool,
            canChatWithoutConnection: T.bool,
            canSeeConnections: T.bool,
            canSeeUsersGroups: T.bool,
            canSeeSurveyFields: T.bool,
            canSeeExtendedProfile: T.bool

        })),
        rolePermissions:T.shape({
            id: T.number,
            canPostInAnnouncement: T.bool
        }),
        showModerationControls: T.bool,
        opennedPinWindow: T.bool,
        isAnnouncement: T.bool
    }

    constructor(props) {

        super(props);

        internals.scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);
        let containerHeight = 0;
        if (internals.scrollContainer) {
            containerHeight = internals.scrollContainer.clientHeight - CHAT_FRAME_OFFSET;
        }

        this.state = {
            heights: {},
            isInfiniteLoading: false,
            hasMore: true,
            containerHeight
        };

        this.canInfiniteLoad = null;

        this.setHeight = this._setHeight.bind(this);
        this.startInfiniteLoading = this._startInfiniteLoading.bind(this);
        this.stopInfiniteLoading = this._stopInfiniteLoading.bind(this);
        this.handleInfiniteLoad = Debounce(this._handleInfiniteLoad.bind(this), 2000);
        this.onResizeListener = this._onResizeListener.bind(this);
    }

    _onResizeListener(evt) {

        if (!internals.scrollContainer) {
            return;
        }

        const containerHeight = internals.scrollContainer.clientHeight - CHAT_FRAME_OFFSET;
        this.setState({ containerHeight });
    }

    UNSAFE_componentWillMount() {

        setTimeout(() => {

            // Wait to allow infinite loading, so that it doesn't get overwhelmed on initial load
            this.canInfiniteLoad = (this.canInfiniteLoad === null) ? true : this.canInfiniteLoad;
        }, 4000);
    }

    componentDidMount() {

        this.mounted = true;

        internals.scrollContainer = document.querySelector('#' + ELEMENT_IDS.scrollContainer);

        if (!internals.scrollContainer) {
            return;
        }

        const containerHeight = internals.scrollContainer.clientHeight - CHAT_FRAME_OFFSET;
        this.setState({ containerHeight });

        window.addEventListener('resize', this.onResizeListener);

        // A couple things might happen here on iOS: the chat textfield might be
        // auto-focused making the keyboard pop-up right away, super annoying
        // The scroll could be all messed up and not at the bottom of the chat

        // Let's help out react-infinite a little and make sure we're all scrolled to the bottom

        let tryCount = 0;
        const scrollToBottom = () => {

            ++tryCount;

            internals.scrollContainer.scroll(0, internals.maxScroll());

            if (this.mounted) {
                setTimeout(() => {

                    if (tryCount < 6 && internals.scrollContainer.scrollTop < internals.maxScroll()) {
                        if (this.mounted) {
                            scrollToBottom();
                        }
                    }
                }, 200);
            }
        };

        setTimeout(() => {

            if (this.mounted) {
                scrollToBottom();
            }
        }, 100);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //const MESSAGES_PAGE_SIZE = 100; this comes from src/api/twilio.js
        if ((this.props.messages.length % 100 !== 0) && this.state.hasMore === true){

            this.setState({
                hasMore: false
            });
        }
    }

    componentWillUnmount() {

        this.mounted = false;
        window.removeEventListener('resize', this.onResizeListener);
    }

    _setHeight(sid) {

        return ({ bounds }) => {

            const perceivedHeight = this.state.heights[sid] || ChatItem.minHeight;

            if (bounds.height === perceivedHeight) {
                return;
            }

            const heights = { ...this.state.heights, [sid]: bounds.height };

            this.setState({ heights });
        };
    }

    getHeights() {

        return this.props.messages.map((m) => {

            const key = m.sid || m.id;

            return this.state.heights[key] || ChatItem.minHeight;
        });
    }

    _startInfiniteLoading() {

        this.canInfiniteLoad = false;
        this.setState({ isInfiniteLoading: true });
    }

    _stopInfiniteLoading() {

        this.setState({ isInfiniteLoading: false }, () => {

            this.canInfiniteLoad = true;
        });
    }

    _handleInfiniteLoad() {

        if (!this.canInfiniteLoad ||
            !this.mounted ||  this.props.isAnnouncement || !this.state.hasMore) {

            return;
        }

        this.startInfiniteLoading();

        // Regarding stopInfiniteLoading(),
        // We try to sync the isInfiniteLoading state with the change to messages in componentWillReceiveProps()
        // because it helps ReactInfinite keep scroll state correctly.  But in case we don't catch it there,
        // we still need to make sure to stop loading.  Essentially, it might just fire later and no-op.
        this.props.onRequestMessages().then(this.stopInfiniteLoading);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        const nextMessages = nextProps.messages;
        const currentMessages = this.props.messages;

        const hasCurrent = currentMessages.length !== 0;
        const hasNext = nextMessages.length !== 0;

        let lastCurrent;
        let lastNext;

        if (hasCurrent) {
            lastCurrent = currentMessages[currentMessages.length - 1];
        }

        if (hasNext) {
            lastNext = nextMessages[nextMessages.length - 1];
        }

        const newMessage = (hasNext && !hasCurrent) || ((hasNext && hasCurrent) && lastNext.sid !== lastCurrent.sid);

        if (nextProps.opennedPinWindow !== this.props.opennedPinWindow) {
            internals.scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);
            internals.topPortalWindow = document.getElementById(ELEMENT_IDS.scrollPortalIds.top);

            let pinWindowHeight = 0;
            if (!internals.scrollContainer) {
                return;
            }

            if (nextProps.opennedPinWindow === true) {
                pinWindowHeight =  internals.topPortalWindow.clientHeight;
            }

            const containerHeight = internals.scrollContainer.clientHeight - CHAT_FRAME_OFFSET - pinWindowHeight;
            this.setState({ containerHeight });

            const insideThreshold = internals.maxScroll() - internals.scrollContainer.scrollTop <= MAINTAIN_BOTTOM_THRESHOLD;

            if (insideThreshold) {

                setTimeout(() => {

                    internals.scrollContainer.scroll(0, internals.maxScroll());
                }, 0);
            }
        }

        if (newMessage) {

            if (!internals.scrollContainer) {
                return;
            }

            // We're doin our own work to keep scroll to the bottom
            // react-infinite eh it's not the best at this UX-wise
            const insideThreshold = internals.maxScroll() - internals.scrollContainer.scrollTop <= MAINTAIN_BOTTOM_THRESHOLD;

            if (insideThreshold) {

                setTimeout(() => {

                    internals.scrollContainer.scroll(0, internals.maxScroll());
                }, 0);
            }
            else {

                if (this.state.isInfiniteLoading && newMessage) {
                    this.stopInfiniteLoading();
                }
            }
        }
    }

    checkListTooShort(itemHeights) {

        if (!internals.scrollContainer) {
            return true;
        }

        const totalItemHeight = itemHeights.reduce((total, height) => {

            total += height;
            return total;
        }, 0);

        // This arbitrary offset includes the top and bottom padding heights
        // The remainder of this value is in question where it comes from,
        // but it consistently gives good results
        const arbitraryOffset = 122;

        return internals.scrollContainer.clientHeight >= (totalItemHeight + arbitraryOffset);
    }

    render() {

        if (!internals.scrollContainer) {
            return null;
        }

        const { InfiniteWindow, NoMoreMsgWrapper } = internals;

        const {
            messages,
            onSaveMessage,
            onModerateMessage,
            onPinMessage,
            onRemoveMessage,
            onRemoveOwnMessage,
            onFlagInappropriate,
            updateAlertFunction,
            showModerationControls,
            showNotification,
            rolesInteractions,
            rolePermissions,
            isAnnouncement,
            ...other
        } = this.props;

        const { isInfiniteLoading, containerHeight,hasMore } = this.state;

        const messageItems = messages.map((m, i) => {

            const key = m.sid || m.id;
            return <Measure
                key={key}
                bounds
                onResize={this.setHeight(key)}
            >
                {({ measureRef }) => (

                    <div ref={measureRef}>
                        <ChatItem
                            onSave={onSaveMessage}
                            onModerate={onModerateMessage}
                            onPin={onPinMessage}
                            onRemove={onRemoveMessage}
                            onRemoveOwn={onRemoveOwnMessage}
                            onFlagInappropriate={onFlagInappropriate}
                            updateAlertFunction={updateAlertFunction}
                            showModerationControls={showModerationControls}
                            showNotification={showNotification}
                            rolesInteractions={rolesInteractions}
                            rolePermissions={rolePermissions}
                            className={Classes.cf}
                            message={m}
                            isAnnouncement={isAnnouncement}
                        />
                    </div>
                )}
            </Measure>;
        });

        const itemHeights = this.getHeights();
        const listTooShort = this.checkListTooShort(itemHeights);

        // Disable loading if the list is shorter than the scroll area
        // The react-infinite docs say to set the infiniteLoadBeginEdgeOffset to
        // undefined to disable it
        const loadOffset = listTooShort ? undefined : LOAD_OFFSET;

        return <div role='log' aria-label='chat'>
            <div className={clsx(Classes.spinnerHolder,!listTooShort && Classes.spinnerSpacing)}>
                {!hasMore ? <NoMoreMsgWrapper className={clsx(!hasMore && Classes.noMoreMsgHolder)}>
                    <Typography align={'center'} variant={'caption'}>
                        All available chats are displayed
                    </Typography></NoMoreMsgWrapper> : null}
            </div>
            <InfiniteWindow
                {...other}
                displayBottomUpwards
                scrollContainerSelector={`#${ELEMENT_IDS.scrollContainer}`}
                containerHeight={containerHeight}
                onInfiniteLoad={this.handleInfiniteLoad}
                isInfiniteLoading={isInfiniteLoading}
                loadingSpinnerDelegate={<div className={Classes.spinner}>
                    <Loader />
                </div>}
                infiniteLoadBeginEdgeOffset={hasMore ? loadOffset : undefined}
                elementHeight={itemHeights}
                preloadBatchSize={Infinite.containerHeightScaleFactor(1)}
                preloadAdditionalHeight={Infinite.containerHeightScaleFactor(1)}
            >
                {messageItems}
            </InfiniteWindow>
        </div>;
    }
};

internals.InfiniteWindow = class InfiniteWindow extends Infinite {

    constructor(props) {

        super(props);

        this.getLowestPossibleScrollTop = internals.maxScroll;

        const generateComputedUtilityFunctions = this.generateComputedUtilityFunctions;
        this.generateComputedUtilityFunctions = (anyProps) => {

            const utils = generateComputedUtilityFunctions(anyProps);

            // Do not allow scrolling to trigger a load, by getting near the load offset but not right on it.
            // It seems this is primarily useful for inertial scrolling (e.g. iOS) where you can have things like negative scroll positions.
            const scrollTop = utils.setScrollTop;
            utils.setScrollTop = (top) => {

                return scrollTop(Math.max(LOAD_OFFSET, top));
            };

            return utils;
        };
    }
};

internals.maxScroll = () => {

    if (!internals.scrollContainer) {
        return 0;
    }

    const containerHeight = internals.scrollContainer.clientHeight - CHAT_FRAME_OFFSET;
    return Math.max(internals.scrollContainer.scrollHeight, internals.scrollContainer.offsetHeight, internals.scrollContainer.clientHeight) - containerHeight;
};

internals.NoMoreMsgWrapper = Styled.div`
    background-color: ${({ theme }) => alpha(theme.palette.primary.light,0.75)};
    border-radius:10px;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    margin-top: 20px;
`;
