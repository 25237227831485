const React = require('react');
const { default: Tooltip } = require('@mui/material/Tooltip');

const { default: Classes } = require('./styles.scss');
const T = require('prop-types');
const { default: styled } = require('styled-components');

const CustomTooltip = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: rgba(255, 255, 255, 0.87);
    font-size: 0.75rem;
  }

  .MuiTooltip-arrow {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const PassionItem = ({ svg, interest }) => {

    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
    const buff = Buffer.from(svg);
    const base64data = buff.toString('base64');

    const imageData = `data:image/svg+xml;base64,${base64data}`;

    const handleClose = () => {

        setTooltipIsOpen(false);
    };

    const handleOpen = () => {

        setTooltipIsOpen(true);
    };

    return (
        <CustomTooltip
            title={interest.name}
            placement={'top'}
            arrow
            open={tooltipIsOpen}
            onClose={handleClose}
            onOpen={handleOpen}
        >
            <div
                onClick={(event) => {

                    event.preventDefault();
                    setTooltipIsOpen(true);
                }}
                className={Classes.container}
            >
                <img alt={`${interest.name} icon`} src={imageData}/>
            </div>
        </CustomTooltip>
    );
};

PassionItem.propTypes = {
    svg: T.any,
    interest: T.object
};

module.exports = PassionItem;
