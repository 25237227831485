const { ASYNC, ERROR, SIMPLE } = require('../utils/redux-helpers');

module.exports = {
    LOGIN: ASYNC,
    LOGIN_TOKEN: ASYNC,
    LOGOUT: ASYNC,
    LOGIN_AUTH_ZERO_TOKEN: ASYNC,
    CLOSE_ACCOUNT: ASYNC,
    ACCOUNT_CLOSED_CLEAR: SIMPLE,
    LOGIN_INPUT_FAILURE: ERROR,
    REQUEST_PASSWORD_RESET: ASYNC,
    RESET_PASSWORD: ASYNC,
    CHECK_RESET_TOKEN: ASYNC,
    UPDATE_TWILIO_TOKEN: ASYNC
};
