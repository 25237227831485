const T = require('prop-types');

// Note: this directory cannot be required directly as require('domain') since "domain" is reserved

module.exports = {
    alerts: require('./alerts'),
    auth: require('./auth'),
    signup: require('./signup'),
    School: {
        id: T.number,
        name: T.string,
        domains: T.arrayOf(T.string)
    },
    Interest: {
        id: T.number,
        interest: T.string
    },
    Class: {
        id: T.number,
        name: T.string,
        emojiSymbol: T.string,
        type: T.string,
        number: T.string,
        classLocation: T.string,
        additionalDescription: T.string,
        facilitator: T.string,
        shortDescription: T.string,
        private: T.string,
        campus: T.string,
        section: T.string,
        semester: T.string,
        credits: T.number,
        room: T.string,
        misc: T.string,
        isAnnouncement: T.bool,
        roles: T.array
    },
    Survey: {
        id: T.number,
        title: T.string,
        description: T.string,
        startDate: T.string,
        endDate: T.string,
        isActive: T.bool,
        startImmediately: T.bool,
        notifyUsers: T.bool,
        manuallyStop: T.bool,
        questions:T.array
    }
};
