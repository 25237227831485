const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { NOTIFICATIONS: Types } = require('../action-types');

module.exports = (context) => {

    const selectors = context.selectors.all;
    const redux = context.redux.hooks;
    const actions = context.actions;
    const api = context.api.nearpeer;

    return {
        markRead: makeActionCreator(Types.MARK_READ, { ids: T.arrayOf(T.string) }, { async: api.notifications.markRead }),
        clearMentions: makeActionCreator(Types.CLEAR_MENTIONS, { channelSid: T.string }, {
            async: ({ channelSid }) => {

                if (!selectors.hasMentions(redux.getState(), channelSid)) {
                    return { ids: [] };
                }

                return api.notifications.clearMentions({ channelSid });
            }
        }),
        accept: makeActionCreator(Types.ACCEPT, { id: T.string, type: T.string, userId: T.string }, {
            async: api.notifications.accept,
            after: () => actions.dataFetching.fetchCurrentUser()
        }),
        acceptTransfer: makeActionCreator(Types.ACCEPT_TRANSFER, { notificationId: T.string }, { async: api.notifications.acceptTransfer }),
        decline: makeActionCreator(Types.DECLINE, { id: T.string, type: T.string, userId: T.string }, {
            async: api.notifications.decline,
            after: () => actions.dataFetching.fetchCurrentUser()
        }),
        answerQuestion: makeActionCreator(Types.ANSWER_QUESTION, { notificationId: T.string, answer: T.string }, {
            async: api.notifications.answerQuestion,
            after: () => actions.dataFetching.fetchNotifications()
        }),
        dismiss: makeActionCreator(Types.DISMISS, { id: T.string }, {
            async: api.notifications.dismiss,
            after: () => actions.dataFetching.fetchNotifications()
        })
    };
};
