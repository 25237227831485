const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { CHATS: Types } = require('../action-types');
const { MESSAGES_SORT_TYPE } = require('../utils/constants');

module.exports = (context) => {

    const api = context.api.nearpeer;

    return {
        setPinHeaderState:   makeActionCreator(Types.SET_PIN_HEADER_STATE,   { key: T.string, status: T.oneOf(['open','hide']) }),
        clear: makeActionCreator(Types.CLEAR, { key: T.string }),
        setAllMessagesSortType:   makeActionCreator(Types.SET_ALL_MESSAGES_SORTING_TYPE,   { sortType: T.oneOf([MESSAGES_SORT_TYPE.BY_NAME,MESSAGES_SORT_TYPE.BY_DATE_UPDATED]) }),
        addTwilioUserToChat: makeActionCreator(Types.ADD_TWILIO_USER, { userId: T.number, channelSid: T.string }, {
            async: api.communication.addTwilioUserToChannel
        })
    };
};
