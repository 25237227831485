const React = require('react');
const { useState,useEffect } = require('react');
const T = require('prop-types');

const { default: Popover } = require('@mui/material/Popover');
const { default: Button } = require('@mui/material/Button');


const { default: Picker } = require('emoji-picker-react');

const { default: Classes } = require('./styles.scss');
const Emoji = require('../Emoji');
const { default: CancelIcon } = require('@mui/icons-material/Cancel');
const { default: styled } = require('styled-components');



const StyledArrow = styled.span`
  position: absolute;
  font-size: 7px;
  width: 3em;
  height: 3em;
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const StyledPopover = styled(Popover)`
  z-index: 1;

  &[x-placement*="top"] {
    ${StyledArrow} {
      bottom: 0;
      left: 0;
      margin-bottom: -0.9em;
      width: 3em;
      height: 1em;

      &::before {
        border-width: 1em 1em 0 1em;
        border-color: ${({ theme }) => `${theme.palette.background.paper} transparent transparent transparent`};
      }
    }
  }
`;








const EmojiPicker = (props) => {

    const {
        onChange,
        label,
        value
    } = props;

    const [arrowRef, setArrowRef] = useState(null);
    const [anchorRef, setAnchorRef] = useState(null);
    const [emojiSymbol, setEmojiSymbol] = useState('');



    const open = Boolean(anchorRef);

    useEffect(() => {

        setEmojiSymbol(value);
    },[value]);


    const handleClick = (event) => {

        setAnchorRef(event.currentTarget);
    };

    const handleClose = () => {

        setAnchorRef(null);
    };

    const onEmojiClick = (event, emojiObject) => {

        const selectedEmojiSymbol = emojiObject.emoji;

        onChange(selectedEmojiSymbol);
        setEmojiSymbol(selectedEmojiSymbol);

        handleClose();
    };


    const onClearBtnCLick = (event) => {

        onChange('');
        setEmojiSymbol('');
    };


    return (
        <React.Fragment>
            <Button  variant="contained" color="primary" onClick={handleClick}>
                Select Emoji
            </Button>
            {emojiSymbol && <div className={Classes.selectedEmojiHolder}>
                <span >{label}</span>
                <Emoji symbol={ emojiSymbol } />
                <Button
                    variant='contained'
                    color='secondary'
                    size="small"
                    onClick={onClearBtnCLick}
                    startIcon={<CancelIcon style={{ color:'#ffffff' }} />}
                >
                    Remove
                </Button>
            </div>}
            <StyledPopover
                open={open}
                anchorEl={anchorRef}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                onClose={handleClose}
                // modifiers={{
                //     arrow: {
                //         enabled: true,
                //         element: arrowRef
                //     }
                // }}
            >
                <StyledArrow ref={setArrowRef} />
                <Picker
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                    disableSkinTonePicker={false}
                    groupVisibility={{
                        flags: false
                    }}
                    native
                />
            </StyledPopover>
        </React.Fragment>
    );
};

EmojiPicker.propTypes = {
    onChange:T.func,
    label:T.string,
    value:T.string
};

module.exports = EmojiPicker;

