const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: Popper } = require('@mui/material/Popper');
const { default: List } = require('@mui/material/List');
const { default: ClickAwayListener } = require('@mui/material/ClickAwayListener');
const { default: Grow } = require('@mui/material/Grow');
const { default: Paper } = require('@mui/material/Paper');

const AppMenuItem = require('./AppMenuItem');

const internals = {};

// TODO submenu items aren't currently focusable.
// We'll want to update that for a11y

const StyledIconButton = Styled(IconButton)`
    &:focus-visible {
        background-color: rgba(255, 255, 255, 0.4);
    }
`;

module.exports = function AppMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleIconClick = (event) => {

        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const closeMainMenu = () => {

        setAnchorEl(null);
    };

    const handleListKeyDown = (event) => {

        if (event.currentTarget.lastElementChild === event.target){
            setAnchorEl(null);
        }
    };

    const {
        Icon,
        items
    } = props;

    const {
        StyledList
    } = internals;

    return (
        <React.Fragment>
            {Icon && (
                <StyledIconButton
                    aria-label='Main dropdown menu'
                    onClick={handleIconClick}
                    size='large'>
                    {Icon}
                </StyledIconButton>
            )}
            <Popper
                placement='bottom-start'
                disablePortal={true}
                open={open}
                anchorEl={anchorEl}
                transition
                // modifiers={{
                //     flip: {
                //         enabled: true
                //     },
                //     preventOverflow: {
                //         enabled: false
                //     },
                //     arrow: {
                //         enabled: false
                //     }
                // }}
            >
                {({ TransitionProps, placement }) => (

                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'center top' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={closeMainMenu}>
                                <StyledList component='nav'  disablePadding onBlur={handleListKeyDown}>
                                    {/* <AppMenuItem {...appMenuItems[0]} /> */}
                                    {items.map((item, index) => {

                                        return <AppMenuItem

                                            closeMainMenuFunction={closeMainMenu}
                                            {...item}
                                            key={index}
                                        />;
                                    })}
                                </StyledList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

module.exports.propTypes = {
    id: T.string.isRequired,
    Icon: T.element,
    items: T.arrayOf(T.shape({
        name: T.string.isRequired,
        link: T.string,
        Icon: T.elementType,
        items: T.array,
        inset: T.bool,
        disabled: T.bool,
        onClick: T.func
    }))
};

internals.StyledList = Styled(List)`
    width: 200px !important;
     ${(props) => props.theme.breakpoints.between(props.theme.breakpoints.values.mobileL,props.theme.breakpoints.values.sm)} {
        width: 180px !important;
    }
    ${(props) => props.theme.breakpoints.between(props.theme.breakpoints.values.mobileM,props.theme.breakpoints.values.mobileL)} {
        width: 160px !important;
    }
    ${(props) => props.theme.breakpoints.down(props.theme.breakpoints.values.mobileM)} {
        width: 145px !important;
    }
`;
