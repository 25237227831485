const { CONNECTIVITY: Types } = require('../action-types');

const initialState = {
    isOffline: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case Types.OFFLINE:
            return {
                ...state,
                isOffline: action.payload.isOffline
            };
        default:
            return state;
    }
};

const selectors = {
    getIsOffline: (state) => !!state.isOffline
};

module.exports = Object.assign(reducer, { selectors });
