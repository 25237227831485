const { connect } = require('react-redux');
const { context } = require('app-context');
const ForgotPassword = require('../components/ForgotPassword');
const { push: Push } = require('connected-react-router');

const actions = context.actions;

module.exports = connect(
    null, {
        onSubmit: actions.auth.requestPasswordReset,
        push: Push,
        onInvalidSubmit: () => actions.alerts.notification.push({ message: 'Oops! It looks like some fields are invalid.' })
    }
)(ForgotPassword);
