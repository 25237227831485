const IsString = require('lodash/isString');
const IsArray = require('lodash/isArray');
const IsEmpty = require('lodash/isEmpty');
const IsObject = require('lodash/isObject');
const Has = require('lodash/has');
const Get = require('lodash/get');
const Keys = require('lodash/keys');
const OmitBy = require('lodash/omitBy');
const Join = require('lodash/join');
const { assert } = require('../utils/assert');
const ExtendableError = require('extendable-error-class');

const makeContext = ({ name: contextName = 'APP', definition }) => {

    assert(IsString(contextName) && !IsEmpty(contextName));
    assert(definition === PLACEHOLDER || IsObject(definition));

    const PATH_SEPARATOR = '.';
    const formatPath = (path) => {

        return IsEmpty(path) ? contextName : `${ contextName }::${ Join(path, PATH_SEPARATOR) }`;
    };

    const makeContainer = ({ path }) => {

        assert(IsArray(path) && (IsEmpty(path) || Has(definition, path)));

        const containerDefinition = IsEmpty(path) ? definition : Get(definition, path);
        assert(containerDefinition === PLACEHOLDER || IsObject(containerDefinition));

        return containerDefinition === PLACEHOLDER
            ? makeItemContainer({ path })
            : makeGroupContainer({ path });
    };

    const makeItemContainer = ({ path }) => {

        assert(IsArray(path) && (IsEmpty(path) || Has(definition, path)));

        const containerDefinition = IsEmpty(path) ? definition : Get(definition, path);
        assert(containerDefinition === PLACEHOLDER);

        const data = {};
        let _isFinalized = false;

        const isFinalized = () => _isFinalized;
        const assign = (value) => {

            assert(!isFinalized());
            assert(IsEmpty(data));

            Object.assign(data, value);
            Object.freeze(data);

            _isFinalized = true;
        };

        return { context: data, isFinalized, assign };
    };

    const makeGroupContainer = ({ path }) => {

        assert(IsArray(path) && (IsEmpty(path) || Has(definition, path)));

        const containerDefinition = IsEmpty(path) ? definition : Get(definition, path);
        assert(containerDefinition !== PLACEHOLDER && IsObject(containerDefinition));

        const meta = {};
        const data = {};
        let _isFinalized = false;

        Keys(containerDefinition).forEach((key) => {

            meta[key] = makeContainer({ path: [...path, key] });
        });
        Keys(containerDefinition).forEach((key) => {

            Object.defineProperty(data, key, {
                enumerable: true,
                configurable: false,
                get: () => meta[key].context,
                set: meta[key].assign
            });
        });

        Object.freeze(meta);
        // Object.freeze(data);

        const isFinalized = () => _isFinalized;
        const assign = (value) => {

            assert(!isFinalized());

            Object.assign(data, value);

            finalize();
        };

        const finalize = () => {

            assert(!isFinalized());

            const missing = Keys(OmitBy(meta, (element) => element.isFinalized()));

            if (!IsEmpty(missing)) {
                throw new ContextError(
                    `Can not finalize ${ formatPath(path) }. Missing values: ${ missing }.`
                );
            }

            _isFinalized = true;
        };

        return { context: data, isFinalized, assign, finalize };
    };

    return makeContainer({ path: [] });
};

class ContextError extends ExtendableError {}

const PLACEHOLDER = {};

module.exports = { makeContext, ContextError, PLACEHOLDER };
