const KeyMirror = require('keymirror');
const { AUTH: Types, SIGNUP: SignupTypes } = require('../action-types');

const AUTH_STATUSES = KeyMirror({
    INIT: true,
    WAITING: true,
    WAITING_LOGOUT: true,
    FINISHED: true
});

const initialState = {
    status: AUTH_STATUSES.INIT
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case Types.UPDATE_TWILIO_TOKEN.SUCCESS:

            if (!selectors.getIsAuthenticated(state)) {
                return state;
            }

            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    twilio: action.payload.result.token
                }
            };
        case SignupTypes.STEP_1.COMPLETE.SUCCESS:
            return {
                ...state,
                tokens: null
            };
        case Types.LOGIN.REQUEST:
        case Types.LOGIN_TOKEN.REQUEST:
            return {
                ...state,
                status: AUTH_STATUSES.WAITING
            };
        case Types.LOGIN.SUCCESS:
        case Types.LOGIN_TOKEN.SUCCESS:
            return {
                ...state,
                status: AUTH_STATUSES.FINISHED,
                tokens: action.payload.result.tokens,
                isVerified: action.payload.result.isVerified
            };
        case Types.LOGIN_AUTH_ZERO_TOKEN.SUCCESS:
            return {
                ...state,
                tokens: action.payload.result.tokens,
                isVerified: action.payload.result.isVerified
            };
        case Types.LOGOUT.REQUEST:
            return {
                ...state,
                status: AUTH_STATUSES.WAITING_LOGOUT
            };
        case Types.LOGIN.FAILURE:
        case Types.LOGIN_TOKEN.FAILURE:
        case Types.LOGOUT.SUCCESS:
        case Types.CLOSE_ACCOUNT.SUCCESS:
        case Types.ACCOUNT_CLOSED_CLEAR:
            return {
                ...state,
                status: AUTH_STATUSES.FINISHED,
                tokens: null
            };
        case Types.LOGIN_AUTH_ZERO_TOKEN.FAILURE:
            return {
                ...state,
                tokens: null
            };
        case Types.LOGOUT.FAILURE:
            return {
                ...state,
                status: AUTH_STATUSES.FINISHED
            };
        default:
            return state;
    }
};

const selectors = {
    AUTH_STATUSES,
    getIsAuthenticated: (state) => !!state.tokens?.nearpeer,
    getAuthStatus: (state) => state.status,
    getVerifiedStatus: (state) => state.isVerified,
    getSSOToken: (state) => state.tokens?.sso,
    getApiToken: (state) => {

        if (!selectors.getIsAuthenticated(state)) {
            throw new Error('User is not logged-in!');
        }

        return state.tokens.nearpeer;
    },
    getTwilioToken: (state) => {

        if (!selectors.getIsAuthenticated(state)) {
            throw new Error('User is not logged-in!');
        }

        return state.tokens.twilio;
    }
};

module.exports = Object.assign(reducer, { selectors });
