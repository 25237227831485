const React = require('react');
const T = require('prop-types');

module.exports = class Passthrough extends React.Component {

    static propTypes = {
        children: T.node,
        to: T.element,
        as: T.element
    }

    render() {

        const { to, as, children, ...others } = this.props;

        return React.cloneElement(as, {
            children: [React.cloneElement(to, others)].concat(React.Children.toArray(children))
        });
    }
};
