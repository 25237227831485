const { context } = require('../app-context');

module.exports = async (store) => {

    const {
        actions,
        redux: { hooks: { getState } },
        selectors: { all: selectors }
    } = context;

    const promiseSchools = store.dispatch(actions.dataFetching.fetchSchools());
    const promiseCategories = store.dispatch(actions.dataFetching.fetchCategories());
    const promiseRoleGroups = store.dispatch(actions.dataFetching.fetchRoleGroups());

    // We might get the schoolId from the URL
    const schoolId = selectors.getCurrentSchoolId(getState());

    if (schoolId) {
        store.dispatch(actions.dataFetching.fetchBadgeTypes({ schoolId }));
        store.dispatch(actions.dataFetching.fetchConversationStarters({ schoolId }));
        store.dispatch(actions.dataFetching.fetchYearsHired({ schoolId }));
        store.dispatch(actions.dataFetching.fetchOffices({ schoolId }));
    }

    await Promise.all([
        promiseSchools,
        promiseCategories,
        promiseRoleGroups
    ]);
};
