exports.getDevice = () => {

    const userAgent = navigator.userAgent;

    // Check if cordova
    const cordovaPlatform = (window.device?.platform || '').toLowerCase();

    if (cordovaPlatform) {
        // Is cordova app
        if (cordovaPlatform === 'android') {
            return 'android:app';
        }
        else if (cordovaPlatform === 'ios') {
            return 'ios:app';
        }
    }

    const { platform, browser } = exports.getPlatformAndBrowser(userAgent);

    return `${platform}:${browser}`;
};

exports.isIOS = (deviceStr) => deviceStr?.startsWith('ios:');

exports.isAndroid = (deviceStr) => deviceStr?.startsWith('android:');

exports.isDesktop = (deviceStr) => {

    const desktopPlatforms = ['windows', 'mac_os', 'linux', 'chrome_os', 'freebsd', 'openbsd', 'sun_os'];
    return desktopPlatforms.find((platform) => deviceStr?.startsWith(`${platform}:`));
};

exports.isUnknown = (deviceStr) => deviceStr?.startsWith('unknown:');

exports.getPlatformAndBrowser = (userAgent) => {

    const platforms = [
        { name: 'ios', regex: /iPhone|iPad|iPod/i },
        { name: 'android', regex: /Android/i },
        { name: 'windows', regex: /Windows NT/i },
        { name: 'mac_os', regex: /Macintosh|Mac OS X/i },
        { name: 'linux', regex: /Linux/i },
        { name: 'chrome_os', regex: /CrOS/i },
        { name: 'freebsd', regex: /FreeBSD/i },
        { name: 'openbsd', regex: /OpenBSD/i },
        { name: 'sun_os', regex: /SunOS/i }
    ];

    const browsers = [
        { name: 'chrome', regex: /Chrome\/[0-9.]+/i, exclude: /Edg\/[0-9.]+|OPR\/[0-9.]+/i },
        { name: 'firefox', regex: /Firefox\/[0-9.]+/i },
        { name: 'safari', regex: /Safari\/[0-9.]+/i, exclude: /Chrome\/[0-9.]+|Edg\/[0-9.]+|OPR\/[0-9.]+/i },
        { name: 'edge', regex: /Edg\/[0-9.]+/i },
        { name: 'opera', regex: /OPR\/[0-9.]+/i },
        { name: 'ie', regex: /MSIE [0-9.]+|Trident\/[0-9.]+/i },
        { name: 'brave', regex: /Brave\/[0-9.]+/i },
        { name: 'vivaldi', regex: /Vivaldi\/[0-9.]+/i }
    ];

    const platform = platforms.find((p) => p.regex.test(userAgent))?.name || 'unknown';

    let browser = 'unknown';

    for (const b of browsers) {
        if (b.regex.test(userAgent)) {
            // Test against exclude regexps
            if (b.exclude && b.exclude.test(userAgent)) {
                continue;
            }

            browser = b.name;
            break;
        }
    }

    return { platform, browser };
};
