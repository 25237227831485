const Axios = require('axios');
const MockAdapter = require('axios-mock-adapter');
const DeepEql = require('deep-eql');

const HTTP = {
    OK: 200,
    NO_CONTENT: 204,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500
};

const GET    = (url)       => ({ method: 'get',    url       });
const POST   = (url, data) => ({ method: 'post',   url, data });
const PATCH  = (url, data) => ({ method: 'patch',  url, data });
const DELETE = (url)       => ({ method: 'delete', url       });

const AUTH = (token) => ({ Authorization: `Bearer ${token}` });

const OK = (data) => [HTTP.OK, typeof data !== 'undefined' ? data : {}];

const NO_CONTENT            = () => [HTTP.NO_CONTENT,            {}];
const INTERNAL_SERVER_ERROR = () => [HTTP.INTERNAL_SERVER_ERROR, {}];
const UNAUTHORIZED          = () => [HTTP.UNAUTHORIZED,          {}];

const makeApi_usingRulesFactory = (makeApi, makeContext) => {

    return (rulesFactory = null) => {

        const { http } = makeHttp_usingRulesFactory(rulesFactory);

        return { api: makeApi(makeContext(http)) };
    };
};
const makeApi_usingInteractionSpec = (makeApi, makeContext) => {

    return (interactionSpec = null) => {

        const { http, inspector } = makeHttp_usingInteractionSpec(interactionSpec);

        return { api: makeApi(makeContext(http)), inspector };
    };
};

const makeHttp_usingRulesFactory = (rulesFactory = null) => {

    rulesFactory = rulesFactory || ((ruleBuilder) => {});

    const http = Axios.create();
    const ruleBuilder = new MockAdapter(http);

    rulesFactory(ruleBuilder);

    return { http };
};
const makeHttp_usingInteractionSpec = (interactionSpec = null) => {

    interactionSpec = interactionSpec || [];

    const inspector = makeInspector(interactionSpec);

    const rulesFactory = (ruleBuilder) => {

        ruleBuilder.onAny().reply((actualRequest) => {

            const { expectedRequest, expectedResponse } = inspector.popNextStep();

            if (!areEqualRequests({ expectedRequest, actualRequest })) {

                inspector.pushFailedStep({ expectedRequest, actualRequest });

                return INTERNAL_SERVER_ERROR();
            }

            return expectedResponse;
        });
    };

    const { http } = makeHttp_usingRulesFactory(rulesFactory);

    return { http, inspector };
};

const makeInspector = (interactionSpec) => {

    const remainingSteps = interactionSpec.slice();
    const failedSteps = [];

    const inspector = {

        popNextStep: () => {

            const [request, authorization, response] = remainingSteps.shift();

            return Array.isArray(authorization) && typeof response === 'undefined'
                ? { expectedRequest: combine(request, {}),            expectedResponse: authorization }
                : { expectedRequest: combine(request, authorization), expectedResponse: response      };
        },
        pushFailedStep: (expectedRequest, actualRequest) => {

            failedSteps.push({ expected: expectedRequest, actual: actualRequest });
        },

        validateInteraction: () => {

            remainingSteps.should.deep.equal([], 'Remaining Steps:');
            failedSteps   .should.deep.equal([], 'Failed Steps');
        }
    };

    const combine = (request, authorization) => {

        return Object.assign(
            {},
            request,
            { headers: authorization }
        );
    };

    return inspector;
};

const areEqualRequests = ({ expectedRequest, actualRequest }) => {

    return DeepEql(
        normalizeRequest(expectedRequest),
        normalizeRequest(normalizeRequestData(actualRequest))
    );
};
const normalizeRequest = (request) => {

    if (request.headers && request.headers.Authorization) {
        const { method, url, headers: { Authorization }, data } = request;
        return { method, url, headers: { Authorization }, data };
    }
    else { // eslint-disable-line no-else-return
        const { method, url, data } = request;
        return { method, url, headers: {}, data };
    }
};
const normalizeRequestData = (request) => {

    const { data: jsonData } = request;

    return jsonData
        ? Object.assign({}, request, { data: JSON.parse(jsonData) })
        : request;
};

const isRulesFactory = (rulesFactoryOrInteractionSpec) => typeof rulesFactoryOrInteractionSpec === 'function';

const makeApi_ROOT = (makeApi, makeContext) => {

    return (rulesFactoryOrInteractionSpec = null) => {

        return (isRulesFactory(rulesFactoryOrInteractionSpec))
            ? makeApi_usingRulesFactory(makeApi, makeContext)(rulesFactoryOrInteractionSpec)
            : makeApi_usingInteractionSpec(makeApi, makeContext)(rulesFactoryOrInteractionSpec);
    };
};
const makeHttp_ROOT = (rulesFactoryOrInteractionSpec = null) => {

    return (isRulesFactory(rulesFactoryOrInteractionSpec))
        ? makeHttp_usingRulesFactory(rulesFactoryOrInteractionSpec)
        : makeHttp_usingInteractionSpec(rulesFactoryOrInteractionSpec);
};

module.exports = {
    HTTP,

    GET, POST, PATCH, DELETE,
    AUTH,
    OK, NO_CONTENT, INTERNAL_SERVER_ERROR, UNAUTHORIZED,
    makeApi_usingRulesFactory, makeApi_usingInteractionSpec,
    makeHttp_usingRulesFactory, makeHttp_usingInteractionSpec,

    makeApi_using: makeApi_ROOT,
    makeHttp: makeHttp_ROOT
};
