const Login = require('./containers/Login');
const ForgotPassword = require('./containers/ForgotPassword');
const PasswordReset = require('./containers/PasswordReset');
const PakistanNearpeerAttention = require('./components/PakistanNearpeerAttention');
const TokenNotExists = require('./components/TokenNotExists');
const LoginHelp = require('./components/LoginHelp');
const { context } = require('app-context');

const redux = context.redux.hooks;
const actions   = context.actions;
module.exports = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/forgot-password',
        component: ForgotPassword
    },
    {
        path: '/password-reset',
        component: PasswordReset
    },
    {
        path: '/login-help',
        component: LoginHelp,
        onWillMount: async () => {

            await redux.dispatch(actions.app.checkUserIp());
        }
    },
    {
        path: '/nearpeer-pakistan',
        component: PakistanNearpeerAttention
    },
    {
        path: '/reset-link-expired',
        component: TokenNotExists
    }
];
