const { connect } = require('react-redux');
const { context } = require('../app-context');
const InterestsInputField = require('components/InterestsInputField');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        categories: selectors.getCategories(state),
        interests: selectors.getInterests(state),
        searchText: selectors.getInterestsSearchText(state)
    }), {
        setSearchText:  (searchText) => actions.app.setInterestsSearchText({ interestsSearchText: searchText })
    }
)(InterestsInputField);
