const React = require('react');
const { context: appContext } = require('app-context');
const { push: Push } = require('connected-react-router');

const actions = appContext.actions;
const redux   = appContext.redux.hooks;

module.exports = class ControlCenter extends React.Component {

    onLoginBrs   = () => redux.dispatch(actions.auth.login({ email:'ivan@bigroomstudios.com',  password:'123' }));
    onLoginGmail = () => redux.dispatch(actions.auth.login({ email:'ivan.saskor@gmail.com',    password:'123' }));
    onLoginDevin = () => redux.dispatch(actions.auth.login({ email:'devin@bigroomstudios.com', password:'123' }));
    onLogout     = () => redux.dispatch(actions.auth.logout());

    onFetchSchools      = () => redux.dispatch(actions.dataFetching.fetchSchools());
    onFetchInterests    = () => redux.dispatch(actions.dataFetching.fetchInterests());
    onFetchBadgeTypes   = () => redux.dispatch(actions.dataFetching.fetchBadgeTypes());
    onFetchUsers        = () => redux.dispatch(actions.dataFetching.fetchUsers());
    onFetchCurrentUser  = () => redux.dispatch(actions.dataFetching.fetchCurrentUser());
    onFetchChannels     = () => redux.dispatch(actions.dataFetching.fetchChannels());
    onFetchUnreadCounts = () => redux.dispatch(actions.dataFetching.fetchUnreadMessageCounts());
    onFetchUpdateDates  = () => redux.dispatch(actions.dataFetching.fetchChannelUpdateDates());
    onFetchMessages     = () => redux.dispatch(actions.dataFetching.fetchMessages.firstPage({ channelSid: 'CHb80cb85b5d974bdab2100125d96219d1' }));
    onFetchNextMessages = () => redux.dispatch(actions.dataFetching.fetchMessages.nextPage({ channelSid: 'CHb80cb85b5d974bdab2100125d96219d1' }));

    onGoToHome        = () => redux.dispatch(Push('/'));
    onGoToLogin       = () => redux.dispatch(Push('/login'));
    onGoToProfile2    = () => redux.dispatch(Push('/app/profile/2'));
    onGoToSignup1     = () => redux.dispatch(Push('/signup'));
    onGoToSignup2     = () => redux.dispatch(Push('/signup/choose-password?v=1234-abcd'));
    onGoToAppHome     = () => redux.dispatch(Push('/app/welcome'));
    onGoToDms         = () => redux.dispatch(Push('/app/messaging'));
    onGoToNetwork     = () => redux.dispatch(Push('/app/connections/network'));
    onGoToProfileEdit = () => redux.dispatch(Push('/app/profile/edit'));

    onSendMessage           = () => redux.dispatch(actions.communication.sendMessage({ channelSid: 'CHb80cb85b5d974bdab2100125d96219d1', message: 'test message from Control Center' }));
    onMarkChannelAsUnread   = () => redux.dispatch(actions.communication.setLastReadMessageIndex({ channelSid: 'CHb80cb85b5d974bdab2100125d96219d1', messageIndex: null }));
    onMarkMessageAsLastRead = () => redux.dispatch(actions.communication.setLastReadMessageIndex({ channelSid: 'CHb80cb85b5d974bdab2100125d96219d1', messageIndex: 2 }));

    onShowSnackbar    = () => redux.dispatch(actions.alerts.notification.push({ message: 'Hi, I\'m the snackbar!' }));

    render = () => (

        <div className='container-fluid'>
            <div className='card card-inverse card-primary mt-3 mb-3 text-center'>
                <div className='card-block'>
                    <h1 className='card-title'>Control Center</h1>
                </div>
            </div>
            <div className='card mb-3'>
                <div className='card-block'>
                    <h2 className='card-title'>Authentication</h2>
                    <div className='btn-group'>
                        <a className='btn btn-secondary' onClick={this.onLoginBrs}>Login - ivan 1</a>
                        <a className='btn btn-secondary' onClick={this.onLoginGmail}>Login - ivan 2</a>
                        <a className='btn btn-secondary' onClick={this.onLoginDevin}>Login - devin</a>
                        <a className='btn btn-secondary' onClick={this.onLogout}>Logout</a>
                    </div>
                </div>
            </div>
            <div className='card mb-3'>
                <div className='card-block'>
                    <h2 className='card-title'>Data Fetching</h2>
                    <div className='btn-group'>
                        <a className='btn btn-secondary' onClick={this.onFetchSchools}>Schools</a>
                        <a className='btn btn-secondary' onClick={this.onFetchInterests}>Interests</a>
                        <a className='btn btn-secondary' onClick={this.onFetchBadgeTypes}>Badge Types</a>
                        <a className='btn btn-secondary' onClick={this.onFetchUsers}>Users</a>
                        <a className='btn btn-secondary' onClick={this.onFetchCurrentUser}>Current User</a>
                        <a className='btn btn-secondary' onClick={this.onFetchChannels}>Channels</a>
                        <a className='btn btn-secondary' onClick={this.onFetchUnreadCounts}>Unread Counts</a>
                        <a className='btn btn-secondary' onClick={this.onFetchUpdateDates}>Update Dates</a>
                        <a className='btn btn-secondary' onClick={this.onFetchMessages}>Messages</a>
                        <a className='btn btn-secondary' onClick={this.onFetchNextMessages}>Next Messages</a>
                    </div>
                </div>
            </div>
            <div className='card mb-3'>
                <div className='card-block'>
                    <h2 className='card-title'>Go To</h2>
                    <div className='btn-group'>
                        <a className='btn btn-secondary' onClick={this.onGoToHome}>Home</a>
                        <a className='btn btn-secondary' onClick={this.onGoToLogin}>Login</a>
                        <a className='btn btn-secondary' onClick={this.onGoToProfile2}>User Profile 2</a>
                        <a className='btn btn-secondary' onClick={this.onGoToSignup1}>Signup - Step 1</a>
                        <a className='btn btn-secondary' onClick={this.onGoToSignup2}>Signup - Step 2</a>
                        <a className='btn btn-secondary' onClick={this.onGoToAppHome}>App Home</a>
                        <a className='btn btn-secondary' onClick={this.onGoToDms}>DMS</a>
                        <a className='btn btn-secondary' onClick={this.onGoToNetwork}>Network</a>
                        <a className='btn btn-secondary' onClick={this.onGoToProfileEdit}>Profile Edit</a>
                    </div>
                </div>
            </div>
            <div className='card mb-3'>
                <div className='card-block'>
                    <h2 className='card-title'>Communication</h2>
                    <div className='btn-group'>
                        <a className='btn btn-secondary' onClick={this.onSendMessage}>Send Message</a>
                        <a className='btn btn-secondary' onClick={this.onMarkChannelAsUnread}>Mark chanel As Unread</a>
                        <a className='btn btn-secondary' onClick={this.onMarkMessageAsLastRead}>Mark Message As Last Read</a>
                    </div>
                </div>
            </div>
            <div className='card mb-3'>
                <div className='card-block'>
                    <h2 className='card-title'>Alerts</h2>
                    <div className='btn-group'>
                        <a className='btn btn-secondary' onClick={this.onShowSnackbar}>Show snackbar</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
