// Just the modules necessary for initial render!
const App = require('./app');
const Home = require('./home');
const Signup = require('./signup');
const Login = require('./login');
const Legal = require('./legal');
const SSO = require('./sso');

// Create routes
module.exports = (store) => ([
    Home,
    Signup.signupRoute,
    App(store),
    ...Signup(),
    ...Login,
    ...Legal,
    ...SSO,

    Signup.specificParent,

    // Must come after App routes since it's sort of a catchall
    Signup.specificSchool,
    // Page not found
    { redirect: { to: '/' } }
]);
