const _ = require('lodash');
const { connect } = require('react-redux');
const { context } = require('../app-context');
const Snackbar = require('components/Snackbar');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        open: !!selectors.getCurrentErrorNotification(state),
        message: _.get(selectors.getCurrentErrorNotification(state), 'message', ''),
        displayType: _.get(selectors.getCurrentErrorNotification(state), 'displayType', 'snackbar'),
        popUpTitle: _.get(selectors.getCurrentErrorNotification(state), 'popUpTitle', ''),
        actionLabel: _.get(selectors.getCurrentErrorNotification(state), 'action.label', null),
        onActionClick: _.get(selectors.getCurrentErrorNotification(state), 'action.onExecute', null)
    }),
    {
        onRequestClose: () => actions.alerts.notification.dismiss()
    }
)(Snackbar);
