const React = require('react');
const T = require('prop-types');

const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemSecondaryAction } = require('@mui/material/ListItemSecondaryAction');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { default: AssignmentIcon } = require('@mui/icons-material/Assignment');
const { default: EditIcon } = require('@mui/icons-material/Edit');
const { default: StartIcon } = require('@mui/icons-material/PlayCircleFilled');
const { default: CheckIcon } = require('@mui/icons-material/Done');
const { default: ReplayIcon } = require('@mui/icons-material/Replay');
const { default: StopIcon } = require('@mui/icons-material/HighlightOff');
const { default: DeleteIcon } = require('@mui/icons-material/Delete');
const { NavLink: Link } = require('react-router-dom');
const { default: styled } = require('styled-components');

// eslint-disable-next-line hapi/hapi-scope-start
const StyledListItem = styled(ListItem)(({ theme, isSolved }) => ({
    flexWrap: 'wrap',
    cursor: 'pointer',
    paddingRight: 96,
    paddingLeft: isSolved ? 8 : 16,
    background: isSolved === false ? `linear-gradient(0deg, #A79CDC 25%, #DDD9F0 100%)` : '',
    '&:hover':{
        backgroundColor:theme.palette.action.disabledBackground
    },
    '&.MuiListItem-container':{
        '&:not(:last-of-type)':{
            borderBottom: `1px solid ${theme.palette.action.disabledBackground}`
        }
    }
}));

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)(({ theme }) => ({
    display:'flex',
    alignItems:'center'
}));

const SurveyListItem = (props) => {

    const {
        survey,
        canEdit,
        canSeeReport,
        showStart,
        onDelete,
        onStop
    } = props;

    return (
        <StyledListItem
            isSolved={survey.solved}
            component={(showStart && survey.isActive) ? Link : 'li'}
            to={(showStart && survey.isActive) ? `/app/surveys/${survey.id}/start` : null}
        >
            <ListItemText
                primary={survey.title}
                secondary={survey.description}
            />
            <StyledListItemSecondaryAction>
                {!survey.solved ? null : <Tooltip arrow={true} title={'Survey Completed'} placement={'top'}>
                    <CheckIcon />
                </Tooltip>
                }
                {(showStart && survey.isActive) ? <Tooltip arrow={true} title={!survey.solved ? 'Start The Survey' : 'Edit my answers'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label={!survey.solved ?  'Start The Survey' : 'Edit my answers'}
                        component={Link}
                        to={`/app/surveys/${survey.id}/start`}
                        size='large'
                        style={{ paddingRight: !canEdit && survey.solved ? 0 : 12 }}
                    >
                        {!survey.solved ?  <StartIcon /> : <ReplayIcon />}
                    </IconButton>
                </Tooltip> :  null}
                {(canEdit && survey.status === 'active') ? <Tooltip arrow={true} title={'Stop Survey'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="Stop Survey"
                        onClick={() => onStop(survey)}
                        size="large">
                        <StopIcon />
                    </IconButton>
                </Tooltip> : null}
                {(canEdit && survey.status === 'upcoming') ? <Tooltip arrow={true} title={'Edit'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="edit"
                        component={Link}
                        to={`/app/surveys/${survey.id}/edit`}
                        size="large">
                        <EditIcon />
                    </IconButton>
                </Tooltip> : null}
                {(canEdit && survey.status === 'upcoming') ? <Tooltip arrow={true} title={'Delete Survey'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="delete survey"
                        onClick={() => onDelete(survey)}
                        size="large">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip> : null}
                {(canSeeReport && survey.status !== 'upcoming') ? <Tooltip arrow={true} title={'Report'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="report"
                        component={Link}
                        to={`/app/surveys/${survey.id}/report`}
                        size="large"
                        style={{ paddingRight: survey.solved ? 0 : 12 }}
                    >
                        <AssignmentIcon />
                    </IconButton>
                </Tooltip> : null}
            </StyledListItemSecondaryAction>
        </StyledListItem>
    );
};

SurveyListItem.propTypes = {
    survey: T.shape({
        id: T.string,
        title: T.string,
        description: T.string,
        isActive: T.bool,
        started: T.bool,
        solved: T.bool,
        status: T.oneOf(['active', 'upcoming', 'finished'])
    }),
    canEdit: T.bool,
    canSeeReport: T.bool,
    showStart: T.bool,
    onStop: T.func,
    onDelete: T.func
};

module.exports = SurveyListItem;
