const { connect } = require('react-redux');
const { context } = require('app-context');
const PasswordReset = require('../components/PasswordReset');
const { replace: Replace } = require('connected-react-router');

const actions = context.actions;

module.exports = connect(
    null, {
        onSubmit: actions.auth.resetPassword,
        checkResetToken: actions.auth.checkResetToken,
        replace: Replace
    }
)(PasswordReset);
