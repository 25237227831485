const { CHATS: Types, COMMUNICATION: Communication_types } = require('../action-types');
const { MESSAGES_SORT_TYPE } = require('../utils/constants');
const UniqBy = require('lodash/uniqBy');

const initialState = {
    allMessagesSortingType: MESSAGES_SORT_TYPE.BY_DATE_UPDATED,
    messagesToSendOnServer: [],
    pinHeadersStates: {}
};

const reducer = (state = initialState, action) => {

    const { key } = (action.payload || {});

    switch (action.type) {
        case Types.SET_PIN_HEADER_STATE:
            return {
                ...state,
                pinHeadersStates: {
                    ...state.pinHeadersStates,
                    [key]: action.payload.status
                }
            };
        case Types.CLEAR:
            delete state[key];
            return state;
        case Types.SET_ALL_MESSAGES_SORTING_TYPE:
            return {
                ...state,
                allMessagesSortingType: action.payload.sortType
            };
        case Communication_types.STORE_MESSAGE_FOR_SENDING_ON_SERVER: {

            // don't add message if already exist
            if (state.messagesToSendOnServer.find((message) => message.messageSid === action.payload.messageSid)) {
                return state;
            }

            return {
                ...state,
                messagesToSendOnServer: [...state.messagesToSendOnServer, action.payload]
            };
        }

        case Communication_types.REMOVE_MESSAGE_FOR_SENDING_ON_SERVER: {

            const { messageSid } = action.payload;

            return {
                ...state,
                messagesToSendOnServer: state.messagesToSendOnServer.filter((message) => {

                    return message.messageSid !== messageSid;
                })
            };
        }

        case Communication_types.REMOVE_MESSAGES_FOR_SENDING_ON_SERVER: {

            const { messages } = action.payload;

            return {
                ...state,
                messagesToSendOnServer: [...state.messagesToSendOnServer.filter((messageOne) => {

                    return !messages.find((sentMessage) => messageOne.messageSid === sentMessage.messageSid);
                })]
            };
        }

        default:
            return state;
    }
};

const selectors = {
    getChatTextBySid: (state, key) => state[key],
    getPinHeaderStateBySid: (state, key) => state.pinHeadersStates[key],
    getAllMessagesSortType: (state) => state.allMessagesSortingType,
    getAllMessagesToSendOnServer: (state) => UniqBy(state.messagesToSendOnServer, 'messageSid')
};

module.exports = Object.assign(reducer, { selectors });
