const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { CONNECTIVITY: Types } = require('../action-types');

module.exports = (context) => {

    const twilio = context.api.twilio;

    return {
        offline: makeActionCreator(Types.OFFLINE, { isOffline: T.bool }, {
            after: twilio.updateToken
        })
    };
};
