const { connect } = require('react-redux');
const { context } = require('../app-context');
const SearchResult = require('components/SearchResult');

const selectors = context.selectors.all;
const actions   = context.actions;
module.exports = connect(
    (state) => ({
        isParent: selectors.isParentPermission(state),
        interestsLoading: selectors.getIsLoadingInterests(state),
        rolesInteractions: selectors.getCurrentUserRoleInteractions(state),
        categories: selectors.getCategories(state),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        badges: selectors.getAllBadges(state)
    }), {
        onClickShowToast: (msg) => actions.alerts.notification.push({ message: msg })
    }
)(SearchResult);
