const { forwardRef } = require('react');
const { NavLink: Link } = require('react-router-dom');

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name,react/prop-types,hapi/hapi-scope-start
const RouterLink = forwardRef(({ to, ...other }, ref) => (
    <Link ref={ref} to={to} {...other} />
));

module.exports = RouterLink;
