const React = require('react');
const PropTypes = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Paper } = require('@mui/material/Paper');
const { Typography } = require('@mui/material');

const UserInputs = require('components/UserInputs');
const { default: Classes } = require('./styles.scss');
const Colors = require('styles/colors.json');
const Fonts = require('styles/fonts.json');
const BottomButtonContainer = require('components/BottomButtonContainer');
const ScrollContainerLayout = require('layouts/ScrollContainerLayout');

const internals = {};

module.exports = class UserInfo extends UserInputs {

    static propTypes = Object.assign({}, UserInputs.propTypes, {
        onGoBack: PropTypes.func.isRequired,
        onLogout: PropTypes.func,
        isAuthenticated: PropTypes.bool.isRequired
    });

    constructor(props) {

        super(props);

        this.isSignupPage = true;
        this.goBack = this._goBack.bind(this);
        this.logout = this._logout.bind(this);
    }

    componentDidMount() {

        super.componentDidMount();
        document.body.classList.add(Classes.userInfoBody);
    }

    componentWillUnmount() {

        super.componentWillUnmount();
        document.body.classList.remove(Classes.userInfoBody);
    }

    _goBack() {

        this.props.onGoBack(this.getSignupDetails());
    }

    _logout() {

        this.props.onLogout();
    }

    render() {

        const { isParent } = this.props;
        const { FieldsWrapper, HeaderText } = internals;

        return <ScrollContainerLayout>
            <BottomButtonContainer
                btnLabel='Save'
                onBtnClick={this.submit}
                disabled={this.state.submitting}
            >
                <div className={`${Classes.fixedCaps} ${Classes.header}`}>
                    <Paper className={Classes.paper} elevation={1}>
                        <div className='contentWrapper'>
                            <HeaderText tabIndex={0}>
                                {!isParent && 'Complete your profile to start meeting peers'}
                                {isParent && 'Complete your profile to start meeting other families'}
                            </HeaderText>
                        </div>
                    </Paper>
                </div>
                <div className={`${Classes.main}`}>
                    {this.renderFields({ fieldsWrapper: FieldsWrapper })}
                </div>
            </BottomButtonContainer>
        </ScrollContainerLayout>;
    }
};

internals.styles = {
    button: {
        backgroundColor: Colors.lightText,
        height: '2.25em'
    },
    buttonLabel: {
        fontFamily: Fonts.headerFont,
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '1.25em',
        letterSpacing: 1,
        lineHeight: '2.5em'
    }
};

internals.FieldsWrapper = Styled.div`
    margin: 0 auto;
    @media screen and (min-width: 360px) {
        max-width: 400px;
    }
    @media screen and (min-width: 600px) {
        max-width: 600px;
    }

    button:hover, div[role="button"]:hover {
        opacity: 0.9;
        & > div > div {
            background-color: initial !important;
            opacity: 1;
        }
    }
`;

internals.HeaderText = Styled(Typography).attrs({
    variant: 'h1'
})`
    font-size: 22px;
`;
