const React = require('react');
const T = require('prop-types');
const { default: IconButton } = require('@mui/material/IconButton');

module.exports = class IconMenuButton extends React.PureComponent {

    static propTypes = {
        onClick: T.func
    }

    constructor() {

        super();

        this.onClick = this._onClick.bind(this);
    }

    _onClick(ev) {

        if (this.props.onClick) {
            this.props.onClick(ev);
        }
    }

    render() {

        return <IconButton {...this.props} onClick={this.onClick} size="large" />;
    }
};
