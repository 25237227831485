const React = require('react');
const T = require('prop-types');
const { default: Paper } = require('@mui/material/Paper');
const { default: Classes } = require('./styles.scss');

// Adapted from BottomButton
module.exports = class MessageBanner extends React.PureComponent {

    static propTypes = {
        text: T.string,
        className: T.string,
        TypographyProps: T.object
    }

    render() {

        const {
            text,
            className,
            ...rest } = this.props;

        return <div className={`${Classes.MessageBannerWrapper} ${className ? className : ''}`} {...rest}>
            <Paper className={`${Classes.paper} contentWrapper`} elevation={0}>
                {text &&
                    <div className={`${Classes.banner} ${Classes.innerWrapper}`}>
                        {text}
                    </div>
                }
            </Paper>
        </div>;
    }
};
