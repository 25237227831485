const { connect } = require('react-redux');
const { context } = require('../app-context');
const InterestPopper = require('components/InterestPopper');
const { safeWarning } = require('assertions-simplified');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const selectors = context.selectors.all;
const actions = context.actions;
const redux = context.redux.hooks;

module.exports = connect(
    (state) => ({
        currentUserInterestsIds : selectors.getCurrentUserInterestsIds(state),
        userPreferences : selectors.getPreferences__forUserPreferencesForm(state)
    }), {
        onSubmitNewInterest: (newInterestId) => (dispatch) => { // eslint-disable-line

            const currentUserInterestsIds = selectors.getCurrentUserInterestsIds(redux.getState());

            const diff = {
                interests:[...currentUserInterestsIds,newInterestId]
            };

            return dispatch(actions.profileManagement.updateProfile(diff))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Add Interest failed');
                }

                dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

                dispatch(actions.alerts.notification.push({
                    message: 'Interest added'
                }));

                setTimeout(() => {

                    AnimatedFocusIndicator.wrapFocusIndicatorForElement();
                }, 100);
            })
            .catch(safeWarning);
        },
        updateUserPreferences: (userPreferences) => (dispatch) => { // eslint-disable-line

            return dispatch(actions.profileManagement.updateUserPreferences(userPreferences)).then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update preferences failed');
                }

            })
            .catch(safeWarning);
        }
    }
)(InterestPopper);
