const MakeStringFromArray = require('./makeStringFromArray');

const checkIfSimilaritiesExist = ({ similarities }) => {

    similarities = Object.entries(similarities)
    .filter(([val]) => {

        // Check if val is an array or a boolean, otherwise return false.
        // The main case in mind for this is to filter out the 'distance' field
        // which can appear in similarity results, but there could be others later.

        if (Array.isArray(val)) {
            return val.length > 0;
        }

        const strVal = String(val);
        if (strVal === 'true' || strVal === 'false') {
            return !!val;
        }

        return false;
    });

    return Object.keys(similarities).length;
};

const makeSimilarityText = ({ similarities, areStartTxt, insideClassDisplay = false }) => {

    similarities = Object.entries(similarities)
    .filter(([, val]) => {

        if (Array.isArray(val)) {
            return val.length > 0;
        }

        return !!val;
    })
    .reduce((obj, [key, val]) => ({ // Turn back into an object
        ...obj,
        [key]: val
    }), {});

    /* if ('classes' in similarities && Object.keys(similarities).length > 1){ // Only display groups in common if you have nothing else in common. Otherwise, leave that sentence off

        delete similarities['classes'];
    }*/

    if (Object.keys(similarities).length === 0) {
        return '';
    }

    const formattedSimilarities = sortAndFormatSimilarities(similarities);
    const formattedClassSimilarities = sortAndFormatClassSimilarities(similarities);

    let lastVerbUsed;

    const result = formattedSimilarities
    .reduce((similarityTexts, item, i) => {

        const {
            val,
            config: {
                connector,
                singularConnector,
                pluralConnector,
                verb,
                format
            },
            type
        } = item;

        const formatFunc = format || ((x) => x);
        const formatted = [].concat([].concat(val)).map(formatFunc);

        const isPlural = Array.isArray(formatted) && formatted.length > 1;
        // Falls back to 'connector'
        const connectorText = (isPlural ? pluralConnector : singularConnector) || connector;

        const itemText = listItemText(formatted,type);

        const fullItemText = `${connectorText}${itemText ? ` ${itemText}` : itemText}`;

        const isLastItem = i === formattedSimilarities.length - 1;

        const getVerb = () => {

            const toReturn = ((verb === lastVerbUsed) && !isLastItem) ? '' : verb;
            lastVerbUsed = verb;
            return toReturn;
        };

        // At the beginning of the list
        if (i === 0) {

            let beginning;
            switch (verb) {
                case 'are':
                    beginning = `${areStartTxt ? `You ${areStartTxt}` : 'You\'re'} both ${fullItemText}`;
                    break;
                case 'share':
                    beginning = `You ${getVerb()} ${fullItemText}`;
                    break;
                default:
                    beginning = `You both ${getVerb()} ${fullItemText}`;
                    break;
            }

            return [
                beginning + (isLastItem ? '.' : '')
            ];
        }

        // At the end of the list
        if (isLastItem) {
            // Sir Oxford enters the scene
            return [
                ...similarityTexts,
                ` and ${getVerb()} ${fullItemText}` + '.'
            ];
        }

        // In the middle of the list
        return [
            ...similarityTexts,
            ` ${getVerb()} ${fullItemText}`
        ];
    }, []);

    const classesResult =  formattedClassSimilarities
    .reduce((classSimilarityTexts, item, i) => {

        const {
            val,
            config: {
                connector,
                singularConnector,
                pluralConnector,
                format
            },
            type
        } = item;

        const formatFunc = format || ((x) => x);
        const formatted = [].concat([].concat(val)).map(formatFunc);

        const isPlural = Array.isArray(formatted) && formatted.length > 1;
        // Falls back to 'connector'
        const connectorText = (isPlural ? pluralConnector : singularConnector) || connector;

        const itemText = listItemText(formatted, type, insideClassDisplay);

        const fullItemText = `${connectorText}${itemText ? ` ${itemText}` : itemText}`;

        return [
            (formatted.length ? (formatted.length === 1 ? `You're both ${fullItemText}.` : `You have ${fullItemText}.`) : `` )
        ];
    }, []);

    // clear result text if there is any multiple spaces

    const clearedResult = result.map((baseSimilarity) => {

        return baseSimilarity.replace(/  +/g, ' ');
    });
    const clearedClassesResult = classesResult.map((classSimilarity) => {

        return classSimilarity.replace(/  +/g, ' ');
    });

    return {
        baseSimilarities: MakeStringFromArray(clearedResult),
        classSimilarities: MakeStringFromArray(clearedClassesResult)
    };
};

const formatSimilarityText = (text) => {

    if (Array.isArray(text)) {
        text = text[0];
    }

    return text.replaceAll('both', '<b>both</b>').replaceAll('share', '<b>share</b>');
};

const filterOutKeys = (obj, keys) => {

    return Object.keys(obj)
    .filter((key) => !keys.includes(key))
    .reduce((newObject, key) => {

        return {
            ...newObject,
            [key]: obj[key]
        };
    }, {});
};

const filterInKeys = (obj, keys) => {

    return Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce((newObject, key) => {

        return {
            ...newObject,
            [key]: obj[key]
        };
    }, {});
};

// No classes
const sortAndFormatSimilarities = (similarities) => {

    const order = [
        'majors',
        'passionInterests',
        'interests',
        'veteran',
        'parent',
        'partTime',
        'online',
        'transfer'
    ];

    return order
    .filter((type) => !!similarities[type])
    .map((type) => {

        const val = similarities[type];

        return {
            type,
            val,
            config: getItemsConfig(type)
        };
    });
};

const sortAndFormatClassSimilarities = (similarities) => {

    const order = [
        'classes'
    ];

    return order
    .filter((type) => !!similarities[type])
    .map((type) => {

        const val = similarities[type];

        return {
            type,
            val,
            config: getItemsConfig(type)
        };
    });
};

const getItemsConfig = (type) => {

    switch (type) {
        case 'majors':
            return {
                connector: 'same',
                verb: 'have',
                format: ({ name }) => 'major'
            };
        case 'classes':
            return {
                singularConnector: 'in',
                pluralConnector: ' ',
                format: ({ name }) => `"${name}"`
            };
        case 'passionInterests':
            return {
                connector: 'passionate about',
                verb: 'are',
                format: ({ name }) => name
            };
        case 'interests':
            return {
                singularConnector: 'an Interest in',
                pluralConnector: 'Interests in',
                verb: 'share',
                format: ({ name }) => name
            };
        case 'veteran':
            return {
                connector: 'veterans',
                verb: 'are'
            };
        case 'parent':
            return {
                connector: 'parents',
                verb: 'are'
            };
        case 'partTime':
            return {
                connector: 'part-timers',
                verb: 'are'
            };
        case 'online':
            return {
                connector: 'online students',
                verb: 'are'
            };
        case 'transfer':
            return {
                connector: 'transfer students',
                verb: 'are'
            };
    }
};

const listItemText = (items,type,insideClassDisplay = false) => {

    if (!items) {
        return '';
    }

    if (type === 'classes') {
        switch (items.length) {
            case 0:
                return '';
            case 1:
                // eslint-disable-next-line
                const item = String(items[0]);
                if (item === 'true') {
                    return '';
                }

                return items[0];
            default: {

                return `${items.length} ${insideClassDisplay ? 'other' : ''} groups in common`;
            }
        }
    }
    else {

        switch (items.length) {
            case 0:
                return '';
            case 1:
                // eslint-disable-next-line
                const item = String(items[0]);
                if (item === 'true') {
                    return '';
                }

                return items[0];
            case 2:
                return `${items[0]} and ${items[1]}`;
            default: {

                const restLen = items.length - 2;

                let endWord = null;

                switch (type){
                    case 'classes':{
                        endWord = 'group';
                    }
                }

                return `${items[0]}, ${items[1]}, and ${restLen} other${(restLen === 1) ? '' : (endWord) ? '' : 's'}${endWord ? ((restLen === 1) ? ` ${endWord}` : ` ${endWord}s` ) : ''}`;
            }
        }
    }
};

module.exports = {
    filterOutKeys,
    filterInKeys,
    sortAndFormatSimilarities,
    getItemsConfig,
    makeSimilarityText,
    formatSimilarityText,
    checkIfSimilaritiesExist
};
