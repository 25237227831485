const React = require('react');
const T = require('prop-types');
const { default: Classes } = require('./styles.scss');

module.exports = class NotFound extends React.PureComponent {

    static propTypes = {
        history: T.object.isRequired
    };

    constructor() {

        super();

        this.goBack = this._goBack.bind(this);
    }

    _goBack(e) {

        e.preventDefault();
        this.props.history.goBack();
    }

    render() {

        return <div className={Classes.root}>
            <div>
                <h3>404</h3>
                <h4>Not Found</h4>
                Would you like to <a href onClick={this.goBack}>go back</a>?
            </div>
        </div>;
    }
};
