const React = require('react');

const AppBarLayout = require('layouts/AppBarLayout');
const LoginHelpContents = require('containers/LoginHelpContents');
const { default: Classes } = require('./styles.scss');
const PlainLayout = require('layouts/PlainLayout');

const internals = {};

module.exports = class LoginHelp extends React.Component {

    render() {

        return (
            <PlainLayout>
                <AppBarLayout hideFooter>
                    <LoginHelpContents className={Classes.contents} />
                </AppBarLayout>
            </PlainLayout>
        );
    }
};
