const LocalStorageAvailable = (storageType) => {

    let storage;

    if (storageType === 'persist') {
        storage = localStorage;
    }
    else if (storageType === 'session') {
        storage = sessionStorage;
    }
    else {
        return false;
    }

    const test = 'test';
    try {
        storage.setItem(test, test);
        storage.removeItem(test);
        return true;
    }
    catch (e){
        return false;
    }
};

module.exports = LocalStorageAvailable;
