module.exports = (context) => ({
    alerts:            require('./alerts'            )(context),
    signup:            require('./signup'            )(context),
    auth:              require('./auth'              )(context),
    dataFetching:      require('./data-fetching'     )(context),
    profileManagement: require('./profile-management')(context),
    communication:     require('./communication'     )(context),
    appFeedback:       require('./app-feedback'      )(context),
    notifications:     require('./notifications'     )(context),
    classes:           require('./classes'           )(context),
    surveys:           require('./surveys'           )(context),
    connectivity:      require('./connectivity'      )(context),
    app:               require('./app'               )(context),
    chats:             require('./chats'             )(context),
    router:            require('./router'            )(context)
});
