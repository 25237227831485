const React = require('react');
const { default: Styled } = require('styled-components');
const { default: CircularProgress } = require('@mui/material/CircularProgress');
const { motion } = require('framer-motion');

const internals = {};

module.exports = function FallbackSpinner() {

    const { SpinnerContainer, Spinner, styles } = internals;

    const icon = {
        hidden: {
            opacity: 0,
            pathLength: 0,
            fill: 'rgba(255, 255, 255, 0)',
            stroke: 'rgba(255, 255, 255, 0)'
        },
        visible: {
            opacity: 1,
            pathLength: 1,
            fill: '#ff4081',
            stroke: '#ff4081'
        }
    };

    const diagonalLine = {
        hidden: {
            opacity: 0,
            pathLength: 0,
            fill: 'rgba(255, 255, 255, 0)',
            stroke: 'rgba(255, 255, 255, 0)'
        },
        visible: {
            opacity: 1,
            pathLength: 1,
            fill: '#4e00b0',
            stroke: '#4e00b0'
        }
    };

    return (
        <SpinnerContainer>
            <div  style={styles.container}>
                <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55.19995 55.34607"
                    style={styles.item}
                >
                    <motion.path
                        d="M13.89978,36.39453V9.8999h-8V36.39471a9.9,9.9,0,1,0,8-.00018Z"
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{
                            default: {
                                duration: 1.5,
                                ease: 'easeInOut',
                                repeat: Infinity,
                                repeatType: 'mirror'
                            },
                            fill: {
                                duration: 1.5,
                                ease: [1, 0, 0.8, 1],
                                repeat: Infinity,
                                repeatType: 'mirror'
                            }
                        }}
                    />
                    <motion.path
                        d="M55.2,9.9a9.9,9.9,0,1,0-13.89978,9.05151V46.2998h8V18.95135A9.89683,9.89683,0,0,0,55.2,9.9Z"
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{
                            default: {
                                duration: 1.5,
                                ease: 'easeInOut',
                                repeat: Infinity,
                                repeatType: 'mirror'
                            },
                            fill: {
                                duration: 1.5,
                                ease: [1, 0, 0.8, 1],
                                repeat: Infinity,
                                repeatType: 'mirror'
                            }
                        }}
                    />
                    <motion.path
                        d="M45.3,35.546a9.85724,9.85724,0,0,0-3.55.66693L19.119,13.48834a9.92919,9.92919,0,1,0-5.66937,5.6449l22.63129,22.7243A9.89541,9.89541,0,1,0,45.3,35.546Z"
                        variants={diagonalLine}
                        initial="hidden"
                        animate="visible"
                        transition={{
                            default: {
                                duration: 1.5,
                                ease: 'easeInOut',
                                repeat: Infinity,
                                repeatType: 'mirror'
                            },
                            fill: {
                                duration: 1.5,
                                ease: [1, 0, 0.8, 1],
                                repeat: Infinity,
                                repeatType: 'mirror'
                            }
                        }}
                    />
                </motion.svg>
            </div>
            <Spinner
                size={80}
                thickness={4}
                color='secondary'
            />
        </SpinnerContainer>
    );
};

internals.SpinnerContainer = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

internals.Spinner = Styled(CircularProgress)`
    position: 'absolute';
        top: '50%';
        left: '50%';
        marginTop: -35;
        marginLeft: -35;
`;

internals.styles = {
    container: {
        width: '100px',
        height: '100px',
        display: 'flex',
        placeContent: 'center',
        overflow: 'hidden',
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '30px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -50,
        marginLeft: -50
    },
    item: {
        width: '40%',
        overflow: 'visible',
        stroke: '#fff',
        strokeWidth: 2,
        strokeLinejoin: 'round',
        strokeLinecap: 'round'
    }
};
