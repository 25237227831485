const T = require('prop-types');

module.exports = {
    Channel: {
        id: T.string,
        name: T.string
    },
    Message: {
        text: T.string
    }
};
