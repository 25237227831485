const IsEqual = require('lodash/isEqual');
const Get = require('lodash/get');
const LocalStorageAvailable = require('./check-storage');

const internals = {};
internals.cache = { persist: {}, session: {} };

const storageApi = {};

module.exports = storageApi;

internals.checkStorageAvailable = (storageType, func) => {

    if (!LocalStorageAvailable(storageType)) {
        console.warn(`${storageType} storage unavailable`);
        // eslint-disable-next-line react/display-name
        return () => null;
    }

    return func;
};

internals.withCache = (storageType, actionType, { key, value }, func) => {

    const { cache } = internals;

    switch (actionType) {
        case 'get':
            if (cache[storageType][key]) {
                return cache[storageType][key];
            }

            break;
        case 'set':
            cache[storageType][key] = value;
            break;
        case 'remove':
            delete cache[storageType][key];
            break;
    }

    return func();
};

internals.subscribeStorePathWithStore = (storageType, store, path, key, middleware) => {

    store.subscribe(() => {

        const state = store.getState();

        let valToWrite = Get(state, path);
        const cachedVal = internals.cache[storageType][key];

        if (typeof middleware === 'function') {
            valToWrite = middleware(valToWrite);
        }

        let funcsBase;
        if (storageType === 'persist') {
            funcsBase = module.exports;
        }
        else {
            funcsBase = module.exports.session;
        }

        if (typeof valToWrite === 'undefined') {
            funcsBase.remove(key);
            return;
        }

        let valToCompare;
        if (typeof cachedVal === 'undefined') {
            valToCompare = funcsBase.get(key);
        }
        else {
            valToCompare = cachedVal;
        }

        if (!IsEqual(valToWrite, valToCompare)) {
            funcsBase.set(key, valToWrite);
        }
    });
};

// Here's the API!

const checkStoragePersist = internals.checkStorageAvailable.bind(null, 'persist');
const withCachePersist = internals.withCache.bind(null, 'persist');

const checkStorageSession = internals.checkStorageAvailable.bind(null, 'session');
const withCacheSession = internals.withCache.bind(null, 'session');

storageApi.get = checkStoragePersist((key) => withCachePersist('get', { key }, () => JSON.parse(localStorage.getItem(key) || 'null')));
storageApi.set = checkStoragePersist((key, value) => withCachePersist('set', { key, value }, () => localStorage.setItem(key, JSON.stringify(value))));
storageApi.remove = checkStoragePersist((key) => withCachePersist('remove', { key }, () => localStorage.removeItem(key)));
storageApi.subscribeStorePathWithStore = internals.subscribeStorePathWithStore.bind(null, 'persist');
storageApi.session = {
    get: checkStorageSession((key) => withCacheSession('get', { key }, () => JSON.parse(sessionStorage.getItem(key) || 'null'))),
    set: checkStorageSession((key, value) => withCacheSession('set', { key, value }, () => sessionStorage.setItem(key, JSON.stringify(value)))),
    remove: checkStorageSession((key) => withCacheSession('remove', { key }, sessionStorage.removeItem(key))),
    subscribeStorePathWithStore: internals.subscribeStorePathWithStore.bind(null, 'session')
};
