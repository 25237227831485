const { connect } = require('react-redux');
const { context } = require('app-context');
const NotificationsDialog = require('../components/NotificationsDialog');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        notifications: selectors.getNotifications_forDialog(state),
        currentUserRolesInteractions:selectors.getCurrentUserRoleInteractions(state)
    }), {
        onDismissNotification: actions.notifications.dismiss,
        onClickAnswerQuestion: actions.notifications.answerQuestion,
        onRequestIgnore: ({ id, type, senderId: userId }) => actions.notifications.decline({ id, type, userId }),
        onRequestAccept: ({ id, type, senderId: userId }) => actions.notifications.accept({ id, type, userId }),
        onAcceptTransfer: ({ id }) => actions.notifications.acceptTransfer({ notificationId: id }),
        showNotification: (msg) => actions.alerts.notification.push({ message: msg }),
        onOpen: () => (dispatch, getState) => { // eslint-disable-line

            const unreadNotifications = selectors.getUnreadNotifications(getState());

            if (!unreadNotifications || !unreadNotifications.length) {
                return;
            }

            dispatch(actions.notifications.markRead({ ids: unreadNotifications.map((n) => n.id) }));
        },
        openAlertDialog: actions.app.openAlertDialog,
        onClickJoinClass: ({ id, name }) => actions.classes.join({ id, name })
    }
)(NotificationsDialog);
