const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

const { default: Typography } = require('@mui/material/Typography');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemAvatar } = require('@mui/material/ListItemAvatar');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: ListItemSecondaryAction } = require('@mui/material/ListItemSecondaryAction');

const { default: IconButton } = require('@mui/material/IconButton');
const { default: ClassIcon } = require('@mui/icons-material/Class');
const { default: GroupIcon } = require('@mui/icons-material/Group');
const UnreadMessagesBadge = require('../../../../../routes/app/components/UnreadMessagesBadge');
const { default: styled } = require('styled-components');
const Emoji = require('components/Emoji');
const { transient$Props } = require('utils/styles');

const { default: Classes } = require('./styles.scss');

const internals = {};

const StyledListItemAvatar = styled(ListItemAvatar)`
   &&{
     position: relative;
   }
`;

const StyledListItem = styled(ListItem)`
    && {
        width: 100%;
        list-style: none;
        flex: 1 100%;
    }
`;

const StyledRootListItem = styled(ListItem)(({ useOnHover, theme }) => ({
    flexWrap: 'wrap',
    '&:hover': {
        backgroundColor: useOnHover && theme.palette.action.disabledBackground
    },
    '.MuiListItem-container':{
        flex: 1
    }
}));

module.exports = class ClassListItem extends React.Component {

    static propTypes = {
        action: T.func,
        middleIcon: T.node,
        middleActionButton: T.node,
        darkBackground: T.bool,
        titleClass: T.string,
        subtitleClass: T.string,
        infoClass: T.string,
        withoutAvatar: T.bool,
        announcementDescInTitle: T.bool,
        extraSecondaryText: T.any,
        showUnreadBadge: T.bool,
        showPrivate: T.bool,
        useOnHover: T.bool,
        class_: T.shape({
            name: T.string,
            emojiSymbol: T.string,
            facilitator: T.string,
            campus: T.string,
            shortDescription: T.string,
            number: T.string,
            additionalDescription: T.string,
            location: T.string,
            room: T.string,
            isAnnouncement: T.bool,
            private: T.bool,
            channel: T.oneOfType([
                T.oneOf([null]),
                T.shape({
                    hasUnreads: T.bool
                })
            ]),
            conversation: T.oneOfType([
                T.oneOf([null]),
                T.shape({
                    hasUnreads: T.bool
                })
            ]),
            type: T.string
        }),
        displayDetails: T.bool,
        titleUseH2: T.bool
    }

    constructor() {

        super();

        this.action = this._action.bind(this);
    }

    _action(event) {

        if (event){
            event.preventDefault();
        }

        if (!this.props.action) {
            return;
        }

        // We wait because onClick has some funny timing triggering a click event as well.
        // If we dont wait to show (for example) a confirmation dialog, it fires twice!
        setTimeout(() => {

            this.props.action(this.props.class_);
        });
    }

    render() {

        const {
            middleActionButton = null,
            darkBackground = false,
            middleIcon,
            titleClass,
            subtitleClass,
            infoClass,
            withoutAvatar,
            extraSecondaryText,
            showUnreadBadge,
            useOnHover,
            announcementDescInTitle,
            showPrivate = true,
            class_: {
                name,
                emojiSymbol,
                private: classIsPrivate,
                shortDescription,
                number,
                additionalDescription,
                facilitator,
                campus,
                location,
                room,
                channel,
                type,
                conversation,
                isAnnouncement
            },
            displayDetails,
            titleUseH2,
            ...other
        } = this.props;

        const {
            DetailsWrapper,
            TitleText
        } = internals;

        let LeftIcon;

        switch (type) {
            case 'class':
                LeftIcon = ClassIcon;

                if (emojiSymbol){
                    // eslint-disable-next-line react/display-name
                    LeftIcon =  () => {

                        return  <Emoji style={{ padding: 0 }} symbol={emojiSymbol} />;
                    };
                }

                break;
            case 'group':
                LeftIcon = GroupIcon;

                if (emojiSymbol) {
                    // eslint-disable-next-line react/display-name
                    LeftIcon =  () => {

                        return <Emoji style={{ padding: 0 }} symbol={emojiSymbol} />;
                    };
                }

                break;
            default:
                LeftIcon = GroupIcon;

                if (emojiSymbol) {
                    // eslint-disable-next-line react/display-name
                    LeftIcon =  () => {

                        return <Emoji style={{ padding: 0 }} symbol={emojiSymbol} />;
                    };
                }

                break;
        }

        const middleActionButtonWithAction = (middleActionButton && this.props.action) ? React.cloneElement(middleActionButton, {
            onClick: this.action
        }) : middleActionButton || null;

        const secondaryText = (`${shortDescription || ''} ${number || ''}`).trim();

        const numOfUnreads = isAnnouncement ? conversation && conversation.numUnreads : channel && channel.numUnreads;

        return (
            <StyledRootListItem
                {...other}
                disableGutters={true}
                useOnHover={useOnHover}
            >
                <StyledListItem>
                    <StyledListItemAvatar>
                        <Avatar>
                            <LeftIcon />
                        </Avatar>
                        {showUnreadBadge && <UnreadMessagesBadge numUnreads={numOfUnreads} className={Classes.unreadMessagesBadge} />}
                    </StyledListItemAvatar>
                    <ListItemText
                        primary={<div className={` ${titleClass || ''}`}>
                            <TitleText $titleUseH2={titleUseH2}>{name}</TitleText>
                        </div>}
                        secondary={(secondaryText || extraSecondaryText || isAnnouncement || (showPrivate && classIsPrivate)) && (
                            <div className={Classes.secondaryTextHolder}>
                                {secondaryText ? (
                                    <span className={`${subtitleClass || ''}`} style={{ marginRight: 7 }}>
                                        {secondaryText}
                                    </span>
                                ) : <span />}
                                {extraSecondaryText ? <div style={{ fontStyle: 'italic' }}>{extraSecondaryText}</div> : null}
                                {!isAnnouncement && showPrivate && classIsPrivate && <div style={{ fontSize: 11, fontStyle: 'italic', color: darkBackground ? 'white' : 'inherit' }}>
                                    (PRIVATE)
                                </div>}
                                {announcementDescInTitle && isAnnouncement && <div style={{ fontStyle: 'italic', fontSize: 11 }}>(ANNOUNCEMENTS ONLY)</div>}
                            </div>
                        )}
                    />
                    {middleIcon && (
                        <ListItemSecondaryAction>
                            <IconButton edge="end" size="large" tabIndex={-1}>
                                {middleIcon}
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                    {middleActionButtonWithAction}
                </StyledListItem>
                {displayDetails && (facilitator || campus || location || additionalDescription)  &&
                    <ListItem
                        className={Classes.detailItem}
                    >
                        <DetailsWrapper>
                            <ListItemText
                                inset={!withoutAvatar}
                                primary={<div className={`${Classes.detailText} ${infoClass || ''}`}>
                                    {facilitator ? <div><b>Facilitator:</b>&nbsp;{facilitator}</div> : <div />}
                                    {campus ? <div><b>Campus:</b>&nbsp;{campus}</div> : <div />}
                                    {location ? <div><b>Location:</b>&nbsp;{location}{room && ` ${room}`}</div> : <div />}
                                    {additionalDescription ? <div>{additionalDescription}</div> : <div />}
                                </div>}
                            />
                        </DetailsWrapper>
                    </ListItem>
                }
            </StyledRootListItem>
        );
    }
};

internals.DetailsWrapper = Styled.div`
    width: 100%;
    padding-bottom: ${(props) => props.theme.spacing(1)};
`;

internals.TitleText = Styled(Typography, transient$Props).attrs({
    variant: 'h2'
})`
    font-size: 17px;
    font-weight: 500;
`;
