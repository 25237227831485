module.exports = (context) => {

    const selectors = context.selectors.all;
    const redux = context.redux.hooks;

    const getAuthStatus = () => selectors.getAuthStatus(redux.getState());
    const getIsAuthenticated = () => selectors.getIsAuthenticated(redux.getState());
    const getIsVerified = () => selectors.getVerifiedStatus(redux.getState());
    const hasCurrentUser = () => selectors.hasCurrentUser(redux.getState());
    const hasCurrentSchool = () => selectors.hasCurrentSchool(redux.getState());

    return {
        // Wait for user to be logged-in then current user fetched, otherwise for auth failure
        resolveIsAuthenticated: () => {

            if (getAuthStatus() === selectors.AUTH_STATUSES.FINISHED) {
                if (getIsAuthenticated()) {
                    if ((hasCurrentUser() && hasCurrentSchool()) || !getIsVerified() ) {
                        return Promise.resolve();
                    }
                }
                else {
                    return Promise.reject(new Error('Not authenticated'));
                }
            }

            return new Promise((resolve, reject) => {

                const stop = redux.subscribe(() => {

                    if (getAuthStatus() === selectors.AUTH_STATUSES.FINISHED) {
                        if (getIsAuthenticated()) {
                            if ((hasCurrentUser() && hasCurrentSchool()) || !getIsVerified()) {
                                stop();
                                return resolve();
                            }
                        }
                        else {
                            stop();
                            return reject(new Error('Not authenticated'));
                        }
                    }
                });
            });
        }
    };
};
