const React = require('react');

const T = require('prop-types');
const { default: Snackbar } = require('@mui/material/Snackbar');
const { default: SnackbarContent } = require('@mui/material/SnackbarContent');
const { default: Classes } = require('./styles.scss');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Button } = require('@mui/material/Button');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: styled } = require('styled-components');
const { ELEMENT_IDS } = require('utils/constants');

const { useEffect, useCallback } = React;

const AUTOHIDE_DURATION = 4000;

const internals = {};

module.exports = function AppSnackbar(props) {

    const {
        style = {},
        message,
        open,
        actionLabel,
        onRequestClose,
        popUpTitle,
        displayType,
        onActionClick, // eslint-disable-line no-unused-vars
        ...others
    } = props;

    const {
        StyledSnackbar,
        StyledSnackbarContent
    } = internals;

    const handlePopUpActionClick = () => {

        onRequestClose();
        props?.onActionClick();
    };

    const reqClose = (_evt, reason) => {

        if (reason === 'clickaway') {
            clearTimeout(internals.timeoutId);
        }

        onRequestClose();
    };

    const bottomTabsOnPage = document.getElementById(ELEMENT_IDS.appBottomTabs);
    const bottomButtonOnPage = document.getElementById(ELEMENT_IDS.bottomButton);
    const chatTextfieldOnPage = document.getElementById(ELEMENT_IDS.chatTextfield);

    let bottomDistance = 72;

    if ((bottomTabsOnPage && bottomButtonOnPage) || (bottomTabsOnPage && chatTextfieldOnPage)) {
        bottomDistance += 72;
    }

    useEffect(() => {

        if (open) {

            clearTimeout(internals.timeoutId);

            if (displayType !== 'popUp') {
                internals.timeoutId = setTimeout(() => {

                    onRequestClose();
                }, AUTOHIDE_DURATION);
            }
        }
        else {
            internals.initFocus = false;
        }
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const contentsRef = useCallback((node) => {

        if (node && !internals.initFocus) {
            internals.initFocus = true;
            node.focus();
        }
    }, []);

    const escListener = (evt) => {

        if (evt.key === 'Escape') {
            onRequestClose(evt);
        }
    };

    return displayType !== 'popUp' ? <div>
        <StyledSnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={open}
            className={Classes.snackbar}
            style={{ ...style, bottom: bottomDistance }}
            onClose={reqClose}
            {...others}
        >
            <StyledSnackbarContent message={message} />
        </StyledSnackbar>
    </div> : <Dialog
        open={open}
        onKeyDown={escListener}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <div ref={contentsRef} tabIndex={0} data-focus-outline='radius:15'>
            <DialogTitle id='alert-dialog-title'>{popUpTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handlePopUpActionClick}
                    color='primary'
                    data-focus-outline='radius:40,padding:3'
                >
                    {actionLabel}
                </Button>
            </DialogActions>
        </div>
    </Dialog>;
};

module.exports.propTypes = {
    style: T.object,
    bodyStyle: T.object,
    message: T.string,
    open: T.bool,
    displayType: T.string,
    popUpTitle: T.string,
    actionLabel: T.string,
    onActionClick: T.func,
    onRequestClose: T.func,
    bottom: T.number
};

internals.StyledSnackbar = styled(Snackbar)({
    display: 'flex',
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: 'auto',
    textAlign:'center',
    lineHeight: '25px',
    padding: '12px 24px',
    whiteSpace: 'pre-line',
    color: '#ffffff'
});

internals.StyledSnackbarContent = styled(SnackbarContent)({
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    whiteSpace: 'pre-line',
    background:'rgba(0, 0, 0, 0.87)',
    color:'#ffffff'
});
