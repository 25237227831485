const { LOCATION_CHANGE } = require('connected-react-router');
const { SIMPLE } = require('../utils/redux-helpers');

module.exports = {
    // This project's middle-end wants us to declare
    // each action that will eventually be called.
    // The router will call this action automatically
    // so we're just telling the app about it here
    // via the exported constant 'LOCATION_CHANGE'
    [LOCATION_CHANGE]: SIMPLE,
    ROUTER_PUSH: SIMPLE,
    ROUTER_REPLACE: SIMPLE,
    ROUTER_GO: SIMPLE,
    ROUTER_GO_BACK: SIMPLE,
    ROUTER_GO_FORWARD: SIMPLE
};
