const { makeActionCreator } = require('../utils/redux-helpers');
const { ALERTS: Types } = require('../action-types');
const { Notification } = require('../domain/alerts');

module.exports = (context) => {

    const handlers = context.handlers.errorHandling;

    return {
        notification: {
            push:    makeActionCreator(Types.NOTIFICATION.PUSH,    Notification, { after: handlers.showNext }),
            show:    makeActionCreator(Types.NOTIFICATION.SHOW,    {}                                        ),
            dismiss: makeActionCreator(Types.NOTIFICATION.DISMISS, {},           { after: handlers.showNext })
        }
    };
};
