const T = require('prop-types');
const { push: Push } = require('connected-react-router');
const { makeActionCreator } = require('../utils/redux-helpers');
const { CLASSES: Types } = require('../action-types');
const Domain = require('../domain');
const { ALL_GROUPS_SORT_TYPE } = require('../utils/constants');
const Capitalize = require('lodash/capitalize');

module.exports = (context) => {

    const api = context.api.nearpeer;
    const actions = context.actions;
    const selectors = context.selectors.all;
    const refreshClass = ({ dispatch, payload, state, force }) => {

        const { id } = payload.request;

        if (!force && !selectors.classCouldRefresh(state, id)) {
            return Promise.resolve();
        }

        dispatch(actions.dataFetching.fetchClass({ id }))
        .then(({ payload: classPayload }) => {

            const classId = classPayload.result.result.class;
            const channelSid = classPayload.result.entities.classes[classId].sid;

            if (!channelSid) {
                return;
            }

            return dispatch(actions.dataFetching.fetchChannel({ channelSid }));
        });
    };

    return {
        join: makeActionCreator(Types.JOIN, { id: T.string, name: T.string, userId: T.string }, {
            async: api.classes.join,
            after: ({ dispatch, payload, ...other }) => {

                const { name, userId } = payload.request;

                dispatch(actions.alerts.notification.push({
                    message: userId ? `Added user to ${name || 'class'}` : `Joined ${name || 'class'}`,
                    action: {
                        label: 'Undo',
                        onExecute: () => dispatch(actions.classes.leave(payload.request))
                    }
                }));

                dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

                return refreshClass({ dispatch, payload, ...other });
            }
        }),
        leave: makeActionCreator(Types.LEAVE, { id: T.string, name: T.string, userId: T.string }, {
            async: api.classes.leave,
            after: ({ dispatch, payload, ...other }) => {

                const { name, userId } = payload.request;

                dispatch(actions.alerts.notification.push({
                    message: userId ? `Removed user from ${name || 'class'}` : `Left ${name || 'class'}`,
                    action: {
                        label: 'Undo',
                        onExecute: () => dispatch(actions.classes.join(payload.request))
                    }
                }));

                dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

                return refreshClass({ dispatch, payload, ...other });
            }
        }),
        create: makeActionCreator(Types.CREATE, Domain.Class, {
            async: (class_) => {

                const {
                    facilitator,
                    shortDescription,
                    additionalDescription,
                    classLocation,
                    classRoles:roles,
                    ...others
                } = class_;

                return api.classes.create({
                    professor: facilitator,
                    department: shortDescription,
                    schedule: additionalDescription,
                    location:classLocation,
                    roles,
                    ...others
                });
            },
            after: ({ dispatch, payload }) => {

                const { result: classId } = payload.result;
                const { name } = payload.result.entities.classes[classId];

                dispatch(actions.alerts.notification.push({
                    message: `Created group ${name}`,
                    action: {
                        label: 'View',
                        onExecute: () => dispatch(Push(`/app/classes/${classId}`))
                    }
                }));

                dispatch(Push('/app/classes'));
            }
        }),
        createNoRedirect: makeActionCreator(Types.CREATE, Domain.Class, {
            async: (class_) => {

                const {
                    facilitator,
                    shortDescription,
                    additionalDescription,
                    classLocation,
                    classRoles:roles,
                    ...others
                } = class_;

                return api.classes.create({
                    professor: facilitator,
                    department: shortDescription,
                    schedule: additionalDescription,
                    location:classLocation,
                    roles,
                    ...others
                });
            },
            after: ({ dispatch, payload }) => {

                const { result: classId } = payload.result;
                const { name } = payload.result.entities.classes[classId];

                dispatch(actions.alerts.notification.push({
                    message: `Created group ${name}`,
                    action: {
                        label: 'View',
                        onExecute: () => dispatch(Push(`/app/classes/${classId}`))
                    }
                }));
            }
        }),
        update: makeActionCreator(Types.UPDATE, Domain.Class, {
            async: (class_) => {

                const {
                    facilitator,
                    shortDescription,
                    additionalDescription,
                    classLocation,
                    roles,
                    ...others
                } = class_;

                return api.classes.update({
                    professor: facilitator,
                    department: shortDescription,
                    schedule: additionalDescription,
                    location:classLocation,
                    roles,
                    ...others
                });
            },
            after: ({ dispatch, payload }) => {

                const { result: classId } = payload.result;
                const { name } = payload.result.entities.classes[classId];

                dispatch(actions.alerts.notification.push({
                    message: `Updated group ${name}`,
                    action: {
                        label: 'View',
                        onExecute: () => dispatch(Push(`/app/classes/${classId}`))
                    }
                }));
            }
        }),
        updateNotificationLevel: makeActionCreator(Types.UPDATE_NOTIFICATION_LEVEL, { id: T.string, notificationLevel: T.string }, {
            async: api.classes.updateNotificationLevel,
            after: ({ dispatch, payload, ...other }) => {

                const { notificationLevel } = payload.request;

                dispatch(actions.alerts.notification.push({
                    message: `Set notification level to "${Capitalize(notificationLevel)}"`
                }));

                dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

                return refreshClass({ dispatch, payload, force: true, ...other });
            }
        }),
        setSearchText: makeActionCreator(Types.SET_SEARCH_TEXT, { id: T.string, text: T.string }),
        setAllGroupSortType: makeActionCreator(Types.SET_ALL_GROUPS_SORTING_TYPE, { sortType: T.oneOf([ALL_GROUPS_SORT_TYPE.BY_NAME,ALL_GROUPS_SORT_TYPE.BY_DATE_UPDATED]) }),
        clearSearchText: makeActionCreator(Types.CLEAR_SEARCH_TEXT, { id: T.string })
    };
};
