const T = require('prop-types');

module.exports = {
    Context: {
        verificationToken: T.string,
        permissions: T.string,
        roleId: T.number,
        fetchedProgress: T.bool,
        sso: T.string,
        isVerified: T.bool,
        isEmailVerified: T.bool,
        email: T.string,
        schoolId: T.number,
        usingPassword: T.bool | null,
        usingSSO: T.bool | null,
        hasPassword: T.bool | null,
        hasSSOToken: T.bool | null,
        agreeToTerms: T.bool
    },
    Init: {
        verificationToken: T.string,
        roleId: T.number,
        isEmailVerified: T.bool,
        email: T.string,
        schoolId: T.number
    },
    Step1: {
        email: T.string.isRequired,
        schoolId: T.number.isRequired
    },
    Step2: {
        hasPassword: T.bool | null,
        hasSSOToken: T.bool | null,
        password: T.string,
        agreeToTerms: T.bool,
        verificationToken: T.string
    },
    Step3: {
        type: T.string,
        picFile: T.instanceOf(File),
        picPreviewURL: T.string,
        cropPosition: T.shape({ x: T.number, y: T.number }),
        cropRotation: T.number,
        cropScale: T.number,
        firstName: T.string,
        lastName: T.string,
        profileEmail: T.string,
        birthdate: T.date,
        majorId: T.string,
        departmentId: T.string,
        yearHiredId: T.number,
        officeId: T.number,
        workRemote: T.bool,
        title: T.string,
        career: T.string,
        profession: T.string,
        interests: T.array,
        passionInterests: T.array,
        bio: T.string,
        veteran: T.bool,
        parent: T.bool,
        openToSocial: T.bool,
        fullTime: T.bool,
        gender: T.string,
        transfer: T.object,
        preferences: T.object,
        housing: T.object,
        hometown: T.object,
        incomingClass: T.string,
        graduatingClass: T.string,
        onlineStudent: T.bool
    },
    ProfilePicture:{
        picFile: T.instanceOf(File),
        picPreviewURL: T.string,
        cropPosition: T.shape({ x: T.number, y: T.number }),
        cropRotation: T.number,
        cropScale: T.number
    },
    Complete: {
        type: T.string,
        verificationToken: T.string,
        firstName: T.string,
        lastName: T.string,
        profileEmail: T.string,
        birthdate: T.date,
        major: T.arrayOf(T.string), // id (or name)
        department: T.string,       // id
        officeId: T.number,       // id
        yearHiredId: T.number,       // id
        title: T.string,
        career: T.string,
        profession: T.string,
        interests: T.array,
        passionInterests: T.array,
        bio: T.string,
        veteran: T.bool,
        workRemote: T.bool,
        parent: T.bool,
        openToSocial: T.bool,
        fullTime: T.bool,
        gender: T.string,
        transfer: T.object,
        preferences: T.object,
        housing: T.object,
        hometown: T.object,
        incomingClass: T.string,
        graduatingClass: T.string,
        onlineStudent: T.bool
    },
    SignupCompleted: T.bool
};
