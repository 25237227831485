const _ = require('lodash');
const { combineReducers } = require('redux');
const { safeThrow } = require('../utils/safe-throw');
const { ALERTS: Types } = require('../action-types');

const notifications = (state = { current: null, pending: [] }, action) => {

    switch (action.type) {

        case Types.NOTIFICATION.PUSH:

            return {
                current: state.current,
                pending: [...state.pending, action.payload]
            };

        case Types.NOTIFICATION.SHOW:

            if (state.current) {
                safeThrow(new Error('Current notification should be dismissed before showing next.'));
                return state;
            }

            if (_.isEmpty(state.pending)) {
                safeThrow(new Error('There are no pending notifications.'));
                return state;
            }

            return {
                current: state.pending[0],
                pending: state.pending.slice(1)
            };

        case Types.NOTIFICATION.DISMISS:

            if (!state.current) {
                console.warn(new Error('There is no notification to dismiss.'));
            }

            return {
                current: null,
                pending: [...state.pending || []]
            };

        default:
            return state;
    }
};

const reducer = combineReducers({ notifications });

const selectors = {

    getHasErrorNotificationsToShow: (state) => !state.notifications.current && !_.isEmpty(state.notifications.pending),
    getCurrentErrorNotification: (state) => state.notifications.current
};

module.exports = Object.assign(reducer, { selectors });
