
const internals = {};

module.exports = (context) => {

    const http = context.http.ipStack;

    return {
        check() {

            return http.post('/check').then(
                (response) => response
            );
        }
    };
};

internals.form = (values) => {

    const formData = new FormData();

    for (const key in values) {

        let value = values[key];

        if (value && typeof value === 'object' && !(value instanceof Blob)) {
            value = JSON.stringify(value);
        }

        formData.append(key, value);
    }

    return formData;
};
