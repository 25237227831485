
module.exports = {

    format: {
        commas: (num) => {

            // Grabbed from here: https://blog.tompawlak.org/number-currency-formatting-javascript
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
    }
};
