const React = require('react');
const FallbackSpinner = require('./fallback-spinner');

const { useState, useEffect } = React;

module.exports = (RouteComponent, preFunc, fallback) => {

    return function RouteDataLoaderHelper(props) {

        const [renderComponent, setRenderComponent] = useState(false);

        useEffect(() => {

            const waitForPre = async () => {

                await preFunc(props);
                setRenderComponent(true);
            };

            waitForPre();
        }, [props, setRenderComponent]);

        if (!renderComponent) {
            return fallback ? fallback : <FallbackSpinner />;
        }

        return <RouteComponent {...props} />;
    };
};
