const { connect } = require('react-redux');
const { context } = require('app-context');
const SurveyEditPage = require('../components/SurveyEditPage');
const { goBack: GoBack } = require('connected-react-router');
const { USERS_SORT_TYPE } = require('utils/constants');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state, { match: { params: { id } } }) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        survey: selectors.getSurvey_forEdit(state,id),
        searchResults: selectors.getUsers_forSurveyUsersSearch(state) || [],
        activeSearchFilter:selectors.getSurveySearchFilter(state),
        interestsActiveFilter_survey: selectors.getInterestsActiveFilter_surveySearch(state),
        badges: selectors.getAllBadges(state),
        departments:selectors.getDepartmentsList_forProfileEditPage(state),
        yearsHiredList: selectors.getAllYearsHired(state),
        officesList: selectors.getAllOffices(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        schoolRoles: selectors.getSchoolRoles(state),
        schoolRoleGroups: selectors.getRoleGroups(state),
        majors: selectors.getMajorsList_forCurrentUser(state)
    }), {
        onSubmit: actions.surveys.update,
        goBack: GoBack,
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onClickResetSearchFilters: () => (dispatch) => {

            dispatch(actions.app.setSurveySearchFilter({ filter : {} }));
            return dispatch(actions.dataFetching.fetchUserSearchSurveyResults({ allByMatches: true,sortType:USERS_SORT_TYPE.ALPHABETICAL }));
        }
    }
)(SurveyEditPage);
