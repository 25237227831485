const { connect } = require('react-redux');
const { context } = require('app-context');
const ChoosePassword = require('../components/ChoosePassword');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux = context.redux.hooks;

module.exports = connect(
    (state) => ({
        signupContext: selectors.getSignupContext(state),
        usePasswordRules: selectors.getUsePasswordRules(state)
    }), {
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onSubmit: ({ password, agreeToTerms, verificationToken }) => (dispatch) => {

            const state = redux.getState();

            if (selectors.initPrerequisiteSatisfied(state)) {
                return dispatch(actions.signup.step2.complete({
                    password,
                    agreeToTerms,
                    verificationToken
                }));
            }

            return dispatch(actions.alerts.notification.push({
                message: 'Please click your email link again!'
            }));
        }
    }
)(ChoosePassword);
