const { useState, useEffect } = require('react');

exports.useMediaQuery = function useMediaQuery(query) {

    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {

        const mediaQueryList = window.matchMedia(query);

        const handleChange = (e) => {

            setMatches(e.matches);
        };

        mediaQueryList.addEventListener('change', handleChange);

        // Remove the listener when the component unmounts
        return () => {

            mediaQueryList.removeEventListener('change', handleChange);
        };
    }, [query]);

    return matches;
};
