const React = require('react');
const T = require('prop-types');
const { default: styled } = require('styled-components');
const Autosuggest = require('react-autosuggest');
const { default: TextField } = require('@mui/material/TextField');
const { default: Paper } = require('@mui/material/Paper');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: Classes } = require('./styles.scss');
const UniqueId = require('lodash/uniqueId');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { default: MuiIconButton } = require('@mui/material/IconButton');
const { default: ClearIcon } = require('@mui/icons-material/HighlightOff');
const { useTheme } = require('@mui/material/styles');

const { createRef } = React;

const internals = {};

const renderInput = (inputProps) => {

    const {
        value,
        inputStyle,
        handleClearField,
        ariaLabelledby,
        ariaLabel,
        ...rest
    } = inputProps;

    const { IconButton } = internals;

    return (
        <TextField
            {...rest}
            fullWidth
            value={value}
            inputProps={{
                'aria-label': ariaLabel,
                'aria-labelledby': ariaLabelledby,
                'role': 'presentation',
                'autocomplete': 'off'
            }}
            InputProps={{
                'aria-label': ariaLabel,
                'aria-labelledby': ariaLabelledby,
                endAdornment: (
                    <Tooltip arrow={true} title={'Clear'} placement={'top'}>
                        <IconButton
                            onClick={() => handleClearField()}
                            data-focus-outline='radius:20,zIndex:1'
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                )
            }}
            inputStyle={{ textOverflow: 'ellipsis', ...inputStyle }}
        />
    );
};

const SuggestionsContainer = ({ options }) => {

    const { containerProps, children } = options;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();

    return (
        <Paper className={Classes.suggestionsList} {...containerProps} sx={{
            '>ul li:not(:last-child) div':{
                borderBottom: '1px solid',
                borderBottomColor: theme.palette.divider
            }
        }}>
            {children}
        </Paper>
    );
};

SuggestionsContainer.propTypes = {
    options: T.object
};

module.exports = class MaterialReactAutocomplete extends React.Component {

    constructor(props) {

        super(props);

        this.id = UniqueId('MaterialReactAutocomplete');

        this.inputRef = createRef();
    }

    static propTypes = {
        dataSource: T.array.isRequired,
        dataSourceConfig: T.shape({
            text: T.string,
            value: T.string
        }),
        maxSearchResults: T.int,
        filter: T.func,
        style: T.object,
        onChange: T.func,
        onTextChange: T.func,
        searchText: T.string,
        value: T.any,
        className: T.string,
        listStyle: T.string,
        renderSuggestionsOnFocus: T.bool,
        handleClearField: T.func
    };

    static defaultProps = {
        maxSearchResults: 5,
        filter: function filter(searchText, key) {

            return searchText !== '' && key.indexOf(searchText) !== -1;
        },
        listStyle: null
    }

    getSuggestions = ({ value }) => {

        const {
            dataSource,
            maxSearchResults,
            filter,
            renderSuggestionsOnFocus
        } = this.props;

        const inputLength = value?.length || 0;
        let count = 0;

        const inputIsFocused = this.inputRef?.current?.querySelector('input') === document.activeElement;

        if (renderSuggestionsOnFocus && inputIsFocused && !inputLength) {
        // if (renderSuggestionsOnFocus && !inputLength) {
            return dataSource.filter((listItem) => {

                const keep = count < maxSearchResults;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
        }

        const results = inputLength === 0
            ? []
            : dataSource.filter((listItem) => {

                const item = this.getSuggestionValue(listItem);
                const keep = count < maxSearchResults && filter(value, item);

                if (keep) {
                    count += 1;
                }

                return keep;
            });

        // TODO why doesn't this woooooork
        // if (results.length === 0) {
        //     results.push(
        //         <MenuItem disabled component='div'>
        //             <span>No results</span>
        //         </MenuItem>
        //     );
        // }

        return results;
    }

    getSuggestionValue = (suggestion) => {

        const { dataSourceConfig } = this.props;
        return dataSourceConfig ? suggestion[dataSourceConfig.text] : suggestion;
    }

    renderSuggestion = (suggestion, { query, isHighlighted }) => {

        return (
            <MenuItem className={suggestion.customClass} selected={isHighlighted} component='div'>
                {this.getSuggestionValue(suggestion)}
            </MenuItem>
        );
    }

    handleSuggestionsClearRequested = () => {

        this.setState({
            suggestions: []
        });
    }

    onTextChange = (evt, { newValue }) => {

        const { value, handleClearField } = this.props;
        // Disallow editing the text if a value is selected,
        // Unless the user is clearing the textfield
        if (value && newValue !== '') {
            return;
        }
        else if (newValue === '' && handleClearField) {
            handleClearField();
        }

        this.props.onTextChange(evt, { newValue });
    }

    componentDidMount() {

        this.inputRef.current.querySelector('input').addEventListener('focus', () => {

            this.render();
        });
    }

    render() {

        const {
            className,
            style,
            onChange,
            onTextChange, // eslint-disable-line no-unused-vars
            value, // eslint-disable-line no-unused-vars
            searchText,
            renderSuggestionsOnFocus,
            ...inputProps
        } = this.props;

        const suggestions = this.getSuggestions({ value: searchText });

        const { id } = this;

        return (
            <Autosuggest
                id={id}
                className={className}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.getSuggestions}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                onSuggestionSelected={onChange}
                renderSuggestionsContainer={(options) => <SuggestionsContainer options={options} /> }
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                menuProps={{ className: Classes.autocompleteMenu }}
                inputProps={{
                    value: searchText || '',
                    onChange: this.onTextChange,
                    ref: this.inputRef,
                    ...inputProps
                }}
                theme={{
                    container: style,
                    suggestionsContainer: Classes.suggestionsContainer,
                    suggestionsList: this.props.listStyle ? this.props.listStyle : Classes.suggestionsList,
                    suggestion: Classes.suggestion
                }}
                focusInputOnSuggestionClick={false}
                renderSuggestionsOnFocus={renderSuggestionsOnFocus && searchText === ''}
            />
        );
    }
};

internals.IconButton = styled(MuiIconButton)`
    padding: 4px !important;
`;
