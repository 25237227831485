module.exports = [
    '/privacy-policy',
    '/accessibility',
    '/terms-of-service',
    '/nearpeer-pakistan',
    '/unsubscribe/profile-reminders',
    '/unsubscribe/verification-reminders',
    '/unsubscribe/pending-connections',
    '/unsubscribe/digest',
    '/resubscribe/profile-reminders',
    '/resubscribe/verification-reminders'
];
