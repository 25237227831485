const React = require('react');
const ReactDOM = require('react-dom/client');
const CreateStore = require('./wiring/create-store');
const History = require('./wiring/history');
const AppContainer = require('./containers/App');
const Initializers = require('./initializers');
const Theme = require('./styles/theme');

// Create redux store and sync history with connected-react-router
const initialState = window.__INITIAL_STATE__;
const store = CreateStore({
    initialState,
    history: History
});

Initializers.run(store);

// Developer Tools Setup

if (__DEBUG__) {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        //window.__REDUX_DEVTOOLS_EXTENSION__.open();
    }

    const showControlCenter = require('./utils/control-center/show-control-center');

    showControlCenter(store);
}

// Render Setup

// const MOUNT_NODE = document.getElementById('root');

const render = () => {

    const routes = require('./routes')(store);

    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(<AppContainer
        store={store}
        history={History}
        routes={routes}
        theme={Theme}
    />);
};

// Enable HMR and catch runtime errors in RedBox
// This code is excluded from production bundle

if (__DEV__ && module.hot) {

    // const { default: RedBox } = require('redbox-react');

    // const renderApp = render;

    // const renderError = (error) => {

    //     const root = ReactDOM.createRoot(MOUNT_NODE);
    //     root.render(
    //         <RedBox error={error} />
    //     );
    // };

    // render = () => {

    //     renderApp();

    //     /* catch (error) {
    //         console.error(error.stack);
    //         renderError(error);
    //     }*/
    // };

    module.hot.accept(['./routes/index'], () => render());
}

// Go!
render();
