const { connect } = require('react-redux');
const { context } = require('app-context');
const UnreadMessagesBadge = require('../components/UnreadMessagesBadge');

const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        numUnreads: selectors.numUnreadDMs(state)
    })
)(UnreadMessagesBadge);
