const React = require('react');
const T = require('prop-types');

const internals = {};

module.exports = class LabeledToggle extends React.PureComponent {

    static propTypes = {
        labelWidth: T.string,
        mobileLabelWidth: T.string,
        className: T.string,
        leftLabel: T.string.isRequired,
        leftValue: T.any.isRequired,

        rightLabel: T.string.isRequired,
        rightValue: T.any.isRequired,

        isInteractive: T.bool,
        onChange: T.func,
        shrinkOnMobile: T.bool,
        activeColor: T.string,
        value: T.bool
    }

    constructor(props) {

        super();

        this.state = { isMobile: null };

        this.mql = window.matchMedia('screen and (max-width: 600px)');

        this.handleMediaQuery = this._handleMediaQuery.bind(this);
    }

    componentDidMount() {

        this.handleMediaQuery(this.mql);

        this.mql.addListener(this.handleMediaQuery);
    }

    componentWillUnmount() {

        this.mql.removeListener(this.handleMediaQuery);
    }

    _handleMediaQuery(mql) {

        if (!this.props.shrinkOnMobile) {
            return;
        }

        this.setState({ isMobile: !!mql.matches });
    }

    static get value() {

        return this.state.value;
    }

    render() {

        const {
            value,
            onChange,
            leftLabel,
            leftValue,
            rightLabel,
            rightValue,
            className,
            labelWidth,
            mobileLabelWidth,
            activeColor,
            isInteractive
        } = this.props;

        const { isMobile } = this.state;

        const leftSideActive = value === leftValue;
        const rightSideActive = value === rightValue;

        const {
            toggleContainer,
            toggleChoice,
            active,
            leftSideTriangle,
            rightSideTriangle
        } = internals.styles({
            labelWidth,
            mobileLabelWidth,
            activeColor,
            isInteractive,
            isMobile,
            leftSideActive,
            rightSideActive
        });

        const leftStyles = Object.assign({}, toggleChoice, leftSideActive ? active : {});
        const rightStyles = Object.assign({}, toggleChoice, rightSideActive ? active : {});

        return (
            <div
                className={className || ''}
                style={toggleContainer}
            >
                <span style={leftStyles} onClick={() => onChange(leftValue)}>
                    {leftLabel}
                </span>

                <span style={leftSideTriangle} />
                <span style={rightSideTriangle} />

                <span style={rightStyles} onClick={() => onChange(rightValue)}>
                    {rightLabel}
                </span>
            </div>
        );
    }
};

internals.styles = ({ labelWidth, mobileLabelWidth, activeColor, isInteractive, isMobile, leftSideActive, rightSideActive }) => {

    const _activeColor = activeColor || '#A10036';
    const inactiveColor = 'white';

    const leftTriangleColor = leftSideActive ? _activeColor : inactiveColor;
    const rightTriangleColor = rightSideActive ? _activeColor : inactiveColor;

    const _mobileLabelWidth = mobileLabelWidth ? mobileLabelWidth : '90px';
    const _labelWidth = isMobile ? _mobileLabelWidth : (labelWidth ? labelWidth : '120px');

    const labelheight = isMobile ? '20px' : '25px';

    return {
        toggleContainer: {
            border: '1px solid rgba(89, 89, 89, 0.25)',
            display: 'inline-block',
            lineHeight: isMobile ? '12px' : '13px',
            borderRadius: '2px',
            overflow: 'hidden',
            userSelect: 'none',
            position: 'relative',
            height: parseInt(labelheight, 10) + 2,
            maxHeight: parseInt(labelheight, 10) + 2,
            minWidth: parseInt(_labelWidth, 10) * 2,
            whiteSpace: 'nowrap',
            marginBottom: isMobile ? '10px' : 0
        },
        toggleChoice: {
            cursor: isInteractive ? 'pointer' : 'default',
            background: inactiveColor,
            color: 'rgba(10, 10, 10, 0.95)', // default color
            width: _labelWidth,
            minWidth: _labelWidth,
            display: 'inline-block',
            fontSize: isMobile ? '12px' : '14px',
            padding: isMobile ? '4px 15px' : '6px 15px',
            textAlign: 'center',
            lineHeight: 1,
            height: '100%'
        },
        leftSideTriangle: {
            width: 0,
            height: 0,
            borderTop: `${labelheight} solid ${leftTriangleColor}`,
            borderRight: '10px solid transparent',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)'
        },
        rightSideTriangle: {
            width: 0,
            height: 0,
            borderBottom: `${labelheight} solid ${rightTriangleColor}`,
            borderLeft: '10px solid transparent',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)'
        },
        active: {
            background: _activeColor,
            color: 'white'
        }
    };
};
