const React = require('react');
const T = require('prop-types');
const UserBadge = require('containers/UserBadge');
const { default: Avatar } = require('@mui/material/Avatar');
const { NavLink: Link } = require('react-router-dom');
const { default:ListItem } = require('@mui/material/ListItem');
const { default:ListItemAvatar } = require('@mui/material/ListItemAvatar');
const { default:ListItemText } = require('@mui/material/ListItemText');
const { default: ListItemSecondaryAction } = require('@mui/material/ListItemSecondaryAction');
const { default:IconButton } = require('@mui/material/IconButton');
const { default: RightArrowIcon } = require('@mui/icons-material/ChevronRight');
const { default: PersonAddIcon } = require('@mui/icons-material/PersonAdd');
const { default: DoneIcon } = require('@mui/icons-material/Done');
const { default: MoodIcon } = require('@mui/icons-material/Mood');
const { default: BlockIcon } = require('@mui/icons-material/Block');
const { default: MoreHorizIcon } = require('@mui/icons-material/MoreHoriz');

const { default: Checkbox } = require('@mui/material/Checkbox');
const SUR = require('utils/should-update-record');
const { getSizedImageUrl } = require('utils/image');
const { default: Classes } = require('./styles.scss');
const NoUserProfilePic = require('components/NoUserProfilePic');
const { default: styled } = require('styled-components');
const Moment = require('moment/moment');

const internals = {
    propTypes: {
        user: T.object.isRequired,
        dm: T.object,
        primaryText: T.element,
        secondaryText: T.element,
        leftAvatar: T.element,
        showUserActions: T.bool,
        checked: T.bool,
        canViewBadge: T.bool,
        onCheck: T.func,
        useLink:T.bool,
        linkPath:T.string,
        nonLinkFunction:T.func,
        hasContainerElement:T.bool
    }
};

const StyledListItem = styled(ListItem)`
  && {
    &:hover {
      background-color: ${(props) => props.theme.palette.action.disabledBackground};
    }
  }
`;

module.exports = class UserListItem extends React.Component {

    // NOTE this component implements shouldComponentUpdate()

    // NOTE propTypes defined above in order to work around
    // babel/webpack limitations, referencing static props within a class.

    static propTypes = internals.propTypes

    static defaultProps = {
        showUserActions: false
    };

    constructor() {

        super();

        this.onClickToggleCheckbox = this._onClickToggleCheckbox.bind(this);
        this.renderListItem = this._renderListItem.bind(this);
        this.propNames = Object.keys(internals.propTypes);
    }

    // Essentially identical to PureComponent,
    // but for the user prop only pays attention to id.
    shouldComponentUpdate(nextProps) {

        for (let i = 0; i < this.propNames.length; ++i) {
            const name = this.propNames[i];
            if (name === 'user') {
                if (SUR.shouldUpdate(this.props[name], nextProps[name])) {
                    return true;
                }
            }
            else if (this.props[name] !== nextProps[name]) {
                return true;
            }
        }

        return false;
    }

    _onClickToggleCheckbox() {

        const { user, onCheck } = this.props;
        onCheck(user);
    }

    setAppropriateIcon(user) {

        // If user is me, return <MoodIcon />
        // If user is connected to me, return <DoneIcon />
        // If/When we extend this functionality to accomodate more user actions, we'll want to pass these things down through props
        if (user.isMe) {
            return <MoodIcon />;
        }
        else if (user.peerStatus === 'accepted') {
            return <DoneIcon />;
        }
        else if (user.peerStatus === 'waiting') {
            return <MoreHorizIcon />;
        }
        else if (user.peerStatus === 'declined') {
            return <BlockIcon />;
        }

        return <PersonAddIcon />;
    }

    determineIfDisabled(user) {

        if (user.isMe || user.peerStatus !== null) {
            return true;
        }

        return false;
    }

    _renderListItem() {

        const {
            user,
            primaryText,
            secondaryText,
            leftAvatar,
            canViewBadge,
            dm,
            rightArrowAriaLabel,
            ...other
        } = this.props;

        return (
            <StyledListItem
                {...other}
            >
                <ListItemAvatar>
                    {leftAvatar || ( user.croppedPicture ? <Avatar src={getSizedImageUrl(user.croppedPicture, 100)} alt='' /> : <Avatar alt=''> <NoUserProfilePic iconColor={'#ffffff'} insideAvatar={true} outlined={false}  /></Avatar>)}
                </ListItemAvatar>
                <ListItemText
                    primary={primaryText || <div>
                        <div className={Classes.primaryText}>{`${user.firstName} ${user.lastName}`}<span className={`${user.enabled ? '' : Classes.userDisabled}`} /></div>
                        {canViewBadge && <UserBadge mini className={Classes.userBadge} userType={user.type} />}
                        <div className={Classes.primaryTextTimestamp}>{dm && dm.dateUpdated && dm.lastMessage ? Moment(dm.dateUpdated).calendar(null,{
                            lastDay : '[Yesterday]',
                            sameDay : '[Today]',
                            nextDay : '[Tomorrow]',
                            lastWeek : 'dddd',
                            nextWeek : 'dddd',
                            sameElse : 'MM/DD/YYYY'
                        }) : ' '}  </div>
                    </div>}
                    secondary={secondaryText && secondaryText}
                />
                <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
                    <IconButton
                        edge='end'
                        size='large'
                        data-focus-outline='radius:25,padding:-7,zIndex:1'
                        aria-label={rightArrowAriaLabel || null}
                    >
                        <RightArrowIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </StyledListItem>
        );
    }

    render() {

        const {
            user,
            showUserActions,
            useLink,
            linkPath,
            nonLinkFunction,
            hasContainerElement
        } = this.props;

        const { styles } = internals;

        return (
            <div className={Classes.listItemContainer}>
                <div className={Classes.userAction} style={showUserActions ? { width: '70px' } : { width: '0px' }}>
                    <Checkbox
                        inputProps={{ 'aria-label': `user-check-${user.firstName}` }}
                        checked={this.props.checked}
                        onCheck={this.onClickToggleCheckbox}
                        checkedIcon={<DoneIcon />}
                        uncheckedIcon={this.setAppropriateIcon(user)}
                        style={styles.checkbox(this.props.checked)}
                        iconStyle={styles.icon(this.props.checked)}
                        disabled={this.determineIfDisabled(user)}
                    /></div>
                {hasContainerElement ?
                    useLink
                        ? (
                            <Link
                                style={{ flex:'1 100%', textDecoration: 'none' }}
                                to={linkPath}
                                tabIndex={-1}
                            >
                                {this.renderListItem()}
                            </Link>
                        )
                        : (
                            <a
                                style={{ flex:'1 100%', textDecoration: 'none' }}
                                onClick={nonLinkFunction}
                                tabIndex={-1}
                            >
                                {this.renderListItem()}
                            </a>
                        )
                    : (
                        <Link
                            style={{ flex:'1 100%', textDecoration: 'none' }}
                            to={`/app/profile/${user.id}`}
                            tabIndex={-1}
                        >
                            {this.renderListItem()}
                        </Link>
                    )
                }
            </div>
        );
    }
};

internals.styles = {
    checkbox: (checked) => ({
        transition: 'background-color .3s ease-in-out',
        background: checked ? 'rgb(0, 188, 212)' : '#eee',
        display: 'flex',
        height: '100%'
    }),
    icon: (checked) => ({
        margin: 'auto',
        fill: checked ? 'white' : '#666'
    })
};
