const { connect } = require('react-redux');
const { context } = require('app-context');
const Frame = require('../../components/Chat/Frame');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux     = context.redux.hooks;
const handlers  = context.handlers;

module.exports = connect(
    (state, { match: { params } }) => ({
        header: selectors.getName_forChat(state, params.dmSid || params.sid),
        userDetails: selectors.getUserDetails_forChat(state, params.dmSid || params.sid),
        classId: null,
        messages: selectors.getMessages_forChat(state, params.dmSid || params.sid) || [],
        hasUnreadMessages: !!selectors.numUnreadMessages(state, params.dmSid || params.sid),
        numOfChannelMessages: selectors.getNumOfMessages(state, params.dmSid || params.sid),
        emptyChatInfo: selectors.getInfoMessage_forEmptyUserChat(state, params.dmSid || params.sid),
        users: selectors.getDmUsers_forChat(state, params.dmSid || params.sid) || [],
        onSetLastReadMessageIndex: ({ messageIndex }) => redux.dispatch(actions.communication.setLastReadMessageIndex({ channelSid: params.dmSid || params.sid, messageIndex })),
        onRequestMessages: () => redux.dispatch(actions.dataFetching.fetchMessages.nextPage({ channelSid: params.dmSid || params.sid })),
        requestFirstPageMessages: () => redux.dispatch(actions.dataFetching.fetchMessages.firstPage({ channelSid: params.dmSid || params.sid })),
        ga: {
            onSubmitMessage: ['sent peer message', 'peer: sent message']
        },
        isOffline: selectors.getIsOffline(state),
        includeEveryoneMention: false,
        chatText: selectors.getChatTextBySid(state, params.dmSid || params.sid) || '',
        pinHeaderState: selectors.getPinHeaderStateBySid(state, params.dmSid || params.sid) || 'closed',
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        rolesInteractions: selectors.getCurrentUserRoleInteractions(state),
        role: selectors.getCurrentUserRole(state),
        sid: params.dmSid || params.sid,
        localConversation: null,
        isAnnouncement: false,
        variant: 'dm',
        useProfanityFilter: selectors.getShouldUseProfanityFilter(state),
        conversationStarters: selectors.getAllConversationStarters(state),
        userIsInClass: null,
        currentUser: selectors.getCurrentUser(state)?.user(),
        categories: selectors.getCategories(state),
        collapseHeaders: selectors.getCollapseHeaders(state),
        getCurrentTwilioChannel: handlers.communication.getCurrentChannelSid,
        twilioStartListening: handlers.communication.startListening
    }), {
        onSubmitMessage: actions.communication.sendMessage,
        onSaveMessage: actions.communication.updateMessageBody,
        onModerateMessage: actions.communication.moderateMessage,
        onPinMessage: actions.communication.pinMessage,
        onRemoveMessage: actions.communication.removeMessage,
        onRemoveOwnMessage: actions.communication.removeOwnMessage,
        onFlagInappropriate: actions.communication.flagMessageAsInappropriate,
        setLastReadMessageIndex_byConversationSid: actions.communication.setLastReadMessageIndex_byConversationSid,
        openAlertDialog: actions.app.openAlertDialog,
        showNotification: (msg) => actions.alerts.notification.push({ message: msg }),
        setPinHeaderState: (key, status) => actions.chats.setPinHeaderState({ key, status }),
        setCollapseHeaders: (setting) => actions.app.setCollapseHeaders({ setting })
    }
)(Frame);
