const React = require('react');
const { Provider } = require('react-redux');
const ReactDOM = require('react-dom/client');

module.exports = (store) => {

    const popup = null && window.open(
        null, 'Control Center', 'menubar=no,location=no,resizable=yes,scrollbars=no,status=no'
    );

    if (!popup) {
        return; // In case it was blocked
    }

    popup.location.reload();

    const startRendering = () => {

        popup.document.write(''
            + ' <!DOCTYPE html>'
            + ' <html lang="en">'
            + ' <head>'
            + '     <meta charset="utf-8">'
            + '     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">'
            + '     <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css" integrity="sha384-rwoIResjU2yc3z8GV/NPeZWAv56rSmLldC3R/AZzGRnGxQQKnKkoFVhFQhNUwEyJ" crossorigin="anonymous">'
            + ' </head>'
            + ' <body>'
            + '     <div id="app-root"></div>'
            + '     <script src="https://code.jquery.com/jquery-3.1.1.slim.min.js" integrity="sha384-A7FZj7v+d/sdmMqp/nOQwliLvUsJfDHW+k9Omg/a/EheAdgtzNs3hpfag6Ed950n" crossorigin="anonymous"></script>'
            + '     <script src="https://cdnjs.cloudflare.com/ajax/libs/tether/1.4.0/js/tether.min.js" integrity="sha384-DztdAPBWPRXSA/3eYEEUWrWCy7G5KFbe8fFjk5JAIxUYHKkDx6Qin1DkWx51bBrb" crossorigin="anonymous"></script>'
            + '     <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/js/bootstrap.min.js" integrity="sha384-vBWWzlZJ8ea9aCX4pEW3rVHjgjt7zpkNpZk+02D9phzyeVkE+jo0ieGizqPLForn" crossorigin="anonymous"></script>'
            + ' </body>'
            + ' </html>'
        );

        const rootElement = popup.document.getElementById('app-root');

        const renderTools = () => {

            const ControlCenter = require('utils/control-center/ControlCenter');

            module.hot.accept(['../../utils/control-center/ControlCenter'], renderTools);

            const root = ReactDOM.createRoot(rootElement);

            root.render(
                <Provider store={store}>
                    <ControlCenter />
                </Provider>
            );
        };

        renderTools();
    };

    setTimeout(startRendering, 100);
};
