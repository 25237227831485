const React = require('react');
const { default: Classes } = require('./styles.scss');
const { NavLink: Link } = require('react-router-dom');
const FooterLogomark = require('static/nearpeer_n.svg');

module.exports = class Footer extends React.PureComponent {

    render() {

        return (
            <footer className={Classes.footer} role='footer'>
                <Link to='/' data-focus-outline='radius:4,padding:5'>
                    <img
                        className={Classes.footerLogomark}
                        src={FooterLogomark}
                        alt='nearpeer logo'
                    />
                </Link>
                <div className={Classes.footerNav}>
                    <a
                        className={Classes.footerLink}
                        href='https://nearpeer.com/'
                        data-focus-outline='radius:4,padding:5'
                    >
                        About
                    </a>
                    <Link
                        className={Classes.footerLink}
                        to='/terms-of-service'
                        data-focus-outline='radius:4,padding:5'
                    >
                        Terms
                    </Link>
                    <Link
                        className={Classes.footerLink}
                        to='/privacy-policy'
                        data-focus-outline='radius:4,padding:5'
                    >
                        Privacy
                    </Link>
                    <Link
                        className={Classes.footerLink}
                        to='/accessibility'
                        data-focus-outline='radius:4,padding:5'
                    >
                        Accessibility
                    </Link>
                </div>
                <div className={Classes.copyright}>&copy; {new Date().getFullYear()} Nearpeer Inc.</div>
            </footer>
        );
    }
};
