const React = require('react');
const T = require('prop-types');
const PeersAchievement = require('static/achievement-peers.svg');
const { default: Classes } = require('./styles.scss');

const internals = {};

module.exports = class UserAchievements extends React.PureComponent {

    static propTypes = {
        user: T.object.isRequired,
        extraUserInfo: T.object
    }

    static hasAchievements(user) {

        return Object.keys(internals.achievementTypes)
        .some((achievementName) => {

            return internals.achievementTypes[achievementName].qualify(user);
        });
    }

    static getAchievementsForUser(user) {

        const userAchievements = [];

        Object.keys(internals.achievementTypes)
        .forEach((achievementName) => {

            const currentAchievement = internals.achievementTypes[achievementName];

            if (currentAchievement.qualify(user)) {

                const qualifyingVal = currentAchievement.getQualifyingVal(user);
                let content;
                let highestAchieved;

                switch (currentAchievement.type) {

                    case 'step':

                        for (let i = 0; i < currentAchievement.steps.length; ++i) {

                            const currentStep = currentAchievement.steps[i];

                            if (qualifyingVal >= currentStep) {
                                highestAchieved = currentStep;
                            }
                        }

                        content = highestAchieved + ' Peers';
                        break;
                }

                userAchievements.push({
                    name: achievementName,
                    content
                });
            }
        });

        return userAchievements;
    }

    render() {

        const { user, extraUserInfo } = this.props;

        let calculatedUser = user;

        if (extraUserInfo) {
            calculatedUser = Object.assign(
                {},
                user,
                extraUserInfo
            );
        }

        const achievements = UserAchievements.getAchievementsForUser(calculatedUser);

        if (!achievements) {
            return null;
        }

        return (
            <div>
                {achievements && achievements.map((a) => {

                    const currentAchievementInfo = internals.achievementTypes[a.name];

                    return (
                        <div key={a.name} className={`${Classes.badgeContainer}`}>
                            <img src={currentAchievementInfo.svg} alt={currentAchievementInfo.altText(calculatedUser)} />

                            <div className={Classes.achievementContent}>
                                <div style={currentAchievementInfo.contentStyles}>{a.content}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
};

internals.achievementTypes = {
    numPeers: {
        svg: PeersAchievement,
        type: 'step',
        steps: [5, 10, 25, 50, 100],
        qualify: (user) => user.peers && user.peers.length >= internals.achievementTypes.numPeers.steps[0],
        getQualifyingVal: (user) => user.peers.length,
        altText: (user) => `${user.firstName} has at least ${internals.achievementTypes.numPeers.getQualifyingVal(user)} peers`,
        contentStyles: {
            fontSize: 23,
            fontWeight: 'bold',
            position: 'absolute',
            left: '37px',
            top: '-12px',
            whiteSpace: 'nowrap'
        }
    }
};
