const SortBy = require('lodash/sortBy');

const selectRandomInterests = (interestsArray) => {

    if (interestsArray.length <= 3) {
        return interestsArray;
    }

    const selectedInterests = new Set();
    const numOfItems = Math.floor(Math.random() * (Math.min(5, interestsArray.length) - 2 + 1)) + 3;

    while (selectedInterests.size < numOfItems) {
        const randomIndex = Math.floor(Math.random() * interestsArray.length);

        const selectedItem = interestsArray[randomIndex];
        selectedInterests.add(JSON.stringify(selectedItem));
    }

    return Array.from(selectedInterests).map((item) => JSON.parse(item));
};

const selectFirstFiveInterests = (interestsArray) => {

    return interestsArray.slice(0, 5).filter((x) => !!x);
};

// const formatSentenceFromInterests = ({ userName, interests }) => {

//     const formattedInterests = interests.map(({ name }) => name);
//     return `${userName.trim()}'s interests include ${listItemText(formattedInterests)}.`;
// };

// const makeFirstInterestsText = ({ passions, interests, userName }) => {

//     const totalNumInterests = 4;

//     passions = passions.sort(({ id: aId }, { id: bId }) => {

//         if (aId < bId) {
//             return -1;
//         }
//         else if (bId < aId) {
//             return 1;
//         }

//         return 0;
//     }).reverse();

//     interests = interests.sort(({ id: aId }, { id: bId }) => {

//         if (aId < bId) {
//             return -1;
//         }
//         else if (bId < aId) {
//             return 1;
//         }

//         return 0;
//     }).reverse();

//     if (passions.length >= totalNumInterests) {
//         return formatSentenceFromInterests({
//             userName,
//             interests: passions.slice(0, totalNumInterests)
//         });
//     }

//     const numInterests = totalNumInterests - passions.length;
//     const filteredInterests = interests.filter(({ id }) => !passions.find(({ id: _id }) => id === _id));

//     return formatSentenceFromInterests({
//         userName,
//         interests: passions.concat(filteredInterests.slice(0, numInterests))
//     });
// };

const makeRandomInterestsText = ({ interests, categories, userName, isMe }) => {

    interests = Object.entries(interests)
    .filter(([, val]) => {

        if (Array.isArray(val)) {
            return val.length > 0;
        }

        return !!val;
    })
    .reduce((obj, [key, val]) => ({ // Turn back into an object
        ...obj,
        [key]: val
    }), {});

    if (Object.keys(interests).length === 0) {
        return '';
    }

    const formattedInterests = sortAndFormatInterests(interests, categories);

    let lastVerbUsed;

    return formattedInterests
    .reduce((interestsTexts, item, i) => {

        const {
            sortedVal,
            config: { verb, format }
        } = item;

        const formatFunc = format || ((x) => x);
        const formatted = [].concat([].concat(sortedVal)).map(formatFunc);

        const itemText = listItemText(formatted);

        const fullItemText = `${itemText ? `${itemText}` : itemText}`;

        const getVerb = () => {

            const toReturn = ((verb === lastVerbUsed)) ? '' : verb;
            lastVerbUsed = verb;
            return toReturn;
        };

        // At the beginning of the list

        return [
            (`${isMe ? 'Your' : `${userName.trim()}'s`} interests ${getVerb()} ${fullItemText}`) + '.'
        ];
    }, []);
};

const filterOutKeys = (obj, keys) => {

    return Object.keys(obj)
    .filter((key) => !keys.includes(key))
    .reduce((newObject, key) => {

        return {
            ...newObject,
            [key]: obj[key]
        };
    }, {});
};

const filterInKeys = (obj, keys) => {

    return Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce((newObject, key) => {

        return {
            ...newObject,
            [key]: obj[key]
        };
    }, {});
};

const sortAndFormatInterests = (interests, categories) => {

    const order = [
        'interests'
    ];

    return order
    .filter((type) => !!interests[type])
    .map((type) => {

        const usedInterests = selectFirstFiveInterests(interests[type]);

        const usedCategoriesIds = Array.from(new Set(usedInterests.map((interest) => interest.categoryId)));
        const usedCategories = categories.filter((category) => usedCategoriesIds.includes(category.id));
        const sortedCategories = SortBy(usedCategories, ['name']);

        const sortedVal = sortedCategories.map((category) => {

            return usedInterests.filter((interest) => {

                return interest.categoryId === category.id;
            }).sort(sortInterests);

        }).flat();

        return {
            type,
            sortedVal,
            config: getItemsConfig(type)
        };
    });
};

const sortInterests = (a, b) => {

    const aSpecial = a && a.special;
    const bSpecial = b && b.special;

    return (Number(bSpecial) - Number(aSpecial )) || (a.categoryName.localeCompare(b.categoryName)) || a.name.localeCompare(b.name);
};

const getItemsConfig = (type) => {

    switch (type) {
        case 'interests':
            return {
                verb: 'include',
                format: ({ name }) => name
            };
    }
};

const listItemText = (items) => {

    if (!items) {
        return '';
    }

    switch (items.length) {
        case 0:
            return '';
        case 1:
            // eslint-disable-next-line
            const firstItem = String(items[0]);
            if (firstItem === 'true') {
                return '';
            }

            return items[0];
        case 2:
            return `${items[0]} and ${items[1]}`;
        default: {

            let text = '';

            items.forEach((item, index) => {

                if (index === items.length - 2){
                    text += `${item}`;
                }
                else if (index < items.length - 1){
                    text += `${item}, `;
                }
                else {

                    text += `, and ${item}`;
                }
            });

            return text;
        }
    }
};

module.exports = {
    filterOutKeys,
    filterInKeys,
    sortAndFormatInterests,
    getItemsConfig,
    makeRandomInterestsText
    // makeFirstInterestsText
};
