const React = require('react');
const PlainLayout = require('layouts/PlainLayout');
const AppBarLayout = require('layouts/AppBarLayout');
const TosContents = require('components/TosContents');

module.exports = class PrivacyPolicyDialog extends React.PureComponent {

    render() {

        return (
            <PlainLayout>
                <AppBarLayout>
                    <TosContents />
                </AppBarLayout>
            </PlainLayout>
        );
    }
};
