const React = require('react');
const T = require('prop-types');
const { NavLink: Link } = require('react-router-dom');
const ChatUtils = require('./utils');
const { default: Classes } = require('./styles.scss');
const Linkify = require('linkifyjs');

const internals = {};

module.exports = class ItemBody extends React.PureComponent {

    static propTypes = {
        children: T.oneOfType([T.string, T.arrayOf(T.string)]),
        container: T.element
    }

    static renderFullBody = ChatUtils.processFullBody({
        mention: ([name, id]) => {

            // TODO if someone is mentioned twice in the same message, we'll have duplicate keys here.

            // E.g. for @everyone
            if (id === null) {
                return <span key={name} className={Classes.mention}>@{name}</span>;
            }

            return <Link key={name + id +  Math.random() * 1000} className={Classes.mention} to={`/app/profile/${id}`}>@{name}</Link>;
        }
    })

    static renderLinks = (parts) => {

        const matchesUrl = (maybeUrl) => {

            return Linkify.test(maybeUrl);
        };

        const matchesEmail = (maybeEmail) => {

            return Linkify.test(maybeEmail, 'email');
        };

        return parts.reduce((out, part) => {

            if (typeof part !== 'string') {
                return out.concat(part);
            }

            const subparts = part.split(' ').map((item, index) => {

                let emptySpace = ' ';
                if (index === part.length - 1){
                    emptySpace = '';
                }

                const clearItem = item.replaceAll(',', '');

                if (!matchesUrl(clearItem) && !matchesEmail(clearItem)) {
                    return `${item}${emptySpace}`; // Not actually a url or email
                }

                if (matchesEmail(clearItem)) {
                    return <a key={item} target='_blank' rel="noopener noreferrer" className={Classes.itemLink} href={`mailto:${clearItem}`}>{`${item}${emptySpace}`}</a>;
                }

                return <a key={item} target='_blank' rel="noopener noreferrer" className={Classes.itemLink} href={internals.ensureProtocol(clearItem)}>{`${item}${emptySpace}`}</a>;
            });

            return out.concat(subparts);
        }, []);
    }

    render() {

        const { children, container, ...otherProps } = this.props;

        const message = [].concat(children).filter(Boolean).join(' ').replaceAll('https://',' https://').replaceAll('http://',' https://');

        return container ?
            React.cloneElement(
                container,
                otherProps,
                ItemBody.renderLinks(ItemBody.renderFullBody(message, true))
            ) :
            React.createElement(
                'span',
                otherProps,
                ItemBody.renderLinks(ItemBody.renderFullBody(message, true))
            );
    }
};

internals.ensureProtocol = (url) => {

    if (url.match(/^https?:\/\//i)) {
        return url;
    }

    return `http://${url}`;
};
