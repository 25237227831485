const { useEffect } = require('react');
const { default: Styled } = require('styled-components');
const { transient$Props } = require('utils/styles');
const { ELEMENT_IDS } = require('utils/constants');
const { useHistory } = require('react-router-dom');

const internals = {
    currentFocused: null
};

const BORDER_SIZE = 2;

const BLUR_HIDE_TIMEOUT = 50;

module.exports = function AnimatedFocusIndicator() {

    const { FocusIndicator } = internals;
    const { wrapFocusIndicatorForElement } = module.exports;

    const history = useHistory();

    useEffect(() => {

        window.addEventListener('resize', wrapFocusIndicatorForElement);

        return () => {

            window.removeEventListener('resize', wrapFocusIndicatorForElement);
            internals.scrollContainer?.removeEventListener('scroll', wrapFocusIndicatorForElement);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const unlisten = history.listen(() => {

            module.exports.onBlurHandler();
        });

        // Cleanup function to remove the listener when component unmounts
        return () => unlisten();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    return <FocusIndicator id={ELEMENT_IDS.focusIndicator} />;
};

// This should be placed as onFocus for the wrapper containing this component
module.exports.onFocusHandler = (evt) => {

    const focusedElement = evt.target;

    const { wrapFocusIndicatorForElement } = module.exports;

    const focusOutlineAttr = focusedElement.getAttribute('data-focus-outline');

    if (focusOutlineAttr) {
        internals.scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);

        internals.scrollContainer?.removeEventListener('scroll', wrapFocusIndicatorForElement);
        internals.scrollContainer?.addEventListener('scroll', wrapFocusIndicatorForElement);

        internals.currentFocused = focusedElement;
        internals.parseProps(focusOutlineAttr);

        wrapFocusIndicatorForElement();
    }
};

// This should be placed as onBlur for the wrapper containing this component
module.exports.onBlurHandler = () => {

    const { setFocusIndicatorStyle } = internals;

    internals.currentFocused = null;
    internals.currentProps = {};

    setTimeout(() => {

        if (!internals.currentFocused) {
            setFocusIndicatorStyle({
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                opacity: 0
            });
        }
    }, BLUR_HIDE_TIMEOUT);
};

module.exports.wrapFocusIndicatorForElement = () => {

    if (!internals.currentFocused) {
        return;
    }

    const { setFocusIndicatorStyle } = internals;

    const el = internals.currentFocused;

    // Get the position and dimensions of the focused element relative to the viewport
    const rect = el.getBoundingClientRect();

    const {
        inside,
        radius = 0,
        padding,
        borderSize = BORDER_SIZE,
        zIndex
    } = internals.currentProps || {};

    const numBorderSize = Number(borderSize);

    const top = inside ? rect.top + numBorderSize : rect.top;
    const left = inside ? rect.left + numBorderSize : rect.left;
    const width = inside ? rect.width - numBorderSize * 2 : rect.width;
    const height = inside ? rect.height - numBorderSize * 2 : rect.height;

    setFocusIndicatorStyle({
        top,
        left,
        width,
        height,
        radius,
        padding,
        borderSize: numBorderSize,
        zIndex
    });
};

internals.setFocusIndicatorStyle = (props) => {

    const {
        focusIndicator = document.getElementById(ELEMENT_IDS.focusIndicator),
        top,
        left,
        width,
        height,
        opacity = 1,
        radius = 0,
        padding = 0,
        borderSize = BORDER_SIZE,
        zIndex = 9999
    } = props;

    const numPadding = Number(padding);
    const numBorderSize = Number(borderSize);
    const numZIndex = Number(zIndex);

    if (focusIndicator) {
        focusIndicator.style.top = top - numBorderSize - numPadding + 'px';
        focusIndicator.style.left = left - numBorderSize - numPadding + 'px';
        focusIndicator.style.width = isNaN(width) ? width : width + (numBorderSize * 2) + (numPadding * 2) + 'px';
        focusIndicator.style.height = isNaN(height) ? height : height + (numBorderSize * 2) + (numPadding * 2) + 'px';
        focusIndicator.style.opacity = opacity;
        focusIndicator.style.borderRadius = radius + 'px';
        focusIndicator.style.zIndex = numZIndex;
        focusIndicator.style.border = `${numBorderSize}px solid #4d90fe`; /* WebKit blue color */
    }
};

internals.parseProps = (focusOutlineAttr) => {

    // Parse props
    internals.currentProps = focusOutlineAttr.split(',').reduce((props, prop) => {

        const split = prop.split(':');

        if (split.length === 2) {
            return {
                ...props,
                [split[0]]: split[1]
            };
        }

        return {
            ...props,
            [split[0]]: true
        };
    }, {});
};

// Styled component for the focus indicator
internals.FocusIndicator = Styled('div', transient$Props)`
    position: fixed;
    pointer-events: none;
    border: ${BORDER_SIZE}px solid #4d90fe; /* WebKit blue color */
    box-shadow: 0 0 5px 3px rgba(77, 144, 254, 0.5); /* WebKit blue color with opacity */
    width: 0;
    height: 0;
    top: 100px;
    left: 100px;
    opacity: 0;
    // transition: width 0.2s ease, height 0.2s ease, top 0.2s ease, left 0.2s ease;
    z-index: 9999;
`;
