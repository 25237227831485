const { connect } = require('react-redux');

const InterestPassionPopper = require('components/InterestPassionPopper');


module.exports = connect(
    (state) => ({

    }), {

    }
)(InterestPassionPopper);
