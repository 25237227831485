const React = require('react');
const { useEffect, useState, useRef } = require('react');
const T = require('prop-types');
const { default: Classes } = require('./styles.scss');

const Loader = require('components/Loader');
const { default: Grid } = require('@mui/material/Grid');

const { default: ArrowBack } = require('@mui/icons-material/ArrowBack');
const IsEqual = require('lodash/isEqual');
const { Pie } = require('react-chartjs-2');
const { usePrevious } = require('../../../../../hooks/usePrevious');
const ScrollPortal = require('components/ScrollPortal');
const { default: Paper } = require('@mui/material/Paper');
const { default: IconButton } = require('@mui/material/IconButton');

const internals = {};

const SurveyReportPage = (props) => {

    // eslint-disable-next-line no-empty-pattern
    const {
        goBack,
        getReport,
        survey
    } = props;

    const prevSurveyResult = usePrevious( survey );
    const containerRef = useRef();
    const [surveyReport, setSurveyReport] = useState( {} );

    useEffect( () => {

        if (survey) {

            if (!IsEqual(survey, prevSurveyResult)) {

                getReport({ surveyId: survey.id }).then(({ payload: { result } }) => {

                    setSurveyReport( result );
                });
            }
        }
    }, [survey] ); // eslint-disable-line react-hooks/exhaustive-deps

    const renderTextWithLinks = (text) => {

        const parts = text.split(' ');

        const urlExp = /(\b(?:https?:\/\/)?[a-z0-9\-.]+\.[a-z]{2,24}(?:\/\S*)?\b)/gi;

        const matchesUrl = (maybeUrl) => {

            // If the string has 2 dots in a row, it's not a link.
            // The urlExp regex has trouble with this condition.
            // It will match hello...world, so let's not give it a chance to!
            if (maybeUrl.includes('..')) {
                return null;
            }

            return maybeUrl.match(urlExp);
        };

        const finalString =  parts.reduce((out, part,currentIndex) => {

            if (typeof part !== 'string') {
                return out.concat(part);
            }

            if (part === '<newline>') {

                if (parts.length > 1 && out.length >= 1){
                    out[currentIndex - 1] = `${parts[currentIndex - 1]}\n `;

                    return out.concat('');
                }
            }

            part = part.concat(' ');

            const subparts = part.split(urlExp).map((url) => {

                if (!matchesUrl(url)) {
                    return url; // Not actually a url
                }

                return <a key={url} target='_blank' rel="noopener noreferrer" className={Classes.itemLink} href={internals.ensureProtocol(url)}>{url}</a>;
            });

            return out.concat(subparts);
        }, []);

        return finalString;
    };

    const renderContent = () => {

        if (surveyReport && surveyReport.questions && surveyReport.questions.length) {

            const { totallyAssigned, startedCount,solvedCount } = surveyReport;
            const solvedPercentage = `${Math.round((solvedCount / totallyAssigned) * 1000) / 10}%`;
            const viewedPercentage = `${Math.round((startedCount / totallyAssigned) * 1000) / 10}%`;

            return (
                <div ref={containerRef}>
                    <ScrollPortal top>
                        <Paper className={`${Classes.surveyTitleWrapper} contentWrapper`}>
                            <IconButton aria-label='go back' onClick={goBack} size="large">
                                <ArrowBack/>
                            </IconButton>
                            <span>{surveyReport.title}</span>
                        </Paper>
                    </ScrollPortal>
                    <Grid  container style={{ paddingLeft: 20, paddingRight: 20,paddingBottom:20 }} spacing={2}>

                        <Grid item xs={6} spacing={2}>
                            <h4>Invited: {totallyAssigned}</h4>
                            <h4>Viewed: {startedCount} {startedCount ? `(${viewedPercentage})` : ''}</h4>
                            <h4>Completed: {solvedCount} {solvedCount ? `(${solvedPercentage})` : ''}</h4>
                        </Grid>
                        {surveyReport.solvedCount ? <Grid item xs={12} spacing={2}>
                            {surveyReport.questions.map( (questionItem, questionIndex) => {

                                if (questionItem.freeInput) {
                                    return <Grid key={questionItem.id} container spacing={2}>
                                        <Grid item xs={12} >
                                            <h4 style={{ marginBottom: 0 }}>{`${questionIndex + 1}. `} {renderTextWithLinks(questionItem.text)}</h4>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <h5 style={{ marginBottom: 0, marginTop: 10 }}>Answers</h5>
                                            {questionItem.report.freeAnswers.map((answerItem, index) => (

                                                <p style={{ paddingLeft: 10 }}
                                                    key={index}>{`${index + 1}. ${answerItem}`}
                                                </p>)
                                            )}
                                        </Grid>
                                    </Grid>;
                                }

                                const graphData = {
                                    labels: questionItem.report.pieChartLabels,
                                    datasets: [
                                        {
                                            data: questionItem.report.pieChartData,
                                            backgroundColor: [
                                                'rgba(153, 102, 255, 0.4)',
                                                'rgba(255, 99, 132, 0.4)',
                                                'rgba(54, 162, 235, 0.4)',
                                                'rgba(242, 76, 21,0.4)',
                                                'rgba(255, 206, 86, 0.4)',
                                                'rgba(75, 192, 192, 0.4)',
                                                'rgba(255, 159, 64, 0.4)',
                                                'rgba(9, 56, 244,0.4)',
                                                'rgba(52, 188, 80,0.4)',
                                                'rgba(193, 29, 94,0.4)',
                                                'rgba(139, 63, 244,0.4)',
                                                'rgb(221, 242, 36,0.4)'
                                            ],
                                            borderColor: [
                                                'rgba(153, 102, 255, 1)',
                                                'rgba(255, 99, 132, 1)',
                                                'rgba(54, 162, 235, 1)',
                                                'rgba(242, 76, 21,1)',
                                                'rgba(255, 206, 86, 1)',
                                                'rgba(75, 192, 192, 1)',
                                                'rgba(255, 159, 64, 1)',
                                                'rgba(9, 56, 244,1)',
                                                'rgba(52, 188, 80,1)',
                                                'rgba(193, 29, 94,1)',
                                                'rgba(139, 63, 244,1)',
                                                'rgb(221, 242, 36,1)'
                                            ],
                                            borderWidth: 1
                                        }
                                    ]
                                };

                                return <Grid key={questionItem.id} container spacing={2} alignContent={'center'}>
                                    <Grid item xs={12} spacing={2}>
                                        <h4 style={{ marginBottom: 0 }}>{`${questionIndex + 1}. ${questionItem.text}`}</h4>
                                    </Grid>
                                    <Grid item justifyContent={'center'} xs={12} spacing={2}>
                                        <Pie data={graphData} options={{
                                            tooltips: {
                                                callbacks: {
                                                    label: (tooltipItem, data) => {

                                                        const label = data.labels[tooltipItem.index] || '';
                                                        const value = data && data.datasets[0].data[tooltipItem.index] || 0;

                                                        return `${label}: ${value} (${(Math.round( value * 100 ) / surveyReport.solvedCount).toFixed( 0 )}%)`;
                                                    }
                                                }
                                            }
                                        }}/>
                                    </Grid>
                                </Grid>;
                            })}
                        </Grid> : null}
                    </Grid>
                </div>
            );
        }
    };

    return (
        <div>
            {surveyReport && surveyReport.questions && surveyReport.questions.length ? renderContent() : <Loader/>}
        </div>
    );
};

SurveyReportPage.propTypes = {
    goBack: T.func,
    getReport: T.func,
    survey: T.object
};

internals.ensureProtocol = (url) => {

    if (url.match(/^https?:\/\//i)) {
        return url;
    }

    return `http://${url}`;
};

module.exports = SurveyReportPage;
