const PrivacyPolicy = require('./components/PrivacyPolicy');
const TermsOfService = require('./components/TermsOfService');
const Accessibility = require('./components/Accessibility');
const Unsubscribe = require('./components/Unsubscribe');
const Resubscribe = require('./components/Resubscribe');

module.exports = [
    {
        path: '/terms-of-service',
        component: TermsOfService
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/accessibility',
        component: Accessibility
    },
    {
        path: '/unsubscribe/:type',
        component: Unsubscribe
    },
    {
        path: '/resubscribe/:type',
        component: Resubscribe
    }
];
