const ConnectedRouter = require('connected-react-router');

module.exports = () => {

    return {
        push: ConnectedRouter.push,
        replace: ConnectedRouter.replace,
        go: ConnectedRouter.go,
        goBack: ConnectedRouter.goBack,
        goForward: ConnectedRouter.goForward
    };
};
