const FallbackLoader = require('components/FallbackLoader');
const SSOLogin = require('./components/SSOLogin');
const SSOAgreeToTerms = require('./components/SSOAgreeToTerms');

const { initSignupContext } = require('../signup');

// Route used to redirect to SSO login page,
// and callback from auth0
exports = module.exports = [{
    path: '/login-placeholder',
    exact: true,
    component: FallbackLoader
},
{
    path: '/login-sso',
    exact: true,
    props: {
        redirectUri: process.env.REACT_APP_AUTH0_LOGIN_CALLBACK_URL
    },
    component: SSOLogin,
    onWillMount: async () => {

        await initSignupContext();
    }
},
{
    path: '/signup-sso',
    exact: true,
    component: SSOLogin,
    props: {
        redirectUri: process.env.REACT_APP_AUTH0_SIGNUP_CALLBACK_URL
    },
    onWillMount: async () => {

        await initSignupContext();
    }
},
{
    path: '/sso-terms',
    exact: true,
    component: SSOAgreeToTerms,
    onWillMount: async () => {

        const { verificationToken } = await initSignupContext();

        if (!verificationToken) {
            return { replace: '/choose-password' };
        }
    }
}];
