const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@mui/material/Typography');
const { default: FlatButton } = require('@mui/material/Button');
const Colors = require('styles/colors.json');
const Fonts = require('styles/fonts.json');

const PlainLayout = require('layouts/PlainLayout');
const AppBarLayout = require('layouts/AppBarLayout');

const EmptySpaceLogo = require('./Unsubscribe/EmptySpaceLogo');
const FormHelperText = require('components/FormHelperText');
const Divider = require('components/Divider');

const internals = {
    unsubscribeTypes: [
        'verification-reminders',
        'profile-reminders'
    ]
};

module.exports = function Resubscribe(props) {

    const {
        MainContent,
        EmptySpaceLogoContainer,
        unsubscribeTypes,
        onClickUnsubscribe,
        parseSchoolFromUrl
    } = internals;

    const { match: { params: { type } } } = props;

    const isResubscribeType = unsubscribeTypes.includes(type);

    return (
        <PlainLayout>
            <AppBarLayout hideFooter>
                <MainContent>
                    <Typography variant='h2'>
                        {type === 'verification-reminders' ? 'You will continue to receive invitations to join Nearpeer' : null}
                        {type === 'profile-reminders' ? 'You will continue to receive reminders to complete your Nearpeer profile' : null}
                    </Typography>
                    <Divider />
                    {isResubscribeType && (
                        <>
                            <Typography variant='body1'>
                                {type === 'verification-reminders' ?
                                    <span dangerouslySetInnerHTML={{
                                        __html: `Follow the invitation instructions to join Nearpeer and <b>find your people at ${parseSchoolFromUrl()}</b> with similar interests, backgrounds and life experiences!`
                                    }} /> : null
                                }
                                {type === 'profile-reminders' ?
                                    <span dangerouslySetInnerHTML={{
                                        __html: `Just a few steps remain, then you can <b>find your people at ${parseSchoolFromUrl()}</b> with similar interests, backgrounds and life experiences!`
                                    }} /> : null
                                }
                            </Typography>
                            <FlatButton
                                color={'primary'}
                                onClick={() => onClickUnsubscribe(type)}
                                fullWidth
                                style={{
                                    backgroundColor: Colors.darkBrandColor,
                                    color: 'white',
                                    height: '2.25em',
                                    fontFamily: Fonts.headerFont,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    fontSize: '1.25em',
                                    letterSpacing: 1,
                                    lineHeight: '2.5em',
                                    marginTop: 20
                                }}
                            >
                                Unsubscribe
                            </FlatButton>
                            <EmptySpaceLogoContainer>
                                <EmptySpaceLogo opacity={1} />
                            </EmptySpaceLogoContainer>
                        </>
                    )}
                    {!isResubscribeType && (<FormHelperText variant='h3'>
                        Update Preferences
                    </FormHelperText>)}
                    {/* <UserPreferences /> */}
                </MainContent>
            </AppBarLayout>
        </PlainLayout>
    );
};

module.exports.propTypes = {
    match: T.shape({
        params: T.shape({
            type: T.string.isRequired
        }).isRequired
    }).isRequired
};

internals.MainContent = Styled.div`
    max-width: 600px;
    margin: auto;
    padding: 30px;
    text-align: center;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    h2 {
        font-size: 22px;
        font-weight: bold;
    }
    h3 {
        font-size: 24px;
    }
    @media screen and (max-width: 600px) {
        padding: 30px;
    }
`;

internals.EmptySpaceLogoContainer = Styled.div`
    width: 70%;
    margin: auto;
    padding-top: 60px;
    @media screen and (max-width: 600px) {
        width: 90%;
        padding-top: 30px;
    }
`;

internals.parseSchoolFromUrl = () => {

    return window.location.search.split('school=')[1].split('&val=')[0].replaceAll('%20', ' ');
};

internals.parseValFromUrl = () => {

    return encodeURIComponent(window.location.search.split('val=')[1]);
};

internals.onClickUnsubscribe = (type) => {

    const val = internals.parseValFromUrl();
    window.location = `${process.env.API_HOST}${process.env.API_PREFIX}/unsubscribe/${type}?val=${val}`;
};
