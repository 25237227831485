const React = require('react');
const { default: Typography } = require('@mui/material/Typography');

const { default: Classes } = require('./styles.scss');
const AppBarLayout = require('layouts/AppBarLayout');
const PlainLayout = require('layouts/PlainLayout');

module.exports = class PakistanNearpeerAttention extends React.PureComponent {

    componentDidMount() {

        document.body.classList.add(Classes.homeBody);
    }

    componentWillUnmount() {

        document.body.classList.remove(Classes.homeBody);
    }

    render() {

        return (
            <PlainLayout>
                <AppBarLayout hideFooter>
                    <div className={Classes.textWrapper}>
                        <Typography align={'center'} variant={'h4'}>
                            Visitors from Pakistan. You may be looking for <a href={'https://nearpeer.org/'}>nearpeer.org</a>, as we do not currently serve Pakistan. Thank you.
                        </Typography>
                    </div>
                </AppBarLayout>
            </PlainLayout>
        );
    }
};
