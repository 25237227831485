const React = require('react');
const T = require('prop-types');
const { default: styled } = require('styled-components');
const { useInView } = require('react-intersection-observer');
// @mui
const { default: Box } = require('@mui/material/Box');

const GetRatio = require('./getRatio');
const { transient$Props } = require('utils/styles');
const { ELEMENT_IDS } = require('utils/constants');
const { default: Classes } = require('./styles.scss');

// ----------------------------------------------------------------------

const internals = {};

// eslint-disable-next-line react/display-name
const Image = ({ ratio, src, width, height, afterLoad, ...other }) => {

    const { RatioBox } = internals;

    const scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '800px',
        root: scrollContainer
    });

    const content = (
        (inView) ? (<Box
            component={'img'}
            src={src}
            width={width}
            height={height}
            loading='lazy'
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
            onLoad={afterLoad}
            {...other}
        />) : null
    );

    if (ratio) {

        return (
            <RatioBox
                ref={ref}
                $ratio={ratio}
            >
                {content}
            </RatioBox>
        );
    }

    return (
        <Box
            ref={ref}
            component="span"
            style={{
                lineHeight: 1,
                display: 'block',
                overflow: 'hidden',
                position: 'relative'
            }}

            className={Classes.wrapper}
        >
            {content}
        </Box>
    );
};

Image.propTypes = {
    ratio: T.oneOf(['1/1', '4/3' , '3/4' , '6/4' , '4/6' , '16/9' , '9/16' , '21/9' , '9/21']),
    afterLoad: T.func,
    src: T.string,
    width: T.any,
    height: T.any
};

module.exports = Image;

internals.RatioBox = styled('div', transient$Props)`
    width: 100%;
    lineHeight: 1;
    overflow: hidden;
    position: relative;
    padding-bottom: ${({ $ratio }) => GetRatio($ratio)};
`;

internals.StyledImage = styled('img')``;
