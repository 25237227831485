const React = require('react');
const T = require('prop-types');

const { default: Avatar } = require('@mui/material/Avatar');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemAvatar } = require('@mui/material/ListItemAvatar');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: AnnouncementIcon } = require('@mui/icons-material/Announcement');
const { getSizedImageUrl } = require('utils/image');
const IsEqual = require('lodash/isEqual');
const { default: Classes } = require('./styles.scss');
const NoUserProfilePic = require('components/NoUserProfilePic');

const { createRef } = React;

const internals = {};

module.exports = class Mentions extends React.PureComponent {

    static propTypes = {
        className: T.any,
        inputEl: T.object,
        users: T.arrayOf(T.shape({
            id: T.any.isRequired,
            firstName: T.string.isRequired,
            lastName: T.string.isRequired
        })),
        message: T.string,
        onRequestChange: T.func,
        onSearchFinish: T.func,
        searchUsers: T.func,
        variant: T.oneOf(['dm', 'group']),
        classId: T.any,
        includeEveryoneMention: T.bool
    }

    constructor() {

        super();

        this.state = {
            matches: [],
            selected: -1
        };

        this.root = createRef();

        this.handleRequestClose = this._handleRequestClose.bind(this);
        this.useCurrentSelection = this._useCurrentSelection.bind(this);
        this.handleChooseItem = this._handleChooseItem.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.message.trim().endsWith(this.lastMentionText)) {
            // Ignore if new text is the mention the user just clicked
            return;
        }

        if ((!IsEqual(nextProps.message, this.props.message)
            || !IsEqual(nextProps.users, this.props.users))) {
            this.maybeMention(nextProps);
        }
    }

    async maybeMention({ message, users }) {

        const { inputEl, includeEveryoneMention, variant, classId } = this.props;

        if (!inputEl) {
            return;
        }

        // If message is empty, hide it
        if (message === '') {
            return this.setState({
                matches: [],
                selected: -1
            });
        }

        // Bail early if possible
        if (message.indexOf('@') === -1 || (!users && variant === 'dm') || users.length === 0 && variant === 'dm') {
            return this.setState({
                matches: [],
                selected: -1
            });
        }

        const textBeforeCaret = message.slice(0, inputEl.selectionStart);
        const atPosition = textBeforeCaret.lastIndexOf('@');

        // If there's no "@" or there is one but it's preceded by a word character (a-z0-9_), bail
        if (atPosition === -1 ||
            (textBeforeCaret[atPosition - 1] || '').match(/\w/)) {

            return this.setState({
                matches: [],
                selected: -1
            });
        }

        const potentialMatch = textBeforeCaret.slice(atPosition + 1);

        if (potentialMatch === '') {
            return this.setState({
                matches: [],
                selected: -1
            });
        }

        if (potentialMatch.split(' ').length > 2){
            return this.setState({
                matches: [],
                selected: -1
            });
        }

        const potentialMatchNormalized = potentialMatch.toLowerCase();

        if (variant === 'dm') {
            const matches = users.filter((user) => {

                const name = internals.name(user);

                if (name === potentialMatch) {
                    return false;
                }

                return name.toLowerCase().indexOf(potentialMatchNormalized) === 0;
            });

            if (includeEveryoneMention &&
                ('everyone').indexOf(potentialMatchNormalized) === 0) {

                matches.push({ isSpecialMention: true, type: 'everyone', text: 'everyone' });
            }

            this.setState({
                matches,
                selected: matches.length ? 0 : -1
            });
        }
        else {

            const response = await this.props.searchUsers(classId, potentialMatchNormalized);

            const matches = response.payload.result ? response.payload.result : [];

            if (includeEveryoneMention &&
                ('everyone').indexOf(potentialMatchNormalized) === 0) {

                matches.push({ isSpecialMention: true, type: 'everyone', text: 'everyone' });
            }

            this.setState({ matches });

            return this.props.onSearchFinish(matches);
        }
    }

    _handleRequestClose() {

        this.setState({
            matches: [],
            selected: -1
        });
    }

    handleKeyDown(ev) {

        const { selected, matches } = this.state;

        if (selected === -1) {
            return;
        }

        if (ev.key === 'ArrowDown') {
            this.setState({ selected: Math.min(selected + 1, matches.length - 1) });
            ev.preventDefault();
            return true;
        }

        if (ev.key === 'ArrowUp') {
            this.setState({ selected: Math.max(selected - 1, 0) });
            ev.preventDefault();
            return true;
        }

        if (ev.key === 'Tab' || ev.key === 'Enter') {
            this.useCurrentSelection();
            ev.preventDefault();
            return true;
        }
    }

    _useCurrentSelection() {

        const { selected, matches } = this.state;
        const { message, inputEl } = this.props;

        if (selected === -1 || !matches[selected]) {
            return;
        }

        const textBeforeCaret = message.slice(0, inputEl.selectionStart);
        const atPosition = textBeforeCaret.lastIndexOf('@');

        const beforeMention = message.slice(0, atPosition);

        const matchText = (() => {

            const match = matches[selected];

            if (match.isSpecialMention) {
                return match.text || '';
            }

            // Most of the time this will be a user
            const user = match;

            return internals.name(user);
        })();

        const afterCaret = message.slice(inputEl.selectionStart);

        const nextMessage = `${beforeMention}@${matchText} ${afterCaret}`;

        this.lastMentionText = matchText;

        return this.props.onRequestChange(nextMessage, () => {

            const nextCaretPosition = `${beforeMention}@${matchText} `.length;

            inputEl.focus();
            inputEl.setSelectionRange(nextCaretPosition, nextCaretPosition);

            this.handleRequestClose();
        });
    }

    _handleChooseItem(index) {

        return (ev) => {

            this.setState({ selected: index }, () => this.useCurrentSelection());
        };
    }

    render() {

        if (!this.state.matches.length && this.state.selected === -1) {
            return null;
        }

        const { className } = this.props;

        return <div
            ref={this.root}
            className={`${className}
            ${Classes.shadow}`}
            style={{
                maxHeight: 250,
                overflow: 'scroll'
            }}
        >
            {this.state.matches.map((match, i) => {

                if (match.isSpecialMention) {

                    const { type: matchType, text } = match;

                    switch (matchType) {
                        case 'everyone':
                            return <ListItem
                                key={`special-${matchType}`}
                                button={true}
                                focusRipple={this.state.selected === i}
                                onClick={this.handleChooseItem(i)}
                            >
                                <ListItemAvatar>
                                    {<Avatar icon={<AnnouncementIcon />} />}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<b>@{text}</b>}
                                />
                            </ListItem>;
                        default:
                            return null;
                    }
                }

                // Most of the time this will be a user
                const user = match;

                return <ListItem
                    key={`user-${user.id}`}
                    button={true}
                    focusRipple={this.state.selected === i}
                    onClick={this.handleChooseItem(i)}
                >
                    <ListItemAvatar>
                        {user.croppedPicture ? <Avatar src={getSizedImageUrl(user.croppedPicture, 100)} /> : <Avatar> <NoUserProfilePic iconColor={'#ffffff'} insideAvatar={true} outlined={false}  /></Avatar>}
                    </ListItemAvatar>
                    <ListItemText
                        primary={internals.name(user)}
                    />
                </ListItem>;
            })}
        </div>;
    }
};

internals.name = ({ firstName, lastName }) => [firstName, lastName].filter((x) => !!x).map((x) => x.trimStart()).join(' ');
