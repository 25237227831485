const { makeContext, PLACEHOLDER } = require('../utils/context');
const Configuration = require('./configuration');
const { selectors } = require('../reducers');

const { context, finalize } = makeContext({ definition: {
    configuration: {
        twilioApi: PLACEHOLDER,
        firebase: PLACEHOLDER
    },
    http: {
        nearpeer: PLACEHOLDER
    },
    api: {
        nearpeer: PLACEHOLDER,
        twilio: PLACEHOLDER,
        ipStack: PLACEHOLDER
    },
    handlers: {
        signup: PLACEHOLDER,
        communication: PLACEHOLDER,
        errorHandling: PLACEHOLDER,
        auth: PLACEHOLDER
    },
    actions: {
        alerts: PLACEHOLDER,
        signup: PLACEHOLDER,
        auth: PLACEHOLDER,
        dataFetching: PLACEHOLDER,
        profileManagement: PLACEHOLDER,
        communication: PLACEHOLDER,
        appFeedback: PLACEHOLDER,
        notifications: PLACEHOLDER,
        classes: PLACEHOLDER,
        connectivity: PLACEHOLDER,
        app: PLACEHOLDER,
        chats: PLACEHOLDER
    },
    selectors: {
        all: PLACEHOLDER
    },
    redux: {
        hooks: PLACEHOLDER
    }
} });

context.configuration = {
    twilioApi: {
        environmentPrefix: Configuration.twilioApi.environmentPrefix
    },
    firebase: {
        senderId: Configuration.firebase.senderId
    }
};

context.http = {
    nearpeer: Configuration.nearpeerApi.makeHttp(),
    ipStack: Configuration.ipStackApi.makeHttp()
};
context.selectors = {
    all: selectors
};

context.api      = require('../api'     )(context);
context.handlers = require('../handlers')(context);
context.actions  = require('../actions' )(context);

const registerReduxHooks = ({ dispatch, getState, subscribe }) => {

    context.redux = {
        hooks: { dispatch, getState, subscribe }
    };

    finalize();
};

module.exports = { context, registerReduxHooks };
