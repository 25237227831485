const { connect } = require('react-redux');
const { context } = require('app-context');
const SurveySolvePage = require('../components/SurveySolvePage');
const { goBack: GoBack, push: Push } = require('connected-react-router');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state, { match: { params: { id } } }) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        currentUserId: selectors.getCurrentUserId(state),
        survey: selectors.getSurvey_forSolve(state,id)
    }), {
        onStart: actions.surveys.startSolving,
        onSubmit: actions.surveys.submitAnswers,
        goBack: GoBack,
        navigate: Push
    }
)(SurveySolvePage);
