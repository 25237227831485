const { SIMPLE, ASYNC, ERROR } = require('../utils/redux-helpers');

module.exports = {
    CONTEXT_INIT: SIMPLE,
    CONTEXT_UPDATE: SIMPLE,
    VERIFY_EMAIL: ASYNC,
    SCHOOL_CHECK: ASYNC,
    STEP_1: {
        COMPLETE: ASYNC,
        RESEND_EMAIL: ASYNC,
        FAILURE: ERROR
    },
    STEP_2: {
        CHECK_PASSWORD: ASYNC,
        COMPLETE: ASYNC,
        COMPLETE_SSO: SIMPLE
    },
    STEP_3: {
        COMPLETE: SIMPLE,
        GO_BACK: SIMPLE,
        FETCH_SIGNUP_PROGRESS: ASYNC,
        UPDATE_SIGNUP_PROGRESS: ASYNC,
        UPDATE_SIGNUP_PROGRESS_PROFILE_PICTURE: ASYNC,
        UPDATE_USER_PREFERENCES_PRE_SIGNUP: ASYNC
    },
    COMPLETE: ASYNC,
    SIGNUP_COMPLETED: SIMPLE
};
