const React = require('react');

const AppBarLayout = require('layouts/AppBarLayout');
const PrivacyPolicyContents = require('components/PrivacyPolicyContents');
const { default: Classes } = require('./styles.scss');
const PlainLayout = require('layouts/PlainLayout');

module.exports = class PrivacyPolicy extends React.PureComponent {

    render() {

        return (
            <PlainLayout>
                <AppBarLayout>
                    <PrivacyPolicyContents className={Classes.legalContents} />
                </AppBarLayout>
            </PlainLayout>
        );
    }
};
