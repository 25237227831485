const { connect } = require('react-redux');
const { context } = require('app-context');
const NotificationsButton = require('../components/NotificationsButton');

const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        unreadCount: selectors.getUnreadNotificationsCount(state)
    })
)(NotificationsButton);
