const Redux = require('redux');
const Wiring = require('./');
const { connectRouter } = require('connected-react-router');

exports.makeRoot = ({ asyncReducers, history }) => {

    return Redux.combineReducers({
        ...Wiring.reducers(), // Everything in reducers/
        router: connectRouter(history),
        ...asyncReducers
    });
};

exports.inject = ({ store, history, key, reducer }) => {

    store.asyncReducers[key] = reducer;
    const root = exports.makeRoot({
        asyncReducers: store.asyncReducers,
        history
    });
    store.replaceReducer(root);
};
