const React = require('react');
const { useRef } = require('react');
const T = require('prop-types');
const { default: ListItem } = require('@mui/material/ListItemButton');
const { NavLink } = require('react-router-dom');

const AppMenuItemComponent = (props) => {

    const { className, onClick, link, children,disabled } = props;

    const element = useRef(null);

    // If link is not set return the orinary ListItem
    if (!link || typeof link !== 'string') {
        return (
            <ListItem
                button
                disabled={disabled}
                className={className}
                /* eslint-disable-next-line react/no-children-prop */
                children={children}
                onClick={onClick}
                ref={element}
            />
        );
    }

    // Return a LitItem with a link component
    return (
        <ListItem
            button
            disabled={disabled}
            className={className}
            /* eslint-disable-next-line react/no-children-prop */
            children={children}
            onClick={onClick}
            ref={element}
            /* eslint-disable-next-line react/display-name */
            component={React.forwardRef((_props, ref) => (

                <NavLink exact {..._props}  innerRef={ref} />
            ))}
            to={link}
        />
    );
};

AppMenuItemComponent.propTypes = {
    className: T.string,
    link: T.string,
    onClick:T.func,
    children:T.any,
    disabled:T.bool
};

module.exports = AppMenuItemComponent;
