const T = require('prop-types');
const { default: Styled } = require('styled-components');
const Logomark = require('static/nearpeer_n_white.svg');
const { transient$Props } = require('utils/styles');

const internals = {};

module.exports = function EmptySpaceLogo(props) {

    const { opacity } = props;
    const { LogoBackground } = internals;

    return <LogoBackground $opacity={opacity}>
        <img src={Logomark} alt='Nearpeer logo' />
    </LogoBackground>;
};

module.exports.propTypes = {
    opacity: T.number
};

internals.LogoBackground = Styled('div', transient$Props)`
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    opacity: ${({ $opacity }) => $opacity || 0.2};
    background-color: ${({ theme }) => theme.palette.primary.main};
    padding: 80px;
    @media screen and (max-width: 600px) {
        padding: 50px;
    }
`;
