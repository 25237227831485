const React = require('react');
const T = require('prop-types');

const { default: Radio } = require('@mui/material/Radio');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');

module.exports = class RadioButton extends React.PureComponent {

    static propTypes = {
        label: T.string,
        value: T.string,
        dataFocusOutline: T.string
    }

    render() {

        const {
            label,
            value,
            dataFocusOutline
        } = this.props;

        return (
            <FormControlLabel
                value={value}
                aria-label={label}
                label={label}
                tabIndex={-1}
                control={<Radio
                    color='primary'
                    tabIndex={0}
                    style={{
                        paddingTop: 5,
                        paddingBottom: 5
                    }}
                    inputProps={{
                        'data-focus-outline': dataFocusOutline || 'radius:30,padding:-4,zIndex:1'
                    }}
                />}
            />
        );
    }
};
