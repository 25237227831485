const { Suspense, lazy } = require('react');
// components
const FallbackSpinner = require('./helpers/fallback-spinner');

// ----------------------------------------------------------------------
// eslint-disable-next-line react/display-name, hapi/no-arrowception, hapi/hapi-scope-start
const Loadable = (Component) => (props) => (
    <Suspense fallback={<FallbackSpinner />}>
        <Component {...props} />
    </Suspense>
);

// ----------------------------------------------------------------------
//APP
const BatchNotify =  Loadable(lazy(() => require('./admin/containers/BatchNotify')));
const PreapprovedEmailUpload = Loadable(lazy(() => require('./admin/containers/PreapprovedEmailUpload')));
const SurveysContainer = Loadable(lazy(() => require('./surveys/components/SurveysContainer')));
const SurveysManagement = Loadable(lazy(() => require('./surveys/containers/SurveysManagement')));
const SurveysCreatePage = Loadable(lazy(() => require('./surveys/containers/SurveyCreate')));
const SurveysEditPage = Loadable(lazy(() => require('./surveys/containers/SurveyEdit')));
const SurveyReportPage = Loadable(lazy(() => require('./surveys/containers/SurveyReport')));
const MySurveysPage = Loadable(lazy(() => require('./surveys/containers/MySurveys')));
const SurveySolvePage = Loadable(lazy(() => require('./surveys/containers/SurveySolvePage')));

module.exports = {
    BatchNotify,
    PreapprovedEmailUpload,
    SurveysContainer,
    SurveysManagement,
    SurveysCreatePage,
    SurveysEditPage,
    SurveyReportPage,
    MySurveysPage,
    SurveySolvePage
};
