module.exports = (multiLineElement,inputAriaLabel) => {

    if (multiLineElement &&  multiLineElement.lastChild &&  multiLineElement.lastChild.lastChild){
        const hiddenChild = multiLineElement.lastChild.lastChild;

        hiddenChild.setAttribute('aria-label', inputAriaLabel);
    }


};
