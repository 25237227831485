const React = require('react');
const T = require('prop-types');

const { default: Classes } = require('./styles.scss');

module.exports = class AdminToolsPage extends React.PureComponent {

    static propTypes = {
        children: T.node.isRequired
    };

    render() {

        const { children } = this.props;

        return (
            <div className={Classes.wrapper}>
                <div className={Classes.innerWrapper}>
                    {children}
                </div>
            </div>
        );
    }
};
