
const History = require('../wiring/history');
const { context } = require('../app-context');

module.exports = (store) => {

    const actions = context.actions;
    const redux = context.redux.hooks;

    let previousPathname = '/';

    History.listen((location) => {

        redux.dispatch(actions.app.setHistoryPreviousPathname({ pathname: previousPathname }));
        const currentPathname = location.pathname;
        previousPathname = currentPathname;
        redux.dispatch(actions.app.setHistoryPathname({ pathname: currentPathname }));
    });
};
