const React = require('react');
const PropTypes = require('prop-types');
const { default: Classes } = require('./styles.scss');

module.exports = class AccessibilityContents extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string
    }

    render() {

        const { className } = this.props;

        return <div className={className || ''}>

            <div className={Classes.header}>
                <h2>ACCESSIBILITY STATEMENT</h2>
                <h3>Nearpeer For College</h3>
            </div>

            <div>
                <h3>Measures to support accessibility</h3>
                <p>Nearpeer takes the following measures to ensure accessibility of Nearpeer for College:</p>
                <ul>
                    <li>
                        Include accessibility throughout our internal policies.
                    </li>
                    <li>
                        Assign clear accessibility goals and responsibilities.
                    </li>
                </ul>
            </div>

            <div>
                <h3>Conformance status</h3>
                <p>The Web Content Accessibility Guidelines (WCAG) defines requirements for designers and developers to improve accessibility for people with disabilities.
                    <br/>Nearpeer for College conforms with WCAG 2.1 level AA standards.
                </p>
            </div>
            <div>
                <h3>Feedback</h3>
                <p>We welcome your feedback on the accessibility of Nearpeer for College. Please let us know if you encounter accessibility barriers on Nearpeer for College:</p>
                <ul>
                    <li>
                        E-mail: helpdesk@nearpeer.net
                    </li>
                    <li>
                        Postal Address: 63 Federal Street, Portland, ME 04101
                    </li>
                </ul>
                <p>We try to respond to feedback within 2 business days.</p>
            </div>
            <div>
                <h3>Technical specifications</h3>
                <p>Accessibility of Nearpeer for College relies on the following technologies to work with the particular combination of web browser and any assistive technologies or plugins installed on your computer:</p>
                <ul>
                    <li>
                        HTML
                    </li>
                    <li>
                        WAI-ARIA
                    </li>
                    <li>
                        CSS
                    </li>
                    <li>
                        JavaScript
                    </li>
                </ul>
                <p>These technologies are relied upon for conformance with the accessibility standards used.</p>
            </div>
            <div>
                <h3>Assessment approach</h3>
                <p>Nearpeer assessed the accessibility of Nearpeer for College by the following approaches:</p>
                <ul>
                    <li>
                        Self-evaluation
                    </li>
                    <li>
                        External evaluation
                    </li>

                </ul>
            </div>


        </div>;
    }
};
