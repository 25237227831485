module.exports = (str) => {

    switch (str) {
        case '':
        case 0:
        case '0':
        case null:
        case 'null':
        case false:
        case 'false':
        case undefined:
        case 'undefined':
            return true;
        default:
            return false;
    }

};

