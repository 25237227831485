const React = require('react');
const T = require('prop-types');
const Moment = require('moment');
const { default: Styled } = require('styled-components');
const KeyMirror = require('keymirror');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: Menu } = require('@mui/material/Menu');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: MuiCircularProgress } = require('@mui/material/CircularProgress');
const IconMenuButton = require('components/IconMenuButton');
const Copy = require('clipboard-copy');
const { default: FaceIcon } = require('@mui/icons-material/Face');
const { default: MoreIcon } = require('@mui/icons-material/MoreVert');
const { default: TextField } = require('@mui/material/TextField');
const { default: FlatButton } = require('@mui/material/Button');
const { NavLink: Link } = require('react-router-dom');
const ItemBody = require('./ItemBody');
const ChatUtils = require('./utils');
const { getSizedImageUrl } = require('utils/image');
const { default: Classes } = require('./styles.scss');
const NoUserProfilePic = require('components/NoUserProfilePic');
const FixMuiTextareaAutosizeAriaLabel = require('utils/fixMui4TextareaAutosizeAriaLabel');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const internals = {};
const modes = KeyMirror({
    VIEW: true,
    EDIT: true
});

module.exports = class ChatItem extends React.Component {

    static propTypes = {
        className: T.string,
        onSave: T.func.isRequired,
        onModerate: T.func.isRequired,
        onPin: T.func.isRequired,
        onRemove: T.func.isRequired,
        onRemoveOwn: T.func.isRequired,
        onFlagInappropriate: T.func.isRequired,
        updateAlertFunction: T.func,
        showNotification: T.func,
        rolesInteractions:T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool,
            canViewInGroup: T.bool,
            canChat: T.bool,
            canChatWithoutConnection: T.bool,
            canSeeConnections: T.bool,
            canSeeUsersGroups: T.bool,
            canSeeSurveyFields: T.bool,
            canSeeExtendedProfile: T.bool
        })),
        rolePermissions:T.shape({
            id: T.number,
            canPostInAnnouncement: T.bool,
            canPinMessage: T.bool,
            canDeleteMessages: T.bool
        }),
        showModerationControls: T.bool,
        message: T.shape({
            sid: T.string,
            id: T.string,
            channelSid: T.string,
            classId: T.number,
            conversationId: T.string,
            body: T.string.isRequired,
            timestamp: T.instanceOf(Date).isRequired,
            user: T.shape({
                id: T.oneOfType([T.string, T.number]).isRequired,
                firstName: T.string.isRequired,
                lastName: T.string.isRequired,
                croppedPicture: T.string.isRequired,
                isMe: T.bool,
                roleId: T.number,
                enabled: T.bool
            }),
            moderationStatus: T.oneOf(['moderated', 'ok']),
            isOptimistic: T.bool,
            isPinned: T.oneOf([false, true]),
            isEdited: T.oneOf([false, true])
        }),
        isAnnouncement: T.bool,
        hideMenu: T.bool
    }

    static minHeight = 83; // A one-line chat message is this tall

    constructor() {

        super();

        this.state = {
            mode: modes.VIEW,
            editBody: null,
            menuOpen: false,
            anchorEl:null
        };

        this.input = null; // ref

        this.beginEditMode = this._beginEditMode.bind(this);
        this.endEditMode = this._endEditMode.bind(this);
        this.onBodyChange = this._onBodyChange.bind(this);
        this.save = this._save.bind(this);
        this.deleteOwn = this._deleteOwn.bind(this);
        this.delete = this._delete.bind(this);
        this.flagInappropriate = this._flagInappropriate.bind(this);
        this.moderate = this._moderate.bind(this);
        this.unmoderate = this._unmoderate.bind(this);
        this.pin = this._pin.bind(this);
        this.unpin = this._unpin.bind(this);
        this.setInputRef = this._setInputRef.bind(this);
        this.onMenuRequestChange = this._onMenuRequestChange.bind(this);
        this.handleMenuOpen = this._handleMenuOpen.bind(this);
        this.handleMenuClose = this._handleMenuClose.bind(this);
        this.copyText = this._copyText.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.input){
            FixMuiTextareaAutosizeAriaLabel(this.input,'edit message element');
        }
    }

    _onMenuRequestChange(open, reason) {

        if (open) {
            this.setState({ menuOpen: true });
        }
        else {
            this.setState({ menuOpen: false });
        }
    }

    _handleMenuOpen(event){

        this.setState({ menuOpen: true,anchorEl: event.currentTarget });
    }

    _handleMenuClose(){

        this.setState({ menuOpen: false,anchorEl: null });
    }

    _beginEditMode() {

        this.setState({
            mode: modes.EDIT,
            editBody: ChatUtils.fullToEditBody(this.props.message.body)
        }, () => {

            setTimeout(() => {

                this.input.focus();
                this.handleMenuClose();
            }, 2); // Longer than clickCloseDelay
        });
    }

    _endEditMode() {

        this.setState({
            mode: modes.VIEW,
            editBody: null
        });
    }

    _onBodyChange(e) {

        this.setState({
            editBody: e.target.value
        });
    }

    _save() {

        // todo check edit mode

        if (this.props.isAnnouncement) {

            const { id: messageId, conversationId, classId } = this.props.message;

            this.props.onSave({
                messageId,
                conversationId,
                classId,
                editBody: this.state.editBody
            });

        }
        else {
            this.props.onSave({
                sid: this.props.message.sid,
                editBody: this.state.editBody
            });
        }

        this.endEditMode();
    }

    async _copyText() {

        const { message } = this.props;

        if (message && message.body) {
            const processMessage = ChatUtils.processFullBody({
                mention: ([name, id]) => {

                    return `@${name}`;
                }
            });

            const processedMessage = processMessage(message.body, true);
            const messageBody = [].concat(processedMessage).filter(Boolean).join('');

            await Copy(messageBody);

            this.props.showNotification('Message copied');
            this.handleMenuClose();
        }
    }

    _delete() {

        const { sid: messageSid, channelSid } = this.props.message;

        this.props.updateAlertFunction(() => {

            AnimatedFocusIndicator.onBlurHandler();
            this.props.onRemove({ channelSid, messageSid });

        },'Are you sure you\'d like to delete this message?','Delete Message');

        this.handleMenuClose();
    }

    _deleteOwn() {

        const { sid: messageSid, channelSid } = this.props.message;

        if (this.props.isAnnouncement) {

            const { id: messageId, conversationId,classId } = this.props.message;

            this.props.updateAlertFunction(() => {

                AnimatedFocusIndicator.onBlurHandler();
                this.props.onRemoveOwn({
                    conversationId: conversationId.toString(),
                    messageId: messageId.toString(),
                    classId: classId.toString()
                });

            }, 'Are you sure you\'d like to delete this message?','Delete Message');
        }
        else {

            this.props.updateAlertFunction(() => {

                this.props.onRemoveOwn({ channelSid,messageSid });

            },'Are you sure you\'d like to delete this message?','Delete Message');
        }

        this.handleMenuClose();
    }

    _flagInappropriate() {

        if (this.props.isAnnouncement){
            const { id: messageId,classId } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onFlagInappropriate({ id: messageId, classId });
            }, 'Are you sure you\'d like to flag this message as inappropriate?','Flag as Inappropriate');
        }
        else {
            this.props.updateAlertFunction(() => {

                this.props.onFlagInappropriate({ sid: this.props.message.sid });
            }, 'Are you sure you\'d like to flag this message as inappropriate?','Flag as Inappropriate');
        }

        this.handleMenuClose();
    }

    _moderate() {

        if (this.props.isAnnouncement){
            const { id: messageId, conversationId, classId } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onModerate({ status: 'moderated',classId, conversationId:conversationId.toString(), messageId:messageId.toString() });
            },'Are you sure you\'d like to moderate this message?','Moderate Message');
        }
        else {
            const { sid: messageSid, channelSid } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onModerate({ status: 'moderated', channelSid, messageSid });

            },'Are you sure you\'d like to moderate this message?','Moderate Message');
        }

        this.handleMenuClose();
    }

    _pin() {

        if (this.props.isAnnouncement) {
            const { id: messageId, conversationId,classId } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onPin({ isPinned: true,classId, conversationId:conversationId.toString(), messageId:messageId.toString() });
            }, 'Are you sure you\'d like to pin this message?','Pin Message');
        }
        else {
            const { sid: messageSid, channelSid } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onPin({ isPinned: true, channelSid, messageSid });
            }, 'Are you sure you\'d like to pin this message?','Pin Message');
        }

        this.handleMenuClose();
    }

    _unmoderate() {

        if (this.props.isAnnouncement) {
            const { id: messageId, conversationId,classId } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onModerate({ status: 'ok',classId, conversationId:conversationId.toString(), messageId:messageId.toString() });
            },'Are you sure you\'d like to unmoderate this message?','Unmoderate Message');
        }
        else {
            const { sid: messageSid, channelSid } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onModerate({ status: 'ok', channelSid, messageSid });

            },'Are you sure you\'d like to unmoderate this message?','Unmoderate Message');
        }

        this.handleMenuClose();
    }

    _unpin() {

        if (this.props.isAnnouncement) {
            const { id: messageId, conversationId,classId } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onPin({ isPinned: false, classId, conversationId:conversationId.toString(), messageId:messageId.toString() });
            }, 'Are you sure you\'d like to unpin this message?','Unpin Message');
        }
        else {
            const { sid: messageSid, channelSid } = this.props.message;

            this.props.updateAlertFunction(() => {

                this.props.onPin({ isPinned: false, channelSid, messageSid });

            }, 'Are you sure you\'d like to unpin this message?','Unpin Message');
        }

        this.handleMenuClose();
    }

    _setInputRef(input) {

        this.input = input;
    }

    render() {

        const { styles, CircularProgress } = internals;

        const {
            message,
            className,
            showModerationControls,
            rolesInteractions,
            rolePermissions,
            isAnnouncement,
            hideMenu
        } = this.props;

        const { menuOpen } = this.state;

        const user = message.user;
        const isMe = !!(user && user.isMe);
        const directionClass = isMe ? Classes.right : Classes.left;
        const isModerated = (message.moderationStatus === 'moderated');
        const isPinned = (message.isPinned === true);
        const isEdited = (message.isEdited === true);
        const firstLastName = user ? user.firstName + ' ' + user.lastName : '';

        let canViewUserProfile = false;

        if (user) {
            canViewUserProfile = rolesInteractions && rolesInteractions.find((roleInteraction) => {

                return roleInteraction.id === user.roleId;
            }).canViewProfile;
        }

        return <div className={`${Classes.chatItemWrapper} ${directionClass} ${className}`}>
            <div className={Classes.avatarWrapper}>
                {(user && !isMe && (!isModerated || showModerationControls)) && (canViewUserProfile ? (
                    <Link
                        to={`/app/profile/${user.id}`}
                        className={`${Classes.avatar} ${Classes.avatarLink}`}
                        data-focus-outline={`radius:40${isPinned ? '' : ',zIndex:1'}`}
                    >
                        {user.croppedPicture ?
                            <Avatar
                                className={Classes.avatarImg}
                                src={getSizedImageUrl(user.croppedPicture, 100)}
                                alt={firstLastName}
                                size={60}
                            /> :
                            <Avatar
                                className={Classes.avatarImg}
                                alt={firstLastName}
                                sizes={60}
                            >
                                <NoUserProfilePic iconColor={'#ffffff'} insideAvatar={true} outlined={false}  />
                            </Avatar>
                        }
                    </Link>) :
                    <a
                        className={`${Classes.avatar} ${Classes.avatarLink}`}
                        onClick={() => {

                            this.props.showNotification('Sorry, you cannot see this user\'s profile');
                        }}
                        data-focus-outline={`radius:40${isPinned ? '' : ',zIndex:1'}`}
                    >
                        {user.croppedPicture ?
                            <Avatar
                                className={Classes.avatarImg}
                                src={getSizedImageUrl(user.croppedPicture, 100)}
                                alt={firstLastName}
                                sizes={60}
                            /> :
                            <Avatar
                                className={Classes.avatarImg}
                                alt={firstLastName}
                                sizes={60}
                            >
                                <NoUserProfilePic iconColor={'#ffffff'} insideAvatar={true} outlined={false}  />
                            </Avatar>
                        }
                    </a>)}
                {user ? ((isMe || (isModerated && !showModerationControls)) && (
                    <Link
                        to={user ? `/app/profile/${user.id}` : ''}
                        className={`${Classes.avatar} ${Classes.avatarLink}`}
                        data-focus-outline={`radius:40${isPinned ? '' : ',zIndex:1'}`}
                    >
                        {isMe && user.croppedPicture ?
                            <Avatar
                                className={Classes.avatarImg}
                                src={(user && isMe) && getSizedImageUrl(user.croppedPicture, 100)}
                                alt={firstLastName}
                                icon={(!user || !isMe) ? <FaceIcon /> : null}
                                sizes={60}
                            /> :
                            <Avatar
                                className={Classes.avatarImg}
                                alt={firstLastName}
                                sizes={60}
                            >
                                <NoUserProfilePic iconColor={'#ffffff'} insideAvatar={true} outlined={false}  />
                            </Avatar>}
                    </Link>
                )) : <a
                    className={`${Classes.avatar} ${Classes.avatarLink} ${Classes.linkDisabled}`}
                    data-focus-outline={`radius:40${isPinned ? '' : ',zIndex:1'}`}
                >
                    {<Avatar
                        className={Classes.avatarImg}
                        alt={firstLastName}
                        sizes={60}
                    >
                        <NoUserProfilePic iconColor={'#ffffff'} insideAvatar={true} outlined={false}  />
                    </Avatar>}
                </a>}
                {!isMe && <div className={Classes.initials}>
                    {(user && (!isModerated || showModerationControls)) ? internals.initials(user.firstName, user.lastName) : ' '}
                </div>}
                {user && !hideMenu && (!isAnnouncement || rolePermissions.canPostInAnnouncement) && <React.Fragment>
                    {message.isOptimistic && <CircularProgress size={15} />}
                    {!message.isOptimistic && <IconMenuButton
                        aria-label='flag message'
                        aria-expanded={menuOpen}
                        style={!isMe ? styles.menuIcon : styles.menuIconRight}
                        onClick={this.handleMenuOpen}
                        data-focus-outline={`radius:20,padding:2${isPinned ? '' : ',zIndex:1'}`}
                    >
                        <MoreIcon />
                    </IconMenuButton>}
                    <Menu
                        className={className}
                        anchorEl={this.state.anchorEl}
                        open={menuOpen}
                        onClose={this.handleMenuClose}
                    >
                        <MenuItem className={Classes.menuItem} onClick={this.copyText}><span >Copy message</span></MenuItem>
                        {user && !isMe &&  <MenuItem className={Classes.menuItem} onClick={this.flagInappropriate}>Flag as inappropriate</MenuItem> }
                        {!isMe && showModerationControls && !isModerated &&
                            <MenuItem className={Classes.menuItem} onClick={this.moderate}><span style={styles.deleteText}>Place in moderation</span></MenuItem>
                        }
                        {!isMe && showModerationControls && isModerated &&
                            <MenuItem className={Classes.menuItem} onClick={this.unmoderate}><span style={styles.deleteText}>Remove from moderation</span></MenuItem>
                        }
                        {rolePermissions.canPinMessage && !isPinned &&
                            <MenuItem className={Classes.menuItem} onClick={this.pin}><span >Pin message</span></MenuItem>
                        }
                        {rolePermissions.canPinMessage && isPinned &&
                            <MenuItem className={Classes.menuItem} onClick={this.unpin}><span >Unpin message</span></MenuItem>
                        }
                        {isMe && !isModerated && <MenuItem className={Classes.menuItem} onClick={this.beginEditMode}>Edit</MenuItem>}
                        {isMe && !isModerated && <MenuItem className={Classes.menuItem} onClick={this.deleteOwn}><span style={styles.deleteText}>Delete</span></MenuItem>}
                        {showModerationControls && !isAnnouncement && isModerated && rolePermissions.canDeleteMessages && <MenuItem className={Classes.menuItem} onClick={this.delete}><span style={styles.deleteText}>Delete</span></MenuItem>}
                    </Menu>
                </React.Fragment>}
            </div>
            <div className={Classes.talkBubble}>
                <div className={Classes.chatItemInfo}>
                    <b className={`${Classes.name} ${isModerated ? Classes.isModerated : ' '}`}>
                        {(user && (isMe || !isModerated || showModerationControls)) ? `${user.firstName} ${user.lastName}` : '[Unknown User]'}
                        &nbsp;
                        <span className={`${(user && !user.enabled) ? Classes.userDisabled : ''}`} />
                    </b>
                    <div className={Classes.timestamp}>
                        {Moment(message.timestamp).calendar(null, {
                            lastDay : '[Yesterday] [at] LT',
                            sameDay : '[Today] [at] LT',
                            nextDay : '[Tomorrow]',
                            lastWeek : 'dddd [at] LT',
                            nextWeek : 'dddd',
                            sameElse : 'MM/DD/YYYY [at] LT'
                        })}
                    </div>
                </div>

                <div className={Classes.talkText}>
                    {(this.state.mode === modes.VIEW) && <p>
                        <ItemBody
                            container={<span style={{ whiteSpace:'pre-line' }} className={isModerated ? Classes.isModerated : ''} />}
                        >
                            {isModerated ? '(Content is pending moderation review)' : null}
                            {(isMe || !isModerated || showModerationControls) && message.body}
                        </ItemBody>
                    </p>}
                    {(this.state.mode === modes.EDIT) && <div>
                        <TextField
                            id={`edit-message-${message.sid}`}
                            ref={this.setInputRef}
                            textareaStyle={styles.textarea}
                            value={this.state.editBody}
                            inputProps={{ 'aria-label': 'edit message input' }}
                            multiline={true}
                            fullWidth={true}
                            maxRows={3}
                            onChange={this.onBodyChange}
                        />
                        <div>
                            <FlatButton
                                color={'primary'}
                                style={styles.saveButton}
                                onClick={this.save}
                            >
                                Save
                            </FlatButton>
                            <FlatButton
                                color={'secondary'}
                                style={styles.cancelButton}
                                onClick={this.endEditMode}
                            >
                                Cancel
                            </FlatButton>
                        </div>
                    </div>}
                </div>
                {isEdited ? <div className={Classes.editStatus}>(edited)</div> : null}
            </div>
        </div>;
    }
};

internals.initials = (firstName, lastName) => {

    const parts = (`${firstName} ${lastName}`).split(' ').filter((part) => part.length > 0);
    const letters = parts.map((part) => part[0].toUpperCase());

    return letters.join('');
};

internals.styles = {
    menuIcon: {
        padding: 0,
        height: 24,
        width: 24,
        right: -16,
        top:-8,
        backgroundColor:'#f3f3f3',
        position:'absolute'
    },
    menuIconRight: {
        padding: 0,
        height: 24,
        width: 24,
        left: -16,
        top:-8,
        backgroundColor:'#d7f4ff',
        position:'absolute'
    },
    deleteText: {
        color: '#C00'
    },
    textarea: {
        background: 'white',
        border: '1px solid grey',
        lineHeight: '18px',
        fontSize: 16
    },
    saveButton: {
        zIndex: 0,
        height: 28,
        lineHeight: '28px',
        minWidth: 0,
        backgroundColor: 'white',
        marginRight: 4
    },
    cancelButton: {
        zIndex: 0,
        height: 28,
        lineHeight: '28px',
        minWidth: 0,
        backgroundColor: 'white'
    }
};

internals.CircularProgress = Styled(MuiCircularProgress)`
    position: absolute;
    width: 15px;
    height: 15px;
    right: 66px;
    top: -5px;
`;
