const { safeWarning } = require('assertions-simplified');
const Delay = require('../utils/delay');

module.exports = (context) => {

    // CAUTION: Delay is required to avoid unread messages tag glitch on send message
    const MESSAGE_REFRESH_DELAY__IN_MILLIS = 200;

    const twilio = context.api.twilio;
    const actions = context.actions;
    const selectors = context.selectors.all;
    const redux = context.redux.hooks;

    let listeningStarted = false;
    let currentChannelSid = null;

    const refreshMessages = () => Promise.resolve(currentChannelSid && redux.dispatch(actions.dataFetching.fetchMessages.firstPage({ channelSid: currentChannelSid })) || undefined);
    const refreshUnreadCounts = () => redux.dispatch(actions.dataFetching.fetchUnreadMessageCounts());
    const refreshMessageCounts = () => redux.dispatch(actions.dataFetching.fetchMessageCounts());
    const refreshUpdateDates = () => redux.dispatch(actions.dataFetching.fetchChannelUpdateDates());
    const refreshLastMessages = () => redux.dispatch(actions.dataFetching.fetchChannelLastMessage());
    const updateMessage = (results) => redux.dispatch(actions.dataFetching.subscribeMessages.update(results));
    const removeMessage = ({ result: sid }) => redux.dispatch(actions.dataFetching.subscribeMessages.remove({ channelSid: currentChannelSid, sid }));
    const fetchChannel = () => redux.dispatch(actions.dataFetching.fetchChannel({ channelSid: currentChannelSid }));
    const fetchChannels = () => redux.dispatch(actions.dataFetching.fetchChannels());

    const listeners = {
        onMessageAdded: async (results) => {

            await Delay(MESSAGE_REFRESH_DELAY__IN_MILLIS);

            await Promise.all([
                refreshMessages(),
                refreshUnreadCounts(),
                refreshLastMessages(),
                refreshUpdateDates()
            ]);

            const { result: messageSid, entities: { messages } } = results;

            const message = messages[messageSid];

            if (message.author === selectors.getCurrentUserId(redux.getState()) &&
                    message.body.indexOf('{{mention(') !== -1) {

                return redux.dispatch(actions.communication.mention({
                    messageSid,
                    channelSid: message.channel
                }));
            }
        },
        onMessageUpdated: updateMessage,
        onMessageRemoved: (results) => Promise.resolve(results).then(removeMessage).then(() => Delay(MESSAGE_REFRESH_DELAY__IN_MILLIS)).then(refreshUnreadCounts).then(refreshMessageCounts)
    };

    return {
        startGlobalListening: () => {

            if (listeningStarted) {
                return Promise.resolve();
            }

            listeningStarted = true;

            return twilio.startListeningMessages({ listeners }).then(Promise.all([
                fetchChannels(),
                refreshUnreadCounts(),
                refreshMessageCounts(),
                refreshLastMessages(),
                refreshUpdateDates()
            ]));
        },
        stopGlobalListening: () => {

            if (!listeningStarted) {
                return Promise.resolve();
            }

            return twilio.stopListeningMessages().then(() => {

                listeningStarted = false;
            });
        },
        startListening: ({ channelSid }) => {

            currentChannelSid = channelSid;

            return Promise.all([
                fetchChannel(),
                refreshMessages()
            ]);
        },
        stopListening: () => {

            if (!currentChannelSid) {
                return Promise.resolve();
            }

            currentChannelSid = null;
            return Promise.resolve();
        },
        getCurrentChannelSid: () => {

            return currentChannelSid;
        }
    };
};
