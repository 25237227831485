const { useState, useEffect } = require('react');
const { useTheme } = require('@mui/material/styles');

exports.useBadgeInvisibleState = function useBadgeInvisibleState(badgeNumber, delay) {

    const [isBadgeVisible, setIsBadgeVisible] = useState(false);

    const { transitions } = useTheme();

    // Toggling the 'invisible' prop on a badge
    // gives us a nice animation, so let's use it!
    useEffect(() => {

        const badgeAnimTimer = setTimeout(() => {

            setIsBadgeVisible(true);
        }, delay || transitions.duration.standard);

        return () => clearTimeout(badgeAnimTimer);
    }, [delay, transitions.duration.standard]);

    return badgeNumber === 0 ? true : !isBadgeVisible;
};
