const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Menu } = require('@mui/material/Menu');
const { default: MenuItem } = require('@mui/material/MenuItem');
const IconMenuButton = require('components/IconMenuButton');
const { default: HamburgerIcon } = require('@mui/icons-material/Menu');

const { default: Classes } = require('./styles.scss');

const internals = {};

module.exports = class MaterialMenu extends React.PureComponent {

    static propTypes = {
        menuOptions: T.arrayOf(T.shape({
            name: T.string,
            action: T.func,
            props: T.object
        })),
        iconElement: T.any,
        anchorOrigin: T.object,
        buttonRef: T.any
    }

    constructor() {

        super();

        this.state = {
            menuOpen: false,
            anchorEl: null
        };

        this.handleClose = this._handleClose.bind(this);
        this.handleClick = this._handleClick.bind(this);
    }

    _handleClick(event){

        this.setState({ menuOpen: true, anchorEl: event.currentTarget });
    }

    _handleClose(){

        this.setState({ menuOpen: false,anchorEl: null });
    }

    render() {

        const { menuOptions, iconElement, anchorOrigin, buttonRef } = this.props;
        const { menuOpen, anchorEl } = this.state;
        const { ButtonIconContainer } = internals;

        if (menuOptions.length === 0) {
            return null;
        }

        return (
            <>
                {/* TODO Band-aid for now – we should refactor later */}
                {iconElement && (
                    <ButtonIconContainer onClick={this.handleClick} ref={buttonRef}>
                        {iconElement}
                    </ButtonIconContainer>
                )}
                {!iconElement && (
                    <IconMenuButton
                        focusVisibleClassName={Classes.iconBtnFocusVisible}
                        aria-expanded={menuOpen}
                        onClick={this.handleClick}
                        ref={buttonRef}
                        aria-label='options'
                        aria-haspopup='true'
                    >
                        <HamburgerIcon />
                    </IconMenuButton>
                )}
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={anchorOrigin || { horizontal: 'left' }}
                    open={menuOpen}
                    onClose={this.handleClose}
                >
                    {menuOptions.map((menuItem, i) => {

                        return <MenuItem
                            key={menuItem.name + i}
                            onClick={() => {

                                menuItem.action();
                                this.handleClose();
                            }}
                            {...menuItem.props}
                        >{menuItem.name}</MenuItem>;
                    })}
                </Menu>
            </>
        );
    }
};

internals.ButtonIconContainer = Styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
`;
