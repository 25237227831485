const React = require('react');
const { default: Classes } = require('./styles.scss');
const T = require('prop-types');

module.exports = function OfflineIndicator(props) {

    const { isOffline } = props;

    return <span>
        <div
            tabIndex={-1}
            className={`${Classes.offlineBanner} ${isOffline && Classes.currentlyOffline}`}
        >
            {isOffline && 'Currently Offline'}
        </div>
        {isOffline && <div role='status' aria-live='assertive' aria-atomic='true' className='visually-hidden'>Currently Offline</div>}
    </span>;
};

module.exports.propTypes = {
    isOffline: T.bool.isRequired
};
