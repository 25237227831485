const React = require('react');
const T = require('prop-types');

const { default: Classes } = require('./styles.scss');


const SurveysContainer = (props) => {

    const { children } = props;

    return (
        <div className={Classes.wrapper}>
            <div className={Classes.innerWrapper}>
                {children}
            </div>
        </div>
    );
};

SurveysContainer.propTypes = {
    children: T.node.isRequired
};

module.exports = SurveysContainer;
