const React = require('react');
const PropTypes = require('prop-types');
const { default: Classes } = require('./styles.scss');
const { NavLink: Link } = require('react-router-dom');

module.exports = class TosContents extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string
    }

    render() {

        const { className } = this.props;

        return <div className={`${Classes.tosContents} ${className || ''}`}>

            <div className={Classes.header}>
                <h2>Terms of Service</h2>
                <h3>(Last Updated August 7, 2017)</h3>
            </div>

            <p>
                Welcome to Nearpeer! Nearpeer is a proprietary social networking, communication and collaboration software platform (the “<b>Platform</b>”) designed to bring together constituents within learning communities, including students and staff, created and maintained by Nearpeer, Inc., a Delaware corporation (“<b>we</b>,” “<b>us</b>,” or “<b>our</b>”). We offer students and staff members of learning communities access as are our clients (such clients, “<b>Communities</b>” and such students and staff, <b>“Members</b>”) access to, and use of the, Platform through the website and associated domains of <Link to='/' data-focus-outline='padding:3,radius:5'>https://nearpeer.net</Link> (our provision of such access and use, the “<b>Services</b>”).
            </p>
            <p>
                These Terms of Service (these “<b>Terms</b>”) explain your obligations to us and our obligations to you with respect to our provision of, and your use of, the Services. These Terms are a legally binding agreement between you and us; please read these Terms carefully and save a copy of it for your records.
            </p>

            <ol>
                <li>
                    <b>YOUR ACCESS TO, AND USE OF, PLATFORM AND OUR OBLIGATIONS WITH RESPECT TO THE SERVICES ARE EXPRESSLY CONDITIONED ON YOUR ACCEPTANCE OF THESE TERMS.</b>
                    <p>
                        By clicking the “<b><u>Save Password</u></b>” button or accessing the Platform, you agree effective as of such date (the “Effective Date”), to be bound by these Terms, and you represent and warrant to us that you: (i) are at least 18 years of age or possess legal parental or guardian consent to enter into these Terms; (ii) are not named on any U.S. government list of persons or entities prohibited from receiving exports; and (iii) are not a resident of, or otherwise domiciled in, any countries set forth on the State Sponsored Terrorism List promulgated by the U.S. government (which currently includes Iran, Sudan and Syria).
                    </p>
                </li>
                <li>
                    <b>Our Privacy Policy Further Describes Our Collection and Use of Your Data</b>
                    <p>
                        Your privacy is very important to us. Please review our Privacy Policy <Link to='/privacy-policy' data-focus-outline='padding:3,radius:5'>here</Link>, to understand our practices. This privacy policies also governs your use of the Services.
                    </p>
                </li>
                <li>
                    <b>Our Provision of the Services is Governed by, and Your Use of the Services is Conditioned on, These Terms</b>
                    <p>
                        Subject to the terms and conditions of these Terms, we shall use commercially reasonable efforts to provide you the Services. Without limiting the foregoing, our provision of the Services, including access to and use of the Platform, is strictly conditioned upon your full compliance with these Terms and all applicable laws, rules and regulations.
                    </p>
                </li>
                <li>
                    <b>We May Require You to Create an Account Using Your “.edu” Email Address to Access and Use the Services</b>
                    <p>
                        To access and use the Services and create a profile, you must register to create an account with us (a “<b>Nearpeer Account</b>”). You may not have more than one active Nearpeer Account at any given time. Because the Services are intended solely for Community Members, we may require that Nearpeer Accounts be created using a Community-granted “.edu” email address. Any access to, or use of, the Services by anyone who is not a Community Member is expressly prohibited, and you hereby represent and warrant that you are a current Community Member.
                    </p>
                </li>
                <li>
                    <b>You Must Provide Us and Maintain Accurate Account Information</b>
                    <p>
                        You represent and warrant (i) all information you provide us about you in creating your Nearpeer Account (such information, “<b>Account Information</b>”) is complete, true and current and accurately reflected in your user profile, and (ii) you have all necessary rights in and to the “.edu” email address to create a Nearpeer Account. If a change occurs such that Account Information is no longer complete, true, current or accurate, you shall, promptly inform us of such change in writing.
                    </p>
                </li>
                <li>
                    <b>You Consent to Receive Commercial Messages</b>
                    <p>
                        We may need make certain commercial offers available to you from time to time. AS SUCH, YOU HEREBY CONSENT TO RECEIVE COMMERCIAL E-MAIL, FAXES AND OTHER MESSAGES FROM US AND/OR OUR PARTNERS, AND YOU HEREBY ACKNOWLEDGES AND AGREES THAT YOUR ACCOUNT INFORMATION MAY BE USED FOR THE PURPOSE OF INITIATING COMMERCIAL E-MAIL, FAXES AND OTHER MESSAGES.  You may opt out of receiving some of these messages, by notifying us in writing; however, to stop receiving all messages from us whatsoever (including administrative messages regarding the Services), you must delete your Nearpeer Account and discontinue use of the Services.
                    </p>
                </li>
                <li>
                    <b>You are Responsible for Certain Information and Obligations Relating to the Services</b>
                    <p>
                        You are solely responsible for (i) securing, and maintaining the confidentiality of, any your Account Information, passwords and files, (ii) obtaining and maintaining—both the functionality and security of—any equipment and ancillary services needed to connect to, access or otherwise use the Platform, including hardware, software, operating systems, networking and the like, (iii) backing-up your Data (defined below). You hereby acknowledge you will not have access to your Data through the Platform following the expiration or termination of these Terms.
                    </p>
                </li>
                <li>
                    <b>You are Prohibited from Certain Actions</b>
                    <p>
                        <p>You shall not, nor permit or encourage any third party to:</p>
                        <ol type='i'>
                            <li>
                                <div><p>directly, or indirectly:</p></div>
                                <ol type='a'>
                                    <li>
                                        reverse engineer, decompile, disassemble or otherwise attempt to discover or derive the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Platform or any software, documentation or Data (defined below) related to the Platform (“<b>Software</b>”);
                                    </li>
                                    <li>
                                        modify, translate, or create derivative works based on the Platform or any Software;
                                    </li>
                                    <li>
                                        use the Platform or any Software for timesharing or service bureau purposes or other computer service to a third party;
                                    </li>
                                    <li>
                                        modify, remove or obstruct any proprietary notices or labels;
                                    </li>
                                    <li>
                                        use any Software or the Platform in any manner to assist or take part in the development, marketing or sale of a product potentially competitive with any Software or the Platform;
                                    </li>
                                    <li>
                                        use the Services for any commercial or other purposes that are not expressly permitted by these Terms;
                                    </li>
                                    <li>
                                        attempt to probe, scan, or test the vulnerability of any our system or network or breach any security or authentication measures;
                                    </li>
                                    <li>
                                        avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by us or any of our providers or any other third party (including another user) to protect the Platform or Services or Collective Content;
                                    </li>
                                    <li>
                                        interfere with or damage the Platform or Services, including, without limitation, through the use of cancel bots, flood pings, denial-of-service attacks, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology; or
                                    </li>
                                    <li>
                                        systematically retrieve data or other content from our Platform to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <div><p>upload, post, publish, submit or transmit (collectively, “<b>Submit</b>”) any text, graphic, content, materials, information or other data (“<b>Data</b>”) to the Platform or otherwise cause any Data to be proceed by the Platform that:</p></div>
                                <ol type='a'>
                                    <li>
                                        is defamatory, harmful to minors, obscene, indecent, pornographic, libelous, threatening, harassing, false, misleading or inaccurate;
                                    </li>
                                    <li>
                                        contains or causes to be placed on our or other third party’s systems any Trojan horses, worms, viruses or programming routines intended to interfere, damage, corrupt, surreptitiously intercept or expropriate any system, Data or personal information;
                                    </li>
                                    <li>
                                        violates any applicable local, state, federal or foreign law, rule or regulation, including privacy laws and privacy standards;
                                    </li>
                                    <li>
                                        impersonates any person or entity, or falsifies or otherwise misrepresents yourself or your affiliation with any person or entity; or
                                    </li>
                                    <li>
                                        contains any information that does or can identify a specific individual, or by or from which a specific individual may be identified, contacted or located, including, without limitation, “nonpublic personal information” as defined under the Gramm-Leach-Bliley Act, “protected health information” as defined under HIPAA, “personal information” as defined under COPPA.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </p>
                </li>
                <li>
                    <b>We May Use Your Data in Connection with the Services</b>
                    <p>
                        Subject to Sections 10 and 11, as between us and you, you own all right, title, and interest in and to any Data that (i) is collected by us from you in connection with your use of the Services, or (ii) that you Submit to the Platform (collectively, “<b>your Data</b>”). To the fullest extent permissible under applicable law, you hereby grant to us a perpetual non-exclusive, transferable, sublicensable, royalty free world-wide license to use your Data (I) to provide the Services, and (II) as necessary to monitor and improve the Platform. Furthermore, you hereby represent and warrant that you own or otherwise have all rights necessary to grant the licenses relating to your Data herein.
                    </p>
                </li>
                <li>
                    <b>We May Use and We Own Certain Blind Data</b>
                    <p>
                        You hereby grant to us a perpetual, non-exclusive, transferable, sublicensable, royalty free world-wide license to use your Data to collect, develop, create, extract or otherwise generate statistics and other information and to otherwise compile, synthesize and analyze such Data (“<b>Blind Data</b>”). Notwithstanding anything in these Terms to the contrary, to the extent we collect or generate Blind Data, such Blind Data will be owned solely by us and may be used for any lawful business purpose without a duty of accounting or obligation.
                    </p>
                </li>
                <li>
                    <b>We Own All Rights in the Platform, Software and Certain Data</b>
                    <p>
                        You agree and acknowledge that, as between you and us, we shall own and retain all right, title and interest in and to (i) the Platform, the Services and Software and all improvements, enhancements and modifications thereto, (ii) Blind Data, and other material, that is anonymized, de-identified, or otherwise rendered not reasonably associated or linked to you or any other identifiable individual person or entity, (iii) comments, suggestions, or other feedback regarding the Services or the Platform Submitted by you to us, and (iv) all intellectual property and proprietary rights in and related to any of the foregoing (collectively, “<b>Nearpeer IP</b>”). To the extent you have or acquire any right, title or interest in any Nearpeer IP, you hereby assign all such right, title and interest in such Nearpeer IP to us.
                    </p>
                </li>
                <li>
                    <b>You Will Defend Us Against Certain Claims</b>
                    <p>
                        You shall defend us and hold us harmless against any claim, suit, demand, or action made or brought against us (i) alleging that your use of the Platform in breach of these Terms infringes, misappropriates, dilutes or otherwise violates any individual’s or entity’s intellectual property rights, (ii) relating to or arising from our use of your Data as permitted by these Terms, or (iii) relating to or arising from your breach of Section 8. In addition, you shall indemnify us from any damages, losses, liabilities, costs and fees (including reasonable attorney’s fees) finally awarded against us in connection with or in settlement of any such claim, suit, demand, or action.
                    </p>
                </li>
                <li>
                    <b>WE DISCLAIM ALL WARRANTIES NOT EXPRESSLY STATED HEREIN</b>
                    <p>
                        EXCEPT AS EXPRESSLY PROVIDED HEREIN, (i) WE DO NOT WARRANT THAT PROVISION OF THE SERVICES, INCLUDING, WITHOUT LIMITATION, ACCESS TO AND USE OF THE PLATFORM, WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT THE PLATFORM OR PROVISION OF THE SERVICES will produce any particular result or any solution to YOUR particular needs, and (ii) THE SERVICES, PLATFORM and SOFTWARE ARE PROVIDED “AS IS,” AND WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, IN EACH CASE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                    </p>
                </li>
                <li>
                    <b>We Make No Representations That the Platform Will Always be Available, Properly Functioning or Secure</b>
                    <p>
                        Without limiting the generality of Section 14, we make no representation about the Platform’s uptime, or the Platform’s availability or permissibility in any particular location. Scheduled system maintenance may take place from time to time, and emergency maintenance may be required at other times, and, in each case, during such times, the Platform may be unavailable. Use of the Platform is void where prohibited. Your use of the Platform is at your own initiative and you are responsible for compliance with any applicable laws in connection with such use. Additionally, except as explicitly set forth herein, the Services are not designed with the security standards necessary for sending confidential information, and should not be used to communicate confidential information.
                    </p>
                </li>
                <li>
                    <b>The Platform and Services Utilize Third Party Materials</b>
                    <p>
                        The Platform may display, include, or make available Data from third parties, including, without limitation, other users of the Services (collectively, “<b>Third-Party Materials</b>”), or provide links to third-party websites. You acknowledge and agree that we are not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect of such Third-Party Materials or websites. Without limiting the generality of Section 14 of these Terms, we do not warrant or endorse, and will not have any liability or responsibility to you or any other person for, any Third-Party Materials.
                    </p>
                </li>
                <li>
                    <b>WE ARE NOT LIABLE FOR CERTAIN DAMAGES AND LIABILITIES AND WE HAVE MAXIMUM LIABILITY HEREUNDER</b>
                    <p>
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THESE TERMS, IN NO EVENT WILL (i) WE, OUR SUPPLIERS OR VENDORS, OR OUR OR THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, AFFILIATES, SUPPLIERS, VENDORS, LICENSORS OR PARTNERS (COLLECTIVELY, “NEARPEER PARTIES”) INCUR LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, EXCEEDING, IN THE AGGREGATE, $500 (SUCH AMOUNT BEING INTENDED AS A CUMULATIVE CAP AND NOT PER INCIDENT), AND (ii) ANY NEARPEER PARTY HAVE ANY LIABILITY TO YOU FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, COVER, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT SUCH NEARPEER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS AND DISCLAIMERS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                    </p>
                </li>
                <li>
                    <b>Term and Termination</b>
                    <p>
                        Unless earlier terminated as provided herein, these Terms are effective beginning on the Effective Date and will continue until you delete your Nearpeer Account or are no longer a Community Member.
                    </p>
                    <p>
                        We may, without limiting any other available remedy, terminate these Terms, and/or suspend provision of Services to you at any time and without notice if we believe, or receive a claim that, you are in violation of any section of these Terms, including, without limitation, the restrictions on Platform use set forth in Section 8.
                    </p>
                    <p>
                        Upon termination of these Terms, you must immediately cease all use of and all access to the Platform. Sections 10–24 of these Terms shall survive any termination or expiration of these Terms. All other rights and obligations shall be of no further force or effect.
                    </p>
                </li>
                <li>
                    <b>These Terms are Governed by the Laws of The State of Delaware</b>
                    <p>
                        These Terms are governed in all respects by the laws of the State of Delaware, without giving effect to its rules relating to conflict of laws. Neither any adoption of the Uniform Computer Information Transactions Act nor the U.N. Convention on the International Sale of Goods applies to these Terms or to the rights or duties of the parties hereunder.
                    </p>
                </li>
                <li>
                    <b>Disputes Relating to these Terms Will Be Resolved Through Binding Arbitration</b>
                    <p>
                        Any dispute arising out of or relating to these Terms, or its subject matter, shall be resolved exclusively by binding arbitration under the Commercial Arbitration Rules of the American Arbitration Association (“<b>AAA</b>”). The arbitration will be conducted in Portland, Maine by a single arbitrator knowledgeable in the commercial aspects of “software as a service” or “platform as a service” arrangements and intellectual property. The arbitrator will provide detailed written explanations to the parties to support his/her award and regardless of outcome, each party shall pay its own costs and expenses (including attorneys’ fees) associated with the arbitration proceeding and fifty percent (50%) of the fees of the arbitrator and the AAA. The arbitration award will be final and binding and may be enforced in any court of competent jurisdiction.
                    </p>
                </li>
                <li>
                    <b>We are Not Responsible for Things We Cannot Control</b>
                    <p>
                        Without limiting the generality of Section 14, the Platform and the Services rely on third party technology and services such as web hosting services. Any change to the products or services offered by any of these third-party providers may materially and adversely affect, or entirely disable, your use of or access to the Platform. Likewise, we cannot guarantee that your Data hosted on a third-party server will remain secure. You hereby acknowledge that we cannot offer any additional or modified security procedures other than those put in place by our hosting service provider(s); however, we shall provide you with information regarding the hosting service provider’s security practices upon request made to support@nearpeet.net.
                    </p>
                </li>
                <li>
                    <b>Assigning These Terms</b>
                    <p>
                        Neither party may assign any of its rights or delegate any of its obligations hereunder, whether by operation of law, change of control or otherwise, without the prior written consent of the other party (not to be unreasonably withheld). Notwithstanding the foregoing, we may assign these Terms in its entirety, without your consent in connection with any merger, acquisition, corporate reorganization, or sale of all or substantially all of our assets. Additionally, we may delegate any of its duties or assign any of its rights hereunder to a Nearpeer Party without notice to you, or your consent. Any assignment or delegation in violation of this Section shall be void. Subject to the foregoing, these Terms shall bind and inure to the benefit of the parties, their respective successors and permitted assigns.
                    </p>
                </li>
                <li>
                    <b>DMCA Notice</b>
                    <p>
                        If you believe that your work has been copied and posted on the Platform in a way that constitutes copyright infringement, please send our copyright agent a notice of infringement with the following information: (i) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright; (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description of where the material that you claim is infringing is located on the Platform; (iv) your address, telephone number, and primary email address; (v) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner{'\''}s behalf. You may file a notice of infringement with our designated Copyright Agent.
                    </p>
                    <p>
                        It is our policy to respond to notices of alleged copyright infringement that comply with the requirements of the Digital Millennium Copyright Act (“<b>DMCA</b>”). Our designated Copyright Agent to receive DMCA communications (including notices and counter-notices of alleged infringement) is: Nearpeer, Inc. Attn: Copyright Agent, 63 Federal Street, Portland, ME 04101, Email: legal@nearpeer.net, Phone: 207-615-0414.
                    </p>
                    <p>
                        Upon receipt of a proper DMCA notice of infringement, we will promptly remove or disable access to the content that is the subject of the notice. It is our policy in appropriate circumstances to suspend, disable or terminate, without notice and in our sole discretion, the accounts of those users that we determine to be “repeat infringers.”
                    </p>
                </li>
                <li>
                    <b>General Terms of These Terms</b>
                    <p>
                        In connection with these Terms, each party is an independent contractor and as such will not have any authority to bind or commit the other.  Nothing herein shall be deemed or construed to create a joint venture, partnership, fiduciary or agency relationship between the parties for any purpose.
                    </p>
                    <p>
                        If any provision of these Terms is held to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.
                    </p>
                    <p>
                        These Terms, together with our Privacy Policy, is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms.
                    </p>
                    <p>
                        Any modification to these Terms by us shall be effective after we first notify you of the modification (via an update delivered through the Platform or the Site or via email or other notification) and you signify its acceptance via acknowledgement or continued use of any applicable Services for a reasonable time.
                    </p>
                    <p>
                        The heading references herein are for convenience purposes only and shall not be deemed to limit or affect any of the provisions hereof. Unless otherwise indicated to the contrary herein by the context or use thereof: (i) the words “hereof,” “hereby,” “herein,” “hereto,” and “hereunder” and words of similar import shall refer to these Terms as a whole and not to any particular Section or paragraph of these Terms; (ii) the words “include,” “includes” or “including” are deemed to be followed by the words “without limitation;” (iii) references to a “Section” are references to a section of these Terms; and (iv) derivative forms of defined terms will have correlative meanings.
                    </p>
                    <p>
                        All notices required under or otherwise sent relating to these Terms will be in writing and, if to us shall be delivered to legal@nearpeer.net, and if to you shall be delivered to the email address associated with your Nearpeer Account; provided, however, any notice from you to us required by, arising out of, or related to Sections 12 or 13 shall be delivered via certified mail, return receipt requested, to: Nearpeer, Inc., 63 Federal Street, Portland, ME 04101, Attention: Legal. Notice will be deemed to have been duly given when received when receipt is electronically confirmed.
                    </p>
                </li>
            </ol>
        </div>;
    }
};
