const { connect } = require('react-redux');
const { context } = require('app-context');
const WelcomePage = require('../components/WelcomePage');
const { safeWarning } = require('assertions-simplified');
const { push: Push } = require('connected-react-router');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        showClassesHelperPopup: selectors.getClassesCount_forCurrentUser(state) === 0,
        signupCompleted: selectors.getSignupCompleted(state),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        schoolImg: selectors.getCurrentSchoolWelcomeImgSrc(state),
        schoolWelcomeText: selectors.getCurrentUserSchoolHomeText(state),
        schoolRoleWelcomeImgUrl: selectors.getSchoolRoleWelcomeImgUrl(state),
        isAvailableForAppReview: selectors.getUserReviewAvailableStatus(state),
        currentUser: selectors.getCurrentUserObject(state)
    }),
    {
        // action props
        onSubmitAppReviewStatus: (status) => (dispatch) => { // eslint-disable-line

            dispatch(actions.app.setUserReviewStatus({ status:false }));
            return dispatch(actions.app.setAppReviewStatus({ status })).catch(safeWarning);

        },
        push: Push
    }
)(WelcomePage);
