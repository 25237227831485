const React = require('react');
const T = require('prop-types');

const { useState, useEffect } = React;

const { default: List } = require('@mui/material/List');
const { default: ListItemIcon } = require('@mui/material/ListItemIcon');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: Grow } = require('@mui/material/Grow');
const { default: Popper } = require('@mui/material/Popper');
const { default: Paper } = require('@mui/material/Paper');

const { default: RightArrow } = require('@mui/icons-material/KeyboardArrowRight');

const AppMenuItemComponent = require('./AppMenuItemComponent');
const { default: Styled } = require('styled-components');

// React runtime PropTypes
const AppMenuItemPropTypes = {
    name: T.string.isRequired,
    link: T.string,
    Icon: T.elementType,
    items: T.array,
    inset: T.bool,
    disabled: T.bool,
    onClick: T.func,
    closeMainMenuFunction: T.func
};

const StyledList = Styled(List)`
    width: 200px !important;
     ${(props) => props.theme.breakpoints.between( props.theme.breakpoints.values.mobileL,props.theme.breakpoints.values.sm)} {
        width: 180px !important;
    }
    ${(props) => props.theme.breakpoints.between( props.theme.breakpoints.values.mobileM,props.theme.breakpoints.values.mobileL)} {
        width: 160px !important;
    }
    ${(props) => props.theme.breakpoints.down( props.theme.breakpoints.values.mobileM)} {
        width: 145px !important;
    }
`;

const AppMenuItemComponentStyled = Styled(AppMenuItemComponent)`
    height: 48px;

    &.Mui-disabled {
        opacity: 1;

        & .MuiListItemText-root {
            & .MuiTypography-root {
                color: rgb(70, 17, 169);
            }
        }
    }

    &.active {
        background: rgb(70, 17, 169);
        color: #fff;

        & .MuiListItemIcon-root,
        & .menuItemIcon {
            color: #fff;
        }

        & .MuiListItemText-root {
            & .MuiTypography-root {
                color: #fff;
            }
        }
    }

    ${(props) => {

        return `
            @media (max-width: ${props.theme.breakpoints.values.mobileL}px) {
                & .MuiListItemIcon-root,
                & .menuItemIcon {
                    font-size: 0.8rem;
                }

                & .MuiListItemText-root {
                    & .MuiTypography-root {
                        font-size: 0.8rem;
                    }
                }
            }
        `;
    }}
`;

const AppMenuItem = (props) => {

    const { name, link,onClick, closeMainMenuFunction, disabled = false, Icon,inset = false, items = [] } = props;

    const isExpandable = items && items.length > 0;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(Boolean(anchorEl));
    const handleClick = (event) => {

        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    useEffect(() => {

        setIsOpen(Boolean(anchorEl));
    }, [anchorEl] );

    const handleListKeyDown = (event) => {

        if (event.currentTarget.lastElementChild === event.target){

            setAnchorEl(null);
        }
    };

    const MenuItemRoot = (
        <AppMenuItemComponentStyled disabled={disabled} link={link} onClick={(event) => {

            handleClick(event);
            if (onClick && typeof onClick === 'function'){

                onClick();
            }

            if (!isExpandable){
                closeMainMenuFunction();
            }
        }}>
            {/* Display an icon if any */}
            {!!Icon && (
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
            )}
            <ListItemText primary={name} inset={inset} />
            {/* Display the expand menu if the item has children */}
            {isExpandable && <RightArrow />}
        </AppMenuItemComponentStyled>
    );

    const MenuItemChildren = isExpandable ? (
        <Popper
            placement="right-start"
            disablePortal={true}
            open={isOpen}
            anchorEl={anchorEl}
            transition
            // modifiers={{
            //     flip: {
            //         enabled: true
            //     },
            //     preventOverflow: {
            //         enabled: false,
            //         boundariesElement: 'scrollParent'
            //     },
            //     arrow: {
            //         enabled: false
            //     }
            // }}
        >
            {({ TransitionProps, placement }) => (

                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: 'left top' }}
                >
                    <Paper>
                        <StyledList component="div" disablePadding onBlur={handleListKeyDown} >
                            {items.map((item, index) => (

                                <AppMenuItem
                                    closeMainMenuFunction={closeMainMenuFunction}
                                    {...item}
                                    key={index} />
                            ))}
                        </StyledList>
                    </Paper>
                </Grow>
            )}
        </Popper>
    ) : null;

    return (
        <>
            {MenuItemRoot}
            {isOpen ? MenuItemChildren : null}
        </>
    );
};

AppMenuItem.propTypes = AppMenuItemPropTypes;

module.exports = AppMenuItem;
