const React = require('react');
const T = require('prop-types');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: NotificationsIcon } = require('@mui/icons-material/Notifications');
const UnreadMessagesBadge = require('../UnreadMessagesBadge');
const { default: Classes } = require('./styles.scss');
const { default: styled } = require('styled-components');

const StyledIconButton = styled(IconButton)`
  &:focus-visible {
      background-color: rgba(255, 255, 255, 0.4);
  }
`;

const NotificationsButton = (props) => {

    const {
        unreadCount,
        color,
        dispatch, // eslint-disable-line no-unused-vars
        ...other
    } = props;

    return (
        <StyledIconButton
            {...other}
            aria-label='view notifications'
            size='large'
        >
            <NotificationsIcon color={color} />
            <UnreadMessagesBadge className={Classes.badge} numUnreads={unreadCount} />
        </StyledIconButton>
    );
};

NotificationsButton.propTypes = {
    color: T.string,
    unreadCount: T.number,
    dispatch: T.func // Comes from redux
};

module.exports = NotificationsButton;
